import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import LsqSwitch from "../../components/LsqSwitch";
import CustomInput from "../../components/CustomInput";
import PublishView from "../../components/PublishView";
import { teamIdsToTreeList, teamListBuilder } from "../../components/TeamAssignmentMultiselectTreeDropdown/TeamAssignmentMultiselectTreeDropdownUtils";
import { useForm } from "react-hook-form";
import { ReactComponent as Dashborad } from "../../assets/svgIcons/dashboard_filled.svg";
import { publishHomepage } from "../../reducers/homePageSlice";
import { generateTeamAssignmentPostData } from "../../utils/casaUtils";
import { changeToastMessage } from "../../reducers/previewPaneSlice";
import {
  casaConstants,
  globalConstantValues,
  homepageConstants,
  stringConstants,
  toastMessageConstants,
} from "../../constants/globalConstant";
import { hierarchyToLinear } from "../../utils/globalUtils";
import TeamAssignmentMultiselectTreeDropdown from "../../components/TeamAssignmentMultiselectTreeDropdown";
import useToast from "../../hooks/useToast"
import { API_GET_PREVIEW_PANE_THUMBNAIL_UPLOAD_URL, API_IMAGE_UPLOAD } from "../../api/configurationScreenServices";

export default function PublishHomepage({
  publishState,
  setPublishState,
  status,
  previewPaneScreenshot,
}) {
  const dispatch = useDispatch();
  const [published, setPublished] = useState(false);
  const previewPaneToast = useToast(changeToastMessage)
  const teamHierarchy = useSelector((state) => state.homepage.teams);
  const teamDashboards = useSelector((state) => state.homepage.teamDashboards);
  const { isVersionEnabled } = useSelector((state) => state.homepage);
  const { hasDraftChanges } = useSelector((state) => state.previewPane);
  const publish = useSelector(
    (state) => state.homepage.publish
  );
  const {isPublishLoading, isPublished} = publish
  const { t } = useTranslation();

  const teamLinear = useMemo(() => {
    return hierarchyToLinear(teamHierarchy)
  }, [teamHierarchy])

  const teamIds = publishState.data?.teamIds;
  const dashboardName = publishState.data?.dashboardName;
  const homepageId = publishState.data?.selectedHomepageId;
  const isDefault = publishState.data?.isDefault;
  
  const defaultValues = {
    projectTitle: dashboardName.trim(),
    isDefault: false,
    teamAssigned: {},
    createVersion: false,
  };
  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
    getValues,
  } = useForm({ defaultValues });

  const isDefaultEnabled = watch("isDefault");
  const watchTeamAssigned = watch("teamAssigned");

  const isPublishDisabled = () => {
    if(isPublishLoading || isPublished) return true;
   return (isDefaultEnabled === false &&
      Object.keys(watchTeamAssigned).length === 0)
  };

  const onDismissPublishView = () => {
    setPublishState({ state: false, data: null });
  };

  const uploadPreviewPaneScreenshotToS3 = async () => {
    const uploadURLResponse = await API_GET_PREVIEW_PANE_THUMBNAIL_UPLOAD_URL(homepageId)
    const { url , fields} = uploadURLResponse.data
    const response = await fetch(previewPaneScreenshot)
    const blob = await response.blob()
    const file = new File([blob], "thumbnail" ,{type: blob.type })
    API_IMAGE_UPLOAD(url, fields, file)
  }

  const isPublishClicked = (data) => {
    const existingTeamAssigned = {
      isDefault: publishState.data?.isDefault,
      teamAssigned: teamIds,
    };
    const currentTeamAssigned = {
      ...data,
    };
    if (isDefaultEnabled) {
      currentTeamAssigned.teamAssigned = {};
    }
    const createVersion = data.createVersion;

    const dashboardUpdatedName = getValues("projectTitle");

    const teamAssigmentPostData = generateTeamAssignmentPostData(
      existingTeamAssigned,
      currentTeamAssigned,
      dashboardUpdatedName.trim(),
    );

    dispatch(
      publishHomepage({
        homepageId,
        postBody: teamAssigmentPostData,
        createVersion,
      })
    ).then(({ type, payload}) => {
      if (type.includes("rejected")) {
        const message = payload || toastMessageConstants.SOMETHING_WENT_WRONG
        previewPaneToast.error(message)
      } else {
        uploadPreviewPaneScreenshotToS3()
        setPublished(true);
        setTimeout(() => {
          setPublishState({ state: false, data: null });
        }, 2000);
      }
    });
  };

  const defaultHomepages = teamDashboards?.defaultDashboards?.homepages;
  const isDefaultSwitchDisabled =
    defaultHomepages?.length >=
      globalConstantValues.MAX_NUMBER_OF_TEAM_DASHBOARD &&
    !defaultHomepages?.includes(parseInt(homepageId));


  useEffect(() => {
    if(status !== casaConstants.DRAFT){
      setValue("teamAssigned", teamIdsToTreeList(teamIds, teamLinear));
      setValue("isDefault", isDefault);
    }
  }, [teamIds]);

  const teamTreeList = teamListBuilder(
    teamHierarchy,
    teamDashboards.teamsDashboards,
    homepageId
  );

  return (
    <PublishView
      published={published}
      publishViewIcon={<Dashborad/>}
      publishViewTitle={t(homepageConstants.PUBLISH_HOMEPAGE)}
      publishSuccessBoxIcon={<Dashborad/>}
      publishSuccessBoxTitle={t(getValues("projectTitle"))}
      publishSuccessBoxMessage={t(
        homepageConstants.HOMEPAGE_PUBLISHED_SUCCESSFULLY
      )}
      onPublishClicked={handleSubmit(isPublishClicked)}
      isPublishDisabled={isPublishDisabled}
      onDismissPublishView={onDismissPublishView}
    >
      <CustomInput
        fieldName="projectTitle"
        errors={errors}
        control={control}
        label={t("Enter Homepage Title")}
        placeholder="Type Here"
        maxLength={30}
        data-testid="project-title"
        isMandatory
      />
      {isVersionEnabled && hasDraftChanges && (
        <LsqSwitch
          control={control}
          label={stringConstants.SAVE_VERSION}
          switchName="createVersion"
        />
      )}
      <div className="flex flex-column flex-start gap-4 w-full">
        <LsqSwitch
          control={control}
          label="Set as default homepage"
          switchName="isDefault"
          disabled={isDefaultSwitchDisabled}
        />
        {!isDefaultEnabled && (
          <TeamAssignmentMultiselectTreeDropdown
            control={control}
            errors={errors}
            fieldName="teamAssigned"
            teamHierarchy={teamHierarchy}
            teamTreeList={teamTreeList}
          />
        )}
      </div>
    </PublishView>
  );
}
