import React from "react";
import styles from "./styles.module.css";
import Text from "../Text";
import { getStatusColor } from "../../utils/globalUtils";

const TeamsViewMenuCard = ({ menu, cardClickHandler }) => {
  const statusColorClass = styles[getStatusColor(menu?.status)];
const statusStyleClass = styles["status-style"];
const combinedClass = `${statusColorClass} ${statusStyleClass}`;

  return (
    <div
      className={`cursor-pointer ${styles["menu-card-container"]}`}
      data-testid="teams-view-menu-card"
      onClick={cardClickHandler}
    >
      <div
        className={`flex align-items-center gap-1 p-3 w-full h-full ${styles["menu-card"]}`}
      >
        <div
          className={combinedClass}
        ></div>
        <Text type="T3B" color={"#314361"} className={`ellipsis w-full`}>
          {menu?.name}
        </Text>
      </div>
    </div>
  );
};

export default TeamsViewMenuCard;
