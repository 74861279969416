import React, { useState, useEffect } from "react";
import styles from "../WidgetList/styles.module.css";
import { useSelector, useDispatch } from "react-redux";
import { placeholderConstants } from "../../constants/globalConstant";
import { changeSelectedWidgetListTab } from "../../reducers/widgetTemplateListSlice";
import { ReactComponent as SearchBar } from "../../assets/svgIcons/search.svg";
import CustomInputV2 from "../CustomInputV2";
import { ReactComponent as Cross } from "../../assets/svgIcons/cross.svg";
import ToggleTab from "../ToggleTab";
import WidgetListFactory from "./WidgetListFactory";
import { widgetTabs } from "../../utils/widgetUtils";

const WidgetList = () => {
  const dispatch = useDispatch();
  const [isSearchBarClicked, setIsSearchBarClicked] = useState(false);
  const [searchText, setSearchText] = useState("");
  const selectedHomepageId = useSelector(
    (state) => state?.homepage?.selectedHomepageId
  );
  const widgetTemplateList = useSelector((state) => state.widgetTemplateList);
  const { selectedWidgetListTab } = widgetTemplateList;
  const _selectedTab = widgetTabs.filter(
    (tab) => tab.key === selectedWidgetListTab
  )[0];
  const [activeTab, setActiveTab] = useState(_selectedTab);

  const onTabChange = (value) => {
    setActiveTab(value);
    dispatch(changeSelectedWidgetListTab(value));
  };

  const searchBarClickHandler = () => {
    setIsSearchBarClicked(true);
  };

  const cancelHandler = () => {
    setIsSearchBarClicked(false);
    setSearchText("");
  };

  useEffect(() => {
    setSearchText("");
    setIsSearchBarClicked(false);
  }, [selectedHomepageId]);

  return (
    <div
      className={`flex flex-column w-full h-full ${styles["widget-library"]} coach-widget-panel`}
    >
      <div
        className={`flex align-items-center justify-content-between w-full py-3 ${styles["widget-library-header"]}`}
      >
        <div
          className={`${styles["widget-library-sub-header"]} justify-content-between`}
        >
          {isSearchBarClicked ? (
            <CustomInputV2
              LeftIcon={SearchBar}
              RightIcon={Cross}
              rightIconOnClick={cancelHandler}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder={placeholderConstants.SEARCH_WIDGETS}
              className={`${styles["search-bar"]}`}
              autoFocus={true}
            />
          ) : (
            <div className="flex justify-content-between align-items-center gap-2 pl-3 w-11 gap-3">
              <SearchBar
                style={{ transform: "scale(1.4)", cursor: "pointer" }}
                onClick={searchBarClickHandler}
                data-testid="widgetlist-search-icon"
              />
              <ToggleTab
                tabs={widgetTabs}
                activeTab={activeTab}
                setActiveTab={onTabChange}
              />
            </div>
          )}
        </div>
      </div>
      <WidgetListFactory searchText={searchText} tab={activeTab.key} />
    </div>
  );
};

export default WidgetList;
