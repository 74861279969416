import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import Wifi from "../../assets/svgIcons/wifi.svg";
import Cellular from "../../assets/svgIcons/cellular.svg";
import Battery from "../../assets/svgIcons/battery.svg";
import Hamburger from "../../assets/svgIcons/Menu.svg";
import Notifications from "../../assets/svgIcons/notification.svg";
import LeadsNearMe from "../../assets/svgIcons/nearme.svg";
import { ReactComponent as DragHandleIcon } from "../../assets/svgIcons/drag_handle.svg";
import CustomButton from "../../components/CustomButton";
import { ReactComponent as Add } from "../../assets/svgIcons/lsq_add.svg";
import Row from "./Row";
import PropTypes from "prop-types";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { WidgetFactory } from "../../widgetPreview/WidgetFactory";
import { useSelector } from "react-redux";
import {
  doesSectionContainStandaloneWidget,
  isWidgetEdgeToEdge,
} from "../../utils/previewPaneUtils";
import { homepageConstants } from "../../constants/globalConstant";
import usePreviewPane from "../../hooks/usePreviewPane";

export default function MobileScreen({ previewPaneData, setSelectedSection }) {
  const { loading, widgetConfiguration } = useSelector(
    (state) => state.previewPane
  );
  const { t } = useTranslation();
  const { addNewSection } = usePreviewPane();
  const getCurrentTime = () => {
    return new Date().toLocaleTimeString("en-US", {
      hour12: false,
      hour: "numeric",
      minute: "numeric",
    });
  };

  function getStyle(style, snapshot) {
    if (!snapshot.isDropAnimating) {
      return style;
    }
    const { moveTo, curve, duration } = snapshot.dropAnimation;
    // move to the right spot
    const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`;
    // add a bit of turn for fun
    const scaleDown = "scale(0.1)";
    // patching the existing style
    return {
      ...style,
      transform: `${translate} ${scaleDown}`,
      transition: `all ${curve} ${duration}s`,
    };
  }

  const { sections, selectedSection } = previewPaneData;

  return (
    <div
      id="mobile-preview"
      className={`mobile-screen ${
        loading && styles["disabled"]
      } coach-mobile-panel`}
    >
      <div className={styles["status-bar"]}>
        <div className={styles["time"]}>{getCurrentTime()}</div>
        <div className={styles["status-bar-content"]}>
          <img alt="wifi" src={Wifi} />
          <img alt="cellular" src={Cellular} />
          <img alt="battery" src={Battery} />
        </div>
      </div>
      <div className={styles["title-bar"]}>
        <div
          style={{ flex: "1" }}
          className={`flex h-full justify-content-center align-items-center`}
        >
          <img alt="hamburger" src={Hamburger} />
        </div>
        <div
          style={{ flex: "6", padding: "0px 3%" }}
          className={`flex h-full align-items-center justify-content-between`}
        >
          <div className={styles["home-text"]}>{t("Home")}</div>
          <div className="flex align-items-center gap-1">
            <img alt="notifications" src={Notifications} />
            <img alt="lead near me" src={LeadsNearMe} />
          </div>
        </div>
      </div>
      <Droppable type="Sections" droppableId="Sections">
        {(provided) => {
          return (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={styles["sections-screen"]}
            >
              {sections.map((section, index) => {
                return (
                  <Draggable
                    isDragDisabled={false}
                    index={index}
                    key={section.sectionId}
                    draggableId={String(section.sectionId)}
                  >
                    {(provided, snapshot) => {
                      const isEdgeToEdge = isWidgetEdgeToEdge(
                        section,
                        widgetConfiguration
                      );
                      return (
                        <div
                          data-testid="section-drag"
                          onMouseDown={() => setSelectedSection(index)}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={styles["section-drag"]}
                          key={section.sectionId}
                          style={
                            snapshot.draggingOver === "delete"
                              ? getStyle(
                                  provided.draggableProps.style,
                                  snapshot
                                )
                              : {
                                  ...provided.draggableProps.style,
                                }
                          }
                        >
                          <div
                            {...provided.dragHandleProps}
                            className={styles["section-drag-handle"]}
                          >
                            <DragHandleIcon />
                          </div>
                          <Droppable
                            key={section.sectionId}
                            direction="vertical"
                            type="widgets"
                            droppableId={section.sectionId}
                            isDropDisabled={doesSectionContainStandaloneWidget(
                              section
                            )}
                          >
                            {(provided2) => {
                              return (
                                <div
                                  key={section.sectionId}
                                  {...provided2.droppableProps}
                                  ref={provided2.innerRef}
                                  style={{
                                    overflowY: "auto",
                                    minHeight: `${
                                      section.rows.length > 0 ? "auto" : "8rem"
                                    }`,
                                    boxShadow:
                                      selectedSection === index
                                        ? "0px 0px 9px rgba(0, 132, 255, 0.54)"
                                        : "",
                                    overflow: "auto",
                                    padding: `${
                                      doesSectionContainStandaloneWidget(
                                        section
                                      )
                                        ? "0"
                                        : "2.5% 0"
                                    }`,
                                    width: `${isEdgeToEdge ? "100%" : "94%"}`,
                                    borderRadius: `${
                                      isEdgeToEdge ? "0px" : "12px"
                                    }`,
                                  }}
                                  className={styles["section"]}
                                >
                                  {section.rows?.length > 0 &&
                                    section.rows.map((row, rowIndex) => {
                                      return (
                                        <div
                                          className="flex flex-column w-full align-items-center gap-1"
                                          key={rowIndex}
                                        >
                                          {row.widgets[0].type === "half" ? (
                                            <Row
                                              index={rowIndex}
                                              id={row.id}
                                              length={row.widgets.length}
                                              key={row.id}
                                            >
                                              {row.widgets.map(
                                                (widget, index) => {
                                                  return (
                                                    <WidgetFactory
                                                      key={widget.widgetId}
                                                      index={index}
                                                      widgetInformation={widget}
                                                    />
                                                  );
                                                }
                                              )}
                                            </Row>
                                          ) : (
                                            <WidgetFactory
                                              key={row.widgets[0].widgetId}
                                              index={rowIndex}
                                              widgetInformation={row.widgets[0]}
                                            />
                                          )}
                                          {section.rows?.length >
                                            rowIndex + 1 && (
                                            <hr
                                              style={{
                                                border: `1px solid #ECEDF0`,
                                                width: "95%",
                                              }}
                                            />
                                          )}
                                        </div>
                                      );
                                    })}
                                  {provided2.placeholder}
                                </div>
                              );
                            }}
                          </Droppable>
                        </div>
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
              <div className={styles["add-new-section"]}>
                <CustomButton
                  data-testid="add-new-section-btn"
                  onClick={() => addNewSection()}
                  varient="outline"
                  label={t(homepageConstants.ADD_NEW_SECTION)}
                  icon={<Add />}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          );
        }}
      </Droppable>
    </div>
  );
}

MobileScreen.propTypes = {
  previewPaneData: PropTypes.object,
  setSelectedSection: PropTypes.func,
  setShowDeletePlaceholder: PropTypes.func,
};
