import React ,{memo} from 'react'
import PropTypes from "prop-types"

const arePropsEqual = (oldKey, newKey) => {
  return oldKey.children.key === newKey.children.key
}
function RightPanel({children}) {
  return (
    <div
      style={{ backgroundColor: "var(--N20)" }}
      className='flex h-full w-full justify-content-center align-items-center'>
        {children}
    </div>
  )
}

export default memo(RightPanel, arePropsEqual)

RightPanel.propTypes = {
  children: PropTypes.element
}