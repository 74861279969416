import React from "react";
import styles from "./styles.module.css";
import CustomButton from "../CustomButton";
import { casaConstants } from "../../constants/globalConstant";

export default function ConfirmDialog({
  setState,
  illustration,
  displayText,
  buttonLabel,
  clickListener
}) {

  const confirmHandler = (e) => {
    setState({ state: false })
    e.stopPropagation()
  }
  return (
    <div className={`absolute flex flex-column gap-3 ${styles["confirm-box"]}`}>
      <div className="flex flex-column justify-content-end align-items-center h-full w-full gap-3">
        {illustration}
        <div
          className={`flex flex-column justify-content-center align-items-center gap-1 text-center white-space-normal`}
        >
          {displayText}
        </div>
      </div>
      <div
        className={`flex align-items-center flex-row gap-2 justify-content-center`}
      >
        <CustomButton
          varient="outline"
          label={casaConstants.CANCEL}
          data-testid="cancel-btn"
          onClick={confirmHandler}
          style={{ cursor: "pointer", fill: "#314361" }}
        />
        <CustomButton
          varient={`capitalize filled`}
          data-testid="confirm-btn"
          label={buttonLabel}
          onClick={clickListener}
        />
      </div>
    </div>
  );
}
