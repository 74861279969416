import React, { Fragment, useEffect, useState} from "react";
import MobileScreenTemplate from "../../views/Templates/MobileScreenTemplate";
import styles from "./styles.module.css";
import CustomButton from "../../components/CustomButton";
import { ReactComponent as Previous } from "../../assets/svgIcons/back.svg";
import ListView from "../../views/Templates/ListView";
import analytics from "../../utils/analytics";
import { useDispatch, useSelector } from "react-redux";
import { getTemplateLayout } from "../../reducers/templatesSlice";

const LSQCarousel = ({
  items = [],
  setSelectedItemIndex,
  selectedItemIndex,
}) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [animation, setAnimation] = useState(0);
  const dispatch = useDispatch();
  const isTemplateLayoutLoading = useSelector((state) => state.templates.isTemplateLayoutLoading);

  useEffect(() => {
    if(items.length > 0 && !items[activeItemIndex].sections){
      dispatch(getTemplateLayout(items[activeItemIndex].templateId))
    }
  } , [activeItemIndex])

  return (
    <div className={`flex w-full h-full`}>
      <ListView
        items={items}
        activeItemIndex={activeItemIndex}
        setActiveItemIndex={setActiveItemIndex}
      ></ListView>
      <div
        className={`flex w-full h-full flex-column align-items-center gap-1 justify-content-center`}
      >
        <div
          style={{ columnGap: "3rem" }}
          className="flex flex-row w-full align-items-center justify-content-center mt-2"
        >
          {items.map((item, index) => {
            return (
              <Fragment key={item.templateId}>
                {index === activeItemIndex && (
                 <MobileScreenTemplate
                    key={activeItemIndex}
                    className={`${styles["lsq-carousel"]} ${
                      animation === 1
                        ? styles["lsq-carousel-left"]
                        : animation === 2 && styles["lsq-carousel-right"]
                    }`}
                    sections={item.sections || []}
                    isSectionsLoading = {isTemplateLayoutLoading}
                  />
                )}
              </Fragment>
            );
          })}
        </div>
        <div className="flex flex-direction-row gap-2 justify-content-center align-items-center">
          <CustomButton
            varient="text"
            icon={<Previous />}
            disabled={activeItemIndex === 0 || items.length === 0}
            label=""
            onClick={() => {
              if (activeItemIndex === 0) return;
              setActiveItemIndex((prev) => prev - 1);
              setAnimation(1);
            }}
            data-testid="left-btn"
          />
          <CustomButton
            varient="outline"
            disabled={items.length === 0}
            label="Apply"
            data-testid="apply-btn"
            onClick={() => {
              if (selectedItemIndex === activeItemIndex) {
                setSelectedItemIndex(-1);
              } else {
                setSelectedItemIndex(activeItemIndex);
                analytics.sendEvent(`selected ${activeItemIndex + 1} template`);
              }
            }}
          />
          <CustomButton
            varient="text"
            icon={<Previous style={{transform : "rotate(180deg)"}}/>}
            disabled={
              activeItemIndex === items.length - 1 || items.length === 0
            }
            label=""
            onClick={() => {
              if (activeItemIndex === items.length - 1) return;
              setActiveItemIndex((prev) => prev + 1);
              setAnimation(2);
            }}
            data-testid="right-btn"
          />
        </div>
      </div>
    </div>
  );
};

export default LSQCarousel;
