import React, { useState } from "react";
import { ReactComponent as LSQMore } from "../../assets/svgIcons/lsq_more.svg";
import styles from "./styles.module.css";
import Text from "../Text/index.js";
import { useOnClickOutside } from "../../hooks/useOnClickOutside.js";
import {
  dashboardCardConstants,
  casaConstants,
} from "../../constants/globalConstant";
import ConfirmDialog from "../Modal/ConfirmDialog";
import { ReactComponent as DeleteIllustration } from "../../assets/illustrations/delete_illustration.svg";
import { ReactComponent as UpdateIllustration } from "../../assets/illustrations/update_illustration.svg";
import useHomepageCard from "../../hooks/useHomepageCard";

export const DashboardCardMenu = ({ items, teamId, homepage, setDialog }) => {
  const [popUp, setPopUp] = useState(false);
  const menuRef = useOnClickOutside(() => setPopUp(false));
  const {
    publishHomepageClicked,
    unpublishHomepageClicked,
    deleteHomepageClicked,
    unassignHompeageClicked,
    cloneHomepageClicked,
  } = useHomepageCard();
  const sourceClicked = dashboardCardConstants.DASHBOARD_CARD;

  const updateDashboard = () => {
    const status = homepage.status;
    if (status === dashboardCardConstants.UNPUBLISHED) {
      publishHomepageClicked(homepage, sourceClicked);
    } else {
      setDialog({
        state: true,
        dialogBox: dialogBox(casaConstants.UNPUBLISH),
      });
    }
  };

  const unpublishDashboard = () => {
    unpublishHomepageClicked(homepage, sourceClicked);
    setDialog({ state: false, dialogBox: {} });
  };

  const deleteHandler = () => {
    deleteHomepageClicked(homepage, sourceClicked);
    setDialog({ state: false, dialogBox: {} });
  };

  const unAssignHandler = () => {
    unassignHompeageClicked(homepage, teamId, sourceClicked);
    setDialog({ state: false, dialogBox: {} });
  };

  const dialogBox = (option) => {
    let displayIcon = <></>;
    let actionLabel = "";
    let clickListener;
    let dashboardName = homepage.homepageName;
    switch (option) {
      case casaConstants.UNPUBLISH:
        {
          displayIcon = <UpdateIllustration />;
          actionLabel = casaConstants.UNPUBLISH;
          clickListener = unpublishDashboard;
        }
        break;
      case casaConstants.DELETE:
        {
          displayIcon = <DeleteIllustration />;
          actionLabel = casaConstants.DELETE;
          clickListener = deleteHandler;
        }
        break;
      case casaConstants.UNASSIGN:
        {
          displayIcon = <UpdateIllustration />;
          actionLabel = casaConstants.UNASSIGN;
          clickListener = unAssignHandler;
        }
        break;
      default:
        break;
    }
    const ConfirmDialogHandler = (e) => {
      clickListener();
      e.stopPropagation();
    };
    return (
      <ConfirmDialog
        setState={setDialog}
        illustration={displayIcon}
        buttonLabel={actionLabel}
        displayText={
          <>
            <Text type="heading" className={`capitalize`} color="#0A1F43">
              {`${actionLabel} ${dashboardName}`}
            </Text>
            <Text type="sub-heading" color="#0A1F43">
              {`Are you sure you want to ${actionLabel} ?`}
            </Text>
          </>
        }
        clickListener={ConfirmDialogHandler}
      />
    );
  };

  const clickHandler = (label) => {
    switch (label) {
      case casaConstants.CLONE:
        {
          cloneHomepageClicked(homepage, sourceClicked);
        }
        break;
      case casaConstants.PUBLISH:
      case casaConstants.UNPUBLISH:
        updateDashboard();
        break;
      case casaConstants.DELETE:
        setDialog({
          state: true,
          dialogBox: dialogBox(casaConstants.DELETE),
        });
        break;
      case casaConstants.UNASSIGN:
        setDialog({
          state: true,
          dialogBox: dialogBox(casaConstants.UNASSIGN),
        });
        break;
    }
  };

  const menuItemHandler = (e, label) => {
    setPopUp(false);
    clickHandler(label);
    e.stopPropagation();
  };

  return (
    <div ref={menuRef}>
      <div
        onClick={() => {
          setPopUp((prev) => !prev);
        }}
        data-testid="dashboard-menu"
        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        className="coach-dashboard-card-menu-icon"
      >
        <LSQMore
          style={{ transform: "scale(1.3)" }}
          className={styles["more-icon"]}
        />
      </div>
      <div className={`${styles["container"]}`} data-testid="menu-items">
        {popUp ? (
          <div className={`${styles["menu_container"]}`}>
            {items.map(({ label }) => {
              return (
                <div
                  className={`${styles["menu_item"]} capitalize`}
                  onClick={(e) => menuItemHandler(e, label)}
                  key={label}
                  data-testid="menu-item"
                >
                  <Text type="T4" color="#768196">
                    {label}
                  </Text>
                </div>
              );
            })}
          </div>
        ) : (
          <> </>
        )}
      </div>
    </div>
  );
};
