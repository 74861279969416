import React from "react";
import styles from "./styles.module.css";
import Wifi from "../../assets/svgIcons/wifi.svg";
import Cellular from "../../assets/svgIcons/cellular.svg";
import Battery from "../../assets/svgIcons/battery.svg";
import { ReactComponent as Cross } from "../../assets/svgIcons/cross.svg";
import { ReactComponent as Chevron_Down } from "../../assets/svgIcons/lsq_chevron_down.svg";
import { ReactComponent as Date_Time } from "../../assets/svgIcons/lsq_date_time.svg";
import Text from "../../components/Text";
import { getCurrentTime } from "../../utils/globalUtils";

export default function MobileDynamicFormThemePreview({ colorPalette }) {
  const formFields = [
    {
      label: "Associate Lead",
      value: "Aishwarya",
      icon: (
        <Chevron_Down
          color={colorPalette.neutral.N600}
          className={`${styles["mobile_preview_icons"]}`}
        ></Chevron_Down>
      ),
      required: true,
    },
    {
      label: "Opportunity Name",
      value: "Akhilesh Kondam",
      required: true,
    },
    {
      label: "Expected Deal Size",
      placeholder: "Choose",
      required: false,
    },
    {
      label: "Expected Closure Date",
      value: "07 Feb 2022, 10:47 AM",
      required: false,
      icon: (
        <Date_Time
          color={colorPalette.neutral.N600}
          className={`${styles["mobile_preview_icons"]}`}
        ></Date_Time>
      ),
    },
  ]; // this is dummy data to show the static page with theming

  const tabValues = [
    {
      backgroundColor: colorPalette.secondary.S800,
      border: colorPalette.secondary.S800,
      textColor: colorPalette.neutral.N0,
      tab: "1",
    },
    {
      backgroundColor: colorPalette.neutral.N20,
      border: `1px solid ${colorPalette.secondary.S800}`,
      textColor: colorPalette.secondary.S800,
      tab: "2",
    },
    {
      backgroundColor: colorPalette.neutral.N20,
      border: `1px solid ${colorPalette.neutral.N80}`,
      textColor: colorPalette.neutral.N80,
      tab: "3",
    },
    {
      backgroundColor: colorPalette.neutral.N20,
      border: `1px solid ${colorPalette.neutral.N80}`,
      textColor: colorPalette.neutral.N80,
      tab: "4",
    },
    {
      backgroundColor: colorPalette.neutral.N20,
      border: `1px solid ${colorPalette.neutral.N400}`,
      textColor: colorPalette.neutral.N400,
      tab: "5",
    },
  ]; // this is dummy data to show the static page with theming

  return (
    <div className={`mobile-screen`}>
      <div className={styles["status-bar"]}>
        <div className={styles["time"]}>{getCurrentTime()}</div>
        <div className={styles["status-bar-content"]}>
          <img alt="wifi" src={Wifi} />
          <img alt="cellular" src={Cellular} />
          <img alt="battery" src={Battery} />
        </div>
      </div>
      <div className="flex flex-column h-full overflow-hidden">
        <div className="flex align-items-center p-3 gap-2 bg-white w-full">
          <Cross fill={colorPalette.neutral.N400}></Cross>
          <div className="flex flex-column">
            <Text type="title" color={colorPalette.neutral.N400}>
              {"Activity Fields"}
            </Text>
            <Text type="sub-heading" color={colorPalette.neutral.N200}>
              {"Tab 2"}
            </Text>
          </div>
        </div>
        <div className="flex py-3 px-2 align-items-center">
          {tabValues?.map((value) => {
            return (
              <div
                key={value.tab}
                className="flex justify-content-center align-items-center"
              >
                <div
                  className="flex justify-content-center align-items-center border-round p-1"
                  style={{
                    background: value.backgroundColor,
                    border: value.border,
                    height: "1.7rem",
                    width: "1.7rem",
                  }}
                >
                  <Text type="mobile-numbers" color={value.textColor}>
                    {value.tab}
                  </Text>
                </div>
                <hr
                  style={{
                    border: `0.5px dashed ${colorPalette.neutral.N60}`,
                    width: "2.8rem",
                  }}
                ></hr>
              </div>
            );
          })}
        </div>
        <div className="flex h-full px-2 gap-1 flex-column overflow-hidden">
          <div className="flex h-full flex-column border-round-top bg-white p-2 gap-2">
            <Text type="title" color={colorPalette.neutral.N600}>
              {"Section 2"}
            </Text>
            <hr
              style={{
                border: `0.5px solid ${colorPalette.neutral.N20}`,
                width: "100%",
              }}
            ></hr>
            <div className="flex flex-column">
              <Text type="title" color={colorPalette.neutral.N600}>
                {"Form 12"}
              </Text>
              <Text type="sub-heading" color={colorPalette.secondary.S800}>
                {"Tab 1>Section 1"}
              </Text>
            </div>
            <div className="flex flex-column gap-2 mt-1">
              {formFields?.map((field) => {
                return (
                  <span key={formFields.label} className={`relative`}>
                    <label
                      className={`${styles["custom-dropdown-field-label"]} flex align-items-center`}
                    >
                      <Text color={colorPalette.neutral.N200} type="T5">
                        {field.label}
                      </Text>
                      {field.required && (
                        <span style={{ color: "#E02020", fontSize: "0.8rem" }}>
                          *
                        </span>
                      )}
                    </label>
                    <div
                      className={`flex justify-content-between align-items-center ${styles["custom-dropdown-input-Text"]}`}
                      style={{
                        border: `1px solid ${colorPalette.neutral.N40}`,
                      }}
                    >
                      <Text
                        color={
                          field.value
                            ? colorPalette.neutral.N600
                            : colorPalette.neutral.N60
                        }
                        type="T4"
                      >
                        {field.value || field.placeholder}
                      </Text>
                      {field?.icon}
                    </div>
                  </span>
                );
              })}
            </div>
            <hr
              style={{
                border: `0.5px solid ${colorPalette.neutral.N20}`,
                width: "100%",
              }}
            ></hr>
            <div className="flex flex-column">
              <Text type="title" color={colorPalette.neutral.N600}>
                {"Form 12"}
              </Text>
              <Text type="sub-heading" color={colorPalette.secondary.S800}>
                {"Tab 1>Section 1"}
              </Text>
            </div>
            <div className="flex flex-column gap-2 mt-1">
              {formFields?.slice(0, 3).map((field) => {
                return (
                  <span key={formFields.label} className={`relative`}>
                    <label
                      className={`${styles["custom-dropdown-field-label"]} flex align-items-center`}
                    >
                      <Text color={colorPalette.neutral.N200} type="T5">
                        {field.label}
                      </Text>
                      {field.required && (
                        <span style={{ color: "#E02020", fontSize: "0.8rem" }}>
                          *
                        </span>
                      )}
                    </label>
                    <div
                      className={`flex justify-content-between align-items-center ${styles["custom-dropdown-input-Text"]}`}
                      style={{
                        border: `1px solid ${colorPalette.neutral.N40}`,
                      }}
                    >
                      <Text type="T4" color={colorPalette.neutral.N600}>
                        {field.value}
                      </Text>
                      {field?.icon}
                    </div>
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex p-2 bg-white w-full justify-content-between"
        style={{ boxShadow: "0px -2px 8px 0px #0000000F" ,gap:'8px'}}
      >
        <div
          className="flex justify-content-center align-items-center p-2 border-round w-full"
          style={{ border:`1px solid ${colorPalette.secondary.S800} `}}
        >
          <Text type="sub-heading-bold" color={colorPalette.secondary.S800}>
            {"Previous"}
          </Text>
        </div>{" "}
        <div
          className="flex justify-content-center align-items-center p-2 border-round w-full"
          style={{ backgroundColor: colorPalette.primary.B800 }}
        >
          <Text type="sub-heading-bold" color="var(--N0)">
            {"Save"}
          </Text>
        </div>
      </div>
    </div>
  );
}
