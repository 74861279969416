import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";
import { QuickLauncherIcons } from "../../assets/icons/QuickLauncherIcons";
import Text from "../../components/Text";

const LauncherGridView = ({ label = "", elementList = [] }) => {
  return (
    <div className={styles["launcher-container"]} data-testid = "grid-view">
      <div className="flex flex-row justify-content-between align-items-center mx-2 mt-1">
        <Text type="sub-heading-bold" color="var(--text-focused)">
          {label}
        </Text>
      </div>
      <div
        data-testid="launcher-grid-box"
        className={styles["launcher-grid-box"]}
      >
        {elementList.map((element) => {
          return (
            <div
              data-testid={`launcher-element-box-${element.id}`}
              key={element.id}
              className={styles["launcher-element-box"]}
            >
              <div className={`${styles["image"]}`}>
                <QuickLauncherIcons
                  style={{
                    width: "1.5rem",
                  }}
                  icon={element.icon.imgName}
                />
              </div>
              <Text className="ellipsis w-11 text-center" type="sub-heading" color="#314361">
              {element.displayName}
              </Text>
            </div>
          );
        })}
      </div>
    </div>
  );
};

LauncherGridView.propTypes = {
  index: PropTypes.number,
  elementList: PropTypes.array,
};

export default LauncherGridView;
