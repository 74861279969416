import {
  leadControlsConstants,
  leadControlsWidgetConstants,
} from "../constants/globalConstant";
import { ReactComponent as LeadCard } from "../assets/svgIcons/lsq_lead_card.svg";
import { ReactComponent as Page } from "../assets/svgIcons/lsq_change_image.svg";
import { ReactComponent as Link } from "../assets/svgIcons/link.svg";
import {
  API_GET_SIGNED_URL,
  API_GET_UPLOAD_URL,
  API_IMAGE_UPLOAD,
} from "../api/configurationScreenServices";
import cloneDeep from "lodash.clonedeep";

export const leadControlSideMenuOptions = [
  {
    tab: leadControlsConstants.CARD,
    displayName: leadControlsConstants.LEAD_CARD,
    view: leadControlsConstants.LEAD_CARD_VIEW,
    displayIcon: <LeadCard />,
    analyticsMessage: "lead_controls_card_view_click",
    "data-testid": "lead-card-tab",
  },
  {
    tab: leadControlsConstants.PAGE,
    displayName: leadControlsConstants.PAGE,
    view: leadControlsConstants.PAGE_VIEW,
    displayIcon: <Page />,
    analyticsMessage: "lead_controls_page_view_click",
    "data-testid": "lead-page-tab",
  },
  {
    tab: leadControlsConstants.ACTIONS,
    displayName: leadControlsConstants.ACTIONS,
    view: leadControlsConstants.ACTIONS_VIEW,
    displayIcon: <Link />,
    analyticsMessage: "lead_controls_actions_view_click",
    "data-testid": "lead-actions-tab",
  },
];

export const leadCardTabs = (disableLeadListTab) => {
  return [
    {
      value: leadControlsConstants.QUICK_VIEW_CARD,
      key: leadControlsConstants.QUICK_VIEW_CARD,
      disabled: false,
      id: "quick-view-card",
      "data-testid": "quick-view-card",
    },
    {
      value: leadControlsConstants.LEAD_LIST_CARD,
      key: leadControlsConstants.LEAD_LIST_CARD,
      disabled: disableLeadListTab,
      id: "lead-list-card",
      "data-testid": "lead-list-card",
    },
  ];
};

export const leadCardFields = [
  { key: 1, view: "empty" },
  { key: 2, view: "empty" },
  { key: 3, view: "empty" },
  { key: 4, view: "empty" },
];

export const leadCardActions = [
  { key: 1, view: "empty" },
  { key: 2, view: "empty" },
  { key: 3, view: "empty" },
  { key: 4, view: "empty" },
];

export const searchFields = (fields = [], searchParameter = "") => {
  if (searchParameter?.trim() === "") return fields;
  let searchedFields = fields.filter((field) => {
    return field?.displayName
      ?.trim()
      .toLowerCase()
      .includes(searchParameter.trim().toLowerCase());
  });
  return searchedFields;
};

export const findLeadWidgetsRowIndexWithId = (rowId, widgetsLayout) => {
  let rowIndex = -1;
  for (let i = 0; i < widgetsLayout.length; i++) {
    let section = widgetsLayout[i];
    if (section.rowId === rowId) {
      rowIndex = i;
      return rowIndex;
    }
  }
};

export const findLeadWidgetsIndexInRowWithID = (widgetId, widgetsLayout) => {
  for (let i = 0; i < widgetsLayout.length; i++) {
    let row = widgetsLayout[i];
    for (let j = 0; j < row.widgets.length; j++) {
      if (row.widgets[j] === widgetId) {
        return [i, j];
      }
    }
  }
};

export const removeWidgetFromRow = (layout, rowAndWidgetIndex) => {
  const [updatedRow] = layout.splice(rowAndWidgetIndex[0], 1);
  updatedRow.widgets.splice(rowAndWidgetIndex[1], 1);
  updatedRow.widgets.length !== 0 &&
    layout.splice(rowAndWidgetIndex[0], 0, updatedRow);
  return layout;
};

export const getFieldOptionBySchemaName = (fields, schemaName) => {
  return fields?.find((obj) => obj.schemaName === schemaName);
};

export const getFieldOptionById = (fields, id) => {
  return fields?.find((obj) => obj.id === id);
};

export const formatLeadCardFields = (fields) => {
  return fields?.reduce((result, field) => {
    if (field.view === leadControlsConstants.FILLED) {
      result.push({
        id: field.schemaName,
        type: field.dataType,
        width: field.size === leadControlsConstants.EXPENDED ? "full" : "half",
        name: field.name,
        displayName: field.displayName,
      });
    }
    return result;
  }, []);
};

export const formatLeadCardActions = (fields) => {
  return fields?.reduce((result, field) => {
    if (field.view === leadControlsConstants.FILLED) {
      result.push({
        id: field.id,
        icon: field.imgName,
        name: field.name,
      });
    }
    return result;
  }, []);
};

export const formatLeadTabsWithIsCustom = (tabs, isCustom) => {
  return tabs?.map((tab) => {
    return {
      ...tab,
      isCustom: isCustom,
    };
  });
};

export const formatLeadCardVisibleFields = (
  dummyFields,
  visibleFields,
  availableFields
) => {
  if (!visibleFields) return dummyFields;
  return dummyFields?.map((field, index) => {
    if (visibleFields[index]) {
      const visibleField = getFieldOptionBySchemaName(
        availableFields,
        visibleFields[index].id
      );
      return {
        ...field,
        ...visibleField,
        view: leadControlsConstants.FILLED,
        size:
          visibleFields[index].width === "half"
            ? leadControlsConstants.STANDARD
            : leadControlsConstants.EXPENDED,
      };
    } else return field;
  });
};

export const formatLeadActionsVisibleFields = (
  dummyActions,
  visibleActions,
  availableActions
) => {
  if (!visibleActions) return dummyActions;
  return dummyActions.map((field, index) => {
    if (visibleActions[index]) {
      const visibleAction = getFieldOptionById(
        availableActions,
        visibleActions[index].id
      );
      return {
        ...field,
        ...visibleAction,
        view: leadControlsConstants.FILLED,
      };
    } else return field;
  });
};

export const getAvailableLeadCardFields = (availableFields, selectedFields) => {
  return availableFields?.filter(
    (item1) => !selectedFields?.some((item2) => item2.id === item1.schemaName)
  );
};

export const getAvailableLeadCardActions = (
  availableActions,
  selectedActions
) => {
  return availableActions?.filter(
    (item1) => !selectedActions?.some((item2) => item2.id === item1.id)
  );
};

export const formatSelectedLeadCard = (data) => {
  return {
    visibleCardActions: data?.actions,
    visibleCardFields: data?.leadDetails,
    isFieldsEnabledInLeadList: data?.useLeadDetailsInListCard,
    isActionsEnabledInLeadList: data?.useActionsInListCard,
  };
};

export const formatLeadCardConfig = (selectedLeadControl, selectedLeadCard) => {
  return {
    leadControlId: selectedLeadControl?.id,
    cardId: selectedLeadControl?.leadCard.id,
    postBody: {
      config: {
        title: selectedLeadControl?.leadCard.config.title,
        leadDetails: selectedLeadCard.visibleCardFields,
        actions: selectedLeadCard.visibleCardActions,
        useLeadDetailsInListCard: selectedLeadCard.isFieldsEnabledInLeadList,
        useActionsInListCard: selectedLeadCard.isActionsEnabledInLeadList,
      },
    },
  };
};

export const formatLeadPageConfig = (
  selectedLeadControl,
  widgetsLayout,
  leadPageMenus
) => {
  const configuaredWidgets = widgetsLayout.length !== 0;
  return {
    leadControlId: selectedLeadControl?.id,
    pageId: selectedLeadControl?.leadPage.id,
    postBody: {
      config: {
        type: !configuaredWidgets
          ? leadControlsWidgetConstants.MENU
          : leadControlsWidgetConstants.WIDGET,
        items: !configuaredWidgets
          ? leadPageMenus.selectedMenus
          : widgetsLayout,
      },
    },
  };
};

export const formatLeadActionConfig = (selectedLeadControl, leadActions) => {
  return {
    leadControlId: selectedLeadControl?.id,
    actionId: selectedLeadControl?.leadAction.id,
    postBody: {
      config: leadActions?.selectedActions,
    },
  };
};

export const leadControlsMoreOptions = [
  {
    id: leadControlsConstants.RENAME,
    label: leadControlsConstants.RENAME,
  },
  {
    id: leadControlsConstants.CHANGE_LEAD_TYPE,
    label: leadControlsConstants.CHANGE_LEAD_TYPE,
  },
];

export const leadWidgetTileStyles = [
  { key: "Icon", value: "Icon" },
  { key: "Image", value: "Image" },
];

export const getLeadWidgetTitlebyWidgetType = (widgetType) => {
  switch (widgetType) {
    case "opportunities":
      return "Opportunities";
    case "activities":
      return "Activities";
    case "accounts":
      return "Accounts";
    case "tasks":
      return "Tasks";
    case "notes":
      return "Notes";
    case "about":
      return "About";
    case "documents":
      return "Documents";
    case "socialprofile":
      return "Social Profile";
    case "customwidget":
      return "Custom Widget";
    default:
      return null;
  }
};

export const uploadImageInToBucket = async (imageConfigurationValues) => {
  let element;
  try {
    const { imageURL, name, imageKey } = imageConfigurationValues;
    const uploadUrlResponse = await API_GET_UPLOAD_URL(imageKey, "leadControl");
    const uploadUrl = uploadUrlResponse.data.url;
    const signedKey = uploadUrlResponse.data.key;
    const uploadAPIFields = uploadUrlResponse.data.fields;
    const res = await fetch(imageURL);
    const blob = await res.blob();
    const file = new File([blob], name, { type: blob.type });
    await API_IMAGE_UPLOAD(uploadUrl, uploadAPIFields, file);
    element = {
      ...imageConfigurationValues,
      uploadStatus: leadControlsWidgetConstants.SUCCESS,
      imageKey: signedKey,
    };
  } catch (error) {
    element = {
      ...imageConfigurationValues,
      uploadStatus: leadControlsWidgetConstants.FAILED,
    };
  }
  return element;
};

export const formatImageFile = (event) => {
  const file = event.files[0];
  const imgFile = {
    imageURL: file.objectURL,
    name: file.name,
  };
  event.options.clear();
  return imgFile;
};

export const fetchImageURL = async (image) => {
  let element;
  try {
    const imageKey = API_GET_SIGNED_URL(image?.imageKey);
    const imageSignedUrlResponse = await imageKey;
    element = {
      ...image,
      imageURL: imageSignedUrlResponse.data.url,
      uploadStatus: leadControlsWidgetConstants.SUCCESS,
    };
  } catch (error) {
    element = {
      ...image,
      uploadStatus: leadControlsWidgetConstants.FAILED,
    };
  }
  return element;
};

export const leadListFilterItems = [
  {
    value: leadControlsConstants.ALL,
    key: leadControlsConstants.ALL,
    id: "laed-all",
    "data-testid": "all-lead",
  },
  {
    value: leadControlsConstants.PUBLISHED,
    key: leadControlsConstants.PUBLISHED,
    id: "lead-published",
    "data-testid": "published-lead",
  },
  {
    value: leadControlsConstants.UNPUBLISHED,
    key: leadControlsConstants.UNPUBLISHED,
    id: "lead-unpublished",
    "data-testid": "unpublished-lead",
  },
  {
    value: leadControlsConstants.DRAFT,
    key: leadControlsConstants.DRAFT,
    id: "lead-draft",
    "data-testid": "draft-lead",
  },
];

export const leadControlsSortItems = [
  {
    id: leadControlsConstants.LAST_MODIFIED,
    label: leadControlsConstants.LAST_MODIFIED,
  },
  {
    id: leadControlsConstants.RECENTLY_CREATED,
    label: leadControlsConstants.RECENTLY_CREATED,
  },
];

export const getAllLeadControlsData = (
  leadControls,
  teamsToLeadCountMapping
) => {
  let clonedLeadControls = cloneDeep(leadControls);
  clonedLeadControls.forEach((lead) => {
    if (teamsToLeadCountMapping[lead.id])
      lead.teamsCount = teamsToLeadCountMapping[lead.id].length;
  });
  return clonedLeadControls;
};

export const sortLeadControls = (leads = [], sortParameter) => {
  const cloneLeads = [...leads];
  let sortFunction;
  switch (sortParameter) {
    case leadControlsConstants.RECENTLY_CREATED:
      sortFunction = (team1, team2) => {
        return new Date(team2.createdAt) - new Date(team1.createdAt);
      };
      break;
    default:
      sortFunction = (team1, team2) => {
        return new Date(team2.updatedAt) - new Date(team1.updatedAt);
      };
  }
  return cloneLeads.sort(sortFunction);
};

export const searchLeadControls = (leads = [], searchParameter) => {
  if (searchParameter.trim() === "") return leads;
  let searchedLeads = leads.filter((lead) => {
    return lead.name
      ?.trim()
      .toLowerCase()
      .includes(searchParameter.trim().toLowerCase());
  });
  return searchedLeads;
};

export const filterLeadControls = (
  leads = [],
  filterParameter,
  leadControlById
) => {
  let filterFunction;
  switch (filterParameter) {
    case leadControlsConstants.PUBLISHED:
      filterFunction = (lead) =>
        leadControlById[lead.id]?.status === leadControlsConstants.PUBLISHED;
      break;
    case leadControlsConstants.UNPUBLISHED:
      filterFunction = (lead) =>
        leadControlById[lead.id]?.status === leadControlsConstants.UNPUBLISHED;
      break;
    case leadControlsConstants.DRAFT:
      filterFunction = (lead) =>
        leadControlById[lead.id]?.status === leadControlsConstants.DRAFT;
      break;
    default:
      filterFunction = (lead) => leadControlById[lead.id]?.status;
  }
  let filteredLeads = leads?.filter(filterFunction);
  return filteredLeads;
};

export const leadListMoreOptions = (lead) => {
  const draftOptions = [
    {
      id: leadControlsConstants.CLONE,
      label: leadControlsConstants.CLONE,
    },
    {
      id: leadControlsConstants.DELETE,
      label: leadControlsConstants.DELETE,
    },
  ];
  if (lead.status === leadControlsConstants.DRAFT) {
    return draftOptions;
  } else {
    const teamOptions =
      lead.status === leadControlsConstants.UNPUBLISHED
        ? {
            id: leadControlsConstants.PUBLISH,
            label: leadControlsConstants.PUBLISH,
          }
        : {
            id: leadControlsConstants.UNPUBLISH,
            label: leadControlsConstants.UNPUBLISH,
          };

    return [...draftOptions, teamOptions];
  }
};
