import React from "react";
import PropTypes from "prop-types";
import TaskCounterSettings from "./TaskCounterSettings";
import WebViewSettings from "./WebViewSettings";
import { QuickLauncherSettings } from "./QuickLauncherSettings";
import { LeadListSettings } from "./LeadListSettings";
import { TaskListSettings } from "./TaskListSettings";
import { BannerSettings } from "./BannerSettings";
import { useSelector } from "react-redux";
import { LeadShortcutSettings } from "./LeadShortcutSettings";
import { MyOpportunitiesSettings } from "./MyOpportunitiesSettings";
import SmartViewCounterSettings from "./SmartViewCounterSettings";
import { MyScorecardSettings } from "./MyScorecardSettings";
import { LeadMetricsSettings } from "./LeadMetricsSettings";
import { KeyLeadMetricsSettings } from "./KeyLeadMetricsSettings";
import { NearMeSettings } from "./NearMeSettings";
import TaskCounterV2Settings from "./TaskCounterV2Settings";
import TaskCounterFuelBarSettings from "./TaskCounterFuelBarSettings";
import SieraAceWebviewSettings from "./SieraAceWebviewSettings";
import { PopUpBannerSettings } from "./PopUpBannerSettings";
import { TilesBannerSettings } from "./TilesBannerSettings";
import { CustomFabSettings } from "./CustomFabSettings";
import UserCheckInStatusSettings from "./UserCheckInStatusSettings";
import { ImageGallerySettings } from "./ImageGallerySettings";

const DashboardSettings = {
  TaskCounterSettings: 1,
  TaskListSettings: 2,
  WebViewSettings: 3,
  LauncherSettings: 4,
  BannerSettings: 5,
  LeadShortcutSettings: 6,
  LeadListSettings: 7,
  MyOpportunitiesSettings: 8,
  SmartViewCounterSettings: 9,
  MyScorecardSettings: 10,
  TaskCounterV2Settings: 13,
  LeadMetricsSettings: 14,
  KeyLeadMetricsSettings: 15,
  CustomFabSettings: 16,
  NearMeSettings: 17,
  TaskCounterFuelBarSettings: 18,
  PopUpBannerSettings: 19,
  TilesBannerSettings: 20,
  SieraAceWebviewSettings: 21,
  UserCheckInStatusSettings: 22,
  ImageGallerySettings: 23,
};

Object.freeze(DashboardSettings);

const SettingsFactory = ({ id }) => {
  const { metaData } = useSelector(
    (state) => state.widgetTemplateList.selectedWidgetConfiguration
  );
  switch (id) {
    case DashboardSettings.TaskCounterSettings:
      return <TaskCounterSettings widgetMetaData={metaData} />;
    case DashboardSettings.TaskListSettings:
      return <TaskListSettings />;
    case DashboardSettings.WebViewSettings:
      return <WebViewSettings />;
    case DashboardSettings.LauncherSettings:
      return <QuickLauncherSettings />;
    case DashboardSettings.BannerSettings:
      return <BannerSettings />;
    case DashboardSettings.LeadShortcutSettings:
      return <LeadShortcutSettings />;
    case DashboardSettings.LeadListSettings:
      return <LeadListSettings />;
    case DashboardSettings.MyOpportunitiesSettings:
      return <MyOpportunitiesSettings />;
    case DashboardSettings.SmartViewCounterSettings:
      return <SmartViewCounterSettings />;
    case DashboardSettings.MyScorecardSettings:
      return <MyScorecardSettings />;
    case DashboardSettings.TaskCounterV2Settings:
      return <TaskCounterV2Settings />;
    case DashboardSettings.LeadMetricsSettings:
      return <LeadMetricsSettings />;
    case DashboardSettings.KeyLeadMetricsSettings:
      return <KeyLeadMetricsSettings />;
    case DashboardSettings.NearMeSettings:
      return <NearMeSettings />;
    case DashboardSettings.TaskCounterFuelBarSettings:
      return <TaskCounterFuelBarSettings />;
    case DashboardSettings.PopUpBannerSettings:
      return <PopUpBannerSettings />;
    case DashboardSettings.SieraAceWebviewSettings:
      return <SieraAceWebviewSettings />;
    case DashboardSettings.TilesBannerSettings:
      return <TilesBannerSettings />;
    case DashboardSettings.CustomFabSettings:
      return <CustomFabSettings />;
    case DashboardSettings.UserCheckInStatusSettings:
      return <UserCheckInStatusSettings />;
    case DashboardSettings.ImageGallerySettings:
      return <ImageGallerySettings />;
    default:
      return <div>Widget Under Development</div>;
  }
};

SettingsFactory.propTypes = {
  id: PropTypes.number,
};

export default SettingsFactory;
