import React from "react";
import Text from "../../components/Text";
import { useTranslation } from "react-i18next";
import { stringConstants } from "../../constants/globalConstant";

export const MyScorecard = ({ title }) => {
  const { t } = useTranslation();
  return (
    <div className="widget-view-container">
      <div className="flex flex-column absolute mt-1 ml-2">
        <Text type="sub-heading-bold" color="var(--text-focused)">
          {" "}
          {t(title)}{" "}
        </Text>
      </div>
      <img className="w-full" src={`/images/widgetPreview/my_scorecard.png`} 
      alt={stringConstants.MY_SCORE_CARD} 
      />
    </div>
  );
};
