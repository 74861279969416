import React from 'react'
import PropTypes from "prop-types"
import styles from "./styles.module.css"

export default function Text({ type = "type-1", children = "", color="", className = '' }) {
  return (
    <div
      className={`${className} ${styles[type]}`}
      style={{ color: `${color}` }}
    >
      {children}
    </div>
  )
}

Text.propTypes= {
  type : PropTypes.string,
  children: PropTypes.any, 
  color: PropTypes.string,
  className : PropTypes.string
}
