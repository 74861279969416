import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Text from "../../../components/Text/index.js";
import { leadControlsConstants } from "../../../constants/globalConstant";
import { changeLeadActions } from "../../../reducers/leadControlsSlice";
import cloneDeep from "lodash.clonedeep";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import LsqExpandableTile from "../../../components/LsqExpandableTile.js";
import LeadActionUpdate from "./LeadActionUpdate.js";
import { QuickLauncherIcons } from "../../../assets/icons/QuickLauncherIcons";

export default function SelectedActionList({ setEditAction }) {
  const dispatch = useDispatch();
  const { leadActions } = useSelector((state) => state.leadControls);
  const selectedActions = leadActions?.selectedActions;
  const [expanded, setExpanded] = useState(null);

  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.source.index === result.destination.index) return;
    const clonedSelectedActions = cloneDeep(leadActions.selectedActions);
    const [selectedAction] = clonedSelectedActions.splice(
      result.source.index,
      1
    );
    clonedSelectedActions.splice(result.destination.index, 0, selectedAction);
    dispatch(
      changeLeadActions({
        ...leadActions,
        selectedActions: clonedSelectedActions,
      })
    );
  };

  const onActionDelete = (action) => {
    const clonedSelectedActions = cloneDeep(leadActions.selectedActions);
    const selectedIndex = clonedSelectedActions?.findIndex(
      (element) => element.id === action?.id
    );
    clonedSelectedActions.splice(selectedIndex, 1);
    dispatch(
      changeLeadActions({
        ...leadActions,
        selectedActions: clonedSelectedActions,
      })
    );
  };

  const closeExpandedPanel = () => {
    setEditAction({});
    setExpanded(null);
  };

  return (
    <DragDropContext
      onDragEnd={(result) => {
        onDragEnd(result);
      }}
    >
      <div
        className={`flex flex-column flex-start h-full justify-content-start`}
        data-testid="lead-selected-action-view"
      >
        <div className="flex justify-content-between align-items-center p-3">
          <Text type="T1B" color="var( --text-primary)">
            {leadControlsConstants.QUICK_ACTIONS}
          </Text>
        </div>
        <Droppable
          type="lead-actions"
          droppableId="lead-quick-actions-fields"
          direction="vertical"
        >
          {(provided) => {
            return (
              <div
                className={`flex flex-column h-full gap-1 overflow-scroll px-3 py-1`}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {selectedActions?.map((action, index) => {
                  return (
                    <Draggable
                      index={index}
                      key={action.id}
                      draggableId={String(action.id)}
                    >
                      {(provided) => {
                        return (
                          <div
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                            key={index}
                            className="flex"
                          >
                            <LsqExpandableTile
                              label={action.name}
                              hasTileImage={true}
                              tileImage={
                                <QuickLauncherIcons
                                  icon={action?.icon?.imgName}
                                  style={{
                                    filter: "opacity(0.7)",
                                    width: "1.2rem",
                                  }}
                                />
                              }
                              onDelete={() => {
                                expanded === action.id && closeExpandedPanel();
                                onActionDelete(action);
                              }}
                              onEdit={() => {
                                setEditAction(action);
                                setExpanded(action.id);
                              }}
                              expanded={action.id === expanded}
                              children={
                                <LeadActionUpdate
                                  editAction={action}
                                  closeExpandedPanel={closeExpandedPanel}
                                />
                              }
                              dragHandleProps={{ ...provided.dragHandleProps }}
                            />
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </div>
    </DragDropContext>
  );
}
