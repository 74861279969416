import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { CustomIconPicker } from "../../../components/CustomIconPicker/CustomIconPicker";
import CustomInput from "../../../components/CustomInput";
import CustomButtom from "../../../components/CustomButton";
import { iconList } from "../../../utils/iconList";
import {
  iconPickerTemplate,
  selectedIconTemplate,
} from "../../../utils/dropdownUtils";
import {
  globalConstantValues,
  leadControlsConstants,
  quicklauncherConstants,
} from "../../../constants/globalConstant";
import { changeLeadActions } from "../../../reducers/leadControlsSlice";

export default function LeadActionUpdate({ editAction, closeExpandedPanel }) {
  const dispatch = useDispatch();
  const { leadActions } = useSelector((state) => state.leadControls);
  const iconPickerRef = useRef(null);
  const [iconPanelWidth, setIconPanelWidth] = useState(0);
  const defaultValues = {
    actionName: "",
    icon: "",
  };
  const actionItem = useForm({
    defaultValues: defaultValues,
  });
  useEffect(() => {
    if (editAction) {
      actionItem.setValue("actionName", editAction?.name);
      actionItem.setValue("icon", editAction?.icon);
    }
  }, [editAction]);

  const onActionUpdation = (data) => {
    const clonedSelectedActions = cloneDeep(leadActions.selectedActions);
    const clonedActions = cloneDeep(leadActions.actions);
    const selectedAvailableIndex = clonedActions.findIndex(
      (element) => element.id === editAction.id
    );
    const selectedVisibleIndex = clonedSelectedActions?.findIndex(
      (element) => element.id === editAction.id
    );
    const [selectedAction] = clonedActions.splice(selectedAvailableIndex, 1);
    clonedActions.splice(selectedAvailableIndex, 0, {
      ...selectedAction,
      name: data.actionName,
      icon: data.icon,
    });
    const [selectedVisibleAction] = clonedSelectedActions.splice(
      selectedVisibleIndex,
      1
    );
    clonedSelectedActions.splice(selectedVisibleIndex, 0, {
      ...selectedVisibleAction,
      name: data.actionName,
      icon: data.icon,
    });
    dispatch(
      changeLeadActions({
        actions: clonedActions,
        selectedActions: clonedSelectedActions,
      })
    );
    closeExpandedPanel();
  };

  return (
    <div className={`flex flex-column h-full gap-2`}>
      <div className={`flex flex-column gap-2 h-full`}>
        <div className={`flex w-full gap-1`} ref={iconPickerRef}>
          <CustomIconPicker
            id="icon-list"
            fieldName="icon"
            control={actionItem.control}
            className="w-full text-base lsq-input lsq-icon-picker"
            options={iconList}
            optionLabel="name"
            filter
            filterBy="name"
            resetFilterOnHide={true}
            filterPlaceholder={quicklauncherConstants.SEARCH_ICONS}
            placeholder={"lsq_change_image"}
            valueTemplate={selectedIconTemplate}
            itemTemplate={iconPickerTemplate}
            label={quicklauncherConstants.ICON}
            isMandatory={true}
            errors={actionItem.formState.errors}
            panelClassName="lsqIconPickerPanel"
            onShow={() => {
              setIconPanelWidth(iconPickerRef.current?.offsetWidth);
            }}
            panelStyle={{ width: iconPanelWidth }}
            containerStyle={{ width: "25%" }}
          />
          <CustomInput
            errors={actionItem.formState.errors}
            fieldName="actionName"
            control={actionItem.control}
            isMandatory
            label="Action Name"
            maxLength={globalConstantValues.CUSTOM_MENU_TAB_LABEL_CHAR_LIMIT}
            placeholder="Type Here"
            containerStyle={{ width: "75%" }}
            data-testid="lead-action-title"
          />
        </div>
      </div>
      <div className="flex w-full gap-1 justify-content-end align-items-center mb-2">
        <CustomButtom
          varient="text"
          label={leadControlsConstants.CANCEL}
          onClick={() => {
            closeExpandedPanel();
          }}
          data-testid="cancel-btn"
        />
        <CustomButtom
          varient="filled"
          label={leadControlsConstants.UPDATE}
          onClick={actionItem.handleSubmit(onActionUpdation)}
          data-testid="update-btn"
        />
      </div>
    </div>
  );
}
