import React from "react";
import Text from "../../../components/Text";
import {
  addToastMessageFromLeadControls, setLeadConfigurationDirty,
} from "../../../reducers/leadControlsSlice";
import useToast from "../../../hooks/useToast";
import {
  leadControlsConstants,
  toastMessageConstants,
} from "../../../constants/globalConstant";
import LsqItemTile from "../../../components/LsqItemTile/LsqItemTile";
import { Draggable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";

export default function LeadAvailableCard({
  availableCardFields,
  visibleCardFields,
  searchedAvailableFields,
  provided,
}) {
  const dispatch = useDispatch();
  const toast = useToast(addToastMessageFromLeadControls);

  const onLeadAvailableFieldClicked = (field) => {
    const emptyIndex = visibleCardFields.fields.findIndex(
      (item) => item.view === leadControlsConstants.EMPTY
    );
    const selectedIndex = availableCardFields.fields.findIndex(
      (element) => element.schemaName === field.schemaName
    );
    if (emptyIndex < 0)
      toast.warn(toastMessageConstants.MAXIMUM_FOUR_FIELDS_IN_LEAD_CARD);
    else {
      const availableField = availableCardFields.fields[selectedIndex];
      visibleCardFields.update(emptyIndex, {
        ...visibleCardFields.fields[emptyIndex],
        view: leadControlsConstants.FILLED,
        ...availableField,
      });
      availableCardFields.remove(selectedIndex);
      dispatch(setLeadConfigurationDirty(true))
    }
  };

  return (
    <div
      className={`flex flex-column h-full w-full px-3 pt-2 gap-1 overflow-scroll`}
    >
      {searchedAvailableFields.map((field, index) => {
        return (
          <Draggable
            index={index}
            key={field.key}
            draggableId={`available-lead-cards-${field.key}`}
          >
            {(provided) => {
              return (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  className="flex flex-row"
                  key={index}
                >
                  <LsqItemTile
                    key={index}
                    label={field.displayName}
                    hasTileImage={true}
                    tileImageProps={{ ...provided.dragHandleProps }}
                    tileImageName={"drag_indicator"}
                    hasTileActionImg={true}
                    tileActionImg={
                      <div
                        className={`flex align-items-center border-round p-1 capitalize`}
                        style={{ backgroundColor: "var(--N20)" }}
                      >
                        <Text
                          color="var(--N80)"
                          className="overflow-wrap-anywhere"
                          type="T5"
                        >
                          {field.dataType}
                        </Text>
                      </div>
                    }
                    onTileClick={() => onLeadAvailableFieldClicked(field)}
                  />
                  {provided.placeholder}
                </div>
              );
            }}
          </Draggable>
        );
      })}
      {provided.placeholder}
    </div>
  );
}
