import axios from "axios";
import { redirectToLogin } from "../utils/globalUtils";
import baseURL from "./server";
import { apiTrace } from "../utils/apiTrace";
import { Buffer } from 'buffer';
const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
  },
  withCredentials: true
});
if (sessionStorage.getItem("authorization")) {
  const token = sessionStorage.getItem("authorization");
  const decryptedToken = Buffer.from(token, 'base64').toString('utf-8');
  axiosInstance.defaults.headers.common["authorization"] = decryptedToken;
}
axiosInstance.interceptors.response.use(
  (response) => {
    if (response.headers?.authorization) {
      const encryptedToken = Buffer.from(response.headers.authorization, 'utf-8').toString('base64');
      sessionStorage.setItem("authorization", encryptedToken);
      axiosInstance.defaults.headers.common["authorization"] =
      Buffer.from(encryptedToken, 'base64').toString('utf-8');
    }
    apiTrace(response);
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      redirectToLogin()
    }
    return Promise.reject(error);
  }
);
export default axiosInstance;