import React, { useState, useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Joyride from "react-joyride";
import ConfigurationScreen from "../../components/ConfigurationScreen/ConfigurationScreen";
import RightPanel from "../../components/RightPanel";
import TopBar from "../../components/TopBar";
import WidgetList from "../../components/WidgetList";
import PreviewPane from "../PreviewPane";
import { getWidgetList } from "../../reducers/widgetTemplateListSlice";
import { getRecentlyModifiedDashboard } from "../../utils/homepageUtils";
import CoachTooltip from "../../components/Coach/CoachTooltip";
import { beginnerSteps } from "../../components/Coach/coachSteps";
import html2canvas from "html2canvas";
import VersionPanelView from "./VersionPanelView";
import { IndeterminateLoader } from "../../components/IndeterminateLoader/index.js";
import PromotionalBannerBuilder from "../../components/PromotionalBannerBuilder/index.js";
import {
  apiAddHomepage,
  apiUpdateUserPreferences,
  changeSelectedHomepage,
  getTeamDashboards,
  getTeams,
} from "../../reducers/homePageSlice";
import PublishHomepage from "./PublishHomepage";
import Modal from "../../components/Modal";
import {
  dashboardsToTeamMapping,
  hierarchyToLinear,
} from "../../utils/globalUtils";
import {
  promotionalBannerConstants,
  globalConstants,
  newlyLaunchedWidgetTypes,
} from "../../constants/globalConstant.js";
import SidePanel from "../../components/SidePanel";
import SidePanelView from "../HomepagesView/SidePanelView.js";

export default function HomeScreen() {
  const [publishState, setPublishState] = useState({
    state: false,
    data: null,
  });
  const [homepageVersion, setHomepageVersion] = useState({
    versionPanel: false,
    versions: null,
  });
  const selectedWidget = useSelector(
    (state) => state.widgetTemplateList.selectedWidget
  );
  const loading = useSelector((state) => state.previewPane.loading);
  const { selectedHomepageId, homepagesById, userPreferences } = useSelector(
    (state) => state.homepage
  );
  const [sidePanelState, setSidePanelState] = useState({
    state: false,
    data: null,
  });
  const [showPromotionalBanner, setShowPromotionalBanner] = useState(false);
  const homepage = homepagesById[selectedHomepageId];
  const { homepageId } = homepage || {};
  const dispatch = useDispatch();
  const showWidgetList = selectedWidget.mode === null;
  const teamDashboards = useSelector((state) => state.homepage.teamDashboards);
  const teamsHierarchy = useSelector((state) => state.homepage.teams);
  const dashboardsMappedWithId =
    useSelector((state) => state.homepage.homepagesById) || {};
  const { coachMarksVisited, newWidgetsAnnouncementVisited } = userPreferences;
  const teamsLinear = useMemo(() => {
    return hierarchyToLinear(teamsHierarchy);
  }, [teamsHierarchy]);
  const dashboardsToTeamCountMapping = useMemo(() => {
    return dashboardsToTeamMapping(
      teamDashboards?.teamsDashboards,
      teamsLinear
    );
  }, [teamDashboards, teamsLinear]);
  const scrollRef = useRef(0);
  const [previewPaneScreenshot, setPreviewPaneScreenshot] = useState();

  const onCoachSkippedOrFinished = () => {
    const payload = {
      coachMarksVisited: true,
    };
    dispatch(apiUpdateUserPreferences(payload));
  };

  useEffect(() => {
    setSidePanelState({ state: false, data: null });
    setPublishState({ state: false, data: null });
  }, [selectedHomepageId]);

  const getHomepageId = () => {
    const recentlyModifiedHomepage = getRecentlyModifiedDashboard(
      Object.values(dashboardsMappedWithId)
    );
    if (Object.values(dashboardsMappedWithId).length > 0)
      dispatch(changeSelectedHomepage(recentlyModifiedHomepage?.homepageId));
    else dispatch(apiAddHomepage());
  };

  useEffect(() => {
    if (teamsHierarchy.length === 0) {
      dispatch(getTeams());
    }
    if (!teamDashboards.teamsDashboards) dispatch(getTeamDashboards());
    dispatch(getWidgetList()).then(({ type, payload }) => {
      if (type.includes(globalConstants.FULFILLED)) {
        const activeWidgets = payload;
        const newlyLaunchedWidgets = activeWidgets.filter((activeWidget) => {
          return newlyLaunchedWidgetTypes.find(
            (type) => activeWidget.widgetType === type
          );
        });
        const widgetUpdatedAt = newlyLaunchedWidgets[0]?.updatedAt;
        if (
          newlyLaunchedWidgets.length === newlyLaunchedWidgetTypes.length &&
          (!newWidgetsAnnouncementVisited ||
            new Date(widgetUpdatedAt) > new Date(newWidgetsAnnouncementVisited))
        )
          setShowPromotionalBanner(true);
      }
    });
    if (!homepageId) {
      getHomepageId();
    }
  }, []);

  const getPreviewPaneScreenShot = () => {
    const previewPane = document.getElementById("mobile-preview");
    html2canvas(previewPane, { backgroundColor: "rgba(0,0,0,0)" }).then(
      (canvas) => {
        const imgData = canvas.toDataURL("image/webp");
        setPreviewPaneScreenshot(imgData);
      }
    );
  };

  const onPromotionalBannerTrialClicked = () => {
    const payload = {
      newWidgetsAnnouncementVisited: new Date().toISOString(),
    };
    dispatch(apiUpdateUserPreferences(payload));
    setShowPromotionalBanner(false);
  };

  return (
    <div className="flex flex-row w-screen h-screen " data-testid="home-screen">
      {!loading && (
        <Joyride
          tooltipComponent={(props) => (
            <CoachTooltip
              {...props}
              onCoachSkippedOrFinished={onCoachSkippedOrFinished}
            />
          )}
          continuous
          run={!coachMarksVisited}
          showProgress
          showSkipButton
          steps={beginnerSteps}
          disableOverlayClose={true}
          disableCloseOnEsc={true}
          spotlightPadding={0}
        />
      )}
      <div className="flex h-full lg:w-8 sm:w-7 justify-content-start flex-column">
        <TopBar
          dashboardsMappedWithId={dashboardsMappedWithId}
          dashboardsToTeamCountMapping={dashboardsToTeamCountMapping}
          setPublishState={setPublishState}
          setHomepageVersion={setHomepageVersion}
          getPreviewPaneScreenShot={getPreviewPaneScreenShot}
          setSidePanelState={setSidePanelState}
        />
        {loading ? (
          <IndeterminateLoader
            color="#0A1F43"
            height="0.25rem"
            backgroundColor="#B0B7C2"
          />
        ) : (
          <div style={{ height: "0.2rem" }} />
        )}
        <div className="flex flex-1 w-full flex-column mt-3">
          <PreviewPane />
        </div>
      </div>
      <div className="flex lg:w-4 sm:w-5">
        <RightPanel>
          {showWidgetList ? (
            <WidgetList scrollRef={scrollRef} key="WidgetList" />
          ) : (
            <ConfigurationScreen key="ConfigurationScreen" />
          )}
        </RightPanel>
        {homepageVersion.versionPanel && (
          <VersionPanelView
            homepageVersion={homepageVersion}
            setHomepageVersion={setHomepageVersion}
            homepageId={homepageId}
          ></VersionPanelView>
        )}
      </div>
      <Modal
        state={sidePanelState.state}
        backgroundStyle={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SidePanel
          state={sidePanelState.state}
          setSidePanelState={setSidePanelState}
        >
          <SidePanelView
            setSidePanelState={setSidePanelState}
            sidePanelState={sidePanelState}
          />
        </SidePanel>
      </Modal>
      <Modal
        state={publishState.state}
        backgroundStyle={{
          backdropFilter: "blur(22px)",
        }}
      >
        <PublishHomepage
          status={homepage?.status}
          setPublishState={setPublishState}
          publishState={publishState}
          dashboardsMappedWithId={dashboardsMappedWithId}
          dashboardsToTeamCountMapping={dashboardsToTeamCountMapping}
          previewPaneScreenshot={previewPaneScreenshot}
        ></PublishHomepage>
      </Modal>
      {coachMarksVisited && (
        <PromotionalBannerBuilder
          state={showPromotionalBanner}
          promotionalImages={promotionalBannerConstants.BANNER_IMAGES}
          actionButtonLabel={promotionalBannerConstants.TRY_NOW}
          actionButtonOnClick={onPromotionalBannerTrialClicked}
          dismissButtonOnClick={onPromotionalBannerTrialClicked}
          bannerTitle={promotionalBannerConstants.BANNER_TITLE}
          bannerDescription={promotionalBannerConstants.BANNER_DESCRIPTION}
        ></PromotionalBannerBuilder>
      )}
    </div>
  );
}
