import React, { useState } from "react";
import styles from "./styles.module.css";
import Text from "../Text";

export default function CustomAccordion({
  title,
  expandIcon,
  collapseIcon,
  children,
}) {
  const [selected, setSelected] = useState(false);
  const toggleView = () => {
    setSelected(!selected);
  };
  return (
    <div className={`flex flex-column ${styles["accordion-container"]}`} data-testid="custom-accordion">
      <div
        className={`flex flex-row w-full align-items-center justify-content-between`}
      >
        <Text type="T2B" color="var(--text-primary)">
          {title}
        </Text>
        <div
          className={`flex cursor-pointer align-items-center ${styles["icon-style"]}`}
          onClick={toggleView}
          data-testid = "toggle-view-icon"
        >
          {selected ? expandIcon : collapseIcon}
        </div>
      </div>
      <div
        className={
          !selected ? `${styles["collapsed-view"]}` : `${styles["expanded-view"]}`
        }
      >
        {children}
      </div>
    </div>
  );
}
