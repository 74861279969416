import React from "react";
import { Skeleton } from "primereact/skeleton";
import { shimmerConstants } from "../../constants/globalConstant";
import "./index.css";

export const Shimmer = ({ label }) => {
  const configurationScreenShimmer = () => {
    const configurationScreenShimmerKeys = [...Array(4)].map(
      (_, i) => `skeleton${i}`
    );
    return (
      <div
        className="flex flex-column h-full gap-3 bg-white mt-5 mx-5"
        data-testid="configuration-shimmer"
      >
        {configurationScreenShimmerKeys.map((key) => {
          return (
            <div key={key} className="flex flex-column w-full gap-1">
              <Skeleton
                width="30%"
                borderRadius="0.5rem"
                style={{ backgroundColor: "var(--B100)" }}
              />
              <Skeleton
                height="3rem"
                width="60%"
                borderRadius="0.5rem"
                style={{ backgroundColor: "var(--B100)" }}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const widgetListShimmer = () => {
    const widgetListShimmerKeys = [...Array(4)].map((_, i) => `skeleton${i}`);

    return (
      <div
        className="flex flex-column h-full gap-4 pt-3 pl-2"
        data-testid="widgetList-shimmer"
      >
        {widgetListShimmerKeys.map((key) => {
          return (
            <div className="flex flex-row gap-2" key={key}>
              <Skeleton
                width="13rem"
                height="9rem"
                borderRadius="0.5rem"
                className="lsq-shimmer"
              />
              <div className="flex flex-column gap-2">
                <Skeleton
                  width="11rem"
                  height="1.5rem"
                  borderRadius="0.5rem"
                  className="lsq-shimmer"
                />
                <Skeleton
                  width="11rem"
                  height="3.5rem"
                  borderRadius="0.5rem"
                  className="lsq-shimmer"
                />
                <Skeleton
                  width="4rem"
                  height="2rem"
                  borderRadius="0.5rem"
                  className="lsq-shimmer"
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const leadTypesShimmer = () => {
    const leadTypesShimmerKeys = [...Array(3)].map((_, i) => `skeleton${i}`);
    return (
      <div
        className="flex flex-column h-full gap-2 bg-white"
        data-testid="lead-types-shimmer"
      >
        {leadTypesShimmerKeys.map((key) => {
          return (
            <Skeleton
              key={key}
              height="1.5rem"
              borderRadius="5px"
              style={{ backgroundColor: "var(--N10)" }}
            />
          );
        })}
      </div>
    );
  };

  switch (label) {
    case shimmerConstants.CONFIGURATION_SCREEN:
      return configurationScreenShimmer();
    case shimmerConstants.WIDGET_LIST:
      return widgetListShimmer();
    case shimmerConstants.LEAD_TYPES:
      return leadTypesShimmer();
    default:
      return <></>;
  }
};
