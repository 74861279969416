import React from "react";
import Text from "../Text";

export const FormErrorMessage = ({message}) => {
  return (
    <Text color="#E02020" type="T4" className="p-error mt-1">
      {message}
    </Text>
  );
};
