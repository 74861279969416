const { createSlice, nanoid } = require("@reduxjs/toolkit");

const initialState = {
  labsPreviewSections: [],
  hasCustomFab: false,
  hasPopUpBanner: {
    state: false,
    imgURL: "",
  },
};

const labsSlice = createSlice({
  name: "labs",
  initialState,
  reducers: {
    addLabWidget(state, action) {
      if (action.payload) {
        state.labsPreviewSections = [
          {
            rows: [action.payload],
            sectionId: `section-${nanoid()}`,
          },
        ];
      } else {
        state.labsPreviewSections = [];
      }
    },
    toggleHasCustomFab(state, action) {
      state.hasCustomFab = action.payload;
    },
    toggleHasPopUpBanner(state, action) {
      state.hasPopUpBanner = action.payload;
    },
    resetNonSectionWidgets(state) {
      state.hasCustomFab = false;
      state.hasPopUpBanner = {
        state: false,
        imgURL: "",
      };
    },
  },
});

export const {
  addLabWidget,
  toggleHasCustomFab,
  toggleHasPopUpBanner,
  resetNonSectionWidgets,
} = labsSlice.actions;

export default labsSlice.reducer;
