import hexToHsl from "hex-to-hsl";
import hslToHex from "hsl-to-hex";
import { themeConstants } from "../constants/globalConstant";

export const adjustLuminosity = (hexCode, Luminosity) => {
  const hslValue = hexToHsl(hexCode);
  return hslToHex(hslValue[0], hslValue[1], Luminosity);
};

export const adjustSaturationAndLuminosity = (
  hexCode,
  Saturation,
  Luminosity
) => {
  const hslValue = hexToHsl(hexCode);
  return hslToHex(hslValue[0], Saturation, Luminosity);
};

export const lsqColorPalette = (primary, secondary, neutral) => {
  return {
    primary: {
      B800: primary,
      B600: adjustLuminosity(primary, 70),
      B100: adjustLuminosity(primary, 95),
    },
    secondary: {
      S800: secondary,
      S600: adjustLuminosity(secondary, 70),
      S100: adjustLuminosity(secondary, 95),
    },
    neutral: {
      N600: neutral,
      N400: adjustSaturationAndLuminosity(neutral, 8, 20),
      N200: adjustSaturationAndLuminosity(neutral, 8, 32),
      N80: adjustSaturationAndLuminosity(neutral, 5, 55),
      N60: adjustSaturationAndLuminosity(neutral, 5, 70),
      N40: adjustSaturationAndLuminosity(neutral, 5, 85),
      N30: adjustSaturationAndLuminosity(neutral, 5, 94),
      N20: adjustSaturationAndLuminosity(neutral, 5, 97),
      N10: adjustSaturationAndLuminosity(neutral, 5, 99),
      N0: adjustSaturationAndLuminosity(neutral, 0, 100),
    },
  };
};

export const lsqPreDefinedThemes = [
  {
    name: themeConstants.DEFAULT,
    primaryColour: "#015CCD",
    secondaryColour: "#303DB1",
    neutralColour: "#1B2332",
  },
  {
    name: themeConstants.ORANGE,
    primaryColour: "#F37E20",
    secondaryColour: "#AE282E",
    neutralColour: "#292323",
  },
  {
    name: themeConstants.GREEN,
    primaryColour: "#639C2C",
    secondaryColour: "#28AAAA",
    neutralColour: "#222522",
  },
];
