import React from "react";
import styles from "../styles.module.css";
import { stringConstants } from "../../constants/globalConstant";
import Text from "../../components/Text";
import { useTranslation } from "react-i18next";

export const DistanceTravelled = () => {
  const { t } = useTranslation();
  const imgURL = `/images/widgetPreview/distance_travelled.png`;
  return (
    <div
      className={`${styles["widget-container"]}`}
      data-testid={`distance-travelled-preview`}
    >
      <div className="flex flex-column absolute mt-1 ml-2">
        <Text type="sub-heading-bold" color="var(--text-focused)">
          {t(stringConstants.DISTANCE_TRAVELLED)}
        </Text>
      </div>
      <img
        className="w-full"
        src={imgURL}
        alt={stringConstants.DISTANCE_TRAVELLED}
      />
    </div>
  );
};
