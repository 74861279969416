export const iconList = [
  { name: "Activity", imgName: "lsq_activity" },
  { name: "Add Activity", imgName: "lsq_add_activity" },
  { name: "Add", imgName: "lsq_add" },
  { name: "Add Lead", imgName: "lsq_add_lead" },
  { name: "Add note", imgName: "lsq_add_note" },
  { name: "Assign Lead", imgName: "lsq_assign_lead" },
  { name: "Tasks", imgName: "lsq_tasks" },
  { name: "Links", imgName: "lsq_link" },
  { name: "Notifications", imgName: "lsq_notification" },
  { name: "Opportunities", imgName: "lsq_opportunity" },
  { name: "Reports", imgName: "lsq_reports" },
  { name: "Accounts", imgName: "lsq_accounts" },
  { name: "Smart Views", imgName: "lsq_smart_views" },
  { name: "Leads", imgName: "lsq_leads" },
  { name: "Location", imgName: "lsq_location" },
  { name: "Goals", imgName: "lsq_ace_goals" },
  { name: "Near Me", imgName: "lsq_near_me" },
  { name: "Help", imgName: "lsq_help" },
  { name: "About", imgName: "lsq_about" },
  { name: "Add Account", imgName: "lsq_add_account" },
  { name: "Add Opportunity", imgName: "lsq_add_opportunity" },
  { name: "Add Task", imgName: "lsq_add_task" },
  { name: "Appointment", imgName: "lsq_appointment" },
  { name: "Back", imgName: "lsq_back" },
  { name: "Battery", imgName: "lsq_battery" },
  { name: "Bookmark", imgName: "lsq_bookmark" },
  { name: "Bug", imgName: "lsq_bug" },
  { name: "Calendar", imgName: "lsq_calendar" },
  { name: "Call", imgName: "lsq_call" },
  { name: "Camera", imgName: "lsq_camera" },
  { name: "Change Owner", imgName: "lsq_change_owner" },
  { name: "Change Status", imgName: "lsq_change_status" },
  { name: "Cross", imgName: "lsq_cross" },
  { name: "Custom Apps", imgName: "lsq_custom_apps" },
  { name: "Dashboard", imgName: "lsq_dashboard" },
  { name: "Date and Time", imgName: "lsq_date_and_time" },
  { name: "Delete", imgName: "lsq_delete" },
  { name: "Direction", imgName: "lsq_direction" },
  { name: "Distance", imgName: "lsq_distance" },
  { name: "Done", imgName: "lsq_done" },
  { name: "Down", imgName: "lsq_down" },
  { name: "Download", imgName: "lsq_download" },
  { name: "Draft", imgName: "lsq_draft" },
  { name: "Duration", imgName: "lsq_duration" },
  { name: "Edit", imgName: "lsq_edit" },
  { name: "Email", imgName: "lsq_email" },
  { name: "Error", imgName: "lsq_error" },
  { name: "Geofence", imgName: "lsq_geofence" },
  { name: "Go To", imgName: "lsq_go_to" },
  { name: "GPS", imgName: "lsq_gps" },
  { name: "Home", imgName: "lsq_home" },
  { name: "Help Text", imgName: "lsq_help_text" },
  { name: "Import", imgName: "lsq_import" },
  { name: "Information", imgName: "lsq_information" },
  { name: "Language", imgName: "lsq_language" },
  { name: "Lock", imgName: "lsq_lock" },
  { name: "Logout", imgName: "lsq_logout" },
  { name: "Lost", imgName: "lsq_lost" },
  { name: "Menu", imgName: "lsq_menu" },
  { name: "Message", imgName: "lsq_message" },
  { name: "Missed Call", imgName: "lsq_missed_call" },
  { name: "Note", imgName: "lsq_note" },
  { name: "Open", imgName: "lsq_open" },
  { name: "Optimize", imgName: "lsq_optimize" },
  { name: "Option", imgName: "lsq_option" },
  { name: "Pending Recording", imgName: "lsq_pending_recording" },
  { name: "Phone", imgName: "lsq_phone" },
  { name: "QR Code", imgName: "lsq_qr_code" },
  { name: "Recurring Task", imgName: "lsq_recurring_task" },
  { name: "Reset", imgName: "lsq_reset" },
  { name: "Resume Trip", imgName: "lsq_resume_trip" },
  { name: "Resume", imgName: "lsq_resume" },
  { name: "Search", imgName: "lsq_search" },
  { name: "Select All", imgName: "lsq_select_all" },
  { name: "Set Homescreen", imgName: "lsq_set_homescreen" },
  { name: "Settings", imgName: "lsq_settings" },
  { name: "Share", imgName: "lsq_share" },
  { name: "SMS Template", imgName: "lsq_sms_template" },
  { name: "Sort and Filter", imgName: "lsq_sort_and_filter" },
  { name: "Sort Descending", imgName: "lsq_sort_descending" },
  { name: "Sort", imgName: "lsq_sort" },
  { name: "Star", imgName: "lsq_star" },
  { name: "Start Flag", imgName: "lsq_start_flag" },
  { name: "Sync", imgName: "lsq_sync" },
  { name: "Tags", imgName: "lsq_tags" },
  { name: "Team", imgName: "lsq_team" },
  { name: "Tick", imgName: "lsq_tick" },
  { name: "Time", imgName: "lsq_time" },
  { name: "Timer", imgName: "lsq_timer" },
  { name: "To Do", imgName: "lsq_todo" },
  { name: "Undo", imgName: "lsq_undo" },
  { name: "Up", imgName: "lsq_up" },
  { name: "Whatsapp", imgName: "lsq_whatsapp" },
  { name: "Won", imgName: "lsq_won" },
  { name: "Journey", imgName: "lsq_journeys" },
  { name: "Add Journey", imgName: "lsq_add_journey" },
  { name: "End Flag", imgName: "lsq_end_flag" },
  { name: "Go to Map", imgName: "lsq_go_to_map" },
  { name: "Location Accuracy", imgName: "lsq_location_accuracy" },
  { name: "My Approvals", imgName: "lsq_my_approvals" },
  { name: "Template", imgName: "lsq_template" },
  { name: "JourneyPlan", imgName: "lsq_journey_plan" },
  { name: "Add document", imgName: "lsq_add_document" },
  { name: "Add social profile", imgName: "lsq_add_social_profile" },
  { name: "Update Lead Location", imgName: "lsq_add_update_lead_location" },
  { name: "Share Lead", imgName: "lsq_add_share_lead" },
  { name: "Add Notes", imgName: "lsq_add_notes" },
  { name: "Connecter", imgName: "lsq_connector" },
];
