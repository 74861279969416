import { useState } from "react";
import {
  bannerSettingsConstants,
  globalConstants,
} from "../../constants/globalConstant";
import CustomButton from "../CustomButton";
import { ImageEdit } from "../ImageEdit";
import { ControlledDatePicker } from "../LSQDatePicker/ControlledDatePicker";
import LsqMobileNavigator from "../LsqMobileNavigator";

export const AddImageForm = ({
  onImageSubmit,
  imageSrc,
  imageConfiguration,
  imageAspectRatio,
  imageUploadHandler,
  maxFileSize,
  navigationList,
  onResetClick,
  endDateFieldName,
  endDateRequired = false,
  navigateToRequired = false,
  unregister,
  edit,
  setEdit,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <form
      style={{ height: "90%" }}
      className="flex flex-column w-full"
      onSubmit={imageConfiguration.handleSubmit(onImageSubmit)}
    >
      <div className="overflow-y-auto">
        <div className={`flex flex-column mt-5 mx-5 gap-3`}>
          <ImageEdit
            src={imageSrc}
            imageConfiguration={imageConfiguration}
            aspectRatio={imageAspectRatio}
            imageUploadHandler={imageUploadHandler}
            maxFileSize={maxFileSize}
          />
          <ControlledDatePicker
            id="end-date"
            fieldName={endDateFieldName}
            className="w-full text-base lsq-input"
            isMandatory={endDateRequired}
            control={imageConfiguration.control}
            errors={imageConfiguration.formState.errors}
            label={bannerSettingsConstants.END_DATE_LABEL}
            data-testid="end-date"
            minDate={new Date()}
            dateFormat="dd/mm/yy"
            placeholder={bannerSettingsConstants.SELECT_END_DATE}
          />
          <LsqMobileNavigator
            formValues={imageConfiguration}
            mobileNavigationMenu={navigationList}
            unregister={unregister}
            edit={edit}
            setEdit={setEdit}
            isMandatory={navigateToRequired}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        </div>
      </div>
      <div
        className={`flex w-11 py-2 mt-3 align-self-center align-items-center justify-content-end mt-auto gap-3`}
      >
        <CustomButton
          type="reset"
          onClick={onResetClick}
          varient="text"
          label={globalConstants.BACK}
        />
        <CustomButton
          type="submit"
          varient="filled"
          label={globalConstants.CONFIRM}
        />
      </div>
    </form>
  );
};
