import React, { useState } from "react";
import { useRive } from "@rive-app/react-canvas";
import { getImageNameFromImageUrl } from "../../utils/widgetUtils";
import { getImageAspectRatio } from "../../utils/globalUtils";

export const ExperimentalWidgets = ({ widgetInformation }) => {
  const { imgUrl } = widgetInformation;
  const imageName = getImageNameFromImageUrl(imgUrl);
  const imageLink = `/images/${imageName}.png`;
  const [isAnimationError, setIsAnimationError] = useState(false);
  const aspectRatio = getImageAspectRatio(imageLink);

  const { RiveComponent } = useRive({
    src: `/riv/${imageName}.riv`,
    autoplay: true,
    onLoadError: () => setIsAnimationError(true),
    onLoad: () => setIsAnimationError(false),
  });

  return (
    <div data-testid="experimental-widget">
      {!isAnimationError ? (
        <RiveComponent
          style={{ aspectRatio: `${aspectRatio}`, width: "100%" }}
        />
      ) : (
        <img
          src={imageLink}
          style={{
            width: `100%`,
          }}
          alt="PreviewWidget"
        />
      )}
    </div>
  );
};
