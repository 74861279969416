import React from "react";
import { useSelector, useDispatch } from "react-redux";
import cloneDeep from "lodash.clonedeep";
import { nanoid } from "@reduxjs/toolkit";
import Text from "../../../components/Text";
import { ReactComponent as Delete } from "../../../assets/svgIcons/lsq_delete.svg";
import {
  leadControlsConstants,
  leadControlsWidgetConstants,
} from "../../../constants/globalConstant";
import CustomInput from "../../../components/CustomInput";
import { ReactComponent as LinkIcon } from "../../../assets/svgIcons/link.svg";
import { useForm } from "react-hook-form";
import CustomButton from "../../../components/CustomButton";
import { changeSelectedLeadPageMenu } from "../../../reducers/leadControlsSlice";

export default function CustomMenu({ setCustomMenu }) {
  const dispatch = useDispatch();
  const { leadPageMenus } = useSelector((state) => state.leadControls);
  const defaultValues = {
    identifier: leadControlsConstants.CUSTOM_MENU,
    url: "",
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues: defaultValues });

  const onCustomMenuAdd = (data) => {
    const clonedSelectedMenus = cloneDeep(leadPageMenus.selectedMenus);
    clonedSelectedMenus.push({
      id: `custom-menu-${nanoid()}`,
      isCustom: true,
      navigateTo: data.url,
      name: data.identifier,
      imgName: null,
    });
    dispatch(changeSelectedLeadPageMenu(clonedSelectedMenus));
    setCustomMenu(false);
    reset();
  };

  return (
    <div className={`flex flex-column flex-start h-full p-3 gap-2`}>
      <div className="flex justify-content-between align-items-center">
        <Text type="T1B" color="var( --text-primary)">
          {leadControlsConstants.CUSTOM_MENU}
        </Text>
        <div
          className={`flex cursor-pointer align-items-center justify-content-center border-round h-2 w-2`}
          style={{ background: "var(--R100)" }}
          onClick={() => setCustomMenu(false)}
          data-testid="custom-menu-delete-btn"
        >
          <Delete fill="var(--R700)" style={{ width: "1.2rem" }} />
        </div>
      </div>
      <div className={`flex flex-column h-full gap-2`}>
        <Text type="T4" color="var( --text-primary)">
          {leadControlsWidgetConstants.IDENTIFIER}
        </Text>
        <CustomInput
          control={control}
          errors={errors}
          fieldName="identifier"
          maxLength={30}
          className="lsq-input-bold"
          label={leadControlsWidgetConstants.NAME}
          isMandatory={true}
          data-testid="custom-menu-identifier"
        />
        <hr className="horizontal-divider" />
        <Text type="T4" color="var( --text-primary)">
          {leadControlsWidgetConstants.LAUNCH}
        </Text>
        <div className={`flex flex-column gap-2`}>
          <CustomInput
            RightIcon={LinkIcon}
            fieldName="url"
            errors={errors}
            control={control}
            isMandatory
            label="Custom URL"
            data-testid="custom-menu-url"
            pattern={{
              value: /^((https):\/\/).+$/,
              message: "Please enter a valid URL with https protocol",
            }}
          />
        </div>
        <div className="flex justify-content-end">
          <CustomButton
            label={leadControlsConstants.ADD}
            varient="filled"
            onClick={handleSubmit(onCustomMenuAdd)}
            data-testid="add-custom-menu-btn"
          ></CustomButton>
        </div>
      </div>
    </div>
  );
}
