import React from "react";
import { useTranslation } from 'react-i18next'
import styles from "./index.module.css";
import Text from "../Text";
import PropTypes from "prop-types";

const MessagingFramework = ({ type, message }) => {
  const iconName = {
    error: "error",
    tips: "tips",
    warning: "warning",
  };
  const { t } = useTranslation();
  return (
    <div className="flex justify-content-center">
      <div className={`${styles.msgWrapper} ${styles[type]}`}>
        <img src={`/images/${iconName[type]}.svg`} alt={iconName[type]} />
        <Text type="T4" color={"#3B4C69"}>
          {t(`${message}`)}
        </Text>
      </div>
    </div>
  );
};

MessagingFramework.propTypes = {
  icon: PropTypes.string,
  type: PropTypes.string,
  message: PropTypes.string,
};

export default MessagingFramework;
