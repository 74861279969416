import React from "react";
import { useSelector } from "react-redux";
import Text from "../../../components/Text";
import styles from "./styles.module.css";
import { QuickLauncherIcons } from "../../../assets/icons/QuickLauncherIcons";

export default function QuickActions({ editAction }) {
  const { leadActions } = useSelector((state) => state.leadControls);

  return (
    <div className={`flex flex-wrap gap-1 px-4 w-full overflow-scroll`}>
      {leadActions.selectedActions?.map((field) => {
        return (
          <div
            className={`flex ${styles["quick-action-field"]}
            ${editAction?.id === field.id && styles["selected-quick-action"]}
            `}
            key={field.id}
            data-testid="quick-actions"
          >
            <div
              className={`flex flex-column align-items-center justify-content-center relative gap-1 p-2 w-full`}
            >
              <QuickLauncherIcons
                icon={field.icon?.imgName}
                style={{
                  filter: "opacity(0.7)",
                  width: "1.2rem",
                }}
              ></QuickLauncherIcons>
              <Text type="T3" className="ellipsis w-full text-center">
                {field.name}
              </Text>
            </div>
          </div>
        );
      })}
    </div>
  );
}
