import { useSelector } from "react-redux";
import DetailedView from "../LeadWidgetsPreview/DetailedView";
import TileView from "../LeadWidgetsPreview/TileView";

export default function WidgetsMobileTemplate() {
  const { widgetsLayout } = useSelector((state) => state.leadControls);
  return (
    <div className="flex h-full overflow-scroll flex-column p-2 gap-1 ">
      {widgetsLayout?.map((row) => {
        return (
          <div
            className={`flex ${
              !row.detailedView && "w-full"
            } pointer-events-none`}
            key={row.rowId}
          >
            {row.detailedView ? (
              <DetailedView
                id={row.widgets[0]}
                isDragging={false}
                disablePointerEvents={false}
              />
            ) : (
              <div className={`flex w-full gap-1`} key={row.rowId}>
                {row.widgets.map((widget) => {
                  return (
                    <div key={widget} className={`flex w-full`}>
                      <TileView
                        id={widget}
                        tabCount={row.widgets.length}
                        isDragging={false}
                        disablePointerEvents={false}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
