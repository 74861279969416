import React, { useState, useEffect } from "react";
import Text from "../components/Text";
import { useTranslation } from "react-i18next";
import CustomInput from "../components/CustomInput";
import { useForm } from "react-hook-form";
import { CustomDropdown } from "../components/CustomDropdown/CustomDropdown";
import ControlledMultiselect from "../components/LSQMultiSelect/ControlledMultiselect";
import CustomButton from "../components/CustomButton";
import { apiAddWidget, apiUpdateWidget } from "../reducers/previewPaneSlice";
import { resetWidgetConfiguration } from "../reducers/widgetTemplateListSlice";
import { useDispatch } from "react-redux";
import useWidgetEssentials from "../hooks/useWidgetEssentials";
import {
  getOptionsExistInTheList,
  getWidgetSignature,
  getUpdatedName,
  getWidgetPreviewOfExperimentalWidget,
} from "../utils/widgetUtils";
import { listOptionTemplate, valueTemplate } from "../utils/dropdownUtils";
import analytics from "../utils/analytics";
import {
  globalConstants,
  placeholderConstants,
  widgetConstants,
} from "../constants/globalConstant";

export const NearMeSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [leadFieldOption, setLeadFieldOption] = useState([]);
  const { mode, selectedWidgetConfiguration, selectedWidget, homepageId } =
    useWidgetEssentials();
  const { defaultFields: leadFields, defaultRadius: radiusOptions } =
    selectedWidgetConfiguration.metaData;

  const defaultValues = {
    title: widgetConstants.NEAR_ME_TITLE,
    leadFields: { id: "", name: "", leadFieldOptions: [] },
    leadFieldOption: [],
    radius: { id: "", name: "" },
  };

  const {
    control,
    formState: { errors },
    reset,
    resetField,
    setValue,
    handleSubmit,
  } = useForm({ defaultValues: defaultValues });

  useEffect(() => {
    if (mode === globalConstants.UPDATE) {
      const outdatedMetaValue = selectedWidgetConfiguration.metaValue;
      const outdatedConfig = outdatedMetaValue.config;
      const { title, radius } = outdatedConfig;
      const leadField = getUpdatedName(
        leadFields,
        outdatedConfig.leadFields
      );

      setValue("title", title);
      if (leadField) {
        setValue("leadFields", leadField);
        setLeadFieldOption(leadField.leadFieldOptions);
        setValue(
          "leadFieldOption",
          getOptionsExistInTheList(
            outdatedConfig.leadFieldOption,
            leadField.leadFieldOptions
          ) || []
        );
      }
      setValue("radius", radius);
    }
  }, []);

  const onLeadFieldChanged = (option) => {
    resetField("leadFieldOption");
    setLeadFieldOption(option.value.leadFieldOptions);
    setValue("leadFields", option.value);
  };

  const onResetClicked = () => {
    reset();
    setLeadFieldOption([]);
  };

  const onSubmit = (data) => {
    if(selectedWidget.isExperimental){
      getWidgetPreviewOfExperimentalWidget(selectedWidget.imageUrl, dispatch)
      return;
    }
    if (!data) return;
    let _leadFields = { ...data.leadFields };
    delete _leadFields["leadFieldOptions"];
    const config = {
      title: data.title.trim(),
      leadFields: _leadFields,
      leadFieldOption: data.leadFieldOption,
      radius: data?.radius,
    };

    const metaPostValue = getWidgetSignature(
      homepageId,
      selectedWidget.id,
      config
    );
    const analytics_data = {
      category: "Widget",
      type: "click",
      widgetType: "nearme",
      lead_field: metaPostValue.config.leadFields,
      radius: metaPostValue.config.radius?.name,
    };
    if (mode === globalConstants.CREATE) {
      dispatch(apiAddWidget(metaPostValue));
      analytics.sendEvent("Adding_NearMe", analytics_data);
    } else {
      const updatedMetaValue = {
        widgetId: selectedWidgetConfiguration?.metaValue.widgetId,
        config: {
          ...metaPostValue.config,
        },
      };
      dispatch(apiUpdateWidget(updatedMetaValue));
      analytics_data["widgetId"] =
        selectedWidgetConfiguration?.metaValue.widgetId;
      analytics.sendEvent("Updating_NearMe", analytics_data);
    }
    dispatch(resetWidgetConfiguration());
  };

  return (
    <form
      className="flex flex-column w-full relative h-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex-1 overflow-y-auto">
        <div className={`flex flex-column mt-5 lg:mx-6 md:mx-5 sm: mx-4 gap-3`}>
          <Text type="T4"> {t(widgetConstants.WIDGET_TITLE)}</Text>
          <CustomInput
            control={control}
            errors={errors}
            fieldName={"title"}
            maxLength={30}
            className="lsq-input-bold"
            label={widgetConstants.DISPLAY_NAME}
            isMandatory={true}
            data-testid="title"
          />
        </div>
        <div
          className={`flex flex-column mt-5 lg:mx-6 md:mx-5 sm: mx-4 gap-3 `}
        >
          <Text type="T4">{t(widgetConstants.ADDITIONAL_CONFIGURATIONS)}</Text>
          <CustomDropdown
            fieldName="leadFields"
            control={control}
            errors={errors}
            className="w-full text-base lsq-input"
            options={leadFields}
            optionLabel="name"
            placeholder={placeholderConstants.SELECT}
            itemTemplate={listOptionTemplate}
            valueTemplate={valueTemplate}
            label={t(widgetConstants.DEFAULT_LEAD_FILTER)}
            isMandatory={false}
            onChange={onLeadFieldChanged}
          />
          <ControlledMultiselect
            label={t(widgetConstants.DEFAULT_FIELD)}
            isMandatory={false}
            errors={errors}
            className="w-full text-base lsq-input"
            fieldName="leadFieldOption"
            control={control}
            options={leadFieldOption}
            optionLabel="name"
            placeholder={placeholderConstants.SELECT}
            itemTemplate={listOptionTemplate}
            valueTemplate={valueTemplate}
            panelClassName="lsq-multiselect-panel"
            maxSelectedLabels={-1}
            id="lead-field-options"
            onChange={(e) => setValue("leadFieldOption", e.target.value)}
          />
          <CustomDropdown
            fieldName="radius"
            control={control}
            errors={errors}
            className="w-full text-base lsq-input"
            options={radiusOptions}
            optionLabel="name"
            placeholder={placeholderConstants.SELECT}
            itemTemplate={listOptionTemplate}
            valueTemplate={valueTemplate}
            label={t(widgetConstants.DEFAULT_MAXIMUN_RADIUS)}
          />
        </div>
      </div>
      <div
        className={
          "flex flex-row align-items-center justify-content-end w-11 absolute bottom-0 pb-4 gap-2"
        }
      >
        <CustomButton
          type="reset"
          label="Reset"
          varient="text"
          onClick={onResetClicked}
        />
        <CustomButton type="submit" label="Save" varient="filled" />
      </div>
    </form>
  );
};
