import React from "react";
import styles from "./styles.module.css";
import Text from "../Text";
import CustomButton from "../CustomButton";
import { ReactComponent as CrossIcon } from "../../assets/svgIcons/lsq_close.svg";

export default function PromotionalBannerBuilder({
  state,
  promotionalImages = [],
  actionButtonLabel,
  actionButtonOnClick,
  dismissButtonOnClick,
  bannerTitle,
  bannerDescription,
}) {
  if (state) {
    document.body.classList.add(`${styles["active-modal"]}`);
  } else {
    document.body.classList.remove(`${styles["active-modal"]}`);
  }
  return (
    state && (
      <div
        className={`flex justify-content-center align-items-center w-screen h-screen fixed top-0 right-0 bottom-0 left-0 ${styles["promotional-banner"]}`}
      >
        <div className={`flex gap-4 ${styles["promotional-banner-box"]}`}>
          <div className="flex flex-column gap-2">
            {promotionalImages?.map((item) => {
              return (
                <img
                key={item}
                  className={`w-full ${styles["promotional-banner-images"]}`}
                  src={`/images/${item}.png`}
                ></img>
              );
            })}
          </div>
          <div className="flex flex-column gap-2 w-11">
            <div className={`flex justify-content-end`}>
              <CrossIcon
                data-testid="cross-icon"
                style={{ cursor: "pointer", fill: "var(--icon-primary)" }}
                onClick={dismissButtonOnClick}
              />
            </div>
            <div className={`flex flex-column justify-content-between h-full`}>
              <div className={`flex flex-column gap-2 mr-4`}>
                <Text type="H1B" color="var(--text-primary)">
                  {bannerTitle}
                </Text>
                <Text type="H2" color="var(--sub-text)">
                  {bannerDescription}
                </Text>
              </div>
              <div className={`flex justify-content-end mt-4`}>
                <CustomButton
                  varient="filled"
                  label={actionButtonLabel}
                  data-testid="action-btn"
                  onClick={actionButtonOnClick}
                ></CustomButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
