import React from "react";
import { Droppable } from "react-beautiful-dnd";
import styles from "./styles.module.css";
import { ReactComponent as LsqDelete } from "../../assets/svgIcons/lsq_delete.svg";

export default function SectionDeleteLocator() {
  return (
    <Droppable type="Sections" droppableId="delete">
      {(provided, snapshot) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={styles["section-delete-drop"]}
          >
            <div
              style={{
                backgroundColor: snapshot.isDraggingOver
                  ? "#E75454"
                  : "#FDEFEF",
              }}
              className={styles["section-delete-locator"]}
            >
              <div
                className={styles["section-delete-locator-items"]}
                style={{
                  color: snapshot.isDraggingOver ? "#FFFFFF" : "#E75454",
                }}
              >
                <LsqDelete
                  fill={snapshot.isDraggingOver ? "white" : "#E75454"}
                />
                Drag Here To Delete
              </div>

              {provided.placeholder}
            </div>
          </div>
        );
      }}
    </Droppable>
  );
}
