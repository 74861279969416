import React, { useEffect, useState } from "react";
import { widgetConstants } from "../../constants/globalConstant";
import GalleryGridView from "./GalleryGridView";
import GalleryScrollView from "./GalleryScrollView";
import { fetchImageGalleryLinks } from "../../utils/imageGalleryUtils";

const GalleryWidgetFactory = ({ widgetInformation }) => {
  const [imgUrls, setImgUrls] = useState([]);
  const { config } = widgetInformation;
  const { layout, images } = config;
  const setImagesInCarousel = async () => {
    if (images?.length > 0 && !images[0].imageURL) {
      const imageUrls = await fetchImageGalleryLinks(images);
      setImgUrls(imageUrls);
    } else {
      setImgUrls(images);
    }
  };
  useEffect(() => {
    setImagesInCarousel();
  }, [images]);

  switch (layout) {
    case widgetConstants.GRID:
      return <GalleryGridView widgetInformation={config} imgUrls={imgUrls} />;
    case widgetConstants.SCROLL:
      return <GalleryScrollView widgetInformation={config} imgUrls={imgUrls} />;
    default:
      return <GalleryScrollView widgetInformation={config} imgUrls={imgUrls} />;
  }
};

export default GalleryWidgetFactory;
