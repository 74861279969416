import React, { useState } from "react";
import styles from "./styles.module.css";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import Text from "../Text";

export default function MoreOptionsPopup({
  menu,
  options = [],
  onClickIcon,
  menuItemClickHandler,
  optionStyle,
  ...props
}) {
  const [popUp, setPopUp] = useState(false);
  const menuRef = useOnClickOutside(() => setPopUp(false));

  const menuItemHandler = (e, label) => {
    setPopUp(false);
    menuItemClickHandler(label, menu);
    e.stopPropagation();
  };

  return (
    <div ref={menuRef} {...props}>
      <div
        onClick={() => setPopUp((prev) => !prev)}
        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
      >
        {onClickIcon}
      </div>
      <div className={`${styles["container"]}`} data-testid="menu-items">
        {popUp ? (
          <div className={`${styles["menu_container"]}`}>
            {options.map(({ label }) => {
              return (
                <div
                  className={`${styles["menu_item"]} capitalize`}
                  style={{...optionStyle}}
                  onClick={(e) => menuItemHandler(e, label)}
                  key={label}
                  data-testid="menu-item"
                >
                  <Text type="T4" color="#768196">
                    {label}
                  </Text>
                </div>
              );
            })}
          </div>
        ) : (
          <> </>
        )}
      </div>
    </div>
  );
}
