import React from "react";
import styles from "./styles.module.css";
import { ReactComponent as Preview } from "../../assets/svgIcons/lsq_preview.svg";
import Text from "../Text";
import {
  casaConstants,
  globalConstants,
  toastMessageConstants,
} from "../../constants/globalConstant";
import { getMemoizedLayout, changeToastMessage } from "../../reducers/previewPaneSlice";
import { useDispatch } from "react-redux";
import { DashboardCardMenu } from "../DashboardCardMenu";
import { teamsViewCardItems } from "../../utils/casaUtils";
import analytics from "../../utils/analytics";
import useToast from "../../hooks/useToast";
import { getStatusColor } from "../../utils/globalUtils";

const TeamsViewDashboardCard = ({
  homepage,
  setPreviewPanel,
  setDialog,
  teamId,
}) => {
  const toast = useToast(changeToastMessage);
  const dispatch = useDispatch();
  const previewIconClicked = () => {
    setPreviewPanel({
      state: true,
      data: {
        title: homepage.homepageName,
        homepage: homepage,
        sections: [],
      },
    });
    dispatch(getMemoizedLayout(homepage.homepageId)).then(
      ({ type, payload }) => {
        if (type.includes(globalConstants.FULFILLED)) {
          const publishedViewSections = payload;
          setPreviewPanel((prev) => {
            return {
              ...prev,
              data: {
                ...prev.data,
                sections: publishedViewSections,
              },
            };
          });
        } else {
          setPreviewPanel({
            state: false,
            data: null,
          });
          toast.error(toastMessageConstants.FAILED_TO_FETCH_HOMEPAGE);
        }
      }
    );
    analytics.sendEvent("homeapge_preview_tap_from_teams_view", {
      homepageId: homepage.homepageId,
      status: homepage.status,
      homepageName: homepage.homepageName,
    });
  };
  const getHomepageStatusColor = (status) => {
    let _homepageStatus = "";
    if (
      status === casaConstants.PUBLISHED &&
      homepage.hasDraftChanges
    ) {
      _homepageStatus = casaConstants.UNPUBLISHED_CHANGES;
    } else {
      _homepageStatus = status;
    }
    return getStatusColor(_homepageStatus);
  };
  const homepageStatusColorClass =
    styles[getHomepageStatusColor(homepage?.status)];
  const statusStyleClass = styles["status-style"];
  const combinedClass = `${homepageStatusColorClass} ${statusStyleClass}`;

  return (
    <div
      className={`flex relative ${styles["dashboard-card-container"]}`}
      data-testid="teams-view-dashboard-card"
    >
      <div
        className={`flex align-items-center gap-1 p-3 w-full h-full ${styles["dashboard-card"]}`}
      >
        <div
          className={combinedClass}
        ></div>
        <Text type="T3B" color={"#314361"} className={`ellipsis w-full`}>
          {homepage?.homepageName}
        </Text>
      </div>
      <div
        className={`flex w-full h-full align-items-center p-3 gap-1 justify-content-end absolute visible-on-hover ${styles["dashboard-card-hover"]}`}
      >
        <div
          className="bg-white p-1"
          style={{ borderRadius: "5px" , cursor: "pointer"}}
          onClick={() => previewIconClicked()}
          data-testid="homepage-preview"
        >
          <Preview style={{ width: "1.2rem" }} />
        </div>
        <div className="bg-white p-1" style={{ borderRadius: "5px" }}>
          <DashboardCardMenu
            items={teamsViewCardItems(homepage?.status)}
            homepage={homepage}
            setDialog={setDialog}
            teamId={teamId}
          />
        </div>
      </div>
    </div>
  );
};

export default TeamsViewDashboardCard;
