import React, { useEffect} from "react";
import Text from "../components/Text";
import { useTranslation } from "react-i18next";
import CustomInput from "../components/CustomInput";
import { useForm } from "react-hook-form";
import { CustomDropdown } from "../components/CustomDropdown/CustomDropdown";
import CustomButton from "../components/CustomButton";
import { apiAddWidget, apiUpdateWidget } from "../reducers/previewPaneSlice";
import { resetWidgetConfiguration } from "../reducers/widgetTemplateListSlice";
import { useDispatch } from "react-redux";
import useWidgetEssentials from "../hooks/useWidgetEssentials";
import { getWidgetSignature, getOptionsExistInTheList, getWidgetPreviewOfExperimentalWidget } from "../utils/widgetUtils";
import { valueTemplate, listOptionTemplate } from "../utils/dropdownUtils";
import analytics from "../utils/analytics";
import { globalConstants, stringConstants } from "../constants/globalConstant";
import ControlledMultiselect from "../components/LSQMultiSelect/ControlledMultiselect";

export const MyScorecardSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { mode, selectedWidgetConfiguration, selectedWidget, homepageId } =
    useWidgetEssentials();
  
  const { activityType , dateFilters} = selectedWidgetConfiguration.metaData;

  const defaultValues = {
    title: stringConstants.MY_SCORE_CARD,
    activityType: [],
    dateFilters : { id: "Today", name: "Today" }
  };

  let analytics_data = {
    'category': 'Widget',
    'type': 'click',
    'widgetType': 'MyScorecard',
    'activityTypeCount': 0,
  }

  const {
    control,
    formState: { errors },
    reset,
    setValue,
    handleSubmit,
  } = useForm({ defaultValues });

  useEffect(() => {
    if (mode === globalConstants.UPDATE) {
      const outdatedMetaValue = selectedWidgetConfiguration.metaValue;
      const outdatedConfig = outdatedMetaValue.config;
      const { title, dateFilters, activityType } = outdatedConfig;
      setValue("title", title);
      setValue("activityType", getOptionsExistInTheList(selectedWidgetConfiguration.metaData.activityType, activityType));
      setValue("dateFilters", dateFilters);
    }
  }, []);

  const onSubmit = (data) => {
    if(selectedWidget.isExperimental){
      getWidgetPreviewOfExperimentalWidget(selectedWidget.imageUrl, dispatch)
      return;
    }
    const config = {
      title: data.title.trim(),
      activityType: data.activityType,
      dateFilters : data.dateFilters,
    };

    const metaPostValue = getWidgetSignature(
      homepageId,
      selectedWidget.id,
      config
    );

    if (mode === globalConstants.CREATE) {
      dispatch(apiAddWidget(metaPostValue));
      analytics_data.activityTypeCount = metaPostValue.config.activityType?.length;
      analytics.sendEvent('Adding_myScorecard', analytics_data)
    } else {
      const updatedMetaValue = {
        widgetId: selectedWidgetConfiguration?.metaValue.widgetId,
        config: {
          ...metaPostValue.config,
        },
      };
      dispatch(apiUpdateWidget(updatedMetaValue));
      analytics_data.activityTypeCount = updatedMetaValue.config.activityType?.length;
      analytics_data['widgetId'] = selectedWidgetConfiguration?.metaValue.widgetId;
      analytics.sendEvent('Updating_myScorecard', analytics_data)
    }

    dispatch(resetWidgetConfiguration());
  };

  return (
    <form
      className="flex flex-column w-full relative h-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex-1 overflow-y-auto">
        <div className={`flex flex-column mt-5 lg:mx-6 md:mx-5 sm: mx-4 gap-3`}>
          <Text type="sub-heading"> {t("Widget Title")}</Text>
          <CustomInput
            control={control}
            errors={errors}
            fieldName="title"
            maxLength={30}
            className="lsq-input-bold"
            label="Display Name"
            isMandatory={true}
            data-testid="title"
          />
        </div>
        <div
          className={`flex flex-column mt-5 lg:mx-6 md:mx-5 sm: mx-4 gap-3 `}
        >
          <Text type="sub-heading">{t("Additional Configuration")}</Text>
          <ControlledMultiselect
            label = {stringConstants.SELECT_DEFAULT_ACTIVITY_TYPE}
            isMandatory = {true}
            errors={errors}
            className="w-full text-base lsq-input"
            fieldName="activityType"
            control={control}
            options={activityType}
            optionLabel="name"
            placeholder="Select"
            itemTemplate={listOptionTemplate}
            valueTemplate={valueTemplate}
            panelClassName="lsq-multiselect-panel"
            maxSelectedLabels={-1}
            id="default-activity-type"
            onChange={e => setValue("activityType" , e.target.value)}
          />
           <CustomDropdown
            fieldName="dateFilters"
            control={control}
            errors={errors}
            className="w-full text-base lsq-input"
            options={dateFilters}
            optionLabel="name"
            placeholder="Select"
            itemTemplate={listOptionTemplate}
            valueTemplate={valueTemplate}
            label={t("Select Default Date range")}
          />
        </div>
      </div>
      <div
        className={
          "flex flex-row align-items-center justify-content-end w-11 absolute bottom-0 pb-4 gap-2"
        }
      >
        <CustomButton
          type="reset"
          label={globalConstants.RESET}
          varient="text"
          onClick={() => reset({ defaultValues })}
        />
        <CustomButton
          type="submit"
          label={globalConstants.SAVE}
          varient="filled"
        />
      </div>
    </form>
  );
};
