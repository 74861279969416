import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { SplitButton } from "primereact/splitbutton";
import "./index.css";
import { useTranslation } from "react-i18next";
export default function CustomSplitButton({
  label = null,
  icon = null,
  onClick = () => {},
  type = "",
  varient = "",
  additionalOptions = [],
  dropdownIcon = null,
  ...props
}) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [menuPanelWidth, setMenuPanelWidth] = useState(0);
  const hasAdditionalOptions = additionalOptions.length !== 0;
  return (
    <SplitButton
      ref={ref}
      {...props}
      type={type}
      icon={icon}
      onClick={onClick}
      className={`lsq-split-button`}
      buttonClassName={`lsq-split-default-button ${varient}`}
      menuButtonClassName={`lsq-split-menu-button ${varient} ${
        !hasAdditionalOptions && "hidden"
      }`}
      menuClassName={`lsq-split-button-menu`}
      menuStyle={{ width: menuPanelWidth }}
      onShow={() => setMenuPanelWidth(ref.current.container.offsetWidth)}
      label={t(`${label}`)}
      model={additionalOptions}
      dropdownIcon={dropdownIcon}
    />
  );
}

CustomSplitButton.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.element,
  onClick: PropTypes.func,
  type: PropTypes.string,
  varient: PropTypes.string,
  additionalOptions: PropTypes.array,
  dropdownIcon: PropTypes.element,
};
