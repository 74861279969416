import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../WidgetList/styles.module.css";
import Text from "../Text";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as LsqAdd } from "../../assets/svgIcons/lsq_add.svg";
import { changeSelectedWidget } from "../../reducers/widgetTemplateListSlice";
import {
  apiAddWidget,
  changeSelectedSection,
  changeToastMessage,
} from "../../reducers/previewPaneSlice";
import CustomButton from "../CustomButton";
import {
  doesSectionContainStandaloneWidget,
  findFirstBlankSectionInLayout,
  findNumberOfWidgetsInASection,
  findNumberOfWidgetsInTheLayout,
  findWidgetTypeCountInTheLayout,
  isStandaloneWidgetInSection,
} from "../../utils/previewPaneUtils";
import { countLimit } from "../../utils/globalUtils";
import analytics from "../../utils/analytics";
import { getWidgetSignature, getWidgetTitle, hasConfigurationScreen } from "../../utils/widgetUtils";
import { standaloneWidgets, widgetConstants } from "../../constants/globalConstant";
import usePreviewPane from "../../hooks/usePreviewPane";

const ActiveWidgetWrapper = ({ children, widget }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const previewPane = useSelector((state) => state.previewPane);
  const { selectedSection, showCurrentChanges, hasDraftChanges, sections, smartViewCountRestrictionEnabled } =
    previewPane;
  const homepageId = useSelector((state) => state.homepage.selectedHomepageId);
  const { addNewSection } = usePreviewPane();

  let disableAdditionOfWidget = false;

  if(smartViewCountRestrictionEnabled && (widget.widgetType === widgetConstants.SmartViewCounter)){
    const widgetCount = findWidgetTypeCountInTheLayout(widget.widgetType, previewPane);
    if(widgetCount >= countLimit.SMART_VIEW_ALLOWED_WIDGET_COUNT){
      disableAdditionOfWidget = true;
    }
  }


  const findUsuableSectionForStandaloneWidget = async () => {
    let _previewPane = { ...previewPane };
    if (findNumberOfWidgetsInASection(selectedSection, previewPane) === 0)
      return _previewPane;
    const blankSectionIndex = findFirstBlankSectionInLayout(previewPane);
    if (blankSectionIndex !== -1) {
      dispatch(changeSelectedSection(blankSectionIndex));
      _previewPane.selectedSection = blankSectionIndex;
      return _previewPane;
    }
    if (sections.length < 5) {
      const payload = await addNewSection();
      _previewPane = {
        ..._previewPane,
        ...payload,
        selectedSection: sections.length,
      };
    }
    return _previewPane;
  };

  const onAddWidgetClicked = async () => {
    let _previewPane = { ...previewPane };
    if (standaloneWidgets.includes(widget.widgetType)) {
      _previewPane = await findUsuableSectionForStandaloneWidget();
    }
    if (
      (_previewPane.selectedSection !== undefined &&
        _previewPane.selectedSection < 0) ||
      _previewPane.selectedSection >= _previewPane.sections.length
    ) {
      dispatch(
        changeToastMessage({
          type: "warning",
          title: "Warning",
          message: "Please select a section to add a widget",
        })
      );
      return;
    }
    if (
      findNumberOfWidgetsInASection(
        _previewPane.selectedSection,
        _previewPane
      ) >= countLimit.WIDGET_COUNT_ACROSS_SECTION
    ) {
      dispatch(
        changeToastMessage({
          type: "warning",
          title: "Warning",
          message: `Maximum ${countLimit.WIDGET_COUNT_ACROSS_SECTION} widgets can be added in a section `,
        })
      );
      return;
    }
    if (
      findNumberOfWidgetsInTheLayout(_previewPane) >=
      countLimit.WIDGET_COUNT_ACROSS_PREVIEW_PANE
    ) {
      dispatch(
        changeToastMessage({
          type: "warning",
          title: "Warning",
          message: `Maximum ${countLimit.WIDGET_COUNT_ACROSS_PREVIEW_PANE} widgets can be added in the layout`,
        })
      );
      return;
    }
    if (
      doesSectionContainStandaloneWidget(
        _previewPane.sections[_previewPane.selectedSection]
      )
    ) {
      dispatch(
        changeToastMessage({
          type: "warning",
          title: "Warning",
          message: "To add widgets, please select another section. ",
        })
      );
      return;
    }

    if (
      isStandaloneWidgetInSection(widget.widgetType) &&
      findNumberOfWidgetsInASection(
        _previewPane.selectedSection,
        _previewPane
      ) > 0
    ) {
      dispatch(
        changeToastMessage({
          type: "warning",
          title: "Warning",
          message: "To add widgets, please select another section.",
        })
      );
      return;
    }
    if (!hasConfigurationScreen(widget.widgetType)){
      const config = {}
      const metaPostValue = getWidgetSignature(
        homepageId,
        widget.id,
        config,
      );
      dispatch(apiAddWidget(metaPostValue))
      const analytics_data = {
        category: "Widget",
        type: "click",
        widgetType: widget.widgetType,
        widgetTitle : widget.title,
      };
      analytics.sendEvent(`Adding_${widget.widgetType}`, analytics_data);
      return;
    }
    dispatch(
      changeSelectedWidget({
        mode: "CREATE",
        widgetTitle: getWidgetTitle(widget.id),
        widgetDetails: widget,
      })
    );
    analytics.sendEvent(`clicked on ${widget.title} add button`);
  };

  return (
    <div
      className={`flex justify-content-center align-items-start gap-2 ${styles["widget-wrapper"]} px-2 py-4`}
    >
      <div className="flex flex-1">{children}</div>
      <div
        className={`flex flex-1 flex-column justify-content-start ${styles["widget-content"]}`}
      >
        <Text type="T1B" color="var(--primary)">
          {t(widget.title)}
        </Text>
        <Text type="T4" color="var(--sub-text)">
          {t(widget.description)}
        </Text>
        <div>
          {disableAdditionOfWidget
            ? <div className={styles.addedBadge}>
              <span class="pi pi-check" style={{ fontSize: 12 }}></span>
              <span>Added</span>
            </div>
            : <CustomButton
              disabled={hasDraftChanges && !showCurrentChanges}
              data-testid="widget-add-btn"
              varient="outline"
              label="Add"
              icon={<LsqAdd />}
              onClick={onAddWidgetClicked}
            />
          }
        </div>
      </div>
    </div>
  );
};
export default ActiveWidgetWrapper;

