import React from 'react'
import Text from '../../components/Text'
import { useTranslation } from 'react-i18next'
import styles from '../styles.module.css'
import { stringConstants } from "../../constants/globalConstant";

export const MyOpportunities = ({title}) => {
    const { t } = useTranslation();
  return (
    <div className = {`${styles["widget-container"]}`}>
        <div className="flex flex-column absolute mt-1 ml-2">
            <Text type="sub-heading-bold" color="var(--text-focused)"> { t(title) } </Text>
        </div>
        <img 
        className="w-full"
        src={`/images/widgetPreview/my_opportunities.png`} 
        alt={stringConstants.MY_OPPORTUNITIES} />
    </div>
  )
}