import { configureStore } from "@reduxjs/toolkit";
import homepageSlice from "./reducers/homePageSlice";
import previewPaneSlice from "./reducers/previewPaneSlice";
import templatesSlice from "./reducers/templatesSlice";
import widgetTemplateListSlice from "./reducers/widgetTemplateListSlice";
import labsSlice from "./reducers/labsSlice";
import menuBuilderSlice from "./reducers/menuBuilderSlice";
import themeBuilderSlice from "./reducers/themeBuilderSlice";
import leadControlsSlice from "./reducers/leadControlsSlice";
import leadControlsAssignmentSlice from "./reducers/leadControlsAssignmentSlice";

const store = configureStore({
  reducer: {
    previewPane: previewPaneSlice,
    widgetTemplateList: widgetTemplateListSlice,
    homepage: homepageSlice,
    templates: templatesSlice,
    labs: labsSlice,
    menuBuilder: menuBuilderSlice,
    themeBuilder: themeBuilderSlice,
    leadControls: leadControlsSlice,
    leadControlsAssignment: leadControlsAssignmentSlice,
  },
});

export default store;
