import { useEffect, useRef } from "react";

export const useOnClickOutside = (callbackFn) => {
  const ref = useRef(null);

  const clickHandler = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callbackFn();
    }
  };
  useEffect(() => {
    document.addEventListener("click", clickHandler);
    return () => {
      document.removeEventListener("click", clickHandler);
    };
  }, [ref]);

  return ref;
};
