import React from "react";
import { Controller } from "react-hook-form";
import { FormErrorMessage } from "../FormErrorMessage";
import { errorMessageConstants } from "../../constants/globalConstant";
import { LSQInputTextarea } from "./LSQInputTextarea";

export const ControlledInputTextarea = ({
  fieldName,
  control,
  errorMessage,
  isMandatory,
  errors,
  id,
  ...props
}) => {
  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{
        required:
          isMandatory &&
          (errorMessage
            ? errorMessage
            : errorMessageConstants.PLEASE_TYPE_HERE),
      }}
      render={({ field }) => (
        <div>
          <LSQInputTextarea {...props} id={id} field={field} />
          {errors[fieldName] && (
            <FormErrorMessage message={errors[fieldName].message} />
          )}
        </div>
      )}
    />
  );
};
