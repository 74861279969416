import React from "react";
import styles from "./styles.module.css";
import Text from "../../../components/Text";
import { useSelector } from "react-redux";

export default function MenusMobileTemplate() {
  const { leadPageMenus } = useSelector((state) => state.leadControls);
  return (
    <div className={`flex w-full p-2 gap-1 z-0 ${styles["lead-menu-items"]}`}>
      {leadPageMenus.selectedMenus?.map((menu) => {
        return (
          <div
            className={`flex p-2 align-items-center gap-1 justify-content-center pointer-events-none ${styles["lead-menu-item"]}`}
            key={menu.id}
          >
            <Text type="T3" color="var(--text-primary)" className="ellipsis">
              {menu?.name}
            </Text>
          </div>
        );
      })}
    </div>
  );
}
