import React from "react";
import { quicklauncherConstants } from "../../constants/globalConstant";
import LauncherGridView from "./LauncherGridView";
import { LauncherScrollView } from "./LauncherScrollView";

const LauncherWidgetFactory = ({ widgetInformation }) => {
  const { config } = widgetInformation; 
  const { displayStyle } = config;
  switch(displayStyle) {
    case quicklauncherConstants.GRID : 
       return <LauncherGridView label={config.title} elementList={config.items} />
    case quicklauncherConstants.SCROLL : 
       return<LauncherScrollView label={config.title} elementList={config.items} />
    default :
       return <LauncherScrollView label={config.title} elementList={config.items} />
      
      
  }
}

export default LauncherWidgetFactory;
