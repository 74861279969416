import Cropper from "react-easy-crop";
import { useCallback, useState, useRef } from "react";
import Text from "../Text";
import { ReactComponent as ChangeImg } from "../../assets/svgIcons/change_image.svg";
import { ReactComponent as Rotate } from "../../assets/svgIcons/rotate.svg";
import { ReactComponent as Undo } from "../../assets/svgIcons/lsq_undo.svg";
import LSQSlider from "../LSQSlider";
import { FileUpload } from "primereact/fileupload";
import { bannerSettingsConstants } from "../../constants/globalConstant";
import { FormErrorMessage } from "../FormErrorMessage";
import { getImageCropperAspect } from "../../utils/globalUtils";

export const ImageEdit = ({
  aspectRatio,
  imageConfiguration,
  imageUploadHandler,
  maxFileSize
}) => {
  const [showErrorMessage , setShowErrorMessage] = useState(false)
  const [rotation, crop, zoom, originalImage] =
    imageConfiguration.watch([
      bannerSettingsConstants.ROTATION,
      bannerSettingsConstants.CROP,
      bannerSettingsConstants.ZOOM,
      bannerSettingsConstants.ORIGINAL_IMAGE,
    ]);
  const fileRef = useRef();

  const onUndo = () => {
    const [originalImage, cropPixels, imageName] = imageConfiguration.getValues([
      bannerSettingsConstants.ORIGINAL_IMAGE,
      bannerSettingsConstants.CROP_PIXELS,
      bannerSettingsConstants.IMAGE_NAME,
    ]);
    imageConfiguration.reset();
    imageConfiguration.setValue(bannerSettingsConstants.IMAGE_NAME, imageName);
    imageConfiguration.setValue(bannerSettingsConstants.ORIGINAL_IMAGE, originalImage);
    imageConfiguration.setValue(bannerSettingsConstants.CROP_PIXELS, cropPixels);
  };

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    imageConfiguration.setValue(bannerSettingsConstants.CROP_PIXELS, croppedAreaPixels);
  }, []);

  const setZoom = (zoom) => {
    imageConfiguration.setValue(bannerSettingsConstants.ZOOM, zoom);
  };

  const setCrop = (crop) => {
    imageConfiguration.setValue(bannerSettingsConstants.CROP, crop);
  };

  const setRotation = (rotation) => {
    imageConfiguration.setValue(bannerSettingsConstants.ROTATION, rotation);
  };

  return (
    <div className="flex flex-column w-full">
      <div className="relative w-full h-15rem surface-0">
        <Cropper
          image={originalImage.objectURL}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          onRotationChange={setRotation}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          showGrid={false}
          aspect={getImageCropperAspect(aspectRatio)}
        />
      </div>
      <Text
        className="flex align-self-center"
        color="#8A94A5"
        type="sub-heading"
      >
        Drag to Reposition
      </Text>
      {showErrorMessage && <FormErrorMessage message={`Please upload a file less than ${maxFileSize/1000000}mb!`}/>}
      <div className="flex align-items-center mt-5 justify-content-between">
        <LSQSlider
          step={0.1}
          min={1}
          max={3}
          zoom={1}
          label="Zoom"
          control={imageConfiguration.control}
          fieldName={bannerSettingsConstants.ZOOM}
        />
        <div className="flex align-items-center gap-2">
          <Rotate
            data-testid="banner-rotate-icon"
            className="cursor-pointer"
            onClick={() => {
              const currentRotation = imageConfiguration.getValues(bannerSettingsConstants.ROTATION);
              setRotation((currentRotation + 90) % 360)
            }}
          />
          <ChangeImg
            data-testid="banner-change-image"
            onClick={() => {
              fileRef.current.choose();
            }}
            className="cursor-pointer"
          />
          <Undo data-testid="banner-undo-icon" onClick={onUndo} className="cursor-pointer" />
          <FileUpload
            style={{ display: "none" }}
            className="w-5"
            mode="basic"
            name="banner-image"
            accept="image/jpeg,image/png,image/jpg,image/gif"
            maxFileSize={maxFileSize}
            chooseLabel="Add"
            customUpload={true}
            auto
            uploadHandler={(e) => {
              imageUploadHandler(e)
              setShowErrorMessage(false)
            }}
            onValidationFail={()=>setShowErrorMessage(true)}
            ref={fileRef}
          />
        </div>
      </div>
    </div>
  );
};
