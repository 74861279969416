import { useDispatch, useSelector } from 'react-redux'
import { apiAddNewSection, changeToastMessage } from '../reducers/previewPaneSlice';
import { countLimit } from '../utils/globalUtils';
import useToast from './useToast';
import analytics from '../utils/analytics';

const usePreviewPane = () => {
  const dispatch = useDispatch();
  const {previewPane} = useSelector(state => state)
  const previewPaneToast = useToast(changeToastMessage)
  const addNewSection = async () => {
    const {sections} = previewPane
    const sectionLength = sections.length;
    if (sectionLength >= countLimit.SECTION_COUNT) {
        previewPaneToast.warn(`Maximum ${countLimit.SECTION_COUNT} sections can be added in the layout`)
        analytics.sendEvent("Section limit reached", sectionLength);
        return;
    }
    dispatch(apiAddNewSection()).then(({ payload }) => {
      const analytics_data = {
        category: "Section",
        type: "click",
      };
      analytics.sendEvent("Adding_section", analytics_data);
      return payload;
    });

  }

  const getSections = () => {
    return previewPane
  }
  return {addNewSection, getSections}
}

export default usePreviewPane