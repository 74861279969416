import React from "react";
import { useTranslation } from "react-i18next";
import Text from "../../components/Text";
import styles from "./styles.module.css";
import CustomButton from "../../components/CustomButton";
import { ReactComponent as Retry } from "../../assets/svgIcons/lsq_retry.svg";
import { useSelector } from "react-redux";
import { ProgressBar } from "primereact/progressbar";

const ErrorScreen = ({ onReloadClicked }) => {
  const { t } = useTranslation();
  const loading = useSelector((state) => state.homepage.isHomepageLoading);
  
  return (
    <div className="flex h-screen w-screen flex-column flex-start">
      <div
        className={`flex flex-row w-full align-items-center lg:h-4rem sm:h-6rem pl-5 pr-3 py-2 gap-2 top-bar`}
      >
        <Text color="#314361" type="T1B" className="min-w-max">
          {t("Mobile Home Builder")}
        </Text>
      </div>
      {loading ? (
        <ProgressBar
          color="#007AFF"
          mode="indeterminate"
          style={{ height: "0.2rem" }}
        />
      ) : (
        <div style={{ height: "0.2rem" }} />
      )}
      <div className="flex w-full h-full align-items-center justify-content-center">
        <div className={`${styles["error-box"]}`}>
          <div className={styles["display-text-1"]}>Oops!</div>
          <div className={styles["display-text-2"]}>
            Looks like something went wrong!
          </div>
          <CustomButton
            onClick={onReloadClicked}
            icon={<Retry />}
            label="Reload"
            varient="outline"
          />
        </div>
      </div>
    </div>
  );
};

export default ErrorScreen;
