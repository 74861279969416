import React from "react";
import { useSelector } from "react-redux";
import styles from "./styles.module.css";
import Wifi from "../../assets/svgIcons/wifi.svg";
import Cellular from "../../assets/svgIcons/cellular.svg";
import Battery from "../../assets/svgIcons/battery.svg";
import Casa from "../../assets/svgIcons/casa_logo.svg";
import { ReactComponent as Settings } from "../../assets/svgIcons/settings.svg";
import { ReactComponent as Avatar } from "../../assets/svgIcons/lsq_avatar.svg";
import { ReactComponent as Chevron_Up } from "../../assets/svgIcons/lsq_chevron_up.svg";
import { ReactComponent as Chevron_Right } from "../../assets/svgIcons/lsq_chevron_right_icon.svg";
import { ReactComponent as SmartView } from "../../assets/svgIcons/lsq_smart_views.svg";
import { ReactComponent as Near_me } from "../../assets/svgIcons/lsq_near_me.svg";
import { ReactComponent as Reports } from "../../assets/svgIcons/lsq_reports.svg";
import { ReactComponent as Accounts } from "../../assets/svgIcons/lsq_accounts.svg";
import { ReactComponent as Notifications } from "../../assets/svgIcons/lsq_notifications.svg";
import { ReactComponent as Help } from "../../assets/svgIcons/lsq_help_and_feedback.svg";
import { ReactComponent as Opportunities } from "../../assets/svgIcons/lsq_opportunities.svg";
import { ReactComponent as DayPlan } from "../../assets/svgIcons/lsq_my_trips.svg";
import Text from "../../components/Text";
import { getCurrentTime } from "../../utils/globalUtils";

export default function MobileSideNavigationThemePreview({ colorPalette }) {
  const navigationTiles = [
    {
      id: "Near me",
      icon: (
        <Near_me
          color={colorPalette.secondary.S800}
          className={`${styles["mobile_preview_icons"]}`}
        />
      ),
      name: "Near me",
      backgroundColor: colorPalette.secondary.S100,
      textColor: colorPalette.secondary.S800,
      iconBackground: colorPalette.secondary.S800,
      textVarient: "sub-heading-bold",
    },
    {
      id: "Notifications",
      icon: (
        <Notifications
          color={colorPalette.neutral.N80}
          className={`${styles["mobile_preview_icons"]}`}
        />
      ),
      name: "Notifications",
      backgroundColor: colorPalette.neutral.N0,
      textColor: colorPalette.neutral.N200,
      iconBackground: colorPalette.neutral.N20,
      textVarient: "sub-heading",
    },
    {
      id: "Reports",
      icon: (
        <Reports
          color={colorPalette.neutral.N80}
          className={`${styles["mobile_preview_icons"]}`}
        />
      ),
      name: "Reports",
      backgroundColor: colorPalette.neutral.N0,
      textColor: colorPalette.neutral.N200,
      iconBackground: colorPalette.neutral.N20,
      textVarient: "sub-heading",
    },
    {
      id: "Accounts",
      icon: (
        <Accounts
          color={colorPalette.neutral.N80}
          className={`${styles["mobile_preview_icons"]}`}
        />
      ),
      name: "Accounts",
      backgroundColor: colorPalette.neutral.N0,
      textColor: colorPalette.neutral.N200,
      iconBackground: colorPalette.neutral.N20,
      textVarient: "sub-heading",
    },
    {
      id: "Help and feedback",
      icon: (
        <Help
          color={colorPalette.neutral.N80}
          className={`${styles["mobile_preview_icons"]}`}
        />
      ),
      name: "Help",
      backgroundColor: colorPalette.neutral.N0,
      textColor: colorPalette.neutral.N200,
      iconBackground: colorPalette.neutral.N20,
      textVarient: "sub-heading",
    },
  ]; // this is dummy data to show the static page with theming

  const smartViewOptions = [
    {
      name: "My New Leads",
      color: "#60BF60",
      textColor: colorPalette.neutral.N200,
      iconColor: colorPalette.neutral.N40,
    },
    {
      name: "My New Leads with pending Tasks",
      color: "#D279AA",
      textColor: colorPalette.neutral.N200,
      iconColor: colorPalette.neutral.N40,
    },
    {
      name: "My Engaged Leads",
      color: "#71D9EC",
      textColor: colorPalette.neutral.N200,
      iconColor: colorPalette.neutral.N40,
    },
    {
      name: "My customers",
      color: "#71A4E3",
      textColor: colorPalette.neutral.N200,
      iconColor: colorPalette.neutral.N40,
    },
  ]; // this is dummy data to show the static page with theming

  const navigationOptions = [
    {
      id: "Opprtunities",
      icon: (
        <Opportunities
          style={{ width: "1.2rem" }}
          color={colorPalette.neutral.N80}
          className={`${styles["mobile_preview_icons"]}`}
        />
      ),
      name: "Opprtunities",
      textColor: colorPalette.neutral.N200,
    },
    {
      id: "Day Plan",
      icon: (
        <DayPlan
          style={{ width: "1.2rem" }}
          color={colorPalette.neutral.N80}
          className={`${styles["mobile_preview_icons"]}`}
        />
      ),
      name: "Day Plan",
      textColor: colorPalette.neutral.N200,
    },
  ]; // this is dummy data to show the static page with theming

  const { user } = useSelector((state) => state.homepage);
  return (
    <div className={`mobile-screen`}>
      <div className={styles["status-bar"]}>
        <div className={styles["time"]}>{getCurrentTime()}</div>
        <div className={styles["status-bar-content"]}>
          <img alt="wifi" src={Wifi} />
          <img alt="cellular" src={Cellular} />
          <img alt="battery" src={Battery} />
        </div>
      </div>
      <div className="flex flex-column h-full overflow-hidden">
        <div className="flex justify-content-center align-items-center p-3 bg-white">
          <img alt="Logo" src={Casa} />
        </div>
        <div className="flex justify-content-center align-items-center p-3 bg-white">
          <div
            className="flex justify-content-between align-items-center p-2 w-full border-round gap-1"
            style={{ backgroundColor: colorPalette.neutral.N20 }}
          >
            <div className="flex justify-content-center align-items-center gap-1 overflow-hidden">
              <div className="relative">
                <div
                  style={{
                     mixBlendMode: 'overlay',
                    background: colorPalette.secondary.S800,
                    width: 40,
                    height: 40,
                    borderRadius: "50%",
                  }}   className="absolute  top-0"
                ></div>
                <Avatar
                  style={{
                    width: 40,
                    height: 40,
                  }}
               
                ></Avatar>
              </div>
              <div className="flex flex-column overflow-hidden">
                <Text
                  type="sub-heading-bold"
                  color={colorPalette.neutral.N600}
                >{`Hi, ${user.firstName}`}</Text>
                <Text
                  type="sub-heading"
                  color={colorPalette.neutral.N80}
                  className="ellipsis"
                >{`${user.emailAddress}`}</Text>
              </div>
            </div>
            <div className="flex justify-content-center align-items-center p-2 bg-white border-round">
              <Settings
                color={colorPalette.secondary.S800}
                className={`${styles["mobile_preview_icons"]}`}
              ></Settings>
            </div>
          </div>
        </div>
        <div className="flex h-full p-2 gap-1 flex-column overflow-scroll">
          <div className={`${styles["tile-block"]} ${styles["tab-count-5"]}`}>
            {navigationTiles?.map((tile) => {
              return (
                <div
                  key={tile.id}
                  className={`${styles["tile-view"]}`}
                  style={{ backgroundColor: tile.backgroundColor }}
                >
                  <div className={`${styles["tile-icon"]}`}>
                    <div
                      className={`flex absolute ${styles["tile-icon-background"]}`}
                      style={{ backgroundColor: tile.iconBackground }}
                    ></div>
                    <div className="z-1">{tile.icon}</div>
                  </div>
                  <Text
                    type={tile.textVarient}
                    color={tile.textColor}
                    className="overflow-wrap-anywhere"
                  >
                    {tile.name}
                  </Text>
                </div>
              );
            })}
          </div>
          <div
            className={`flex bg-white flex-column p-2 ${styles["custom-accordion-block"]}`}
          >
            <div className="flex justify-content-between align-items-center w-full p-1 mr-1">
              <div className="flex align-items-center gap-1">
                <SmartView
                  style={{ width: "1.3rem" }}
                  color={colorPalette.secondary.S800}
                  className={`${styles["mobile_preview_icons"]}`}
                />
                <Text
                  type="sub-heading-bold"
                  color={colorPalette.secondary.S800}
                >
                  {"Smart Views"}
                </Text>
              </div>
              <Chevron_Up
                color={colorPalette.secondary.S800}
                className={`${styles["mobile_preview_icons"]}`}
              ></Chevron_Up>
            </div>
            <hr
              style={{
                border: `0.5px solid ${colorPalette.neutral.N30}`,
                width: "97%",
              }}
            ></hr>
            <div className={`flex p-1 gap-1 flex-column w-full mt-1`}>
              {smartViewOptions?.map((option) => {
                return (
                  <div
                    key={smartViewOptions.name}
                    className="flex justify-content-between align-items-center w-full p-1"
                  >
                    <div className="flex align-items-center gap-1">
                      <div
                        className="flex p-1 border-round"
                        style={{ background: option.color }}
                      ></div>
                      <Text type="sub-heading" color={option.textColor}>
                        {option.name}
                      </Text>
                    </div>
                    <Chevron_Right
                      color={option.iconColor}
                      className={`${styles["mobile_preview_icons"]}`}
                    ></Chevron_Right>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className={`flex bg-white flex-column p-2 align-itmes-center ${styles["custom-accordion-block"]}`}
          >
            {navigationOptions?.map((option, index) => {
              return (
                <div
                  key={option.id}
                  className="flex flex-column justify-content-between align-items-center w-full"
                >
                  <div className="flex justify-content-between align-items-center w-full p-1">
                    <div className="flex align-items-center gap-1">
                      {option.icon}
                      <Text type="sub-heading" color={option.textColor}>
                        {option.name}
                      </Text>
                    </div>
                    <Chevron_Right
                      color={option.iconColor}
                      className={`${styles["lsq_chevron_icon"]}`}
                    ></Chevron_Right>
                  </div>
                  {navigationOptions.length > index + 1 && (
                    <hr
                      style={{
                        border: `0.5px solid ${colorPalette.neutral.N30}`,
                        width: "97%",
                      }}
                    ></hr>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div
          className="flex p-2 bg-white w-full justify-content-between"
          style={{ boxShadow: "0px -2px 8px 0px #0000000F" }}
        >
          <div className="flex flex-column">
            <div className="flex gap-1 w-full">
              <Text type="sub-heading-bold" color={colorPalette.neutral.N80}>
                {"Check-in"}
              </Text>
              <Text type="sub-heading-bold" color={colorPalette.neutral.N400}>
                {"10:45 AM"}
              </Text>
            </div>
            <Text type="sub-heading-bold" color={colorPalette.neutral.N400}>
              {"7hr 12min"}
            </Text>
          </div>
          <div
            className="flex justify-content-center align-items-center p-2 border-round w-5"
            style={{ backgroundColor: colorPalette.primary.B800 }}
          >
            <Text type="sub-heading-bold" color="var(--N0)">
              {"Check-out"}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
}
