import { widgetTemplateIds, widgetTypes } from "./globalEnums";

export const bannerSettingsConstants = {
  FAILED: "FAILED",
  SUCCESS: "SUCCESS",
  PENDING: "PENDING",
  UPLOAD_FAILED: "Upload Failed",
  UPLOADING: "Uploading..",
  SQUARE: "Square",
  WIDE: "Wide",
  DEMO_BANNER: "demoBanner",
  DEMO_SQUARE_IMAGE: "/images/square_leadsquared.png",
  DEMO_WIDE_IMAGE: "/images/wide_leadsquared.png",
  ZOOM: "zoom",
  ROTATION: "rotation",
  ORIGINAL_IMAGE: "originalImage",
  CROPPED_IMAGE_URL: "croppedImageURL",
  UPLOAD_STATUS: "uploadStatus",
  IMAGE_NAME: "imageName",
  CROP: "crop",
  CROP_PIXELS: "cropPixels",
  NAVIGATE_TO: "navigateTo",
  EXTERNAL_LINK: "externalLink",
  INTERNAL_LINK: "internalLink",
  ASPECT_RATIO: "aspectRatio",
  DISPLAY_STYLE: "displayStyle",
  END_DATE: "endDate",
  END_DATE_LABEL: "End date",
  SELECT_END_DATE: "Select end date",
  IMAGE_EXPIRED: "Image Expired",
};

export const imageGallerySettingsConstants = {
  TITLE: "title",
  SIZE: "size",
  LAYOUT: "layout",
  FRAME: "frame",
  DEMO_GALLERY_IMAGE: "demoGalleryImage",
  FAILED: "FAILED",
  SUCCESS: "SUCCESS",
  PENDING: "PENDING",
  ORIGINAL_IMAGE: "originalImage",
  IMAGE_URL: "imageURL",
  UPLOAD_STATUS: "uploadStatus",
  SQUARE: "Square",
  DEMO_SQUARE_IMAGE: "/images/square_leadsquared.png",
  LABEL: "Label",
};

export const dashboardCardConstants = {
  PUBLISHED: "published",
  UNPUBLISHED: "unpublished",
  DRAFT: "draft",
  UNPUBLISHED_CHANGES: "unpublishedchanges",
  DASHBOARD_CARD: "Dashboard card",
  SIDE_PANEL_DASHBOARD_CARD: "Side panel dashboard card",
  ADD_PAGE: "Add Page",
};

export const casaConstants = {
  ALL: "all",
  RECENTLY_MODIFIED: "recently modified",
  FAVOURITE: "favourite",
  PUBLISHED: "published",
  UNPUBLISHED: "unpublished",
  DRAFT: "draft",
  UNPUBLISHED_CHANGES: "unpublished changes",
  ALPHABETICAL: "alphabetical",
  DELETE: "delete",
  UNASSIGN: "unassign",
  CLONE: "clone",
  EDIT: "edit",
  NO_TEAMS_FOUND: "No Teams Found",
  NO_DASHBOARD_FOUND: "No Homepage Found",
  PUBLISH: "publish",
  UNPUBLISH: "unpublish",
  SEARCH_BY_TEAM: "Search by team",
  SEARCH_BY_DASHBOARD: "Search by homepage",
  SEARCH: "Search",
  CANCEL: "Cancel",
  PUBLISH_DASHBOARD: "Publish Homepage",
  TEAMS: "Teams",
  HOMEPAGES: "Homepages",
  CREATE_NEW_HOMEPAGE: "Create new Homepage",
  CREATE_NEW_MENU: "Create new Menu",
  CLONE_CURRENT_HOMEPAGE: "Clone current homepage",
  CREATE_USING_TEMPLATE: "Create using template",
  CREATE_NEW_PANEL: "Create new panel",
  CASA_ACTION_BUTTON: "Casa action button",
  PANEL: "panel",
  EXPANDED: "expanded",
  CURRENTY_USING_DEFAULT_HOMEPAGE: "Currenty using default homepage",
};

export const homepageConstants = {
  HOME_BUILDER_VIEW: "HomeBuilder",
  TEAM_ASSIGNMENT_VIEW: "TeamAssignmentView",
  TEMPLATE_VIEW: "TemplateView",
  HOMEPAGES_VIEW: "HomepagesView",
  CREATE_NEW_VIEW: "CreateNewView",
  MENU_BUILDER_VIEW: "MenuBuilderView",
  THEME_BUILDER_VIEW: "ThemeBuilderView",
  THEME_BUILDER: "Theme Builder",
  THEMING: "Theming",
  LEAD_CONTROLS: "Lead Controls",
  LEAD_CONTROLS_VIEW: "LeadControlsView",
  TEAM_COUNT_CLICKED: "TeamCountClicked",
  DASHBOARD_CARD_CLICKED: "DashboardClicked",
  HOME: "Home",
  TEAM_ASSIGNMENT: "Team Assignment",
  HOMEPAGES: "Homepages",
  TEMPLATES: "Templates",
  ADD_NEW_SECTION: "Add New Section",
  HELP_DESK: "Help desk",
  HELP_DESK_VIEW: "HelpDeskView",
  INTERNAL: "internal",
  EXTERNAL: "external",
  INLINE: "inline",
  CREATE_NEW: "Create New",
  MENU_BUILDER: "Menu Builder",
  MENUS: "Menus",
  HOMEPAGE_PUBLISHED_SUCCESSFULLY: "Homepage Published Successfully",
  PUBLISH_HOMEPAGE: "Publish Homepage",
};

export const globalConstants = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  FULL: "full",
  HALF: "half",
  MOBILE_HOME_BUILDER: "Mobile Home Builder",
  TEAM_ASSIGNMENTS: "Team Assignments",
  TEAMS: "Teams",
  HOMEPAGES: "Homepages",
  MENUS: "Menus",
  DEFAULT: "Default",
  MY_DASHBOARDS: "My Homepages",
  FULFILLED: "fulfilled",
  REJECTED: "rejected",
  PENDING: "pending",
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
  NO_AVAILABLE_OPTIONS: "No available options",
  RESET: "Reset",
  SAVE: "Save",
  BACK: "Back",
  CONFIRM: "Confirm",
  ACTIVE_WIDGETS: "ActiveWidgets",
  EXPIREMENTAL_WIDGETS: "ExperimentalWidgets",
  WIDGET_LIBRARY: "Widget Library",
  LABS: "Labs",
  EXPERIMENT_WITH_UPCOMING_WIDGET: "Experiment with upcoming widgets",
  TEAM_MANAGEMENT: "Team Management",
  MY_HOMEPAGES: "My Homepages",
  SQUARE: "Square",
  WIDE: "Wide",
  VERTICAL: "Vertical",
  CANCEL : "Cancel",
  ADD: "Add",
  PUBLISHED : "published",
};

export const ROUTES_PATH = {
  home: "/",
  teams: "/Teams",
  homepages: "/Homepages",
  menus: "/menus",
  homebuilder: "/Homebuilder",
  templates: "/Templates",
  menubuilder: "/Menubuilder",
  themebuilder: "/ThemeBuilder",
  leadControls: "/leadControls",
  leadControlBuilder : "/leadControlBuilder",
};

export const globalConstantValues = {
  MAX_NUMBER_OF_TEAM_DASHBOARD: 4,
  QUICK_LAUNCHER_LABEL_CHAR_LIMIT: 25,
  MAX_NUMBER_OF_BOTTOM_NAVIGATION_TABS: 4,
  CUSTOM_MENU_TAB_LABEL_CHAR_LIMIT: 20,
  CUSTOM_FAB_ITEM_ADD_LIMIT_FOR_SINGLE_TAP: 1,
  CUSTOM_FAB_ITEM_ADD_LIMIT_FOR_MULTI_TAP: 9,
};

export const placeholderConstants = {
  SELECT_TEAMS: "Select Teams",
  SEARCH_TEAMS: "Search Teams",
  SELECT_FORM: "Select Form",
  SELECT_WORKAREA: "Select Work Area",
  SELECT_ENTITY: "Select Entity",
  SELECT: "Select",
  SEARCH_WIDGETS: "Search Widgets",
  TYPE_HERE: "type here",
};

export const errorMessageConstants = {
  API_LAYOUT_ERROR: "FAILED TO FETCH HOMEPAGE LAYOUT",
  API_DISCARD_UNPUBLISHED_CHANGES_ERROR:
    "FAILED TO DISCARD UNPUBLISHED CHANGES",
  API_REPUBLISH_ERROR: "FAILED TO REPUBLISH",
  API_TEAMS_AND_TEAMSDASHBOARDS:
    "FAILED TO FETCH TEAMS AND TEAMSANDTEAMSDASHBOARDS",
  MANDATORY_FIELD_ERROR: "This field cannot be left blank",
  API_DELETE_HOMEPAGES: "FAILED TO DELETE HOMEPAGES",
  API_GET_USER_PREFERENCE: "FAILED TO FETCH USER PREFERENCE",
  PLEASE_TYPE_HERE: "Please type here...",
  SOMETHING_WENT_WRONG: "Something went wrong",
  ERROR_WHILE_LOADING_LEAD_TYPES:
    "Error loading the lead types, please try again.",
  PLEASE_SELECT_OPTION: "Please select an option",
};

export const tooltipConstants = {
  DISCARD_CHANGES: "Discard Changes",
  PREVIEW_LAST_PUBLISHED: "Preview Last Published",
  PREVIEW_CURRENT_CHANGES: "Preview Current Changes",
  MULTI_SELECT: "Multi-select",
  CANCEL: "Cancel",
  DELETE: "Delete",
  WIDE_IMAGE_400X200:
    "Try uploading a minimum of 400x200 px image for best results",
  SQUARE_IMAGE_400X400:
    "Try uploading a minimum of 400x400 px image for best results",
};

export const toastMessageConstants = {
  SAVE_UNSAVED_CHANGES: "Please save all the unsaved changes",
  SAVE_UNSAVED_CHANGES_BEFORE_PUBLISH:
    "Please save all the unsaved changes before publishing the layout.",
  NO_ACTIVE_WIDGETS_TO_BE_PUBLISHED: "No active widgets to be published",
  ERROR_TYPE: "error",
  SUCCESS_TYPE: "success",
  ERROR: "Error",
  SUCCESS: "Success",
  SOMETHING_WENT_WRONG: "Something went wrong, please try again!",
  FAILED_TO_FETCH_TEMPLATES: "Failed to fetch templates",
  FAILED_TO_FETCH_TEMPLATE: "Failed to fetch template",
  FAILED_TO_APPLY_TEMPLATE: "Failed to apply template",
  ADDED_TO_FAVOURITES: "Added To Favourites",
  FAILED_TO_FETCH_HOMEPAGE: "Failed to fetch homepage",
  FAILED_TO_FETCH_METADATA: "Failed to fetch widget metadata",
  MENU_UNASSIGNED_FROM_THE_TEAMS: "Menu was unassigned from the team!",
  MENU_UNASSIGNED: "Menu Unassigned",
  CUSTOM_MENU_TAB_DELETED_SUCCESSFULLY: `Custom Tab deleted successfully`,
  CUSTOM_MENU_TAB_ADDED_SUCCESSFULLY: `Custom Tab added successfully`,
  CUSTOM_MENU_TAB_UPDATED_SUCCESSFULLY: `Custom Tab updated successfully`,
  MAXIMUM_FOUR_TABS_IN_BOTTOM_NAVIGATION:
    "You can add a maximum of four tabs to the bottom navigation",
  ONE_TAB_MUST_BE_PRESENT_IN_BOTTOM_NAVIGATION:
    "A minimum of one tab must be present in the bottom navigation menu to proceed",
  ONE_TAB_MUST_BE_PRESENT_IN_SIDE_NAVIGATION:
    "A minimum of one tab must be present in the side navigation menu to proceed",
  TAB_IS_CURRENTLY_BEING_USED:
    "This tab is currently being used, please remove it to be able to delete",
  THEME_APPLIED_SUCCESSFULLY: "Theme applied successfully",
  MAXIMUM_FOUR_FIELDS_IN_LEAD_CARD: "Maximun four Fields can be added",
  MAXIMUM_FOUR_ACTIONS_IN_LEAD_CARD: "Maximun four Actions can be added",
  MAXIMUM_FIVE_ACTIONS_IN_LEAD_QUICK_ACTION:
    "Maximun five Actions can be added in lead quick actions",
  UNPUBLISHED: "Unpublished!",
  UNPUBLISHED_INFO: "Theme was unpublished successfully.",
  PUBLISHED: "Published!",
  PUBLISHED_INFO: "Theme was published successfully.",
  SAVED: "Saved!",
  SAVED_INFO: "Theme was saved successfully.",
};

export const quicklauncherConstants = {
  DISPLAY_STYLE: "Display Style",
  DISPLAY_NAME: "Display Name",
  DEMO_LAUNCHER_VIEW: "demoLauncherView",
  GRID: "Grid",
  SCROLL: "Scroll",
  EXTERNAL_LINK: "ExternalLink",
  SELECT_ICON: "Select Icon",
  SEARCH_ICONS: "Search icons",
  ICON_PICKER: "Icon Picker",
  ICON: "Icon",
  ADD_ITEM: "Add item",
  SAVE: "Save",
  ADD: "Add",
  CANCEL: "Cancel",
  LABEL: "Label",
};

export const customFabConstants = {
  FAB_TYPE: "Fab type",
  MENU: "Menu",
  DEMO_CUSTOM_FAB_VIEW: "demoCustomFabView",
  EXTERNAL_LINK: "ExternalLink",
  SINGLE_TAP: "Single tap",
  MULTI_TAP: "Multi tap",
  ICON: "Icon",
  ADD_ITEM: "Add item",
  SAVE: "Save",
  ADD: "Add",
  CANCEL: "Cancel",
  LABEL: "Label",
  SEARCH_ICONS: "Search icons",
};

export const mobileHomeBuilderConstants = {
  WIDGET_LIBRARY: "Widget Library",
  UPCOMING_WIDGETS: "Experiment with upcoming widgets",
  PREVIEW: "Preview",
  ADD: "Add",
  NO_UPCOMING_WIDGETS_FOUND: "No experimental widgets available",
  RETRY: "Retry",
  NO_WIDGETS_FOUND: "No widgets Found",
  VERSION_HISTORY: "Version History",
};

export const menuBuilderConstants = {
  MENU_BUILDER: "Menu Builder",
  BOTTOM_NAVIGATION: "Bottom Navigation",
  SIDE_NAVIGATION: "Side Navigation",
  VISIBLE_MENU_TABS: "Visible Menu Tabs",
  AVAILABLE_MENU_TABS: "Available Menu Tabs",
  CREATE_NEW_TAB: "Create New Tab",
  CUSTOM_MENU_TABS: "Custom Menu Tabs",
  DEFAULT_MENU_TABS: "Default Menu Tabs",
  MENU_CONFIGURATION: "Menu Configuration",
  MANAGE_MENU: "Manage Menu",
  PUBLISH_MENU: "Publish Menu",
  SET_AS_DEFAULT_MENU: "Set as default menu",
  UNASSIGN_MENU: "Unassign Menu",
  CREATE_MENU: "Create Menu",
  MENU_PUBLISHED_SUCCESSFULLY: "Menu Published Successfully",
  TRY_NOW: "Try Now",
  MENU_BANNER_INFO:
    "Admins can now customize bottom navigation and side navigation menus, accommodating team-specific requirements and allowing the creation of custom tabs.",
  SEARCH_MENUS: "Search Menus",
  CREATE_NEW: "Create new",
  CLONE: "Clone",
  DELETE: "Delete",
  PUBLISHED: "published",
  UNPUBLISHED: "unpublished",
  LAST_MODIFIED: "last modified",
  RECENTLY_CREATED: "recently created",
  All: "All",
  ENTER_MENU_TITLE: "Enter Menu Title",
  MENUS: "Menus",
  PUBLISH: "publish",
  UNPUBLISH: "unpublish",
  HIDE_CHECKIN_CHECKOUT: "Hide Check-in and Check-out",
  CHECKIN_CUSTOMISATIONS: "Check-in Customisations",
  CANCEL: "Cancel",
  SAVE: "Save",
  RENAME_CHECKIN: "Rename Check-In",
  RENAME_CHECKOUT: "Rename Check-Out",
};

export const themeConstants = {
  THEME_BUILDER: "Theme Builder",
  THEMING: "Theming",
  THEME_BANNER_INFO:
    "Admins can now customise the overall colour theme of the mobile app. Try exploring the configuration for theming now. Theming is not yet available for publishing.",
  THEME_BANNER_PUBLISH_INFO:
    "Theming is not yet available for publishing. You can still continue exploring the configuration and preview here.",
  THEMING_IS_COMING_SOON: "Theming is coming soon!",
  PUBLISH: "Publish",
  APPLY: "Apply",
  RESET: "Reset",
  TRY_NOW: "Try Now",
  CONFIGURE_THEMING: "Configure Theming",
  CONFIGURE: "Configure",
  OKAY: "Okay",
  DEFAULT: "default",
  ORANGE: "orange",
  GREEN: "green",
  CUSTOM: "Custom",
  SELECT_PRIMARY_COLOUR: "Select Primary Colour",
  SELECT_SECONDARY_COLOUR: "Select Secondary Colour",
  SELECT_NEUTRAL_COLOUR: "Select Neutral Colour",
};

export const leadControlsConstants = {
  LEAD_CONTROLS : "Lead Controls",
  LEAD_CARD: "Lead Card",
  CARD: "Card",
  PAGE: "Page",
  ACTIONS: "Actions",
  LEAD_CARD_VIEW: "LeadCardView",
  PAGE_VIEW: "LeadPageView",
  ACTIONS_VIEW: "LeadActionsView",
  QUICK_VIEW_CARD: "Quick view card",
  LEAD_LIST_CARD: "Lead list card",
  ADD_LEAD_IDENTIFIER: "Add Lead Identifier",
  LEAD_IDENTIFIER_MESSAGE:
    "Use the best identifier for your case. If first preference is null, system will show the second field, and so on.",
  LEAD_CARD_HELP_INFO:
    "The configured card will be shown in Leads and Smart view list by default.",
  ALL_FIELDS: "All Fields",
  SHOW_IN_LEAD_LIST_CARD: "Show in lead list card",
  CARD_ACTIONS: "Card Actions",
  TYPE_TO_SEARCH: "Type to search",
  EXPENDED: "expended",
  STANDARD: "standard",
  EMPTY: "empty",
  FILLED: "filled",
  PUBLISH: "publish",
  PUBLISHED: "published",
  CLICK_TO_ADD_FIELDS: "Click To Add Field",
  NO_NAME: "No Name",
  ADD: "Add",
  LEAD_CONTROLS_COMING_SOON: "Lead controls is coming soon!",
  LEAD_CONTROLS_PUBLISH_INFO:
    "Lead controls is not yet available for publishing. You can still continue exploring the configuration and preview here.",
  OKAY: "Okay",
  CLONE: "Clone",
  RENAME: "Rename",
  CHANGE_LEAD_TYPE: "Change Lead Type",
  QUICK_ACTIONS: "Quick Actions",
  PREVIEW_MODE: "Preview Mode",
  CREATE_PAGE: "Create Page",
  CHOOSE_AN_OPTION: "Choose an option",
  CUSTOM_LANDING_PAGE: "Custom landing page",
  CUSTOM_LANDING_PAGE_DESCRIPTION:
    "Personalize widgets to suit your needs, prioritizing essential information. Enjoy extensive customization with filters.",
  CLASSIC_MENU_TABS: "Classic Menu Tabs",
  CLASSIC_MENU_TABS_DESCRIPTION:
    "This option doesn't have a landing page. You can either hide or rearrange your menu items here.",
  RECOMMENDED: "Recommended",
  BASIC: "Basic",
  CHOOSE_PAGE_TYPE: "Choose Page type",
  CLICK_WIDGETS_TO_ADD_PAGE: "Click on the widgets to add to page",
  WIDGETS: "Widgets",
  TRY_WIDGETS: "Try widgets",
  ADD_CUSTOM_MENU: "Add Custom Menu",
  ADD_CUSTOM_WIDGET: "Add Custom Widget",
  MENU_ITEMS: "Menu Items",
  WIDGETS_PAGE: "Widgets_Page",
  MENU_TABS_PAGE: "Menu_Tabs_Page",
  EDIT_ACTION: "Edit Action",
  IDENTIFIER: "Identifier",
  CANCEL: "Cancel",
  UPDATE: "Update",
  PLEASE_SELECT_LEAD_TYPE: "Please select lead type",
  START: "Start",
  SAVE: "Save",
  SAVED: "Saved",
  DEFAULT_LEAD_CONTROLS_NAME: "Untitled Lead Details",
  RETRY: "Retry",
  SKIP_WIDGET_CONFIG: "Skip widget configuration",
  RESET_WIDGET_CONFIGURATION_MESSAGE:
    "This will mean users will not be able to see any widgets. Instead the user will land directly on the individual pages, as configured in the menu",
  BACK_TO_CONFIGURING_WIDGETS: "Back to configuring widgets?",
  RESET_MENU_TABS_MESSAGE:
    "Are you sure you want to go back to configuring widgets? ",
  ENTER_LEAD_CONTROL_TITLE :  "Enter Lead Control Title",
  PUBLISH_LEAD_CONTROL : "Publish Lead Control",
  LEAD_CONTROL_PUBLISHED_SUCCESSFULLY : "Lead control published successlully",
  CUSTOM_MENU : "Custom Menu",
  SEARCH_LEAD_CONTROLS : "Search Lead controls",
  CREATE_NEW : "Create New",
  ALL : "All",
  UNPUBLISHED: "unpublished",
  LAST_MODIFIED: "last modified",
  RECENTLY_CREATED: "recently created",
  DELETE: "Delete",
  UNPUBLISH: "unpublish",
  DRAFT : "draft",
  CLICK_THE_ACTION_TO_ADD : "Click the actions to add",
  BHUPENDRA_JOGI : "Bhupendra Jogi",
  SET_AS_DEFAULT_LEAD_CONTROL : "Set as default Lead Control"
};

export const leadControlsWidgetConstants = {
  FAILED: "FAILED",
  SUCCESS: "SUCCESS",
  PENDING: "PENDING",
  NAME: "Name",
  IDENTIFIER: "Identifier",
  ADDITIONAL_FILTERS: "Additional Filters",
  SELECT_OPPORTUNITY_TYPE: "Select Opportunity Type",
  WIDGET_TYPE: "Widget Type",
  TILE_TYPE: "Tile Type",
  SHOW_TASKS: "Show Tasks",
  SAVE: "Save",
  DETAILED: "Detailed",
  TILE: "Tile",
  CANCEL: "Cancel",
  MENU: "menu",
  WIDGET: "widget",
  CONFIRM: "Confirm",
  IMAGE: "Image",
  ICON: "Icon",
  NAVIGATE_TO: "Navigate to",
  LAUNCH: "Launch",
  SELECT: "Select Lead Fields (max 5)"
};

export const shimmerConstants = {
  CONFIGURATION_SCREEN: "configuration screen",
  WIDGET_LIST: "Widget List",
  LEAD_TYPES: "Lead Types",
};

export const apiExceptionCode = {
  HOMEPAGE_NOT_FOUND: "HOMEPAGE_NOT_FOUND",
};

export const stringConstants = {
  MY_SMART_VIEWS: "My Smart Views",
  MY_SCORE_CARD: "My Scorecard",
  LEAD_SHORTCUT: "Lead Shortcut",
  MY_OPPORTUNITIES: "My Opportunities",
  LEAD_LIST: "Lead List",
  TASK_LIST: "Task List",
  TASK_COUNTER_V2: "Task Counter 2.0",
  DISTANCE_TRAVELLED: "Distance Travelled",
  TASK_COUNTER_FUEL_BAR: "Task Counter Fuel bar",
  USER_CHECKIN_STATUS: "User Check-in Status",
  IMAGE_GALLERY: "Image Gallery",
  FIELD_BLANK: "This field cannot be left blank",
  SELECT_DEFAULT_DATE_RANGE: "Select Default Date range",
  SELECT_DEFAULT_ACTIVITY_TYPE: "Select Default Activity Type",
  SELECT_ACTIVITY_TYPE: "Select Activity Type",
  SELECT_DEFAULT_CHART_TYPE: "Select Default Chart Type",
  SUGGEST_SOMETHING: "Suggest Something",
  TYPE_HERE: "Type here...",
  NEAR_ME: "Near me",
  SAVE_VERSION: "Save version",
  NO_SAVED_VERSIONS_FOUND: "No saved versions found!",
};

export const widgetConstants = {
  TaskCounterWidget: "taskcounter",
  TaskListWidget: "tasklist",
  WebViewWidget: "webview",
  QuickLauncher: "quicklauncher",
  BannerWidget: "banner",
  LeadShortcut: "leadshortcut",
  LeadList: "leadlist",
  MyOpportunities: "myopportunities",
  SmartViewCounter: "smartviewscounter",
  MyScorecard: "myscorecard",
  ExperimentalWidgets: "experimentalWidgets",
  LeadMetrices: "leadmetrics",
  KeyLeadMetrics: "keyleadmetrics",
  NearMe: "nearme",
  TaskCounterV2: "taskcounterv2",
  DistanceTravelled: "distancetravelled",
  CustomFab: "customfab",
  TaskCounterFuelBar: "taskcounterfuelbar",
  SieraAceWidget: "sieraace",
  TilesBannerWidget: "tilesbanner",
  WIDGET_CONFIGURATIONS: "Widget Configurations",
  DISPLAY_NAME: "Display Name",
  DISPLAY_TYPE: "Display Type",
  DISPLAY_STYLE: "Display Style",
  TASK_TO_DISPLAY: "Task To Display",
  STATUS: "Status",
  ASSOCIATED_TO: "Associated To",
  WIDGET_DIMENSION: "Widget dimension",
  URL_ADDEED: "URL added",
  NO_OF_ICONS_ADDED: "No. of icons added",
  ASPECT_RATIO: "Aspect Ratio",
  NO_OF_IMAGES_ADDED: "No. of images added",
  DEFAULT_SORT_ORDER: "Default sort order",
  DEFAULT_DATE_RANGE: "Default Date Range",
  LEAD_CRITERIA: "Lead Criteria",
  OPPORTUNITY_TYPE: "Opportunity Type",
  DEFAULT_ACTIVITY_TYPE: "Default activity type",
  SMART_VIEW: "Smart View",
  SMART_VIEW_TABS: "Smart view tabs",
  EDGE_TO_EDGE: "Edge-to-edge",
  WIDGET_TITLE: "Widget Title",
  ADDITIONAL_CONFIGURATIONS: "Additional Configurations",
  LEAD_METRICS_TITLE: "Charting Widget",
  OVERDUE: "overdue",
  DEFAULT_FIELD: "Default Field",
  KEY_LEAD_METRICS_TITLE: "Key lead metrics",
  NEAR_ME_TITLE: "Near me",
  DEFAULT_LEAD_FILTER: "Default Lead Filter",
  DEFAULT_MAXIMUN_RADIUS: "Default Maximum Radius",
  SET_STATUS_ORDER: "Set Status Order",
  NAVIGATE_TO: "Navigate to",
  DONUT: "donut",
  FUELBAR: "fuelbar",
  GRID: "Grid",
  SCROLL: "Scroll",
  SELECT_LAYOUT: "Select Layout",
  SELECT_SIZE: "Select Size",
  FRAME: "Frame",
  IMAGES: "Images",
};

export const newlyLaunchedWidgetTypes = ["distancetravelled", "keyleadmetrics"];

export const promotionalBannerConstants = {
  BANNER_TITLE: "2 New freshly launched widgets!",
  BANNER_DESCRIPTION:
    "Distance travelled widget and Key lead metrics widgets are now available for you in the widget library to add to your homepage layouts.",
  TRY_NOW: "Try now",
  BANNER_IMAGES: ["distance_travelled", "key_lead_metrics"],
};

export const leadMetricsConstansts = {
  BAR: "Bar",
  LINE: "Line",
  PENDING: "pending",
  CHART_TYPE: "Chart Type",
};

export const taskCounterConfig = {
  SPEEDOMETER: "speedometer",
  NUMERIC: "numeric",
  FUELBAR: "fuelbar",
};

export const myOpportunitiesConstants = {
  OPEN: "Open",
  WON: "Won",
  LOST: "Lost",
};

export const excludedTabsInBottomNavigation = [
  "SmartViews",
  "CustomApps",
  "Journeys",
];

export const standaloneWidgets = [
  widgetTemplateIds.WEB_VIEW,
  widgetTypes.WEB_VIEW,
  widgetTemplateIds.BANNER,
  widgetTypes.BANNER,
  widgetTemplateIds.LEAD_METRICS,
  widgetTypes.LEAD_METRICS,
  widgetTemplateIds.SIERA_ACE_WEBVIEW,
  widgetTypes.SIERA_ACE_WEBVIEW,
  widgetTypes.IMAGE_GALLERY,
  widgetTemplateIds.IMAGE_GALLERY,
];

export const inRowWidgets = [
  widgetTemplateIds.TASK_COUNTER,
  widgetTypes.TASK_COUNTER,
  widgetTemplateIds.TILES_BANNER,
  widgetTypes.TILES_BANNER,
];
