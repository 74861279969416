import React from "react";
import styles from "./styles.module.css";
import { Skeleton } from "primereact/skeleton";
import { ReactComponent as Cross } from "../../assets/svgIcons/cross.svg";
import Wifi from "../../assets/svgIcons/wifi.svg";
import Cellular from "../../assets/svgIcons/cellular.svg";
import Battery from "../../assets/svgIcons/battery.svg";
import { getCurrentTime } from "../../utils/globalUtils";
import Scaffold from "../../components/Scaffold";
import { themeConstants } from "../../constants/globalConstant";
import CustomButton from "../../components/CustomButton";
import RightPanel from "../../components/RightPanel";
import ToggleTab from "../../components/ToggleTab";
import { themeConfiguratorTabs } from "../../utils/homepageUtils";
const Loader = () => {
  return (
    <div
      className="flex h-screen w-screen flex-row pl-7"
      data-testid="theme-builder-view"
    >
      <div className={`flex lg:w-9 sm:w-8`}>
        <Scaffold
          hasBackButton={false}
          rightActionButtons={
            <CustomButton
              varient="filled"
              icon={
                <i
                  className="pi pi-spin pi-spinner"
                  style={{ fontSize: "1em", padding: "0 1.5rem" }}
                />
              }
              data-testid="theme-publish-btn"
              label={""}
            />
          }
          title={themeConstants.THEMING}
        >
          <div className="flex w-full justify-content-center gap-2 relative align-items-center">
            <div className="flex gap-2 coach-theme-preview">
              <div className={`mobile-screen`}>
                <div className={styles["status-bar"]}>
                  <div className={styles["time"]}>{getCurrentTime()}</div>
                  <div className={styles["status-bar-content"]}>
                    <img alt="wifi" src={Wifi} />
                    <img alt="cellular" src={Cellular} />
                    <img alt="battery" src={Battery} />
                  </div>
                </div>
                <div className="flex flex-column overflow-hidden">
                  <div className="flex flex-column  justify-content-center align-items-center p-3 bg-white">
                    <Cross className="ml-auto mr-2  p-1 mb-3" />
                    <Skeleton className="w-full h-3rem" />
                  </div>
                  <div className="flex flex-column w-full p-2">
                    <div className="flex w-full">
                      <Skeleton className="h-4rem mr-1" />
                      <Skeleton className="h-4rem ml-1" />
                    </div>
                    <div className="flex w-full mt-2">
                      <Skeleton className="h-4rem" />
                      <Skeleton className="h-4rem mx-2" />
                      <Skeleton className="h-4rem" />
                    </div>
                    <Skeleton className="h-8rem my-2" />
                    <Skeleton className="h-4rem" />
                  </div>
                  <div className="flex flex-column  justify-content-center align-items-center p-2 bg-white pl-8">
                    <Skeleton className="w-full h-2rem" />
                  </div>
                </div>
              </div>
              <div className={`mobile-screen`}>
                <div className={styles["status-bar"]}>
                  <div className={styles["time"]}>{getCurrentTime()}</div>
                  <div className={styles["status-bar-content"]}>
                    <img alt="wifi" src={Wifi} />
                    <img alt="cellular" src={Cellular} />
                    <img alt="battery" src={Battery} />
                  </div>
                </div>
                <div className="flex flex-column  justify-content-center align-items-center p-5 bg-white"></div>
                <div className="w-full h-full px-2">
                  <Skeleton className="w-full h-full mt-6 " />
                </div>
              </div>
            </div>
          </div>
        </Scaffold>
      </div>
      <div className="flex lg:w-3 sm:w-4">
        <RightPanel>
          <div
            className={`flex flex-column w-full h-full ${styles["theme-configurator"]}`}
          >
            <div
              className={`flex align-items-center justify-content-between w-full py-3`}
            >
              <ToggleTab
                tabs={themeConfiguratorTabs}
                activeTab={themeConfiguratorTabs[0]}
                textType="T4B"
              ></ToggleTab>
            </div>
            <div
              className={`flex flex-column w-full h-full mb-3 p-2 gap-1 bg-white border-round	`}
            >
              <Skeleton className="h-4rem" />
              <Skeleton className="h-4rem" />
              <Skeleton className="h-4rem" />
              <Skeleton className="h-4rem" />
              <Skeleton className="h-2rem mt-auto" />
            </div>
          </div>
        </RightPanel>
      </div>
    </div>
  );
};

export default Loader;
