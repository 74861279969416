import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import LSQCarousel from "../../components/LSQCarousel";
import Text from "../../components/Text";
import { ReactComponent as Back } from "../../assets/svgIcons/back.svg";
import {
  apiSelectTemplate,
  apiTemplateFetcher,
  resetSelectedTemplateFetched,
} from "../../reducers/templatesSlice";
import CustomButton from "../../components/CustomButton";
import {
  addHomepage,
  changeSelectedHomepage,
  addToastMessage
} from "../../reducers/homePageSlice";
import { ROUTES_PATH, globalConstants } from "../../constants/globalConstant";
import { IndeterminateLoader } from "../../components/IndeterminateLoader";
import { countLimit } from "../../utils/globalUtils";
import useToast from "../../hooks/useToast";

const Templates = () => {
  const dispatch = useDispatch();
  const  navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { t } = useTranslation();
  const templatesCollection = useSelector(
    (state) => state.templates.templatesCollection
  );
  const loading = useSelector((state) => state.templates.loading);
  const { selectedHomepageId, homepagesById } = useSelector(
    (state) => state.homepage
  );
  const homepage = homepagesById[selectedHomepageId];
  const homepageId = homepage?.homepageId;
  const toast = useToast(addToastMessage);
  const onIndexChange = (templateId) => {
    homepageId
      ? dispatch(
          apiSelectTemplate({
            templateId,
            homepageId,
          })
        ).then(({ type }) => {
          if (type.includes(globalConstants.FULFILLED)) {
            navigate(ROUTES_PATH.homebuilder);
          }
        })
      : toast.warn(
          `Maximum ${countLimit.HOMEPAGES_COUNT} homepages can be added`
        );
  };
  useEffect(() => {
    if (selectedIndex === -1) return;
    const templateId = templatesCollection[selectedIndex].templateId;
    if (Object.keys(homepagesById).length < countLimit.HOMEPAGES_COUNT) {
      dispatch(
        apiSelectTemplate({
          templateId,
          homepageId,
        })
      ).then(({ type, payload }) => {
        if (type.includes(globalConstants.FULFILLED)) {
          if (!homepageId) {
            dispatch(addHomepage(payload));
            dispatch(changeSelectedHomepage(payload?.homepageId));
          }
          navigate(ROUTES_PATH.homebuilder);
        }
      });
    } else onIndexChange(templateId)
    return () => {
      dispatch(resetSelectedTemplateFetched());
    };
  }, [selectedIndex]);

  useEffect(() => {
    if (templatesCollection.length === 0) {
      dispatch(apiTemplateFetcher());
    }
  }, []);

  return (
    <div
      className="flex h-screen w-screen flex-column flex-start ml-7"
      data-testid="templates-view"
    >
      <div
        className={`flex flex-row w-full align-items-center lg:h-4rem sm:h-6rem pl-5 pr-3 py-2 gap-2 top-bar`}
      >
        <CustomButton
          varient="text"
          icon={<Back />}
          label=""
          onClick={() => {
            homepageId ?  navigate(ROUTES_PATH.homebuilder): navigate(ROUTES_PATH.homepages)
          }}
          data-testid="templates-back-btn"
        />
        <Text color="#314361" type="T1B" className="min-w-max">
          {t("Select Template")}
        </Text>
      </div>
      {loading ? (
        <IndeterminateLoader
          color="#0A1F43"
          height="0.25rem"
          backgroundColor="#B0B7C2"
        />
      ) : (
        <div style={{ height: "0.2rem" }} />
      )}
      <div className="flex flex-1 w-full">
        {loading === false && (
          <LSQCarousel
            items={templatesCollection}
            setSelectedItemIndex={setSelectedIndex}
            selectedItemIndex={selectedIndex}
          />
        )}
      </div>
    </div>
  );
};

export default Templates;
