export const items = [
    {
        "displayName": "Label 1",
        "icon": {
            "name": "Change Image",
            "imgName": "lsq_change_image"
        },
        "navigateTo": {
            "name": "Leads"
        },
        "id": "ddab2362-64d6-4bb2-962a-40dbd840575d"
    },
    {
        "displayName": "Label 2",
        "icon": {
            "name": "Change Image",
            "imgName": "lsq_change_image"
        },
        "navigateTo": {
            "name": "Leads"
        },
        "id": "a6687524-0f6c-4a01-9104-f6b3950b32d4"
    },
    {
        "displayName": "Label 3",
        "icon": {
            "name": "Change Image",
            "imgName": "lsq_change_image"
        },
        "navigateTo": {
            "name": "Leads"
        },
        "id": "d97ad68c-6104-451b-91c9-302d1dbada4d"
    },
    {
        "displayName": "Label 4",
        "icon": {
            "name": "Change Image",
            "imgName": "lsq_change_image"
        },
        "navigateTo": {
            "name": "Leads"
        },
        "id": "345c00ef-2335-435f-80b4-f91568ab95c1"
    },
    {
        "displayName": "Label 5",
        "icon": {
            "name": "Change Image",
            "imgName": "lsq_change_image"
        },
        "navigateTo": {
            "name": "Leads"
        },
        "id": "2c1151c5-36e3-43f2-b06c-53b07796e18f"
    },
    {
        "displayName": "Label 6",
        "icon": {
            "name": "Change Image",
            "imgName": "lsq_change_image"
        },
        "navigateTo": {
            "name": "Leads"
        },
        "id": "dc0fc15d-f794-4997-aeff-3605ff04263f"
    },
    {
        "displayName": "Label 7",
        "icon": {
            "name": "Change Image",
            "imgName": "lsq_change_image"
        },
        "navigateTo": {
            "name": "Leads"
        },
        "id": "8827693e-2058-43ca-b556-7f26ea261e32"
    },
    {
        "displayName": "Label 8",
        "icon": {
            "name": "Change Image",
            "imgName": "lsq_change_image"
        },
        "navigateTo": {
            "name": "Leads"
        },
        "id": "e5030d8b-315a-4e82-8f4f-34cfb1f5180b"
    },
    {
        "displayName": "Label 9",
        "icon": {
            "name": "Change Image",
            "imgName": "lsq_change_image"
        },
        "navigateTo": {
            "name": "Leads"
        },
        "id": "ecaf9a12-777e-479b-a2e3-5bba93044af0"
    }
]