import axios from "axios";
import axiosInstance from "./axios.instance";
import analytics from "../utils/analytics";
import { getWidgetTypeByWidgetId } from "../utils/widgetUtils";

const API_GET_PREVIEW_PANE_THUMBNAIL_UPLOAD_URL = async (homepageId) => {
  return await axiosInstance.get(
    `/homepages/thumbnail/uploadUrl/${homepageId}`
  );
};

const API_PUT_USER_PREFERENCES = async (payload) => {
  return await axiosInstance
    .put("/user-preferences", payload)
    .catch((err) => err.response);
};

const API_GET_USER_PREFERENCES = async () => {
  return await axiosInstance
    .get("/user-preferences")
    .catch((err) => err.response);
};

const API_DELETE_HOMEPAGES = async (homepages) => {
  return await axiosInstance
    .delete("/homepages", { data: { homepageIds: homepages } })
    .catch((err) => err.response);
};

const POST_DATA_FROM_API = async (endpoint, postBody) => {
  return await axiosInstance
    .post(endpoint, postBody)
    .catch((err) => err.response);
};

const API_GET_UPCOMING_WIDGETS = async () => {
  return await axiosInstance
    .get("/widget-templates/labs")
    .catch((err) => err.response);
};

const API_PUT_USER_FEEDBACK = async (widgetId, payload) => {
  return await axiosInstance
    .put(`/user-feedback/${widgetId}`, payload)
    .catch((err) => err.response);
};

const API_GET_HOMEPAGE_VERSION = async (homepageId) => {
  return await axiosInstance
    .get(`/versions/${homepageId}`)
    .catch((err) => err.response);
};

const API_REVERT_HOMEPAGE_VERSION = async (versionId, homepageId) => {
  return await axiosInstance
    .post(`versions/${versionId}/homepages/${homepageId}`)
    .catch((err) => err.response);
};

const API_NOTIFY_PREVIEW_WIDGET = async (widgetId) => {
  return await axiosInstance
    .post(`/widgets/labs/user?widgetId=${widgetId}`)
    .catch((err) => err.response);
};
const API_GET_WIDGET_LIST = async () => {
  return await axiosInstance
    .get("/widget-templates")
    .catch((err) => err.response);
};
const API_REVERT_UNPUBLISHED_CHANGES = async (homepageId) => {
  return await axiosInstance
    .post(`/homepages/${homepageId}/revert`)
    .catch((err) => err.response);
};

const API_REPUBLISH_HOMEPAGE = async (homepageId, createVersion) => {
  return await axiosInstance
    .post(`/homepages/${homepageId}/republish?createVersion=${createVersion}`)
    .catch((err) => err.response);
};
const API_GET_IS_DASHBOARD_ENABLED = async () => {
  return await axiosInstance.get("/is-enabled").catch((err) => err.response);
};
const API_GET_USER = async () => {
  return await axiosInstance.get("/self").catch((err) => err.response);
};
const API_UPDATE_TEAM_DASHBOARDS = async (teamId, postBody) => {
  return await axiosInstance
    .put(`/teams/${teamId}`, postBody)
    .catch((err) => err.response);
};

const API_ADD_HOMEPAGE = async () => {
  return await axiosInstance.post("/homepages").catch((err) => err.response);
};

const API_DELETE_HOMEPAGE = async (homepageId, postBody) => {
  return await axiosInstance
    .delete(`/homepages/${homepageId}`, {
      data: postBody,
    })
    .catch((err) => err.response);
};

const API_CLONE_HOMEPAGE = async (homepageId) => {
  return await axiosInstance
    .post(`/homepages/${homepageId}/clone`)
    .catch((err) => err.response);
};

const API_UPDATE_TEAMS_DASHBOARDS = async (homepageId, postBody) => {
  return await axiosInstance
    .put(`/teams/dashboards/${homepageId}`, postBody)
    .catch((err) => err.response);
};

const API_UPDATE_HOMEPAGE = async (homepageId, postBody) => {
  return await axiosInstance
    .put(`/homepages/${homepageId}`, postBody)
    .catch((err) => err.response);
};
const API_GET_TEAMS = async (getHierarchical) => {
  return await axiosInstance
    .get("/teams" + "?getHierarchical=" + getHierarchical)
    .catch((err) => err.response);
};

const API_GET_TEAM_DASHBOARDS = async () => {
  return await axiosInstance
    .get("/teams/dashboards")
    .catch((err) => err.response);
};

const API_DELETE_IMAGES = async (keys) => {
  const url = "/widget/bannerwidget?keys=" + keys;
  return await axiosInstance.delete(url);
};

const API_IMAGE_UPLOAD = async (uploadUrl, uploadAPIFields, file) => {
  let data = new FormData();
  Object.entries(uploadAPIFields).forEach((entry) => {
     data.append(entry[0], entry[1]);
  });
  data.append("Content-Type", file.type);
  data.append("file", file);
  return await axios.post(uploadUrl, data);
};

const API_GET_UPLOAD_URL = async (key, featureName) => {
  const url =
    "/widget/uploadUrl" +
    `${featureName ? "?featureName=" + featureName : ""}` +
    `${key ? "?=" + key : ""}`;
  return await axiosInstance.get(url);
};

const API_GET_SIGNED_URL = async (key) => {
  return await axiosInstance.get(`/widget/signedUrl/${key}`);
};

const API_PUBLISH_HOMEPAGE = async (homepageId, postBody) => {
  const response = await axiosInstance
    .post(`/homepages/${homepageId}/publish`, postBody)
    .catch((err) => err.response);
  const data = await response?.data;
  const analytics_data = {
    category: "Homepage",
    type: "click",
    homepageId,
    published_homepageId: data && data.homepageId,
  };
  analytics.sendEvent("Publish_homepage", analytics_data);
  return response;
};

const API_SELECT_TEMPLATE = async (templateId, homepageId) => {
  let url = `/homepages/template/${templateId}`;
  if (homepageId) {
    url = url + `?homepageId=${homepageId}`;
  }
  const response = await axiosInstance.post(url).catch((err) => err.response);
  const analytics_data = {
    category: "Template",
    type: "click",
    templateId,
  };
  analytics.sendEvent("Apply_template", analytics_data);
  return response;
};

const API_GET_TEMPLATE_WIDGET = async (widgetId) => {
  const response = await axiosInstance.get(`/template-widget/${widgetId}`);
  const data = await response?.data;
  return data;
};

const API_UPDATE_TEAMSDASHBOARDS_WHEN_TEAM_DELETE_FROM_PLATFORM = async (
  teamId
) => {
  return await axiosInstance.delete(`/teams/${teamId}`);
};
const API_GET_TEMPLATE_LAYOUT = async (templateId) => {
  return await axiosInstance
    .get(`/templates/${templateId}/layout`)
    .catch((err) => err.response);
};

const API_GET_TEMPLATES = async () => {
  return await axiosInstance.get(`/templates`).catch((err) => err.response);
};

const GET_DATA_FROM_API = async (metadataLink) => {
  return await axiosInstance.get(metadataLink).catch((err) => err);
};

const API_DELETE_SECTION = async (homepageId, sectionId) => {
  const response = await axiosInstance
    .delete(`homepages/${homepageId}/widgets?sectionId=${sectionId}`)
    .catch((err) => err.response);
  const analytics_data = {
    category: "Section",
    type: "drag_and_drop",
    sectionId,
  };
  analytics.sendEvent("Deleting_section", analytics_data);
  return response;
};

const API_DELETE_WIDGET = async (homepageId, sectionId, widgetId) => {
  const response = await axiosInstance
    .delete(
      `homepages/${homepageId}/widgets?sectionId=${sectionId}&widgetId=${widgetId}`
    )
    .catch((err) => err.response);
  const analytics_data = {
    category: "Widget",
    type: "click",
    widgetId,
    sectionId,
  };
  analytics.sendEvent("Deleting_widget", analytics_data);
  return response;
};

const API_UPDATE_WIDGET = async (metaValuePostData) => {
  const { config, widgetId } = metaValuePostData;
  return await axiosInstance
    .put(`/widget/${widgetId}/metavalue`, {
      config: {
        ...config,
      },
    })
    .catch((err) => err.response);
};

const API_GET_WIDGET = async (widgetId) => {
  return await axiosInstance
    .get(`/widget/${widgetId}/metavalue`)
    .catch((err) => err.response);
};

const API_ADD_WIDGET = async (metaValuePostData) => {
  return await axiosInstance
    .post("/widget/metavalue", metaValuePostData)
    .catch((err) => err.response);
};
const API_GET_HOMEPAGES = async () => {
  return await axiosInstance.get(`/homepages`).catch((err) => err.response);
};

const API_GET_HOMEPAGE = async (homepageId) => {
  return await axiosInstance
    .get(`/homepages/${homepageId}`)
    .catch((err) => err.response);
};

const API_GET_WIDGET_METADATA = async (widgetId) => {
  let widgetType = getWidgetTypeByWidgetId(widgetId);
  return await axiosInstance
    .get(`/widget/${widgetType}/metadata`)
    .catch((err) => err.response);
};

const getTaskTypes = async (url) => {
  if (url === undefined) return;
  const response = await axios({
    url: url + process.env.REACT_APP_TOKEN,
    method: "POST",
    data: {
      columnName: "",
      columnValue: "",
    },
  }).catch((err) => err);

  const data = await response?.data;
  return data;
};

const API_GET_LAYOUT = async (homepageId) => {
  return await axiosInstance
    .get(`homepages/${homepageId}/layout`)
    .catch((err) => err.response);
};

const API_UPDATE_LAYOUT = async (sections, homepageId) => {
  let updateData = {
    container: "mobile",
    sections: [...sections],
  };

  return await axiosInstance
    .put(`homepages/${homepageId}/layout`, { ...updateData })
    .catch((err) => err.response);
};

const API_GET_MENUS = async () => {
  return await axiosInstance.get("/menus").catch((err) => err.response);
};

const API_GET_TEAMS_MENUS = async () => {
  return await axiosInstance.get("menus/teams").catch((err) => err.response);
};

const API_GET_MENU = async (menuId) => {
  return await axiosInstance
    .get(`/menu/${menuId}`)
    .catch((err) => err.response);
};

const API_GET_MENU_TAB = async () => {
  return await axiosInstance.get("/menu/tab").catch((err) => err.response);
};

const API_ADD_MENU = async (postBody) => {
  return await axiosInstance
    .post(`/menu`, postBody)
    .catch((err) => err.response);
};

const API_ADD_DEFAULT_MENU = async () => {
  return await axiosInstance.post("/menu/default").catch((err) => err.response);
};

const API_UPDATE_MENU = async (menuId, postBody) => {
  return await axiosInstance
    .put(`/menu/${menuId}`, postBody)
    .catch((err) => err.response);
};

const API_DELETE_MENU = async (menuId) => {
  return await axiosInstance
    .delete(`/menu/${menuId}`)
    .catch((err) => err.response);
};

const API_CLONE_MENU = async (menuId) => {
  return await axiosInstance
    .post(`/menu/${menuId}/clone`)
    .catch((err) => err.response);
};

const API_ADD_CUSTOM_MENU_TAB = async (postBody) => {
  return await axiosInstance
    .post(`/menu/tab`, postBody)
    .catch((err) => err.response);
};

const API_DELETE_CUSTOM_MENU_TAB = async (tabId) => {
  return await axiosInstance
    .delete(`/menu/tab/${tabId}`)
    .catch((err) => err.response);
};

const API_UPDATE_CUSTOM_MENU_TAB = async (tabId, postBody) => {
  return await axiosInstance
    .put(`/menu/tab/${tabId}`, postBody)
    .catch((err) => err.response);
};

const API_GET_THEMES = async () => {
  return await axiosInstance.get("/themes").catch((err) => err.response);
};

const API_DELETE_THEMES = async (id) => {
  return await axiosInstance
    .delete(`/themes/${id}`)
    .catch((err) => err.response);
};

const API_GET_TEAMS_THEMES = async () => {
  return await axiosInstance.get("themes/teams").catch((err) => err.response);
};

const API_GET_THEME = async (themeId) => {
  return await axiosInstance
    .get(`/themes/${themeId}`)
    .catch((err) => err.response);
};

const API_ADD_THEME = async (postBody) => {
  return await axiosInstance
    .post(`/themes`, postBody)
    .catch((err) => err.response);
};

const API_EDIT_THEME = async (id, data) => {
  return await axiosInstance
    .put(`/themes/${id}`, data)
    .catch((err) => err.response);
};

const API_UPDATE_THEME = async (themeId, postBody) => {
  return await axiosInstance
    .put(`/themes/${themeId}`, postBody)
    .catch((err) => err.response);
};

const API_GET_LEAD_TYPES = async () => {
  return await axiosInstance.get(`/lead/types`).catch((err) => err.response);
};

const API_GET_LEAD_FIELDS = async () => {
  return await axiosInstance.get(`/lead/fields`).catch((err) => err.response);
};

const API_GET_LEAD_CARD_META_DATA = async () => {
  return await axiosInstance
    .get(`lead/control/card/metadata`)
    .catch((err) => err.response);
};

const API_POST_LEAD_CONTROLS = async (postBody) => {
  return await axiosInstance
    .post(`/lead/control`, postBody)
    .catch((err) => err.response);
};

const API_UPDATE_LEAD_CONTROLS = async (leadControlId, postBody) => {
  return await axiosInstance
    .put(`/lead/control/${leadControlId}`, postBody)
    .catch((err) => err.response);
};

const API_UPDATE_LEAD_CARD = async (leadControlId, cardId, postBody) => {
  return await axiosInstance
    .put(`lead/control/${leadControlId}/card/${cardId}`, postBody)
    .catch((err) => err.response);
};

const API_GET_LEAD_PAGE_MENU_TABS = async () => {
  return await axiosInstance
    .get(`lead/control/menu/tabs`)
    .catch((err) => err.response);
};

const API_GET_LEAD_PAGE_WIDGETS = async () => {
  return await axiosInstance
    .get(`lead/control/widgets`)
    .catch((err) => err.response);
};

const API_GET_LEAD_PAGE_WIDGET_METADATA = async (widgetType) => {
  return await axiosInstance
    .get(`lead/control/widget/${widgetType}/metadata`)
    .catch((err) => err.response);
};

const API_GET_LEAD_WIDGET = async (pageId, widgetId) => {
  return await axiosInstance
    .get(`lead/control/page/${pageId}/widget/${widgetId}`)
    .catch((err) => err.response);
};

const API_ADD_LEAD_WIDGET = async (pageId, postBody) => {
  return await axiosInstance
    .post(`lead/control/page/${pageId}/widget`, postBody)
    .catch((err) => err.response);
};

const API_UPDATE_LEAD_WIDGET = async (pageId, widgetId, postBody) => {
  return await axiosInstance
    .put(`lead/control/page/${pageId}/widget/${widgetId}`, postBody)
    .catch((err) => err.response);
};

const API_DELETE_LEAD_WIDGET = async (pageId, widgetId) => {
  return await axiosInstance
    .delete(`lead/control/page/${pageId}/widget/${widgetId}`)
    .catch((err) => err.response);
};

const API_UPDATE_LEAD_PAGE = async (leadControlId, pageId, postBody) => {
  return await axiosInstance
    .put(`lead/control/${leadControlId}/page/${pageId}`, postBody)
    .catch((err) => err.response);
};

const API_GET_LEAD_ACTIONS_METADATA = async () => {
  return await axiosInstance
    .get(`lead/control/actions/metadata`)
    .catch((err) => err.response);
};

const API_UPDATE_LEAD_ACTIONS = async (leadControlId, actionId, postBody) => {
  return await axiosInstance
    .put(`lead/control/${leadControlId}/action/${actionId}`, postBody)
    .catch((err) => err.response);
};

const API_PUBLISH_LEAD_CONTROL = async (leadControlId, postBody) => {
  return await axiosInstance
    .post(`lead/control/${leadControlId}/publish`, postBody)
    .catch((err) => err.response);
};

const API_UNPUBLISH_LEAD_CONTROL = async (leadControlId) => {
  return await axiosInstance
    .post(`lead/control/${leadControlId}/unpublish`)
    .catch((err) => err.response);
};

const API_UPDATE_LEAD_CONTROL_TEAM_ASSIGNMENT = async (leadControlId, postBody) => {
  return await axiosInstance
    .put(`lead/control/${leadControlId}/teams`, postBody)
    .catch((err) => err.response);
};

const API_GET_ALL_LEAD_CONTROLS = async () => {
  return await axiosInstance
    .get(`lead/controls`)
    .catch((err) => err.response);
};

const API_GET_LEAD_CONTROL_BY_ID = async (leadControlId) => {
  return await axiosInstance
    .get(`lead/control/${leadControlId}`)
    .catch((err) => err.response);
};

const API_GET_ALL_TEAM_LEAD_CONTROLS = async () => {
  return await axiosInstance
    .get(`lead/control/teams`)
    .catch((err) => err.response);
};

const API_DELETE_LEAD_CONTROL = async (leadControlId) => {
  return await axiosInstance
    .delete(`lead/control/${leadControlId}`)
    .catch((err) => err.response);
};

const API_CLONE_LEAD_CONTROL = async (leadControlId) => {
  return await axiosInstance
    .post(`lead/control/${leadControlId}/clone`)
    .catch((err) => err.response);
};

export {
  getTaskTypes,
  API_GET_WIDGET_METADATA,
  API_ADD_WIDGET,
  API_GET_HOMEPAGES,
  API_GET_HOMEPAGE,
  API_GET_WIDGET,
  API_DELETE_WIDGET,
  API_DELETE_SECTION,
  API_UPDATE_WIDGET,
  API_GET_TEMPLATES,
  API_GET_TEMPLATE_LAYOUT,
  API_GET_TEMPLATE_WIDGET,
  API_GET_LAYOUT,
  API_UPDATE_LAYOUT,
  API_SELECT_TEMPLATE,
  API_PUBLISH_HOMEPAGE,
  API_GET_UPLOAD_URL,
  API_GET_SIGNED_URL,
  API_IMAGE_UPLOAD,
  API_DELETE_IMAGES,
  GET_DATA_FROM_API,
  API_GET_TEAMS,
  API_GET_TEAM_DASHBOARDS,
  API_DELETE_HOMEPAGE,
  API_CLONE_HOMEPAGE,
  API_UPDATE_TEAMS_DASHBOARDS,
  API_UPDATE_HOMEPAGE,
  API_ADD_HOMEPAGE,
  API_UPDATE_TEAM_DASHBOARDS,
  API_REVERT_UNPUBLISHED_CHANGES,
  API_REPUBLISH_HOMEPAGE,
  API_GET_USER,
  API_UPDATE_TEAMSDASHBOARDS_WHEN_TEAM_DELETE_FROM_PLATFORM,
  API_GET_IS_DASHBOARD_ENABLED,
  POST_DATA_FROM_API,
  API_GET_WIDGET_LIST,
  API_GET_UPCOMING_WIDGETS,
  API_NOTIFY_PREVIEW_WIDGET,
  API_DELETE_HOMEPAGES,
  API_GET_USER_PREFERENCES,
  API_PUT_USER_PREFERENCES,
  API_PUT_USER_FEEDBACK,
  API_GET_HOMEPAGE_VERSION,
  API_REVERT_HOMEPAGE_VERSION,
  API_GET_PREVIEW_PANE_THUMBNAIL_UPLOAD_URL,
  API_ADD_DEFAULT_MENU,
  API_GET_MENUS,
  API_GET_TEAMS_MENUS,
  API_GET_MENU,
  API_ADD_MENU,
  API_UPDATE_MENU,
  API_DELETE_MENU,
  API_CLONE_MENU,
  API_GET_MENU_TAB,
  API_ADD_CUSTOM_MENU_TAB,
  API_DELETE_CUSTOM_MENU_TAB,
  API_UPDATE_CUSTOM_MENU_TAB,
  API_GET_THEMES,
  API_GET_TEAMS_THEMES,
  API_GET_THEME,
  API_ADD_THEME,
  API_UPDATE_THEME,
  API_GET_LEAD_TYPES,
  API_GET_LEAD_FIELDS,
  API_GET_LEAD_CARD_META_DATA,
  API_POST_LEAD_CONTROLS,
  API_UPDATE_LEAD_CARD,
  API_UPDATE_LEAD_CONTROLS,
  API_GET_LEAD_PAGE_MENU_TABS,
  API_GET_LEAD_PAGE_WIDGETS,
  API_GET_LEAD_PAGE_WIDGET_METADATA,
  API_GET_LEAD_WIDGET,
  API_ADD_LEAD_WIDGET,
  API_UPDATE_LEAD_WIDGET,
  API_DELETE_LEAD_WIDGET,
  API_UPDATE_LEAD_PAGE,
  API_GET_LEAD_ACTIONS_METADATA,
  API_UPDATE_LEAD_ACTIONS,
  API_PUBLISH_LEAD_CONTROL,
  API_UNPUBLISH_LEAD_CONTROL,
  API_UPDATE_LEAD_CONTROL_TEAM_ASSIGNMENT,
  API_GET_ALL_LEAD_CONTROLS,
  API_GET_LEAD_CONTROL_BY_ID,
  API_GET_ALL_TEAM_LEAD_CONTROLS,
  API_DELETE_LEAD_CONTROL,
  API_CLONE_LEAD_CONTROL,
  API_DELETE_THEMES,
  API_EDIT_THEME,
};
