import React from "react";
import "./index.css";
import { InputText } from "primereact/inputtext";
import PropTypes from "prop-types";
export default function CustomInputV2({
  placeholder = "",
  LeftIcon,
  RightIcon,
  leftIconOnClick,
  rightIconOnClick,
  ...props
}) {
  return (
    <span className={`flex align-items-center w-full ${LeftIcon && "p-input-icon-left"} ${RightIcon && "p-input-icon-right"}`}>
      {LeftIcon && <LeftIcon onClick = {leftIconOnClick}/>}
      <InputText
        className="lsq-inputv2"
        placeholder={placeholder}
        {...props}
      />
      {RightIcon && <RightIcon onClick = {rightIconOnClick}/>}
    </span>
  );
}
CustomInputV2.propTypes = {
  placeholder: PropTypes.string,
  LeftIcon: PropTypes.object,
};
