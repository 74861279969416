import React from "react";
import PropTypes from "prop-types";
import { TreeSelect } from "primereact/treeselect";
import { Chip } from "primereact/chip";
import { Controller } from "react-hook-form";
import Text from "../Text";
import "./index.css";
import styles from "./styles.module.css";
import {
  chipGenerator,
  onChipRemoval,
  taskTreeBuilder,
} from "./TaskTypeMultiselectDropdownUtils";
import { ReactComponent as CrossIcon } from "../../assets/svgIcons/crossIcon.svg";
import { FormErrorMessage } from "../FormErrorMessage";

export default function TaskTypeMultiselectDropdown({
  fieldName,
  control,
  isMandatory = false,
  errors,
  taskTypes,
}) {
  const valueTemplate = (length) => {
    if (length === 0) {
      return <Text type="T4">Select</Text>;
    }
    return <Text type="T5">{`${length} selected`}</Text>;
  };

  const onChangeHandler = (e) => {
    return e.value;
  };

  const taskListTree = taskTreeBuilder(taskTypes);

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{
        required: true,
        validate: (value) => {
          if (Object.keys(value).length === 0) {
            return "Please select a task";
          }
        },
      }}
      render={({ field, fieldState: { error } }) => (
        <div style={{ gap: "6px" }} className="flex w-full flex-column">
          <span className={`p-float-label lsq-tree-select`}>
            <TreeSelect
              className="lsq-tree-select w-full"
              valueTemplate={valueTemplate(chipGenerator(field.value)?.length)}
              style={error && { borderColor: "#E02020" }}
              value={field.value}
              options={taskListTree.root}
              onChange={(e) => field.onChange(onChangeHandler(e))}
              placeholder="Select"
              selectionMode="checkbox"
              panelClassName="lsq-tasktype-panel"
            ></TreeSelect>
            <label
              className="lsq-label flex align-items-center"
              htmlFor={field.name}
            >
              <Text
                color={error ? "#E02020" : "#768196"}
                type="T5"
              >
                Select Task Type
              </Text>
              {isMandatory && <span style={{ color: "#E02020" , fontSize: "0.8rem" }}> *</span>}
            </label>
          </span>
          {errors[fieldName] && (
            <FormErrorMessage message={errors[fieldName].message} />
          )}
          <div className={`flex flex-row mt-1 flex-wrap gap-1`}>
            {chipGenerator(field.value)?.map((obj) => {
              return (
                <Chip
                  className={styles["chip"]}
                  removeIcon={
                    <CrossIcon
                      key={obj.key}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        field.onChange(onChipRemoval(field.value, obj.key))
                      }
                    />
                  }
                  key={obj.key}
                  label={obj.value}
                  onRemove={() =>
                    field.onChange(onChipRemoval(field.value, obj.key))
                  }
                  removable
                />
              );
            })}
          </div>
        </div>
      )}
    />
  );
}

TaskTypeMultiselectDropdown.propTypes = {
  fieldName: PropTypes.string,
  isMandatory: PropTypes.bool,
  control: PropTypes.object,
  taskTypes: PropTypes.object,
};
