import { useFieldArray, useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import CustomButton from "../components/CustomButton";
import { CustomImageInput } from "../components/CustomImageInput";
import { ImageEdit } from "../components/ImageEdit";
import useWidgetEssentials from "../hooks/useWidgetEssentials";
import getCroppedImg from "../components/ImageEdit/getCroppedImg";
import BannerElementDragHandler from "../components/BannerElementDragHandler";
import { countLimit } from "../utils/globalUtils";
import LsqMobileNavigator from "../components/LsqMobileNavigator";
import { ControlledDatePicker } from "../components/LSQDatePicker/ControlledDatePicker";
import { toggleHasPopUpBanner } from "../reducers/labsSlice";
import { resetWidgetConfiguration } from "../reducers/widgetTemplateListSlice";
import { bannerSettingsConstants, globalConstants } from "../constants/globalConstant";
import {
  checkIsSaveDisabled,
  deleteImagesFromS3,
  getImageKeys,
} from "../utils/bannerUtils";
import {
  convertDatetoString,
} from "../utils/widgetUtils";
import {
  API_GET_UPLOAD_URL,
  API_IMAGE_UPLOAD,
} from "../api/configurationScreenServices";

export function PopUpBannerSettings() {
  const [updatedElementIndex, setupdatedElementIndex] = useState();
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { selectedWidget, selectedWidgetConfiguration } = useWidgetEssentials();
  const ref = useRef(null);
  const [navigationList, setNavigationList] = useState([
    {
      name: "External Link",
      items: [{ id: "ExternalLink", name: "-----" }],
    },
    {
      name: "Internal Navigation",
      items: [],
    },
  ]);

  const { menu } = selectedWidgetConfiguration.metaData;
  const bannerDefaultValues = {
    items: [],
  };

  const imageConfigurationDefaultValues = {
    zoom: 1,
    rotation: 0,
    crop: { x: 0, y: 0 },
    cropPixels: {},
    navigateTo: {},
    endDate: null,
    externalLink: "",
    externalLinkOptions: "",
    internalLink: {},
    originalImage: null,
    croppedImageURL: null,
    uploadStatus: null,
    imageKey: null,
    imageName: null,
    dynamicForm: {
      entity: "",
      workArea: null,
      form: {},
    },
  };

  const dispatch = useDispatch();
  const { control, handleSubmit, reset, unregister } = useForm({
    defaultValues: bannerDefaultValues,
  });
  const { fields, append, move, swap, remove, update } = useFieldArray({
    control,
    name: "items",
  });
  const imageConfiguration = useForm({
    defaultValues: imageConfigurationDefaultValues,
  });

  const selectedImage = imageConfiguration.watch(
    bannerSettingsConstants.ORIGINAL_IMAGE
  );
  const setSelectedImage = (img) => {
    imageConfiguration.setValue(bannerSettingsConstants.ORIGINAL_IMAGE, img);
    imageConfiguration.setValue(bannerSettingsConstants.IMAGE_NAME, img.name);
  };

  const imageUploadHandler = (event) => {
    const file = event.files[0];
    const imgFile = {
      objectURL: file.objectURL,
      name: file.name,
      size: file.size,
      file: file.type,
      lastModified: file.lastModified,
    };
    setSelectedImage(imgFile);
    event.options.clear();
  };

  const uploadImageInToBucket = async (imageConfigurationValues, index) => {
    let elementIndex;
    if (index !== undefined) elementIndex = index;
    else if (updatedElementIndex !== undefined)
      elementIndex = updatedElementIndex;
    else elementIndex = fields.length;
    let element = {
      ...imageConfigurationValues,
      uploadStatus: bannerSettingsConstants.PENDING,
    };
    update(elementIndex, element);
    try {
      const { croppedImageURL, imageName, imageKey } = imageConfigurationValues;
      const uploadUrlResponse = await API_GET_UPLOAD_URL(imageKey);
      const uploadUrl = uploadUrlResponse.data.url;
      const signedKey = uploadUrlResponse.data.key;
      const uploadAPIFields = uploadUrlResponse.data.fields;
      const res = await fetch(croppedImageURL);
      const blob = await res.blob();
      const file = new File([blob], imageName, { type: blob.type });
      await API_IMAGE_UPLOAD(uploadUrl, uploadAPIFields, file);
      element = {
        ...imageConfigurationValues,
        uploadStatus: bannerSettingsConstants.SUCCESS,
        imageKey: signedKey,
      };
      update(elementIndex, element);
    } catch (error) {
      element = {
        ...imageConfigurationValues,
        uploadStatus: bannerSettingsConstants.FAILED,
      };
      update(elementIndex, element);
    }
  };

  const onImageSubmit = async (data) => {
    imageConfiguration.reset();
    const istEndDate = convertDatetoString(data.endDate) || null;
    const croppedImageURL = await getCroppedImg(
      data.originalImage.objectURL,
      data.cropPixels,
      data.rotation
    );
    const imageConfigurationValues = {
      ...data,
      croppedImageURL: croppedImageURL,
      endDate: istEndDate,
    };
    if (updatedElementIndex !== undefined) {
      update(updatedElementIndex, imageConfigurationValues);
    } else {
      append(imageConfigurationValues);
    }
    uploadImageInToBucket(imageConfigurationValues);
    setupdatedElementIndex();
  };

  const onSubmit = (data) => {
    if (selectedWidget.isExperimental) {
      dispatch(toggleHasPopUpBanner({
        state : true,
        imgUrl : data.items[0].croppedImageURL
      }))
      dispatch(resetWidgetConfiguration());
    }
  };

  const onElementEdit = (index) => {
    setupdatedElementIndex(index);
    if (fields[index][bannerSettingsConstants.END_DATE]) {
      fields[index][bannerSettingsConstants.END_DATE] = new Date(
        fields[index][bannerSettingsConstants.END_DATE]
      );
    }
    Object.keys(fields[index]).forEach((key) => {
      if (key === "id") return;
      imageConfiguration.setValue(key, fields[index][key]);
    });
  };
  const onElementDeleted = (index) => {
    remove(index);
    const imageKeys = [fields[index].imageKey];
    deleteImagesFromS3(imageKeys);
  };

  const onBannerReset = () => {
    reset();
    const imageKeys = getImageKeys(fields);
    deleteImagesFromS3(imageKeys);
  };

  useEffect(() => {
    let tempNavigationList = [...navigationList];
    if (menu) {
      tempNavigationList[1].items = menu;
    }
    setNavigationList(tempNavigationList);
  }, []);

  return (
    <>
      {selectedImage !== null ? (
        <form
          style={{ height: "90%" }}
          className="flex flex-column w-full"
          onSubmit={imageConfiguration.handleSubmit(onImageSubmit)}
        >
          <div className="overflow-y-auto">
            <div className={`flex flex-column mt-5 mx-5 gap-3`}>
              <ImageEdit
                src={selectedImage}
                imageConfiguration={imageConfiguration}
                aspectRatio={globalConstants.VERTICAL}
                imageUploadHandler={imageUploadHandler}
                maxFileSize={countLimit.BANNER_MAX_FILE_SIZE}
              />
              <ControlledDatePicker
                id="end-date"
                fieldName="endDate"
                className="w-full text-base lsq-input"
                isMandatory={false}
                control={imageConfiguration.control}
                errors={imageConfiguration.formState.errors}
                label={bannerSettingsConstants.END_DATE_LABEL}
                data-testid="popup-end-date"
                minDate={new Date()}
                dateFormat="dd/mm/yy"
                placeholder={bannerSettingsConstants.SELECT_END_DATE}
              />
              <LsqMobileNavigator
                formValues={imageConfiguration}
                mobileNavigationMenu={navigationList}
                unregister={unregister}
                edit={edit}
                setEdit={setEdit}
                isMandatory={false}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
              />
            </div>
          </div>

          <div
            className={`flex w-11 py-2 mt-3 align-self-center align-items-center justify-content-end mt-auto gap-3`}
          >
            <CustomButton
              type="reset"
              onClick={() => {
                imageConfiguration.reset();
                setupdatedElementIndex();
              }}
              varient="text"
              label={globalConstants.BACK}
            />
            <CustomButton type="submit" varient="filled" label="Confirm" />
          </div>
        </form>
      ) : (
        <form
          style={{ height: "90%" }}
          className="flex flex-column w-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className=" overflow-y-auto">
            <div className={`flex flex-column mt-5 mx-5 gap-3`} ref={ref}>
              <CustomImageInput
                imageUploadHandler={imageUploadHandler}
                maxFileSize={countLimit.BANNER_MAX_FILE_SIZE}
                isImageInputDisabled={fields.length >= 1}
              />
              <BannerElementDragHandler
                dragAndDrop={false}
                onElementEdit={onElementEdit}
                onElementDeleted={onElementDeleted}
                aspectedRatio={globalConstants.SQUARE}
                elementList={fields}
                move={move}
                swap={swap}
                remove={remove}
                uploadImageInToBucket={uploadImageInToBucket}
                setEdit={setEdit}
              />
            </div>
          </div>
          <div
            className={`flex w-11 py-2 mt-3 align-self-center align-items-center justify-content-end mt-auto gap-3`}
          >
            <CustomButton
              disabled={fields.length === 0}
              type="reset"
              onClick={onBannerReset}
              varient="text"
              label={globalConstants.RESET}
            />

            <CustomButton
              disabled={checkIsSaveDisabled(fields)}
              type="submit"
              varient="filled"
              label={globalConstants.SAVE}
              data-testid="popup-banner-save-btn"
            />
          </div>
        </form>
      )}
    </>
  );
}
