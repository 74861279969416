import { useState, useEffect, useRef } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import styles from "./styles.module.css";
import { ReactComponent as Icon } from "../assets/svgIcons/lsq_change_image.svg";
import { ReactComponent as Add } from "../assets/svgIcons/lsq_add.svg";
import CustomButton from "../components/CustomButton";
import CustomInput from "../components/CustomInput";
import CustomRadioGroup from "../components/CustomRadioGroup";
import { NavigationMenu } from "../constants/globalEnums";
import { iconList } from "../utils/iconList";
import Text from "../components/Text";
import {
  iconPickerTemplate,
  selectedIconTemplate,
} from "../utils/dropdownUtils";
import { CustomIconPicker } from "../components/CustomIconPicker/CustomIconPicker";
import LauncherElementDragHandler from "../components/LauncherElementDragHandler";
import { v4 as uuidv4 } from "uuid";
import useWidgetEssentials from "../hooks/useWidgetEssentials";
import { getUpdatedName } from "../utils/widgetUtils";
import LsqMobileNavigator from "../components/LsqMobileNavigator";
import {
  customFabConstants,
  globalConstantValues,
  placeholderConstants,
} from "../constants/globalConstant";
import { toggleHasCustomFab } from "../reducers/labsSlice";
import { resetWidgetConfiguration } from "../reducers/widgetTemplateListSlice";

export function CustomFabSettings() {
  const [isDisabled, setIsDisabled] = useState(true);
  const iconPickerRef = useRef(null);
  const [iconPanelWidth, setIconPanelWidth] = useState(0);
  const [addItemClicked, setAddItemClicked] = useState(false);
  const [updatedElementIndex, setupdatedElementIndex] = useState();
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [navigationList, setNavigationList] = useState([
    {
      name: "External Link",
      items: [{ id: "ExternalLink", name: "-----" }],
    },
    {
      name: "Internal Navigation",
      items: [],
    },
  ]);
  const dispatch = useDispatch();
  const { selectedWidget, mode, selectedWidgetConfiguration } =
    useWidgetEssentials();

  const { fabType } = selectedWidgetConfiguration.metaData;

  const defaultItem = {
    displayName: "",
    icon: "",
    navigateTo: "",
    internalLink: {},
    externalLink: "",
externalLinkOptions: "",
    dynamicForm: {
      entity: "",
      workArea: null,
      form: {},
    },
  };
  const curItem = useForm({
    defaultValues: defaultItem,
  });
  const defaultValues = {
    fabType: fabType?.length > 0 && fabType[0].value,
    items: [],
  };
  const { control, handleSubmit, unregister, watch } = useForm({
    defaultValues,
  });
  const { fields, append, remove, swap, update, move } = useFieldArray({
    control,
    name: "items",
    rules: { maxLength: 9 },
  });
  const fabTypeWatch = watch("fabType");
  const watchAll = curItem.watch();

  const addItemMaximumLimit =
    fabTypeWatch === customFabConstants.SINGLE_TAP
      ? globalConstantValues.CUSTOM_FAB_ITEM_ADD_LIMIT_FOR_SINGLE_TAP
      : globalConstantValues.CUSTOM_FAB_ITEM_ADD_LIMIT_FOR_MULTI_TAP;

  const onElementAddClicked = (e) => {
    let element;
    if (updatedElementIndex !== undefined) {
      element = {
        ...curItem.getValues(),
        id: uuidv4(),
      };
      update(updatedElementIndex, element);
    } else {
      element = {
        ...e,
        displayName: e.displayName.trim(),
        id: uuidv4(),
      };
      append(element);
    }
    curItem.reset();
    setupdatedElementIndex();
    setAddItemClicked(false);
  };

  const onSubmit = () => {
    if (selectedWidget.isExperimental) {
      dispatch(toggleHasCustomFab(true));
      dispatch(resetWidgetConfiguration());
    }
  };

  const onElementEdit = (index) => {
    setAddItemClicked(true);
    setupdatedElementIndex(index);
    setEdit(true);
    const { displayName, icon, navigateTo, internalLink, externalLink, externalLinkOptions } =
      fields[index];
    let updatedNavigateTo = navigateTo;
    if (navigateTo.id !== customFabConstants.EXTERNAL_LINK) {
      updatedNavigateTo = getUpdatedName(
        selectedWidgetConfiguration.metaData.menu,
        navigateTo
      );
      updatedNavigateTo = updatedNavigateTo || null;
    }
    curItem.setValue("displayName", displayName, { shouldDirty: true });
    curItem.setValue("icon", icon, { shouldDirty: true });
    curItem.setValue("navigateTo", updatedNavigateTo, { shouldDirty: true });
    curItem.setValue("internalLink", internalLink, { shouldDirty: true });
    curItem.setValue("externalLink", externalLink, { shouldDirty: true });
    curItem.setValue("externalLinkOptions", externalLinkOptions, { shouldDirty: true });
  };

  useEffect(() => {
    let tempNavigationList = [...navigationList];
    if (selectedWidgetConfiguration.metaData?.menu) {
      tempNavigationList[1].items = selectedWidgetConfiguration.metaData?.menu;
    }
    setNavigationList(tempNavigationList);
  }, []);

  useEffect(() => {
    const dirtyFieldLength = Object.keys(curItem.formState.dirtyFields).length;
    const navigateTo = watchAll["navigateTo"];
    if (isLoading) setIsDisabled(true);
    else if (dirtyFieldLength < 3) {
      if (!isDisabled) setIsDisabled(true);
    } else if (
      navigateTo?._links?.subMenu?.href !== undefined &&
      navigateTo.name !== NavigationMenu.DYNAMIC_FORMS &&
      dirtyFieldLength <= 3
    ) {
      if (!isDisabled) setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [watchAll]);

  return (
    <form
      style={{ height: "90%" }}
      className="flex flex-column w-full scroll-bar-visible"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex-1 overflow-y-auto">
        <div className={`flex flex-column mt-5 lg:mx-6 md:mx-5 sm: mx-4 gap-3`}>
          <CustomRadioGroup
            control={control}
            label={customFabConstants.FAB_TYPE}
            options={fabType}
            optionLabelValue={true}
            radioName="fabType"
            data-testid="fabType"
          />
          {fields.length >= 1 && (
            <LauncherElementDragHandler
              onElementEdit={onElementEdit}
              elementList={
                fabTypeWatch === customFabConstants.SINGLE_TAP
                  ? [{ ...fields[0] }]
                  : fields
              }
              remove={remove}
              swap={swap}
              move={move}
              updatedElementIndex={updatedElementIndex}
            />
          )}
          {(fields.length < addItemMaximumLimit ||
            updatedElementIndex !== undefined) &&
            (addItemClicked ? (
              <div className={`flex flex-column gap-2`}>
                <div className={`flex w-full gap-1`} ref={iconPickerRef}>
                  <CustomIconPicker
                    id="icon-list"
                    fieldName="icon"
                    control={curItem.control}
                    className="w-full text-base lsq-input lsq-icon-picker"
                    options={iconList}
                    optionLabel="name"
                    filter
                    filterBy="name"
                    resetFilterOnHide={true}
                    filterPlaceholder={customFabConstants.SEARCH_ICONS}
                    placeholder={
                      <Icon className={`flex ${styles["icon-placeholder"]}`} />
                    }
                    valueTemplate={selectedIconTemplate}
                    itemTemplate={iconPickerTemplate}
                    label={customFabConstants.ICON}
                    isMandatory={true}
                    errors={curItem.formState.errors}
                    panelClassName="lsqIconPickerPanel"
                    onShow={() => {
                      setIconPanelWidth(iconPickerRef.current?.offsetWidth);
                    }}
                    panelStyle={{ width: iconPanelWidth }}
                    containerStyle={{ width: "20%" }}
                  />
                  <CustomInput
                    id={"custominput"}
                    errors={curItem.formState.errors}
                    fieldName="displayName"
                    control={curItem.control}
                    isMandatory
                    label={customFabConstants.LABEL}
                    maxLength={
                      globalConstantValues.QUICK_LAUNCHER_LABEL_CHAR_LIMIT
                    }
                    placeholder={placeholderConstants.TYPE_HERE}
                    containerStyle={{ width: "80%" }}
                  />
                </div>
                <LsqMobileNavigator
                  formValues={curItem}
                  mobileNavigationMenu={navigationList}
                  unregister={unregister}
                  edit={edit}
                  setEdit={setEdit}
                  isMandatory={true}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                  mode={mode}
                />
                <div
                  className={`flex w-12 py-2 align-self-center align-items-center justify-content-end mt-auto gap-2`}
                >
                  <CustomButton
                    type="reset"
                    onClick={() => {
                      curItem.reset();
                      setupdatedElementIndex();
                      setAddItemClicked(false);
                    }}
                    varient="text"
                    label={customFabConstants.CANCEL}
                  />
                  <CustomButton
                    data-testid="add-btn"
                    disabled={isDisabled}
                    className="flex justify-content-center w-3"
                    onClick={curItem.handleSubmit((e) =>
                      onElementAddClicked(e)
                    )}
                    label={
                      updatedElementIndex !== undefined
                        ? customFabConstants.SAVE
                        : customFabConstants.ADD
                    }
                    varient="filled"
                  />
                </div>
              </div>
            ) : (
              <div
                className={`flex gap-1 align-items-center p-2 border-round cursor-pointer ${styles["laucher-add-item"]}`}
                onClick={() => setAddItemClicked(true)}
                data-testid="add-fab-item"
              >
                <Add fill="var(--icon-primary)" />
                <Text type="T4B" color="var(--text-primary)">
                  {customFabConstants.ADD_ITEM}
                </Text>
              </div>
            ))}
        </div>
      </div>
      <div
        className={`flex w-11 py-2 mt-3 align-self-center align-items-center justify-content-end mt-auto gap-2`}
      >
        <CustomButton
          disabled={fields.length === 0}
          type="submit"
          varient="filled"
          label={customFabConstants.SAVE}
        />
      </div>
    </form>
  );
}
