import React from "react";
import styles from "./styles.module.css";
import Text from "../Text";
import { QuickLauncherIcons } from "../../assets/icons/QuickLauncherIcons";
import PropTypes from "prop-types";

export default function LsqItemTile({
  label,
  hasTileImage = false,
  tileImageName,
  hasTileActionImg = false,
  tileActionImg,
  tileImageProps = {},
  isTileClickable = true,
  onTileClick,
  disabled = false,
  ...props
}) {
  return (
    <div
      className={`flex align-items-center p-2 justify-content-between w-full gap-1 ${
        styles["item-tile"]
      } 
      ${isTileClickable && `cursor-pointer`}
      ${disabled && 'pointer-events-none'}`}
      data-testid="lsq-item-tile"
      {...props}
      onClick={onTileClick}
    >
      <div className="flex justify-content-center align-items-center gap-1">
        <div className="flex align-items-center gap-2">
          {hasTileImage && (
            <div className="flex justify-content-center" {...tileImageProps}>
              <QuickLauncherIcons
                icon={tileImageName}
                style={{
                  filter: disabled ? "opacity(0.3)" : "opacity(0.7)",
                  width: "1.2rem",
                }}
              />
            </div>
          )}
          <Text
            type="sub-heading"
            color={disabled ? "var(--N60)" : "var(--icon-primary)"}
            className="overflow-wrap-anywhere"
          >
            {label}
          </Text>
        </div>
      </div>
      {hasTileActionImg && tileActionImg}
    </div>
  );
}

LsqItemTile.propTypes = {
  label: PropTypes.string,
  tileImageName: PropTypes.string,
  tileActionImg: PropTypes.element,
  onTileClick: PropTypes.func,
  disabled: PropTypes.bool,
  hasTileImage: PropTypes.bool,
  hasTileActionImg: PropTypes.bool,
};
