import { Controller } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import Text from "../Text";
import "./index.css";
import { FormErrorMessage } from "../FormErrorMessage";
import LsqProgressSpinner from "../LsqProgressSpinner";
import {
  globalConstants,
  errorMessageConstants,
} from "../../constants/globalConstant";
import { ReactComponent as NoResultsFound } from "../../assets/illustrations/no_results_found.svg";

export const CustomIconPicker = ({
  fieldName,
  control,
  label,
  errors,
  id,
  isMandatory,
  errorMessage,
  containerStyle,
  showLabel = true,
  ...props
}) => {
  const emptyMessage=() => {
    if (props.options?.length === 0) {
      return <div>{globalConstants.NO_AVAILABLE_OPTIONS}</div>;
    } else return <LsqProgressSpinner strokeColor={"#314361"} />;
  }
  const emptyFilterMessage=(e) => {
    return (
      <div className="flex flex-column justify-items-center align-items-center w-full">
        <NoResultsFound></NoResultsFound>
        <Text color="var(--sub-text)" type="T5">
          {`No results for ${e.filterValue}`}
        </Text>
      </div>
    );
  }
  return (
    <div className="field" style={{ marginBottom: "0px", ...containerStyle }}>
      <Controller
        name={fieldName}
        control={control}
        rules={{
          required: isMandatory,
          validate: (value) => {
            if (isMandatory) {
              if (value.name === "" || value.name === undefined) {
                if (errorMessage) return errorMessage;
                return errorMessageConstants.MANDATORY_FIELD_ERROR;
              }
              return true;
            } else {
              return true;
            }
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <span className={`p-float-label lsq-dropdown`}>
            <Dropdown
              emptyMessage={emptyMessage}
              emptyFilterMessage={emptyFilterMessage}
              appendTo={"self"}
              id={id}
              {...field}
              {...props}
              style={error && { borderColor: "var(--R800)" }}
            />
            {showLabel && (
              <label
                className="lsq-label flex align-items-center"
                htmlFor={field.name}
              >
                <Text
                  color={error ? "var(--R800)" : "var(--sub-text)"}
                  type="T5"
                >
                  {label}
                </Text>
                {isMandatory && (
                  <span style={{ color: "var(--R800)", fontSize: "0.8rem" }}>
                    {" "}
                    *
                  </span>
                )}
              </label>
            )}
            {errors[fieldName] && (
              <FormErrorMessage message={errors[fieldName].message} />
            )}
          </span>
        )}
      />
    </div>
  );
};
