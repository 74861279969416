import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Text from "../../../components/Text";
import { ReactComponent as Delete } from "../../../assets/svgIcons/lsq_delete.svg";
import {
  globalConstants,
  shimmerConstants,
  toastMessageConstants,
} from "../../../constants/globalConstant";
import {
  addToastMessageFromLeadControls,
  deleteLeadWidget,
  getLeadPageWidgetMetaData,
  getLeadPageWidgetMetaValue,
  resetLeadWidgetConfiguration,
} from "../../../reducers/leadControlsSlice";
import useToast from "../../../hooks/useToast";
import LeadWidgetsSettingsFactory from "./LeadWidgetsSettingsFactory";
import { Skeleton } from "primereact/skeleton";
import { Shimmer } from "../../../components/Shimmer";

const LeadWidgetsConfigurationScreen = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const toast = useToast(addToastMessageFromLeadControls);
  const { mode, widgetTitle, widgetDetails } = useSelector(
    (state) => state.leadControls.selectedLeadWidget
  );
  const showWidgetConfigrationScreen = !!mode;

  const onDeleteWidget = () => {
    if (mode === globalConstants.UPDATE) {
      dispatch(
        deleteLeadWidget({
          pageId: widgetDetails.pageId,
          widgetId: widgetDetails?.id,
        })
      );
    } else dispatch(resetLeadWidgetConfiguration());
  };

  useEffect(() => {
    if (mode === globalConstants.CREATE) {
      const widgetType = widgetDetails?.id;
      dispatch(getLeadPageWidgetMetaData(widgetType))
        .then(({ type }) => {
          if (type.includes(globalConstants.FULFILLED)) setLoading(false);
          else {
            toast.error(toastMessageConstants.SOMETHING_WENT_WRONG);
            dispatch(resetLeadWidgetConfiguration());
          }
        })
        .catch((err) => err);
    } else if (mode === globalConstants.UPDATE) {
      const widgetId = widgetDetails?.id;
      Promise.all([
        dispatch(getLeadPageWidgetMetaData(widgetDetails?.config.widgetType)),
        dispatch(
          getLeadPageWidgetMetaValue({
            pageId: widgetDetails.pageId,
            widgetId: widgetId,
          })
        ),
      ]).then((value) => {
        if (
          value[0].type.includes(globalConstants.FULFILLED) &&
          value[1].type.includes(globalConstants.FULFILLED)
        )
          setLoading(false);
        else {
          toast.error(toastMessageConstants.SOMETHING_WENT_WRONG);
          dispatch(resetLeadWidgetConfiguration());
        }
      });
    }
  });
  if (!showWidgetConfigrationScreen) return <></>;
  return (
    <div className={`flex flex-column flex-start h-full p-3 gap-2`}>
      <div className="flex justify-content-between align-items-center">
        {loading ? (
          <Skeleton
            className="ml-5"
            width="30%"
            borderRadius="8px"
            style={{ backgroundColor: "#E2ECF9" }}
          />
        ) : (
          <>
            <Text type="T1B" color="var( --text-primary)">
              {widgetTitle}
            </Text>
            <div
              className={`flex cursor-pointer align-items-center justify-content-center border-round h-2 w-2 `}
              style={{ background: "var(--R100)" }}
              onClick={onDeleteWidget}
              data-testid="configuration-page-delete-btn"
            >
              <Delete fill="var(--R700)" style={{ width: "1.2rem" }} />
            </div>
          </>
        )}
      </div>
      <div className={`flex flex-column h-full overflow-hidden`}>
        {loading ? (
          <Shimmer label={shimmerConstants.CONFIGURATION_SCREEN} />
        ) : (
          <LeadWidgetsSettingsFactory
            widgetType={
              mode === globalConstants.CREATE
                ? widgetDetails.id
                : widgetDetails?.config?.widgetType
            }
          />
        )}
      </div>
    </div>
  );
};

export default LeadWidgetsConfigurationScreen;
