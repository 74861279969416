import React from "react";
import Text from "../../components/Text";
import { QuickLauncherIcons } from "../../assets/icons/QuickLauncherIcons";
import { ReactComponent as Add } from "../../assets/svgIcons/lsq_add.svg";
import { ReactComponent as Delete } from "../../assets/svgIcons/lsq_delete.svg";
import { ReactComponent as Edit } from "../../assets/svgIcons/lsq_edit.svg";
import styles from "./styles.module.css";

export default function ItemAddList({
  id,
  items = [],
  onAddItemClick,
  hasDeletebutton = false,
  onDeleteIconClicked,
  hasEditbutton = false,
  onEditIconClicked,
}) {
  
  return (
    <div className="flex flex-column w-full" data-testid="item-add-list">
      {items?.map((item, index) => {
        return (
          <div
            className={`flex p-2 flex-row align-items-center justify-content-between ${styles["item-add-card"]}`}
            key={id}
            id={id + index}
          >
            <div className="flex align-items-center gap-2">
              <QuickLauncherIcons
                icon={item.imgName || "lsq_leads"}
                style={{ filter: "opacity(0.7)", width: "1.2rem" }}
              ></QuickLauncherIcons>
              <Text
                type="sub-heading"
                color="var(--icon-primary)"
                className="overflow-wrap-anywhere"
              >
                {item.name}
              </Text>
            </div>
            <div className={`flex gap-1 align-items-center`}>
              <div
                className={`flex gap-1 align-items-center ${styles["card-actions"]}`}
              >
                {hasEditbutton && (
                  <Edit
                    style={{
                      cursor: "pointer",
                      width: "1rem",
                      fill: "var(--icon-primary)",
                    }}
                    onClick={() => onEditIconClicked(index, item)}
                    data-testid="edit-button"
                  ></Edit>
                )}
                {hasDeletebutton && (
                  <Delete
                    style={{
                      cursor: "pointer",
                      width: "1rem",
                      fill: "var(--R800)",
                    }}
                    onClick={() => onDeleteIconClicked(index, item)}
                    data-testid="delete-button"
                  ></Delete>
                )}
              </div>
              <Add
                id={id + "add_icon" + index}
                style={{ cursor: "pointer", fill: "var(--icon-primary)" }}
                onClick={() => onAddItemClick(index, item)}
                data-testid="add-button"
              ></Add>
            </div>
          </div>
        );
      })}
    </div>
  );
}
