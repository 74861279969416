import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import styles from "./styles.module.css";
import { RadioButton } from "primereact/radiobutton";
import Text from "../Text";
import { Controller } from "react-hook-form";
import "./index.css";
import { CustomIconPicker } from "../CustomIconPicker/CustomIconPicker";
import { CustomImageInputV2 } from "../CustomImageInputV2";
import { iconList } from "../../utils/iconList";
import {
  leadControlsWidgetConstants,
  quicklauncherConstants,
} from "../../constants/globalConstant";
import {
  iconPickerTemplate,
  selectedIconTemplate,
} from "../../utils/dropdownUtils";
import { countLimit } from "../../utils/globalUtils";

export default function ControlledInputRadioGroup({
  image,
  label,
  options = [],
  control,
  errors,
  radioName,
  onChange,
  imageUploadHandler,
  selectedRadio,
  ...props
}) {
  const { t } = useTranslation();
  const [iconPanelWidth, setIconPanelWidth] = useState(0);
  const iconPickerRef = useRef(null);

  const renderRadioInput = (option, field) => {
    switch (option) {
      case leadControlsWidgetConstants.ICON:
        return (
          <CustomIconPicker
            id="icon-list"
            fieldName="icon"
            control={control}
            className="w-full text-base lsq-input lsq-icon-picker"
            options={iconList}
            optionLabel="name"
            filter
            filterBy="name"
            resetFilterOnHide={true}
            filterPlaceholder={quicklauncherConstants.SEARCH_ICONS}
            placeholder={"lsq_change_image"}
            valueTemplate={selectedIconTemplate}
            itemTemplate={iconPickerTemplate}
            label={quicklauncherConstants.ICON}
            isMandatory={field.value === option}
            errors={errors}
            showLabel={false}
            panelClassName="lsqIconPickerPanel"
            onShow={() => {
              setIconPanelWidth(iconPickerRef.current?.offsetWidth);
            }}
            panelStyle={{ width: iconPanelWidth }}
            disabled={selectedRadio !== option}
          />
        );
      case leadControlsWidgetConstants.IMAGE:
        return (
          <CustomImageInputV2
            imageUploadHandler={imageUploadHandler}
            maxFileSize={countLimit.BANNER_MAX_FILE_SIZE}
            isImageInputDisabled={selectedRadio !== option || image}
          />
        );
      default:
        return <></>;
    }
  };
  return (
    <div className="flex flex-column gap-2 w-full" ref={iconPickerRef}>
      <Text className="white-space-nowrap" type="T4" color="var(--N90)">
        {t(`${label}`)}
      </Text>
      <div className="flex gap-1">
        {options.map((option, index) => (
          <div
            className={`flex flex-row gap-1 justify-content-between w-6`}
            key={option.key}
          >
            <Controller
              key={option.key}
              name={radioName}
              control={control}
              render={({ field }) => (
                <div className={`flex flex-column gap-2 w-full`}>
                  <div
                    className={`flex flex-row align-items-center ${styles["radio-group"]}`}
                  >
                    <RadioButton
                      className="lsq-radio"
                      name={radioName}
                      id={field.key}
                      value={field.value}
                      {...field}
                      {...props}
                      onChange={() => {
                        field.onChange(option.value);
                        onChange && onChange();
                      }}
                      checked={field.value === option.value}
                    />
                    <label htmlFor={field.key}>
                      <Text type="T4" color="#768196">
                        {option.key}
                      </Text>
                    </label>
                  </div>
                  {renderRadioInput(option.key, field)}
                </div>
              )}
            />
            {options.length > index + 1 && <hr className="vertical-divider" />}
          </div>
        ))}
      </div>
    </div>
  );
}

ControlledInputRadioGroup.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  radioName: PropTypes.string,
  control: PropTypes.object,
};
