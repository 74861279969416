import React from "react";
import { MultiSelect } from "primereact/multiselect";
import { globalConstants } from "../../constants/globalConstant";
import LsqProgressSpinner from "../LsqProgressSpinner";
import Text from "../Text";
import "./index.css";

const LsqMultiSelect = ({ label, isMandatory = false, ...props }) => {
  const { error } = props;
  const emptyMessage=() => {
    if (props.options?.length === 0) {
      return <div>{globalConstants.NO_AVAILABLE_OPTIONS}</div>;
    } else return <LsqProgressSpinner strokeColor={"#314361"} />;
  }
  return (
    <span className={`p-float-label lsq-multiselect`}>
      <MultiSelect
        emptyMessage={emptyMessage}
        style={error && { borderColor: "#E02020" }}
        {...props}
      />
      <label className="lsq-label flex align-items-center">
        <Text color={error ? "#E02020" : "var(--N90)"} type="T5">
          {label}
        </Text>
        {isMandatory && (
          <span style={{ color: "#E02020", fontSize: "0.8rem" }}> *</span>
        )}
      </label>
    </span>
  );
};

export default LsqMultiSelect;
