import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./styles.module.css";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Text from "../../../components/Text";
import { ReactComponent as Delete } from "../../../assets/svgIcons/lsq_delete.svg";
import { changeSelectedLeadPageMenu } from "../../../reducers/leadControlsSlice";
import cloneDeep from "lodash.clonedeep";
import { ReactComponent as Previous } from "../../../assets/svgIcons/back.svg";
import CustomButton from "../../../components/CustomButton";

export default function MenuItemPreview() {
  const dispatch = useDispatch();
  const { leadPageMenus } = useSelector((state) => state.leadControls);

  const onMenuDeletion = (menu) => {
    const clonedSelectedMenus = cloneDeep(leadPageMenus.selectedMenus);
    const selectedIndex = leadPageMenus.selectedMenus.findIndex(
      (element) => element.id === menu.id
    );
    clonedSelectedMenus.splice(selectedIndex, 1);
    dispatch(changeSelectedLeadPageMenu(clonedSelectedMenus));
  };

  const onDragEnd = (result) => {
    const clonedSelectedMenus = cloneDeep(leadPageMenus.selectedMenus);
    if (!result.destination) return;
    if (result.source.index === result.destination.index) return;
    const [dragged] = clonedSelectedMenus.splice(result.source.index, 1);
    clonedSelectedMenus.splice(result.destination.index, 0, dragged);
    dispatch(changeSelectedLeadPageMenu(clonedSelectedMenus));
  };

  return (
    <div className="flex gap-2 flex-row h-full">
      <div
        className={`flex flex-column justify-content-end ${styles["lead-menu-item-background"]}`}
      >
        <div
          className={`flex flex-column gap-2 relative ${styles["lead-menu-item-preview"]}`}
        >
          <div className={`flex overflow-hidden ${styles["lead-menu-img"]}`}>
            <img
              className="w-full"
              src="/images/leadControlsPreview/quickViewCard.png"
            />
          </div>
          <div className="flex z-1">
            <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
              <Droppable
                direction={"horizontal"}
                type="lead-page-menus"
                droppableId="lead-page-menus"
              >
                {(provided) => {
                  return (
                    <div
                      className={`flex w-full p-2 gap-1 z-2 ${styles["lead-menu-items"]}`}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      id={"lead-menu-tab"}
                    >
                      {leadPageMenus.selectedMenus.map((menu, index) => {
                        return (
                          <Draggable
                            index={index}
                            key={menu.id}
                            draggableId={menu.id}
                          >
                            {(provided) => {
                              return (
                                <div
                                  className={`flex p-2 align-items-center gap-1 justify-content-center ${styles["lead-menu-item"]}`}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  key={menu.id}
                                >
                                  <Text
                                    type="T3"
                                    color="var(--text-primary)"
                                    className="ellipsis"
                                  >
                                    {menu?.name}
                                  </Text>
                                  <div
                                    className={`flex border-round ${styles["delete-lead-menu"]}`}
                                    onClick={() => onMenuDeletion(menu)}
                                    data-testid="delete-menu-btn"
                                  >
                                    <Delete
                                      fill="var(--R700)"
                                      style={{
                                        width: "0.9rem",
                                        height: "0.9rem",
                                      }}
                                    />
                                  </div>
                                </div>
                              );
                            }}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
            </DragDropContext>
            <div className="flex absolute w-full justify-content-between align-items-center p-2">
              <CustomButton
                varient="text"
                id="slide-btn-left"
                icon={<Previous style={{ width: "0.4rem" }} />}
                label={""}
                onClick={() => {
                  document.getElementById("lead-menu-tab").scrollLeft += 60;
                }}
                style={{ height: "fit-content", right: "15%" }}
                data-testid="left-btn"
              />
              <CustomButton
                varient="text"
                id="slide-btn-right"
                icon={
                  <Previous
                    style={{ transform: "rotate(180deg)", width: "0.4rem" }}
                  />
                }
                label={""}
                onClick={() => {
                  document.getElementById("lead-menu-tab").scrollLeft -= 60;
                }}
                style={{ height: "fit-content", left: "15%" }}
                data-testid="right-btn"
              />
            </div>
          </div>
          <div
            className={`flex h-full w-full absolute blur-background ${styles["lead-menu-img"]}`}
          />
        </div>
      </div>
    </div>
  );
}
