export const teamsDashboardsMappingWithTeamId = (teamsDashboards, updatedTeamsDashboards) => {
  const teamsDashboardsMappingWithId = {};
  for (let teamDashboards of teamsDashboards) {
    if (!teamsDashboardsMappingWithId[teamDashboards.teamId]) {
      teamsDashboardsMappingWithId[teamDashboards.teamId] =
      teamDashboards;
    }
  }
  for (let updatedTeamsDashboard of updatedTeamsDashboards) {
    if (teamsDashboardsMappingWithId[updatedTeamsDashboard.teamId]) {
      teamsDashboardsMappingWithId[updatedTeamsDashboard.teamId] =
      updatedTeamsDashboard;
    }
  }
  return teamsDashboardsMappingWithId;
};

export const getwidgetTemplateList = (list) => {
    list.sort((a, b) => a.id - b.id);
    list = list.map((e, i) => ({
        ...e,
        index: i
    }));
    return list;
}
