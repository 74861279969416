import React from 'react'
import PropTypes from "prop-types"
import { Button } from 'primereact/button';
import "./index.css"
import { useTranslation } from 'react-i18next'
export default function CustomButton({
    label = null,
    icon = null,
    onClick = () => {},
    type = "",
    varient="",
    ...props
}) {
    const { t } = useTranslation();
    return (
        <Button
            {...props}
            type={type}
            icon={icon}
            onClick={onClick}
            className={`lsq-button flex align-items-center ${varient}`}
            label={t(`${label}`)}
        />
    );
}

CustomButton.propTypes = {
  label: PropTypes.string, 
  icon : PropTypes.element, 
  onClick: PropTypes.func ,
  type: PropTypes.string,
  varient:PropTypes.string
}
