import React from "react";
import DonutWidget from "./DonutWidget";
import FuelbarWidget from "./FuelbarWidget";
import NumericWidget from "./NumericWidget";

const TaskCounterV2WidgetFactory = ({ widgetInformation }) => {
  const TaskCounterConfig = {
    Donut: "donut",
    Numeric: "numeric",
    Fuelbar: "fuelbar",
  };
  const { title, displayType } = widgetInformation.config;
  Object.freeze(TaskCounterConfig);

  const type = displayType;
  switch (type) {
    case TaskCounterConfig.Donut:
      return <DonutWidget label={title} />;
    case TaskCounterConfig.Numeric:
      return <NumericWidget label={title} />;
    case TaskCounterConfig.Fuelbar:
      return <FuelbarWidget label={title} />;
    default:
      return <></>;
  }
}

export default TaskCounterV2WidgetFactory
