import React, { useEffect, useState } from "react";
import { ReactComponent as CasaLogo } from "../../assets/svgIcons/casa_logo.svg";
import CustomButton from "../../components/CustomButton";
import Text from "../../components/Text";
import styles from "./styles.module.css";
import { ReactComponent as Success } from "../../assets/svgIcons/lsq_success.svg";
import { ReactComponent as HindWare } from "../../assets/svgIcons/hindWare.svg";
import { ReactComponent as OlaElectric } from "../../assets/svgIcons/ola_electric.svg";
import { ReactComponent as Euler } from "../../assets/svgIcons/euler.svg";
import { ReactComponent as Hinduja } from "../../assets/svgIcons/hinduja.svg";

export const CasaSubscription = () => {
  const [clicked, setClicked] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const breakPoint = 1000;

  const clickHandler = () => {
    setClicked(true);
  };
  const buttonClicked = () => {
    if (!clicked)
      return (
        <div className={`flex gap-2 align-items-center ${styles["button"]}`}>
          <CustomButton
            label={"Book a Demo"}
            varient="filled"
            onClick={clickHandler}
            data-testid = "book-demo-btn"
          />
          <Text type="H2B" color={"#8A94A5"}>
            {"Or Get in touch with your account manager"}
          </Text>
        </div>
      );
    return (
      <div className={`${styles["booked-demo"]}`} data-testid = "booked-demo-message">
        <Success />
        <div className={`flex flex-column ${styles["booked-demo-content"]}`}>
          <Text type={"H2B"} color={"#627087"}>
            {"Thank you for your interest !"}
          </Text>
          <Text type={"T1"} color={"#627087"}>
            {"We’ll get in touch with you shortly."}
          </Text>
        </div>
      </div>
    );
  };

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  const content = (type) => {
    const headerClassName = styles[`${type}-header`];
    const subcontentClassName = styles[`${type}-subcontent`];
    
    return (
      <>
        <Text type="H2" color={"rgba(1, 92, 205, 1)"}>
          {"⚡ Beta available right now"}
        </Text>
        <div className={headerClassName}>
          <Text>{"Build your prompt engine"}</Text>
        </div>
        <div className={subcontentClassName}>
          <Text>
            {
              "Configure contextual prompts on the home screen to align sales users with the sales process by using our no-code builder."
            }
          </Text>
          <Text color={"#43536F"} className="font-bold">
            {"Let the building begin."}
          </Text>
        </div>
      </>
    );
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return width > breakPoint ? (
    <div className={`${styles["desktop-background"]} w-full h-full`} data-testid = "casa-subscription">
      <div
        className={`flex flex-column justify-content-start ${styles["desktop-content"]}`}
      >
        <div className={`${styles["desktop-logo"]}`}>
          <CasaLogo />
        </div>
        <div className={`${styles["desktop-subcontent-container"]}`}>
          {content("desktop")}
          {buttonClicked()}
          <div className="flex align-items-center gap-4 mt-6">
            <HindWare />
            <OlaElectric />
            <Euler />
            <Hinduja />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={`${styles["mobile-background"]}`}>
      <div className={`${styles["mobile-content"]}`}>
        <div>
          <CasaLogo />
        </div>
        {content("mobile")}
        <div className={`${styles["mobile-button"]}`}>{buttonClicked()}</div>
      </div>
    </div>
  );
};
