import React from "react";
import PropTypes from "prop-types";
import OpportunitiesSettings from "./OpportunitiesSettings";
import ActivitiesSettings from "./ActivitiesSettings";
import TasksSettings from "./TasksSettings";
import NotesSettings from "./NotesSettings";
import DocumentSettings from "./DocumentSettings";
import SocialProfileSettings from "./SocialProfileSettings";
import AboutSettings from "./AboutSettings";
import CustomWidgetSettings from "./CustomWidgetSettings";
import AccountsSettings from "./AccountsSettings";

const LeadWidgetsSettings = {
  opportunities: {
    component: OpportunitiesSettings,
  },
  activities: {
    component: ActivitiesSettings,
  },
  accounts: {
    component: AccountsSettings,
  },
  tasks: {
    component: TasksSettings,
  },
  notes: {
    component: NotesSettings,
  },
  documents: {
    component: DocumentSettings,
  },
  socialprofile: {
    component: SocialProfileSettings,
  },
  about: {
    component: AboutSettings,
  },
  customwidget: {
    component: CustomWidgetSettings,
  },
};

Object.freeze(LeadWidgetsSettings);

const LeadWidgetsSettingsFactory = ({ widgetType }) => {
  const widgetSettings = LeadWidgetsSettings[widgetType];
  if (widgetSettings) {
    const { component: WidgetComponent } = widgetSettings;
    return <WidgetComponent />;
  }

  return <div>Widget Under Development</div>;
};

LeadWidgetsSettingsFactory.propTypes = {
  id: PropTypes.number,
};

export default LeadWidgetsSettingsFactory;
