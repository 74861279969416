import { newRelicAction } from "./newRelic";

export const apiTrace = (apiRes) => {
  const data = {
    api: apiRes.request.responseURL,
    method: apiRes.config.method,
    status: apiRes.status,
    appEnvironment: getEnv(),
    traceId: apiRes.headers?.["x-b3-traceid"],
  };
  newRelicAction(
    extractApiWord(apiRes.request.responseURL) + "WebRequests",
    data
  );
};
const extractApiWord = (url) => {
  const match = /api\/([^/]+)/.exec(url);
  if (match && match[1]) return match[1];
  else return null;
};

const getEnv = () => {
  switch (window.location.host) {
    case "fs-dashboard.marketxpander.net":
      return "dev";
    case "fs-dashboard.crm4b2c.com":
      return "test";
    case "fs-dashboard.lsq-staging.com":
      return "stage";
    case "fs-dashboard-in21.leadsquared.com":
      return "prod";
    default:
      return "local";
  }
};