import React from "react";
import { useSelector } from "react-redux";
import { QuickLauncherIcons } from "../../assets/icons/QuickLauncherIcons";
import styles from "./styles.module.css";
import Wifi from "../../assets/svgIcons/wifi.svg";
import Cellular from "../../assets/svgIcons/cellular.svg";
import Battery from "../../assets/svgIcons/battery.svg";
import Casa from "../../assets/svgIcons/casa_logo.svg";
import Avatar from "../../assets/svgIcons/avatar.svg";
import { ReactComponent as Settings } from "../../assets/svgIcons/lsq_settings.svg";
import Text from "../../components/Text";
import Hamburger from "../../assets/svgIcons/Menu.svg";
import Notifications from "../../assets/svgIcons/notification.svg";
import LeadsNearMe from "../../assets/svgIcons/nearme.svg";
import {
  excludedTabsInBottomNavigation,
  menuBuilderConstants,
} from "../../constants/globalConstant";
import { getUniqueTabsByTabId } from "../../utils/menuBuilderUtils";

function MobileSreenMenuBuilderTemplate({
  className,
  mobileView,
  sideNavigationTabs = [],
  bottomNavigationTabs = [],
  cico,
}) {
  const { user } = useSelector((state) => state.homepage);

  const getCurrentTime = () => {
    return new Date().toLocaleTimeString("en-US", {
      hour12: false,
      hour: "numeric",
      minute: "numeric",
    });
  };

  const renderTab = (mobileView) => {
    const navigationTiles = sideNavigationTabs
      ?.filter((item) => !excludedTabsInBottomNavigation.includes(item.tabId))
      .slice(0, 5);
    const customtags = getUniqueTabsByTabId(
      sideNavigationTabs,
      navigationTiles
    );
    const tileCount = navigationTiles?.length > 5 ? 5 : navigationTiles?.length;
    switch (mobileView) {
      case menuBuilderConstants.SIDE_NAVIGATION:
        return (
          <div
            key={menuBuilderConstants.SIDE_NAVIGATION}
            className="flex flex-column h-full overflow-hidden"
          >
            <div className="flex justify-content-center align-items-center p-3 bg-white">
              <img alt="Logo" src={Casa} />
            </div>
            <div className="flex justify-content-center align-items-center p-3 bg-white">
              <div
                className="flex justify-content-between align-items-center p-2 w-full border-round gap-1"
                style={{ backgroundColor: "var(--N20)" }}
              >
                <div className="flex justify-content-center align-items-center gap-1 overflow-hidden">
                  <img alt="avatar" src={Avatar} />
                  <div className="flex flex-column overflow-hidden">
                    <Text type="sub-heading-bold">{`Hi, ${user.firstName}`}</Text>
                    <Text
                      type="sub-heading"
                      color="var(--N100)"
                      className="ellipsis"
                    >{`${user.emailAddress}`}</Text>
                  </div>
                </div>
                <div className="flex justify-content-center align-items-center p-1 bg-white border-round">
                  <Settings></Settings>
                </div>
              </div>
            </div>
            <div className="flex h-full p-2 gap-1 flex-column overflow-scroll">
              <div
                className={`${styles["tile-block"]} ${
                  styles[`tab-count-${tileCount}`]
                }`}
              >
                {navigationTiles?.map((tile) => {
                  return (
                    <div key={tile.id} className={`${styles["tile-view"]}`}>
                      <div className={`${styles["tile-icon"]}`}>
                        <div
                          className={`flex absolute ${styles["tile-icon-background"]}`}
                        ></div>
                        <QuickLauncherIcons
                          icon={tile.imgName || "lsq_leads"}
                          style={{ filter: "opacity(0.7)" }}
                        />
                      </div>
                      <Text
                        type="sub-heading"
                        color="var(--N100)"
                        className="overflow-wrap-anywhere"
                      >
                        {tile.name}
                      </Text>
                    </div>
                  );
                })}
              </div>
              <div className=" flex flex-column gap-1">
                {customtags?.map((option) => {
                  return (
                    <div
                      key={option.id}
                      className="flex align-items-center p-2 gap-2 bg-white border-round"
                    >
                      <QuickLauncherIcons
                        icon={option.imgName || "lsq_leads"}
                        style={{ filter: "opacity(0.7)" }}
                      ></QuickLauncherIcons>
                      <Text type="sub-heading" color="var(--N100)">
                        {option.name}
                      </Text>
                    </div>
                  );
                })}
              </div>
            </div>
            {!cico.hide && (
              <div className="flex p-2 bg-white">
                <div
                  className="flex justify-content-center align-items-center p-2 w-full border-round"
                  style={{ backgroundColor: "#339E33" }}
                >
                  <Text type="sub-heading-bold" color="var(--N0)">
                    {cico?.checkIn}
                  </Text>
                </div>
              </div>
            )}
          </div>
        );
      case menuBuilderConstants.BOTTOM_NAVIGATION:
        return (
          <div
            key={menuBuilderConstants.BOTTOM_NAVIGATION}
            className="flex flex-column h-full"
          >
            <div className={styles["title-bar"]}>
              <div
                style={{ flex: "1" }}
                className={`flex h-full justify-content-center align-items-center`}
              >
                <img alt="hamburger" src={Hamburger} />
              </div>
              <div
                style={{ flex: "6", padding: "0px 3%" }}
                className={`flex h-full align-items-center justify-content-between`}
              >
                <div className={styles["home-text"]}>{"Home"}</div>
                <div className="flex align-items-center gap-1">
                  <img alt="notifications" src={Notifications} />
                  <img alt="lead near me" src={LeadsNearMe} />
                </div>
              </div>
            </div>
            <div className="flex h-full"></div>
            <div className="flex flex-auto p-2 bg-white border-round justify-content-evenly w-full gap-1  align-items-baseline">
              {bottomNavigationTabs?.map((tab) => {
                return (
                  <div
                    key={tab.id}
                    className="flex flex-column justify-content-center align-items-center"
                    style={{ flexBasis: "25%" }}
                  >
                    <div
                      className={`flex justify-content-center align-items-center p-1`}
                    >
                      <QuickLauncherIcons
                        icon={tab.imgName || "lsq_leads"}
                        style={{ filter: "opacity(0.7)", width: "1.2rem" }}
                      ></QuickLauncherIcons>
                    </div>
                    <Text
                      type="mobile-T5"
                      color="var(--N100)"
                      className="word-break"
                    >
                      {tab.name}
                    </Text>
                  </div>
                );
              })}
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };
  return (
    <div className={`${className} mobile-screen`}>
      <div className={styles["status-bar"]}>
        <div className={styles["time"]}>{getCurrentTime()}</div>
        <div className={styles["status-bar-content"]}>
          <img alt="wifi" src={Wifi} />
          <img alt="cellular" src={Cellular} />
          <img alt="battery" src={Battery} />
        </div>
      </div>
      {renderTab(mobileView)}
    </div>
  );
}

export default MobileSreenMenuBuilderTemplate;
