import React, { useEffect } from "react";
import { ReactComponent as Back } from "../../../assets/svgIcons/lsq_back.svg";
import { ReactComponent as Add } from "../../../assets/svgIcons/lsq_add.svg";
import Text from "../../../components/Text";
import {
  globalConstants,
  leadControlsConstants,
  leadControlsWidgetConstants,
} from "../../../constants/globalConstant";
import LsqItemTile from "../../../components/LsqItemTile/LsqItemTile";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSelectedLeadWidget,
  getAllLeadPageWidgets,
  resetLeadWidgetConfiguration,
} from "../../../reducers/leadControlsSlice";
import { getLeadWidgetTitlebyWidgetType } from "../../../utils/leadControlsUtils";

export default function WidgetsPage({
  setCurrentPage,
  setShowConfirmationPopUp,
}) {
  const dispatch = useDispatch();
  const { leadWidgets, widgetsLayout } = useSelector(
    (state) => state.leadControls
  );
  const { widgetTemplates } = leadWidgets;
  useEffect(() => {
    if (widgetTemplates.list.length === 0) dispatch(getAllLeadPageWidgets());
  }, []);

  return (
    <div className={`flex h-full flex-column flex-start p-3 gap-2`}>
      <div className={`flex gap-2 align-items-center`}>
        <Back
          className="cursor-pointer"
          data-testid="widgets-page-back-btn"
          onClick={() => {
            if (widgetsLayout.length === 0) {
              setCurrentPage();
              dispatch(resetLeadWidgetConfiguration());
            } else {
              setShowConfirmationPopUp({
                state: true,
                data: leadControlsWidgetConstants.WIDGET,
              });
            }
          }}
        />
        <Text type="T2B" color="var(--text-primary)">
          {leadControlsConstants.WIDGETS}
        </Text>
      </div>
      <div className={`flex h-full w-full justify-content-between flex-column`}>
        <div className={`flex flex-column gap-2 `}>
          {widgetTemplates.list?.map((widget) => {
            return (
              <LsqItemTile
                key={widget.id}
                label={widget.name}
                hasTileImage={true}
                tileImageName={widget.imgName}
                hasTileActionImg={true}
                tileActionImg={<Add fill="#2F3237" />}
                onTileClick={() => {
                  dispatch(
                    changeSelectedLeadWidget({
                      mode: globalConstants.CREATE,
                      widgetTitle: widget.name,
                      widgetDetails: widget,
                    })
                  );
                }}
              />
            );
          })}
        </div>
        <LsqItemTile
          key={"custom"}
          label={leadControlsConstants.ADD_CUSTOM_WIDGET}
          hasTileImage={true}
          tileImageName={"lsq_add"}
          hasTileActionImg={false}
          onTileClick={() => {
            dispatch(
              changeSelectedLeadWidget({
                mode: globalConstants.CREATE,
                widgetTitle: getLeadWidgetTitlebyWidgetType("customwidget"),
                widgetDetails: {
                  id: "customwidget",
                  name: getLeadWidgetTitlebyWidgetType("customwidget"),
                },
              })
            );
          }}
        />
      </div>
    </div>
  );
}
