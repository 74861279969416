import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import SidePanel from "../../../components/SidePanel";
import PageOptions from "./PageOptions";
import WidgetPage from "./WidgetsPage";
import MenuTabsPage from "./MenuTabsPage";
import Text from "../../../components/Text";
import { ReactComponent as Help } from "../../../assets/svgIcons/lsq_help.svg";
import {
  leadControlsConstants,
  leadControlsWidgetConstants,
} from "../../../constants/globalConstant";
import LeadWidgetsConfigurationScreen from "../LeadWidgetsSettings/LeadWidgetsConfigurationScreen";
import EmptyLeadPagePreview from "./EmptyLeadPagePreview";
import MenuItemPreview from "./MenuItemPreview";
import LeadWidgetsMobilePreview from "./LeadWidgetsMobilePreview";
import { useSelector, useDispatch } from "react-redux";
import {
  changeSelectedLeadPageMenu,
  resetLeadWidgetConfiguration,
  updateLeadPage,
  updateLeadWidgtesLayout,
} from "../../../reducers/leadControlsSlice";
import ConfirmDialog from "../../../components/Modal/ConfirmDialog";
import CustomMenu from "./CustomMenu";

export default function LeadPage() {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState();
  const [customMenu, setCustomMenu] = useState(false);
  const [showConfirmationPopUp, setShowConfirmationPopUp] = useState({
    state: false,
    data: null,
  });
  const [isHovered, setIsHovered] = useState(false);
  const {
    widgetsLayout,
    selectedLeadWidget,
    leadPageMenus,
    selectedLeadControl,
  } = useSelector((state) => state.leadControls);
  const showWidgetConfigrationScreen = !!selectedLeadWidget.mode;
  const showLeadPageOptions = true;
  const configuaredWidgets = widgetsLayout.length !== 0;
  const configuaredMenus = leadPageMenus.selectedMenus.length !== 0;

  const renderLeadPageConfig = () => {
    switch (currentPage) {
      case leadControlsConstants.WIDGETS_PAGE:
        return (
          <WidgetPage
            setCurrentPage={setCurrentPage}
            setShowConfirmationPopUp={setShowConfirmationPopUp}
          />
        );
      case leadControlsConstants.MENU_TABS_PAGE:
        return (
          <MenuTabsPage
            setCurrentPage={setCurrentPage}
            setShowConfirmationPopUp={setShowConfirmationPopUp}
            setCustomMenu={setCustomMenu}
          />
        );
      default:
        return (
          <PageOptions
            setCurrentPage={setCurrentPage}
            setIsHovered={setIsHovered}
          />
        );
    }
  };

  useEffect(() => {
    dispatch(resetLeadWidgetConfiguration());
    if (configuaredWidgets) setCurrentPage(leadControlsConstants.WIDGETS_PAGE);
    if (configuaredMenus) setCurrentPage(leadControlsConstants.MENU_TABS_PAGE);
  }, []);

  const renderLeadPagePreview = () => {
    switch (currentPage) {
      case leadControlsConstants.WIDGETS_PAGE:
        return widgetsLayout.length === 0 ? (
          <EmptyLeadPagePreview
            label={leadControlsConstants.CLICK_WIDGETS_TO_ADD_PAGE}
          />
        ) : (
          <LeadWidgetsMobilePreview />
        );
      case leadControlsConstants.MENU_TABS_PAGE:
        return <MenuItemPreview />;
      default:
        return (
          <EmptyLeadPagePreview label={leadControlsConstants.CHOOSE_PAGE_TYPE}>
            <div
              className={`flex absolute h-full bg-white ${
                styles["lead-page-preview"]
              } ${isHovered && styles["lead-page-img-preview"]}`}
            >
              <img
                src={
                  isHovered === leadControlsConstants.WIDGETS_PAGE
                    ? "/images/leadControlsPreview/leadWidgetPreview.png"
                    : "/images/leadControlsPreview/leadMenuPreview.png"
                }
                className="w-full"
                style={{ opacity: "0.6" }}
              />
            </div>
          </EmptyLeadPagePreview>
        );
    }
  };

  const resetWidgetConfig = () => {
    setCurrentPage();
    dispatch(resetLeadWidgetConfiguration());
    dispatch(updateLeadWidgtesLayout([]));
    setShowConfirmationPopUp({ state: false, data: null });
    dispatch(
      updateLeadPage({
        leadControlId: selectedLeadControl?.id,
        pageId: selectedLeadControl?.leadPage?.id,
        postBody: { config: null },
      })
    );
  };

  const resetMenuConfig = () => {
    setCurrentPage(leadControlsConstants.WIDGETS_PAGE);
    dispatch(changeSelectedLeadPageMenu([]));
    setShowConfirmationPopUp({ state: false, data: null });
    dispatch(
      updateLeadPage({
        leadControlId: selectedLeadControl?.id,
        pageId: selectedLeadControl?.leadPage?.id,
        postBody: { config: null },
      })
    );
  };

  const renderConfirmPopup = (data) => {
    let displayText = <></>;
    let illustration = <></>;
    let clickListener;
    switch (data) {
      case leadControlsWidgetConstants.WIDGET:
        {
          displayText = (
            <>
              <Text type="H2B" color="#101828">
                {leadControlsConstants.SKIP_WIDGET_CONFIG}
              </Text>
              <Text type="T3" color="#2F3237">
                {leadControlsConstants.RESET_WIDGET_CONFIGURATION_MESSAGE}
              </Text>
            </>
          );
          illustration = <Help />;
          clickListener = resetWidgetConfig;
        }

        break;
      case leadControlsWidgetConstants.MENU:
        {
          displayText = (
            <>
              <Text type="H2B" color="#101828">
                {leadControlsConstants.BACK_TO_CONFIGURING_WIDGETS}
              </Text>
              <Text type="T3" color="#2F3237">
                {leadControlsConstants.RESET_MENU_TABS_MESSAGE}
              </Text>
            </>
          );
          illustration = <Help />;
          clickListener = resetMenuConfig;
        }
        break;
    }
    const ConfirmDialogHandler = (e) => {
      clickListener();
      e.stopPropagation();
    };
    return (
      <ConfirmDialog
        setState={setShowConfirmationPopUp}
        illustration={illustration}
        displayText={displayText}
        buttonLabel={leadControlsWidgetConstants.CONFIRM}
        clickListener={ConfirmDialogHandler}
      />
    );
  };

  const renderCustomConfiguration = () => {
    switch (currentPage) {
      case leadControlsConstants.WIDGETS_PAGE:
        return <LeadWidgetsConfigurationScreen />;
      case leadControlsConstants.MENU_TABS_PAGE:
        return <CustomMenu setCustomMenu={setCustomMenu} />;
      default:
        return <></>;
    }
  };
  return (
    <div
      className={`flex w-full h-full flex-column overflow-scroll align-items-center gap-2`}
      data-testid="lead-page-view"
    >
      {renderLeadPagePreview()}
      <SidePanel
        direction={"left"}
        state={showLeadPageOptions}
        style={{
          position: "absolute",
          width: "21%",
          left: "7rem",
          boxShadow: "-4px 0px 8px 0px #00000005",
        }}
      >
        {renderLeadPageConfig()}
      </SidePanel>
      <SidePanel
        state={showWidgetConfigrationScreen || customMenu}
        style={{
          position: "absolute",
          width: "21%",
          boxShadow: "-4px 0px 8px 0px #00000005",
        }}
      >
        {renderCustomConfiguration()}
      </SidePanel>
      {showConfirmationPopUp.state && (
        <div
          className="flex h-full w-full justify-content-center align-items-center absolute z-2"
          style={{ background: "#00000033", backdropFilter: "blur(2px)" }}
        >
          {renderConfirmPopup(showConfirmationPopUp.data)}
        </div>
      )}
    </div>
  );
}
