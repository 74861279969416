import React from "react";
import { Controller } from "react-hook-form";
import { FormErrorMessage } from "../FormErrorMessage";
import LsqMultiSelect from ".";
import { stringConstants } from "../../constants/globalConstant";

const ControlledMultiselect = ({
  fieldName,
  control,
  label,
  errors,
  isMandatory,
  errorMessage,
  ...props
}) => {
  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{
        validate: (value) => {
          if (isMandatory && value?.length < 1) {
            return errorMessage || stringConstants.FIELD_BLANK;
          }
        }
      }}
      render={({field, fieldState}) => (
        <div>
          <LsqMultiSelect label={label} isMandatory={isMandatory} value={field.value} error={fieldState.error} {...props} />
          {errors[fieldName] && (
            <FormErrorMessage message={errors[fieldName].message} />
          )}
        </div>
      )}
    />
  );
};

export default ControlledMultiselect;
