import React from "react";
import { Calendar } from "primereact/calendar";
import Text from "../Text";
import "./index.css";

export const LSQDatePicker = ({
  field,
  label,
  id,
  isMandatory,
  minDate,
  dateFormat,
  inputStyle,
  ...props
}) => {
  const { error } = props;
  return (
    <span className={`p-float-label lsq-calendar`}>
      <Calendar
        id={id}
        {...field}
        {...props}
        inputStyle={inputStyle}
        minDate={minDate}
        dateFormat={dateFormat}
        readOnlyInput={true}
      />
      <label className="lsq-label flex align-items-center" htmlFor={field.name}>
        <Text color={error ? "var(--R800)" : "var(--sub-text)"} type="T5">
          {label}
        </Text>
        {isMandatory && (
          <span style={{ color: "var(--R800)", fontSize: "0.8rem" }}> *</span>
        )}
      </label>
    </span>
  );
};
