import React from 'react'
import { globalConstants } from '../../constants/globalConstant'
import ActiveWidgetList from '../ActiveWidgetList'
import {ExperimentalWidgetList} from "../ExperimentalWidgetList"

const WidgetListFactory = ({tab, ...props}) => {
  switch (tab){
    case globalConstants.ACTIVE_WIDGETS:
        return <ActiveWidgetList {...props} />
    case globalConstants.EXPIREMENTAL_WIDGETS:
        return <ExperimentalWidgetList {...props} />
    default:
      return <ActiveWidgetList {...props} />
  }
}

export default WidgetListFactory