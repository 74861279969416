import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.css";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { ReactComponent as Delete } from "../../../assets/svgIcons/lsq_delete.svg";
import { ReactComponent as RightSlide } from "../../../assets/svgIcons/lsq_right_slider.svg";
import { ReactComponent as LeftSlide } from "../../../assets/svgIcons/lsq_left_slider.svg";
import { ReactComponent as DragIndicator } from "../../../assets/svgIcons/drag_indicator.svg";
import Text from "../../../components/Text";
import { leadControlsConstants } from "../../../constants/globalConstant";
import { changeSelectedLeadCard, setLeadConfigurationDirty } from "../../../reducers/leadControlsSlice";
import { formatLeadCardFields } from "../../../utils/leadControlsUtils";

export default function LeadVisibleCard({
  visibleCardFields,
  availableCardFields,
  showCardPlaceholder,
}) {
  const dispatch = useDispatch();
  const { selectedLeadCard } = useSelector((state) => state.leadControls);
  const [selectedField, setSelectedField] = useState(null);
  const handleCardSize = (index, element) => {
    visibleCardFields.update(index, {
      ...element,
      size:
        element.size === leadControlsConstants.EXPENDED
          ? leadControlsConstants.STANDARD
          : leadControlsConstants.EXPENDED,
    });
    dispatch(setLeadConfigurationDirty(true))
  };
  const background = showCardPlaceholder ? "#C6EFF7" : "#f0f0f0";

  useEffect(() => {
    dispatch(
      changeSelectedLeadCard({
        ...selectedLeadCard,
        visibleCardFields: formatLeadCardFields(visibleCardFields.fields),
      })
    );
  }, [visibleCardFields.fields]);

  return (
    <Droppable type="lead-visible-fields" droppableId="lead-visible-fields">
      {(provided) => {
        return (
          <div
            className={`w-full ${styles["lead-fields-layout"]}`}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {provided.placeholder}
            {visibleCardFields.fields?.map((element, index) => {
              return element.view !== leadControlsConstants.EMPTY ? (
                <Draggable
                  index={index}
                  key={element.key}
                  draggableId={String(element.key)}
                  isDragDisabled={selectedField !== element.key}
                >
                  {(provided) => {
                    return (
                      <div
                        ref={provided.innerRef}
                        key={element.key}
                        {...provided.draggableProps}
                        className={`flex flex-row cursor-pointer ${
                          styles["visible-lead-field"]
                        } ${
                          selectedField === element.key &&
                          styles["selected-lead-card"]
                        } ${
                          element.size === leadControlsConstants.EXPENDED &&
                          styles["expanded-lead-card"]
                        }`}
                        data-testid="visible-lead-field"
                      >
                        <div className={`flex w-full relative`}>
                          <div
                            className={`flex w-full relative justify-content-between align-items-center p-2 `}
                            onClick={() => setSelectedField(element.key)}
                          >
                            {element.size === leadControlsConstants.EXPENDED ? (
                              <>
                                <Text
                                  type="T4B"
                                  className="capitalize"
                                  color="#AFB1B6"
                                >
                                  {element.dataType}
                                </Text>
                                <Text type="T3" className="ellipsis w-6">{element.displayName} </Text>
                              </>
                            ) : (
                              <div className={`flex flex-column overflow-hidden`}>
                                <Text type="T3" className="ellipsis">{element.displayName}</Text>
                                <Text
                                  type="T4B"
                                  className="capitalize"
                                  color="#AFB1B6"
                                >
                                  {element.dataType}
                                </Text>
                              </div>
                            )}
                            <div
                              className={`flex border-round mr-1`}
                              style={{
                                background: "var(--R100)",
                              }}
                              onClick={() => {
                                availableCardFields.append(element);
                                visibleCardFields.update(index, {
                                  key: visibleCardFields.fields[index].key,
                                  view: leadControlsConstants.EMPTY,
                                });
                                dispatch(setLeadConfigurationDirty(true))
                              }}
                            >
                              <Delete
                                fill="var(--R700)"
                                style={{ width: "1.3rem" }}
                              />
                            </div>
                          </div>
                          {selectedField === element.key && (
                            <>
                              <div
                                className={`flex justify-content-center align-items-center ${styles["lead-card-drag-indicator"]}`}
                                {...provided.dragHandleProps}
                              >
                                <DragIndicator />
                              </div>
                              <div
                                className={`cursor-pointer  ${styles["lead-card-slide-icon"]}`}
                                onClick={() => handleCardSize(index, element)}
                              >
                                {element.size ===
                                leadControlsConstants.EXPENDED ? (
                                  <LeftSlide />
                                ) : (
                                  <RightSlide />
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    );
                  }}
                </Draggable>
              ) : (
                <Droppable
                  type="lead-available-cards"
                  droppableId={String(index)}
                  key={element.key}
                >
                  {(provided, snapshot) => {
                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={`${styles["visible-lead-field"]}`}
                        style={{
                          background,
                          border:
                            snapshot.isDraggingOver && " 1px dashed #55D1E8",
                          width: "48%",
                        }}
                      >
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              );
            })}
          </div>
        );
      }}
    </Droppable>
  );
}
