import React from "react";
import Text from "../Text";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ReactComponent as DragIndicator } from "../../assets/svgIcons/drag_indicator.svg";
import { ReactComponent as LsqClose } from "../../assets/svgIcons/lsq_close.svg";
import styles from "./styles.module.css";

export default function ItemsDragHandler({
  id,
  elements = [],
  move,
  hasCloseButton,
  onCloseButtonClick,
  onDraggingItem = () => {},
}) {
  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.source.index === result.destination.index) return;
    move(result.source.index, result.destination.index);
    onDraggingItem();
  };
  return (
    <DragDropContext
      onDragEnd={(result) => onDragEnd(result)}
      data-testid="itemDragHandler"
    >
      <Droppable
        direction="vertical"
        type="elements"
        droppableId="element-handler"
      >
        {(provided) => {
          return (
            <div
              data-testid="elementDragHandlerBox"
              className="flex flex-column w-full gap-1"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {elements.map((element, index) => {
                return (
                  <Draggable
                    index={index}
                    key={element.id}
                    draggableId={element.id}
                  >
                    {(provided) => {
                      return (
                        <div
                          className={`flex flex-row ${styles["drag_element"]}`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          id={id + index}
                        >
                          <div
                            className="flex justify-content-center align-items-center"
                            {...provided.dragHandleProps}
                            id={id + "darg_indicator" + index}
                          >
                            <DragIndicator />
                          </div>
                          <div
                            className={`flex flex-1 mx-2 flex-row align-items-center justify-content-between `}
                          >
                            <div className={styles.drag_element_label}>
                              <Text
                                color="#314361"
                                className="overflow-wrap-anywhere"
                              >
                                {element.name}
                              </Text>
                            </div>
                            {hasCloseButton && (
                              <LsqClose
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  onCloseButtonClick(index, element)
                                }
                              />
                            )}
                          </div>
                        </div>
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
}
