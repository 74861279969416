import cloneDeep from "lodash.clonedeep";
import {
  API_CLONE_LEAD_CONTROL,
  API_DELETE_LEAD_CONTROL,
  API_GET_ALL_LEAD_CONTROLS,
  API_GET_ALL_TEAM_LEAD_CONTROLS,
  API_GET_LEAD_CONTROL_BY_ID,
} from "../api/configurationScreenServices";
import { toastMessageConstants } from "../constants/globalConstant";
import {
  changeSelectedLeadControl,
  changeSelectedLeadType,
} from "./leadControlsSlice";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
  toastMessages: [],
  leadControlById: {},
  leadControls: [],
  isLeadControlLoading: false,
};

export const getAllLeadControls = createAsyncThunk(
  "leadControlsAssignment/getAllLeadControls",
  async (_, thunkAPI) => {
    const response = await API_GET_ALL_LEAD_CONTROLS();
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const getLeadControlById = createAsyncThunk(
  "leadControlsAssignment/getLeadControlById",
  async ({ leadControlId }, thunkAPI) => {
    const response = await API_GET_LEAD_CONTROL_BY_ID(leadControlId);
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const leadTypeId = data.leadTypeId;
    const { leadPage, leadAction } = data;
    const { leadTypes, allLeadFields, allLeadActions } =
      thunkAPI.getState().leadControls;
    const selectedLeadType = leadTypes?.find(
      (leadType) => leadType.LeadTypeId === leadTypeId
    );
    const leadFields =
      selectedLeadType?.LeadTypeId !== "default"
        ? allLeadFields.filter((field) =>
            selectedLeadType?.FieldConfiguration?.split(", ").includes(
              field.schemaName
            )
          )
        : allLeadFields;
    thunkAPI.dispatch(
      changeSelectedLeadControl({
        selectedLeadControl: data,
        leadFields: leadFields,
        widgetsLayout:
          leadPage?.config?.type === "widget" ? leadPage?.config?.items : [],
        selectedMenus:
          leadPage?.config?.type === "menu" ? leadPage?.config?.items : [],
        leadActions: {
          actions: allLeadActions,
          selectedActions: leadAction?.config || [],
        },
      })
    );
    thunkAPI.dispatch(changeSelectedLeadType(selectedLeadType));
    return data;
  }
);

export const getAllTeamLeadControls = createAsyncThunk(
  "leadControlsAssignment/getAllTeamLeadControls",
  async (_, thunkAPI) => {
    const response = await API_GET_ALL_TEAM_LEAD_CONTROLS();
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const deleteLeadControl = createAsyncThunk(
  "leadControlsAssignment/deleteLeadControl",
  async ({ leadControlId }, thunkAPI) => {
    const response = await API_DELETE_LEAD_CONTROL(leadControlId);
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const { leadControlById, leadControls } =
      thunkAPI.getState().leadControlsAssignment;
    let clonedLeadControlById = { ...leadControlById };
    delete clonedLeadControlById[leadControlId];
    let clonedLeadControls = cloneDeep(leadControls);
    const _defaultLeadControl = clonedLeadControls.defaultLeadControls?.filter(
      (obj) => obj.leadControlId !== leadControlId
    );
    const _teamLeadControls = clonedLeadControls.teamLeadControls?.filter(
      (obj) => obj.leadControlId !== leadControlId
    );
    clonedLeadControls = {
      defaultLeadControls: _defaultLeadControl,
      teamLeadControls: _teamLeadControls,
    };
    return {
      leadControlById: clonedLeadControlById,
      leadControls: clonedLeadControls,
    };
  }
);

export const cloneLeadControl = createAsyncThunk(
  "leadControlsAssignment/cloneLeadControl",
  async ({ leadControlId }, thunkAPI) => {
    const response = await API_CLONE_LEAD_CONTROL(leadControlId);
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const leadTypeId = data.leadTypeId;
    const { leadPage, leadAction } = data;
    const { leadTypes, allLeadFields, allLeadActions } =
      thunkAPI.getState().leadControls;
    const selectedLeadType = leadTypes?.find(
      (leadType) => leadType.LeadTypeId === leadTypeId
    );
    const leadFields =
      selectedLeadType?.LeadTypeId !== "default"
        ? allLeadFields.filter((field) =>
            selectedLeadType?.FieldConfiguration?.split(", ").includes(
              field.schemaName
            )
          )
        : allLeadFields;
    thunkAPI.dispatch(
      changeSelectedLeadControl({
        selectedLeadControl: data,
        leadFields: leadFields,
        widgetsLayout:
          leadPage?.config?.type === "widget" ? leadPage?.config?.items : [],
        selectedMenus:
          leadPage?.config?.type === "menu" ? leadPage?.config?.items : [],
        leadActions: {
          actions: allLeadActions,
          selectedActions: leadAction?.config || [],
        },
      })
    );
    thunkAPI.dispatch(changeSelectedLeadType(selectedLeadType));
    return data;
  }
);

const leadControlsAssignmentSlice = createSlice({
  name: "leadControlsAssignment",
  initialState,
  reducers: {
    addToastMessageFromLeadControlsList(state, action) {
      state.toastMessages.push(action.payload);
    },
    resetToastMessagesFromLeadControlsList(state) {
      state.toastMessages.shift();
    },
    setLeadControls(state, action) {
      state.leadControls = action.payload;
    },
    setLeadControlById(state, action) {
      state.leadControlById = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllLeadControls.fulfilled, (state, action) => {
      const leadControls = action.payload;
      const _leadControlById = Object.fromEntries(
        leadControls?.map((leadControl) => {
          return [leadControl.id, leadControl];
        })
      );
      state.leadControlById = _leadControlById;
      state.isLeadControlLoading = false;
    });
    builder.addCase(getAllLeadControls.pending, (state) => {
      state.isLeadControlLoading = true;
    });
    builder.addCase(getAllLeadControls.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
      state.isLeadControlLoading = false;
    });
    builder.addCase(getAllTeamLeadControls.fulfilled, (state, action) => {
      state.leadControls = action.payload;
      state.isLeadControlLoading = false;
    });
    builder.addCase(getAllTeamLeadControls.pending, (state) => {
      state.isLeadControlLoading = true;
    });
    builder.addCase(getAllTeamLeadControls.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
      state.isLeadControlLoading = false;
    });
    builder.addCase(getLeadControlById.fulfilled, (state) => {
      state.isLeadControlLoading = false;
    });
    builder.addCase(getLeadControlById.pending, (state) => {
      state.isLeadControlLoading = true;
    });
    builder.addCase(getLeadControlById.rejected, (state) => {
      state.isLeadControlLoading = false;
    });
    builder.addCase(cloneLeadControl.fulfilled, (state, action) => {
      state.isLeadControlLoading = false;
      let clonedLeadControlById = state.leadControlById;
      let leadControl = {...action.payload};
      delete leadControl.leadCard;
      delete leadControl.leadPage;
      delete leadControl.leadAction;
      clonedLeadControlById[leadControl.id] = leadControl;
      state.leadControlById = clonedLeadControlById;
    });
    builder.addCase(cloneLeadControl.pending, (state) => {
      state.isLeadControlLoading = true;
    });
    builder.addCase(cloneLeadControl.rejected, (state) => {
      state.isLeadControlLoading = false;
    });
    builder.addCase(deleteLeadControl.fulfilled, (state, action) => {
      const { leadControlById, leadControls } = action.payload;
      state.leadControls = leadControls;
      state.leadControlById = leadControlById;
    });
  },
});
export const {
  setLeadControls,
  setLeadControlById,
  addToastMessageFromLeadControlsList,
  resetToastMessagesFromLeadControlsList,
} = leadControlsAssignmentSlice.actions;
export default leadControlsAssignmentSlice.reducer;
