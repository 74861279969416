import React, { useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import Text from "../../components/Text";
import CustomButton from "../../components/CustomButton";
import CustomInputV2 from "../../components/CustomInputV2";
import { ReactComponent as SearchIcon } from "../../assets/svgIcons/search.svg";
import { ReactComponent as MenuBuilder } from "../../assets/svgIcons/lsq_menu_builder_filled.svg";
import { ReactComponent as Edit } from "../../assets/svgIcons/lsq_edit.svg";
import { ReactComponent as Add } from "../../assets/svgIcons/add.svg";
import { ReactComponent as More } from "../../assets/svgIcons/lsq_more.svg";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { ReactComponent as DeleteIllustration } from "../../assets/illustrations/delete_illustration.svg";
import { ReactComponent as UpdateIllustration } from "../../assets/illustrations/update_illustration.svg";
import {
  formatDateTime,
  hierarchyToLinear,
  teamsToMenuMapping,
} from "../../utils/globalUtils";
import {
  ROUTES_PATH,
  globalConstants,
  menuBuilderConstants,
} from "../../constants/globalConstant";
import MoreOptionsPopup from "../../components/MoreOptionsPopup";
import {
  menuMoreOptions,
  menuSortItems,
  menuFilterItems,
  sortMenus,
  searchMenus,
  filterMenus,
  getAllMenuData,
} from "../../utils/menuBuilderUtils";
import { InlineDropdown } from "../../components/InlineDropdown";
import ToggleTab from "../../components/ToggleTab";
import {
  apiCloneMenu,
  apiDeleteMenu,
  changeSelectedMenuId,
  getMenu,
  addToastMessage,
  apiUpdateMenu,
  getMenus,
  resetMenuConfiguration,
  getTeamsMenus,
  toggleShowMenuInfoBanner,
} from "../../reducers/menuBuilderSlice";
import SidePanel from "../../components/SidePanel";
import TeamsAssignedView from "./TeamAssignedView";
import useToast from "../../hooks/useToast";
import Modal from "../../components/Modal";
import ConfirmDialog from "../../components/Modal/ConfirmDialog";
import PublishMenuBuilder from "./PublishMenuBuilder";
import ToastMessage from "../../components/ToastMessage";
import { IndeterminateLoader } from "../../components/IndeterminateLoader";
import InfoBanner from "../../components/InfoBanner";
import analytics from "../../utils/analytics";
import { getTeams } from "../../reducers/homePageSlice";

export default function MenuBuilderView() {
  const dispatch = useDispatch();
  const toast = useToast(addToastMessage);
  const navigate = useNavigate();
  const menuViewSortState = useState(menuSortItems[0].label);
  const menuViewSearchState = useState("");
  const menuViewFilterState = useState(menuFilterItems[0].value);
  const { userPreferences } = useSelector((state) => state.homepage);
  const {
    menus,
    menuById,
    toastMessages,
    isMenuBuilderLoading,
    showMenuInfoBanner,
  } = useSelector((state) => state.menuBuilder);
  const teamHierarchy = useSelector((state) => state.homepage.teams);
  const defaultMenuId = menus?.defaultMenu?.menuId;
  const [dialog, setDialog] = useState({ state: false, dialogBox: {} });
  const [sidePanelState, setSidePanelState] = useState({
    state: false,
    data: null,
  });
  const [publishState, setPublishState] = useState({
    state: false,
    data: null,
  });
  const [activeTab, setActiveTab] = useState(menuFilterItems[0]);
  const { menuBuilderVisited } = userPreferences;
  const onTabChange = (tab) => {
    setActiveTab(tab);
    menuViewFilterState[1](tab.value);
  };

  useEffect(() => {
    if (teamHierarchy.length === 0) {
      dispatch(getTeams());
    }
    if (!menus.teamMenu) dispatch(getTeamsMenus());
    dispatch(getMenus());
  }, []);

  const teamLinear = useMemo(() => {
    return hierarchyToLinear(teamHierarchy);
  }, [teamHierarchy]);
  const teamsToMenuCountMapping = useMemo(() => {
    return teamsToMenuMapping(menus?.teamMenu, teamLinear);
  }, [menus, teamLinear]);

  const allMenus = getAllMenuData(
    Object.values(menuById),
    teamsToMenuCountMapping
  );
  const totalMenuCreated = allMenus.length;

  let filterAndSortedMenus = searchMenus(allMenus, menuViewSearchState[0]);
  filterAndSortedMenus = sortMenus(filterAndSortedMenus, menuViewSortState[0]);
  filterAndSortedMenus = filterMenus(
    filterAndSortedMenus,
    menuViewFilterState[0],
    menuById
  );

  const onTeamCountClicked = (menuData) => {
    const menuId = menuData.menuId;
    setSidePanelState({
      state: true,
      data: {
        isDefault: defaultMenuId == menuId,
        teamIds: teamsToMenuCountMapping[menuId] || [],
        menuName: menuData.name,
        menuId,
      },
    });
  };
  const teamsTemplate = (value) => {
    const menuId = value.menuId;
    let teams = "";
    if (menuId != defaultMenuId && !teamsToMenuCountMapping[menuId])
      return <></>;
    else
      teams = menuId == defaultMenuId ? "Default" : `${value.teamsCount} Teams`;
    return (
      <div
        onClick={() => onTeamCountClicked(value)}
        className="flex cursor-pointer"
      >
        <Tag style={{ background: "#E2ECF9" }}>
          <Text type="T4B" color="#015CCD">
            {teams}
          </Text>
        </Tag>
      </div>
    );
  };

  const statusTemplate = (value) => {
    let backgroundColor = "";
    let textColor = "";
    if (value.status === menuBuilderConstants.UNPUBLISHED) {
      backgroundColor = "#FFE6E6";
      textColor = "#FA0303";
    } else {
      backgroundColor = "#E6FEE8";
      textColor = "#04A410";
    }
    return (
      <Tag style={{ background: backgroundColor }}>
        <Text type="T4B" color={textColor} className="capitalize">
          {value.status}
        </Text>
      </Tag>
    );
  };
  const lastModifiedTemplate = (value) => {
    const date =
      menuViewSortState[0] === menuBuilderConstants.RECENTLY_CREATED
        ? value.createdAt
        : value.updatedAt;
    return formatDateTime(date).format("HH:mm | D MMM YYYY");
  };

  const editTemplate = (value) => {
    const menuId = value.menuId;
    return (
      <div
        className={`flex cursor-pointer`}
        data-testid="menu-edit-btn"
        onClick={() => {
          dispatch(changeSelectedMenuId(menuId));
          dispatch(getMenu(menuId));
          navigate(ROUTES_PATH.menubuilder);
          analytics.sendEvent("Update Menu from Menus table View", {
            category: "update_menu",
            type: "click",
            menuId: menuId,
          });
        }}
      >
        <Edit />
      </div>
    );
  };

  const unpublishMenuClicked = (menu) => {
    const menuId = menu.menuId;
    const postBody = { status: "unpublished" };
    dispatch(apiUpdateMenu({ menuId, postBody })).then(({ type, payload }) => {
      if (type.includes(globalConstants.FULFILLED)) {
        toast.success(
          `${payload.menu.name} was unpublished successfully!`,
          "Menu Unpublished"
        );
      }
    });
    setDialog({ state: false, dialogBox: {} });
  };

  const cloneMenuClicked = (menu) => {
    const menuId = menu.menuId;
    dispatch(apiCloneMenu({ menuId })).then(({ type, payload }) => {
      if (type.includes(globalConstants.FULFILLED)) {
        const clonedMenu = payload;
        const clonedHomepageId = clonedMenu.menuId;
        dispatch(changeSelectedMenuId(clonedHomepageId));
        navigate(ROUTES_PATH.menubuilder);
      }
    });
  };

  const deleteMenuClicked = (menu) => {
    const menuId = menu.menuId;
    dispatch(apiDeleteMenu({ menuId })).then(({ type, payload }) => {
      if (type.includes(globalConstants.FULFILLED)) {
        toast.success(
          `${payload.menu.name} was deleted successfully!`,
          "Menu Deleted"
        );
      }
    });
    setDialog({ state: false, dialogBox: {} });
  };

  const publishMenuClicked = (menu) => {
    const menuId = menu.menuId;
    setPublishState({
      state: true,
      data: {
        isDefault: defaultMenuId === menuId,
        teamIds: teamsToMenuCountMapping[menuId] || [],
        menuName: menu.name,
        selectedMenuId: menuId,
      },
    });
  };

  const dialogBox = (option, menu) => {
    let displayIcon = <></>;
    let actionLabel = "";
    let clickListener;
    let menuName = menu.name;
    switch (option) {
      case menuBuilderConstants.UNPUBLISH:
        {
          displayIcon = <UpdateIllustration />;
          actionLabel = menuBuilderConstants.UNPUBLISH;
          clickListener = unpublishMenuClicked;
        }
        break;
      case menuBuilderConstants.DELETE:
        {
          displayIcon = <DeleteIllustration />;
          actionLabel = menuBuilderConstants.DELETE;
          clickListener = deleteMenuClicked;
        }
        break;
      default:
        break;
    }
    const ConfirmDialogHandler = (e) => {
      clickListener(menu);
      e.stopPropagation();
    };
    return (
      <ConfirmDialog
        setState={setDialog}
        illustration={displayIcon}
        buttonLabel={actionLabel}
        displayText={
          <>
            <Text type="heading" className={`capitalize`} color="#0A1F43">
              {`${actionLabel} ${menuName}`}
            </Text>
            <Text type="sub-heading" color="#0A1F43">
              {`Are you sure you want to ${actionLabel}?`}
            </Text>
          </>
        }
        clickListener={ConfirmDialogHandler}
      />
    );
  };

  const menuItemClickHandler = (label, menu) => {
    switch (label) {
      case menuBuilderConstants.CLONE:
        cloneMenuClicked(menu);
        break;
      case menuBuilderConstants.DELETE:
        setDialog({
          state: true,
          dialogBox: dialogBox(menuBuilderConstants.DELETE, menu),
        });
        break;
      case menuBuilderConstants.UNPUBLISH:
        setDialog({
          state: true,
          dialogBox: dialogBox(menuBuilderConstants.UNPUBLISH, menu),
        });
        break;
      case menuBuilderConstants.PUBLISH:
        publishMenuClicked(menu);
        break;
    }
  };

  const optionTemplate = (value) => {
    return (
      <div className={`relative`}>
        <MoreOptionsPopup
          menu={value}
          options={menuMoreOptions(value)}
          onClickIcon={<More />}
          menuItemClickHandler={menuItemClickHandler}
          data-testid="menu-more-btn"
        ></MoreOptionsPopup>
      </div>
    );
  };

  const menuTemplate = (value) => {
    return (
      <div className="flex gap-2 align-items-center">
        <MenuBuilder />
        <Text type="T1B" color="var(--text-focused)">
          {value.name}
        </Text>
      </div>
    );
  };

  const createNewMenuClicked = () => {
    dispatch(resetMenuConfiguration());
    navigate(ROUTES_PATH.menubuilder);
    analytics.sendEvent("Create Menu from Menus table View", {
      category: "create_new_menu",
      type: "click",
    });
  };

  const hideInfoMenuBanner = () => {
    dispatch(toggleShowMenuInfoBanner(false));
  };

  return (
    <div
      className="flex h-screen w-screen align-items-center p-3"
      data-testid="menu-builder-data-view"
    >
      <div
        className={`flex h-full w-full align-items-center flex-column overflow-hidden ml-7 ${styles["menu-tabular-view"]}`}
      >
        <div
          className={`flex w-full align-items-center justify-content-between p-3`}
        >
          <div className={`flex align-items-center gap-1`}>
            <Text type="T1B" color="var(--text-primary)">
              {menuBuilderConstants.MENUS}
            </Text>
            <Tag style={{ background: "#E2ECF9" }}>
              <Text
                type="T4B"
                color="#015CCD"
              >{`${totalMenuCreated} created`}</Text>
            </Tag>
          </div>
          <div className={`flex align-items-center gap-1`}>
            <CustomInputV2
              style={{ width: "20rem", height: "2.25rem" }}
              placeholder={menuBuilderConstants.SEARCH_MENUS}
              LeftIcon={SearchIcon}
              value={menuViewSearchState[0]}
              onChange={(e) => menuViewSearchState[1](e.target.value)}
              data-testid="menu-search"
            />
            <CustomButton
              label={menuBuilderConstants.CREATE_NEW}
              varient="filled"
              icon={<Add />}
              style={{ width: "100%", height: "2.25rem" }}
              onClick={createNewMenuClicked}
              data-testid="create-menu-btn"
            ></CustomButton>
          </div>
        </div>
        {toastMessages.length > 0 ? (
          <div className={`${styles["toast_container"]}`} style={{ top: "0" }}>
            <ToastMessage toastMessages={toastMessages} />
          </div>
        ) : (
          <></>
        )}
        <div className={`flex w-full h-full flex-column overflow-scroll`}>
          {isMenuBuilderLoading ? (
            <IndeterminateLoader
              color="#0A1F43"
              height="0.25rem"
              backgroundColor="#B0B7C2"
            />
          ) : (
            <div style={{ height: "0.2rem" }} />
          )}
          <DataTable
            value={filterAndSortedMenus}
            className="lsq-data-table"
            responsiveLayout="scroll"
            paginator
            rows={10}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
              height: "100%",
            }}
          >
            <Column
              field="name"
              style={{ width: "40%" }}
              header={
                <ToggleTab
                  tabs={menuFilterItems}
                  activeTab={activeTab}
                  setActiveTab={onTabChange}
                  style={{ width: "70%" }}
                ></ToggleTab>
              }
              body={menuTemplate}
            ></Column>
            <Column
              field="status"
              header="Status"
              body={statusTemplate}
            ></Column>
            <Column
              field={
                menuViewSortState[0] === menuBuilderConstants.RECENTLY_CREATED
                  ? "createdAt"
                  : "updatedAt"
              }
              header={
                <InlineDropdown
                  items={menuSortItems}
                  selectedLabel={menuViewSortState[0]}
                  setSelectedLabel={menuViewSortState[1]}
                  style={{ alignItems: "flex-start" }}
                ></InlineDropdown>
              }
              body={lastModifiedTemplate}
            ></Column>
            <Column
              field={"teamsCount"}
              header="Teams"
              body={teamsTemplate}
            ></Column>
            <Column style={{ width: "5%" }} body={editTemplate}></Column>
            <Column style={{ width: "5%" }} body={optionTemplate}></Column>
          </DataTable>
        </div>
      </div>
      <SidePanel
        state={sidePanelState.state}
        setSidePanelState={setSidePanelState}
        style={{top: "0"}}
      >
        <TeamsAssignedView
          setSidePanelState={setSidePanelState}
          sidePanelState={sidePanelState}
        />
      </SidePanel>
      <Modal state={publishState.state}>
        <PublishMenuBuilder
          setPublishState={setPublishState}
          publishState={publishState}
        ></PublishMenuBuilder>
      </Modal>
      <Modal state={dialog.state}>{dialog.dialogBox}</Modal>
      {!menuBuilderVisited && (
        <InfoBanner
          state={showMenuInfoBanner}
          bannerImage={`/images/menu_builder.png`}
          bannerTitle={menuBuilderConstants.MENU_BUILDER}
          bannerInfo={menuBuilderConstants.MENU_BANNER_INFO}
          actionButtonLabel={menuBuilderConstants.TRY_NOW}
          dismissButtonOnClick={hideInfoMenuBanner}
          actionButtonOnClick={() => {
            dispatch(toggleShowMenuInfoBanner(false));
            navigate(ROUTES_PATH.menubuilder);
          }}
        ></InfoBanner>
      )}
    </div>
  );
}
