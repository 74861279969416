import React from "react";
import styles from "../styles.module.css";
import Text from "../../components/Text";
import { stringConstants, widgetConstants } from "../../constants/globalConstant";
import { useTranslation } from "react-i18next";

export default function UserCheckinStatus({ widgetInformation }) {
  const { t } = useTranslation();
  const { title, displayStyle } = widgetInformation.config;

  const getCheckinStatusImage = () => {
    switch (displayStyle) {
      case widgetConstants.DONUT:
        return "/images/widgetPreview/user_checkin_donut.png";
      case widgetConstants.FUELBAR:
        return "/images/widgetPreview/user_checkin_fuelbar.png";
      default:
        return "/images/widgetPreview/user_checkin_donut.png";
    }
  };
  return (
    <div
      className={styles["widget-container"]}
      data-testid={
        displayStyle === widgetConstants.DONUT
          ? "donut-user-checkin"
          : "fuelbar-user-checkin"
      }
    >
      <div className="flex flex-column absolute mt-1 ml-2">
        <Text type="sub-heading-bold" color="var(--text-focused)">
          {t(title)}
        </Text>
      </div>
      <img
        className="w-full"
        src={getCheckinStatusImage()}
        alt={stringConstants.USER_CHECKIN_STATUS}
      />
    </div>
  );
}
