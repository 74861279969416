import React from "react";
import { ReactComponent as CrossIcon } from "../../assets/svgIcons/lsq_close.svg";
import CustomButton from "../CustomButton";
import styles from "./styles.module.css";
import Text from "../Text";
import { useForm } from "react-hook-form";
import { ControlledInputTextarea } from "../LSQInputTextarea/ControlledInputTextarea";
import { stringConstants } from "../../constants/globalConstant";

export default function SuggestionBox({ dismissHandler, submitHandler }) {
  const defaultValues = {
    suggestion: "",
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues });

  const onSubmitSuggestion = (data) => {
    submitHandler(data);
    dismissHandler();
  }

  return (
    <div className={`absolute flex flex-column ${styles["suggestion-box"]}`} data-testid="suggestion-box">
      <div
        className={`flex justify-content-between align-items-center ${styles["suggestion-box-header"]}`}
      >
        <Text type="T2B" color="var(--text-primary)">
          {stringConstants.SUGGEST_SOMETHING}
        </Text>
        <CrossIcon
          onClick={dismissHandler}
          style={{ cursor: "pointer", fill: "var(--primary)" }}
          data-testid="cross-button"
        ></CrossIcon>
      </div>
      <form
        className={`flex flex-column w-full ${styles["suggestion-box-container"]}`}
        onSubmit={handleSubmit(onSubmitSuggestion)}
      >
        <ControlledInputTextarea
          fieldName="suggestion"
          className="w-full text-base"
          isMandatory={true}
          control={control}
          errors={errors}
          placeholder={stringConstants.TYPE_HERE}
        />
        <div className="flex justify-content-end">
          <CustomButton
            type="submit"
            label="Submit"
            varient="filled"
            data-testid="submit-button"
          ></CustomButton>
        </div>
      </form>
    </div>
  );
}
