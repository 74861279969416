import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import DashboardCard from "../../components/DashboardCard";
import Text from "../../components/Text";
import { apiUpdateTeamDashboardOrder } from "../../reducers/homePageSlice";
import { dashboardViewItems, teamViewItems } from "../../utils/casaUtils";
import analytics from "../../utils/analytics";

const DashboardContainer = ({
  isScrollable = false,
  heading = "",
  dragAndDrop = false,
  dashboards = [],
  showTeamCount,
  dashboardsMappedWithId = {},
  dashboardsToTeamCountMapping = {},
  setSidePanelState,
  teamId = "",
  setDialog,
  bulkSelectedHomepagesState,
  isHomepagesBulkSelectionEnabled = false,
}) => {
  const dispatch = useDispatch();
  const clonedDashboards = dashboards.filter(
    (dashboardId) => dashboardsMappedWithId[dashboardId] !== undefined
  );
  const teamDashboards = useSelector((state) => state.homepage.teamDashboards);
  const defaultDashboards = teamDashboards?.defaultDashboards;
  const onDragEnd = (result) => {
    const destination = result?.destination;
    const source = result?.source;

    if (source === null || destination === null) return;

    const sourceIndex = source.index;
    const destinationIndex = destination.index;

    const [removed] = clonedDashboards.splice(sourceIndex, 1);
    clonedDashboards.splice(destinationIndex, 0, removed);
    const postBody = {
      dashboards: [...clonedDashboards],
    };
    dispatch(
      apiUpdateTeamDashboardOrder({ teamId: source.droppableId, postBody })
    );
    analytics.sendEvent("teams_order_changed", {
      teamId: source.droppableId,
    });
  };

  const onTeamCountClicked = (e, dashboardId) => {
    setSidePanelState({
      state: true,
      data: {
        isDefault: defaultDashboards?.homepages?.includes(
          parseInt(dashboardId)
        ),
        teamIds: dashboardsToTeamCountMapping[dashboardId] || [],
        dashboardName: dashboardsMappedWithId[dashboardId].homepageName || "",
        dashboardId,
      },
    });
    analytics.sendEvent("teams_count_clicked", {
      dashboardId: dashboardId,
    });
  };
  if (
    Object.keys(dashboardsMappedWithId).length === 0 ||
    dashboards.length === 0
  )
    return <></>;
  return (
    <div className={`flex flex-column w-full`}>
      {heading && (
        <div className="ml-2">
          <Text color={"var(--sub-text)"} type="sub-heading-bold">
            {heading}
          </Text>
        </div>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          type="dashboard-cards"
          droppableId={teamId || "container-1"}
          direction={"horizontal"}
        >
          {(provided) => {
            return (
              <div
                key="container-1"
                ref={provided.innerRef}
                className={`flex w-full gap-3 overflow-x-scroll p-4 ${
                  isScrollable ? `flex-row flex-nowrap` : "flex-row flex-wrap"
                }`}
                {...provided.droppableProps}
              >
                {clonedDashboards.map((dashboardId, index) => {
                  const dashboard = dashboardsMappedWithId[dashboardId];
                  return (
                    <Draggable
                      isDragDisabled={!(dragAndDrop && isScrollable)}
                      key={"id" + dashboardId}
                      index={index}
                      draggableId={"id" + dashboardId}
                    >
                      {(provided) => (
                        <div
                          key={"id" + dashboardId}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <DashboardCard
                            key={dashboardId}
                            title={dashboard.homepageName}
                            teamsCount={
                              dashboardsToTeamCountMapping[dashboardId]
                                ?.length || 0
                            }
                            status={dashboard.status}
                            favourite={dashboard.isFavourite}
                            isTeamView={showTeamCount}
                            items={
                              showTeamCount
                                ? dashboardViewItems
                                : teamViewItems(dashboard.status)
                            }
                            teamId={teamId}
                            homepage={dashboard}
                            onTeamCountClicked={(e) =>
                              onTeamCountClicked(e, dashboardId)
                            }
                            isDefault={defaultDashboards?.homepages?.includes(
                              parseInt(dashboardId)
                            )}
                            setDialog={setDialog}
                            bulkSelectedHomepagesState={
                              bulkSelectedHomepagesState
                            }
                            isHomepagesBulkSelectionEnabled={
                              isHomepagesBulkSelectionEnabled
                            }
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default DashboardContainer;
