import { useState, useEffect, useRef } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import styles from "./styles.module.css";
import { ReactComponent as Add } from "../assets/svgIcons/lsq_add.svg";
import CustomButton from "../components/CustomButton";
import CustomInput from "../components/CustomInput";
import CustomRadioGroup from "../components/CustomRadioGroup";
import { NavigationMenu } from "../constants/globalEnums";
import { iconList } from "../utils/iconList";
import Text from "../components/Text";
import {
  iconPickerTemplate,
  selectedIconTemplate,
} from "../utils/dropdownUtils";
import { CustomIconPicker } from "../components/CustomIconPicker/CustomIconPicker";
import LauncherElementDragHandler from "../components/LauncherElementDragHandler";
import { v4 as uuidv4 } from "uuid";
import {
  addWidget,
  apiAddWidget,
  apiUpdateWidget,
  deleteWidget,
  updateWidgetConfiguration,
} from "../reducers/previewPaneSlice";
import { resetWidgetConfiguration } from "../reducers/widgetTemplateListSlice";
import { items } from "../utils/demoLauncherItems";
import useWidgetEssentials from "../hooks/useWidgetEssentials";
import {
  getUpdatedName,
  getWidgetPreviewOfExperimentalWidget,
  getWidgetSignature,
} from "../utils/widgetUtils";
import analytics from "../utils/analytics";
import LsqMobileNavigator from "../components/LsqMobileNavigator";
import {
  globalConstants,
  globalConstantValues,
  quicklauncherConstants,
} from "../constants/globalConstant";

export function QuickLauncherSettings() {
  const [isDisabled, setIsDisabled] = useState(true);
  const iconPickerRef = useRef(null);
  const [iconPanelWidth, setIconPanelWidth] = useState(0);
  const [addItemClicked, setAddItemClicked] = useState(false);
  const [updatedElementIndex, setupdatedElementIndex] = useState();
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [navigationList, setNavigationList] = useState([
    {
      name: "External Link",
      items: [{ id: "ExternalLink", name: "-----" }],
    },
    {
      name: "Internal Navigation",
      items: [],
    },
  ]);
  const dispatch = useDispatch();
  const { selectedWidget, mode, selectedWidgetConfiguration, homepageId } =
    useWidgetEssentials();

  const { displayStyle } = selectedWidgetConfiguration.metaData;

  const defaultItem = {
    displayName: "",
    icon: "",
    navigateTo: "",
    internalLink: {},
    externalLink: "",
    externalLinkOptions: null,
    dynamicForm: {
      entity: "",
      workArea: null,
      form: {},
    },
  };
  const curItem = useForm({
    defaultValues: defaultItem,
  });
  const defaultValues = {
    widgetTitle: "Quick Launcher",
    displayStyle: displayStyle?.length > 0 && displayStyle[0].value,
    items: [],
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    unregister,
    watch,
    getValues,
  } = useForm({ defaultValues });
  const { fields, append, remove, swap, update, replace, move } = useFieldArray(
    {
      control,
      name: "items",
      rules: { maxLength: 9 },
    }
  );
  const watchAll = curItem.watch();
  const displayStyleWatch = watch("displayStyle");

  const onElementAddClicked = (e) => {
    let element;
    if (updatedElementIndex !== undefined) {
      element = {
        ...curItem.getValues(),
        id: uuidv4(),
      };
      update(updatedElementIndex, element);
    } else {
      element = {
        ...e,
        displayName: e.displayName.trim(),
        id: uuidv4(),
      };
      append(element);
    }
    curItem.reset();
    updateLauncherInPreviewPane();
    setupdatedElementIndex();
    setAddItemClicked(false);
  };

  const onSubmit = (data) => {
    if (selectedWidget.isExperimental) {
      getWidgetPreviewOfExperimentalWidget(selectedWidget.imageUrl, dispatch);
      return;
    }
    const config = {
      title: data.widgetTitle.trim(),
      displayStyle: data.displayStyle,
      items: data.items,
    };
    const metaValueData = getWidgetSignature(
      homepageId,
      selectedWidget?.id,
      config
    );
    const analytics_data = {
      category: "Widget",
      type: "click",
      widgetType: "QuickLauncher",
      itemCount: metaValueData.config.items?.length,
      displayStyle: metaValueData.config.displayStyle,
    };
    if (mode === globalConstants.UPDATE) {
      const updatedMetaData = {
        widgetId: selectedWidgetConfiguration?.metaValue.widgetId,
        config: {
          ...metaValueData.config,
        },
      };
      dispatch(apiUpdateWidget(updatedMetaData));
      (analytics_data["widgetId"] =
        selectedWidgetConfiguration?.metaValue.widgetId);
        analytics.sendEvent("Updating_QuickLauncher", analytics_data);
    } else if (mode === globalConstants.CREATE) {
      dispatch(apiAddWidget(metaValueData));
      analytics.sendEvent("Adding_QuickLauncher", analytics_data);
    }
    dispatch(resetWidgetConfiguration());
  };

  const onElementEdit = (index) => {
    setAddItemClicked(true);
    setupdatedElementIndex(index);
    setEdit(true);
    const { displayName, icon, navigateTo, internalLink, externalLink, externalLinkOptions } =
      fields[index];
    let updatedNavigateTo = navigateTo;
    if (navigateTo.id !== quicklauncherConstants.EXTERNAL_LINK) {
      updatedNavigateTo = getUpdatedName(
        selectedWidgetConfiguration.metaData.menu,
        navigateTo
      );
      updatedNavigateTo = updatedNavigateTo || null;
    }
    curItem.setValue("displayName", displayName, { shouldDirty: true });
    curItem.setValue("icon", icon, { shouldDirty: true });
    curItem.setValue("navigateTo", updatedNavigateTo, { shouldDirty: true });
    curItem.setValue("internalLink", internalLink, { shouldDirty: true });
    curItem.setValue("externalLink", externalLink, { shouldDirty: true });
    curItem.setValue("externalLinkOptions", externalLinkOptions, { shouldDirty: true });
  };

  const getItems = () => {
    if (getValues("items").length === 0) return items;
    else return getValues("items");
  };

  const updateLauncherInPreviewPane = () => {
    const widgetMetaValue = {
      widget: {
        widgetId:
          mode === globalConstants.CREATE
            ? quicklauncherConstants.DEMO_LAUNCHER_VIEW
            : selectedWidgetConfiguration?.metaValue.widgetId,
        config: {
          title: getValues("widgetTitle"),
          displayStyle: getValues("displayStyle"),
          items: getItems(),
        },
      },
    };
    dispatch(updateWidgetConfiguration(widgetMetaValue));
  };

  useEffect(() => {
    const widgetMetaValue = {
      widget: {
        widgetId:
          mode === globalConstants.CREATE
            ? quicklauncherConstants.DEMO_LAUNCHER_VIEW
            : selectedWidgetConfiguration.metaValue.widgetId,
        config: {
          title: getValues("widgetTitle"),
          displayStyle: getValues("displayStyle"),
          items: getItems(),
        },
      },
    };
    dispatch(updateWidgetConfiguration(widgetMetaValue));
  }, [displayStyleWatch]);

  let existingWidgetCongiguration = {};

  useEffect(() => {
    let tempNavigationList = [...navigationList];
    if (selectedWidgetConfiguration.metaData?.menu) {
      tempNavigationList[1].items = selectedWidgetConfiguration.metaData?.menu;
    }
    setNavigationList(tempNavigationList);
    if (mode === globalConstants.CREATE) {
      const demoQuickLauncher = {
        widgetLayout: {
          type: "full",
          widgetId: quicklauncherConstants.DEMO_LAUNCHER_VIEW,
          widgetType: "quicklauncher",
        },
        widgetConfig: {
          homepageId: homepageId,
          widgetTemplateId: 4,
          config: {
            title: "Quick Launcher",
            displayStyle: getValues("displayStyle"),
            items: items,
          },
        },
      };
      dispatch(addWidget(demoQuickLauncher));
    }
    if (
      mode === "UPDATE" &&
      Object.keys(selectedWidgetConfiguration.metaValue).length !== 0
    ) {
      existingWidgetCongiguration =
        selectedWidgetConfiguration.metaValue.config;
      setValue(
        "widgetTitle",
        selectedWidgetConfiguration.metaValue.config.title
      );
      setValue(
        "displayStyle",
        selectedWidgetConfiguration.metaValue.config.displayStyle
      );
      const widgetMetaValue = {
        widget: {
          widgetId: selectedWidgetConfiguration.metaValue.widgetId,
          config: {
            title: getValues("widgetTitle"),
            displayStyle: getValues("displayStyle"),
            items: selectedWidgetConfiguration.metaValue.config.items,
          },
        },
      };
      dispatch(updateWidgetConfiguration(widgetMetaValue));
      replace(selectedWidgetConfiguration.metaValue.config.items);
    }

    return () => {
      if (mode === globalConstants.CREATE) {
        dispatch(
          deleteWidget({
            widgetId: quicklauncherConstants.DEMO_LAUNCHER_VIEW,
          })
        );
      } else if (mode === globalConstants.UPDATE) {
        const widgetMetaValue = {
          widget: {
            widgetId: selectedWidgetConfiguration?.metaValue.widgetId,
            config: {
              ...existingWidgetCongiguration,
            },
          },
        };
        dispatch(updateWidgetConfiguration(widgetMetaValue));
      }
    };
  }, []);

  useEffect(() => {
    const dirtyFieldLength = Object.keys(curItem.formState.dirtyFields).length;
    const navigateTo = watchAll["navigateTo"];
    if (isLoading) setIsDisabled(true);
    else if (dirtyFieldLength < 3) {
      if (!isDisabled) setIsDisabled(true);
    } else if (
      navigateTo?._links?.subMenu?.href !== undefined &&
      navigateTo.name !== NavigationMenu.DYNAMIC_FORMS &&
      dirtyFieldLength <= 3
    ) {
      if (!isDisabled) setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [watchAll]);

  return (
    <form
      style={{ height: "90%" }}
      className="flex flex-column w-full scroll-bar-visible"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex-1 overflow-y-auto">
        <div className={`flex flex-column mt-5 lg:mx-6 md:mx-5 sm: mx-4 gap-3`}>
          <div className={`flex flex-column gap-2`}>
            <CustomInput
              fieldName="widgetTitle"
              errors={errors}
              control={control}
              isMandatory
              label={quicklauncherConstants.DISPLAY_NAME}
              placeholder="Type Here"
              maxLength={30}
            />
          </div>
          <CustomRadioGroup
            control={control}
            label={quicklauncherConstants.DISPLAY_STYLE}
            options={displayStyle}
            radioName="displayStyle"
            data-testid="displayStyle"
          />
          {fields.length >= 1 && (
            <LauncherElementDragHandler
              onElementEdit={onElementEdit}
              elementList={fields}
              remove={remove}
              swap={swap}
              move={move}
              updateLauncherInPreviewPane={updateLauncherInPreviewPane}
              updatedElementIndex={updatedElementIndex}
            />
          )}
          {(fields.length < 9 || updatedElementIndex !== undefined) &&
            (addItemClicked ? (
              <div className={`flex flex-column gap-2`}>
                <div className={`flex w-full gap-1`} ref={iconPickerRef}>
                  <CustomIconPicker
                    id="icon-list"
                    fieldName="icon"
                    control={curItem.control}
                    className="w-full text-base lsq-input lsq-icon-picker"
                    options={iconList}
                    optionLabel="name"
                    filter
                    filterBy="name"
                    resetFilterOnHide={true}
                    filterPlaceholder={quicklauncherConstants.SEARCH_ICONS}
                    placeholder={"lsq_change_image"}
                    valueTemplate={selectedIconTemplate}
                    itemTemplate={iconPickerTemplate}
                    label={quicklauncherConstants.ICON}
                    isMandatory={true}
                    errors={curItem.formState.errors}
                    panelClassName="lsqIconPickerPanel"
                    onShow={() => {
                      setIconPanelWidth(iconPickerRef.current?.offsetWidth);
                    }}
                    panelStyle={{ width: iconPanelWidth }}
                    containerStyle={{ width: "20%" }}
                  />
                  <CustomInput
                    id={"custominput"}
                    errors={curItem.formState.errors}
                    fieldName="displayName"
                    control={curItem.control}
                    isMandatory
                    label={quicklauncherConstants.LABEL}
                    maxLength={
                      globalConstantValues.QUICK_LAUNCHER_LABEL_CHAR_LIMIT
                    }
                    placeholder="Type Here"
                    containerStyle={{ width: "80%" }}
                  />
                </div>
                <LsqMobileNavigator
                  formValues={curItem}
                  mobileNavigationMenu={navigationList}
                  unregister={unregister}
                  edit={edit}
                  setEdit={setEdit}
                  isMandatory={true}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                  mode={mode}
                />
                <div
                  className={`flex w-12 py-2 align-self-center align-items-center justify-content-end mt-auto gap-2`}
                >
                  <CustomButton
                    type="reset"
                    onClick={() => {
                      curItem.reset();
                      setupdatedElementIndex();
                      setAddItemClicked(false);
                    }}
                    varient="text"
                    label={quicklauncherConstants.CANCEL}
                  />
                  <CustomButton
                    data-testid="add-btn"
                    disabled={isDisabled}
                    className="flex justify-content-center w-3"
                    onClick={curItem.handleSubmit((e) =>
                      onElementAddClicked(e)
                    )}
                    label={
                      updatedElementIndex !== undefined
                        ? quicklauncherConstants.SAVE
                        : quicklauncherConstants.ADD
                    }
                    varient="filled"
                  />
                </div>
              </div>
            ) : (
              <div
                className={`flex gap-1 align-items-center p-2 border-round cursor-pointer ${styles["laucher-add-item"]}`}
                onClick={() => setAddItemClicked(true)}
                data-testid="add-launcher-item"
              >
                <Add fill="var(--icon-primary)" />
                <Text type="T4B" color="var(--text-primary)">
                  {quicklauncherConstants.ADD_ITEM}
                </Text>
              </div>
            ))}
        </div>
      </div>
      <div
        className={`flex w-11 py-2 mt-3 align-self-center align-items-center justify-content-end mt-auto gap-2`}
      >
        <CustomButton
          disabled={fields.length === 0}
          type="submit"
          varient="filled"
          label={quicklauncherConstants.SAVE}
        />
      </div>
    </form>
  );
}
