import React, { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, useFieldArray } from "react-hook-form";
import Joyride from "react-joyride";
import { ReactComponent as PublishIcon } from "../../assets/svgIcons/publish.svg";
import { ReactComponent as SavedIcon } from "../../assets/svgIcons/lsq_ok.svg";
import { ReactComponent as DropdownIcon } from "../../assets/svgIcons/lsq_chevron_down_filled.svg";
import MenuConfigurator from "./MenuConfigurator";
import {
  ROUTES_PATH,
  excludedTabsInBottomNavigation,
  globalConstants,
  menuBuilderConstants,
  toastMessageConstants,
} from "../../constants/globalConstant";
import LsqProgressSpinner from "../../components/LsqProgressSpinner";
import MobileSreenMenuBuilderTemplate from "./MobileSreenMenuBuilderTemplate";
import Scaffold from "../../components/Scaffold";
import CustomMenu from "./CustomMenu";
import PublishMenuBuilder from "./PublishMenuBuilder";
import Modal from "../../components/Modal";
import ToastMessage from "../../components/ToastMessage";
import styles from "./styles.module.css";
import useToast from "../../hooks/useToast";
import { hierarchyToLinear, teamsToMenuMapping } from "../../utils/globalUtils";
import CoachTooltip from "../../components/Coach/CoachTooltip";
import { menuBuilderSteps } from "../../components/Coach/coachSteps";
import {
  apiUpdateUserPreferences,
  getTeams,
} from "../../reducers/homePageSlice";
import {
  getMenuTab,
  resetMenuConfiguration,
  addToastMessage,
  getTeamsMenus,
  apiUpdateMenu,
  setCicoCustomisation,
} from "../../reducers/menuBuilderSlice";
import {
  formatNavigationTabData,
  getTabsbyTabId,
  getUniqueTabsByTabId,
} from "../../utils/menuBuilderUtils";
import { useNavigate } from "react-router-dom";
import TeamsAssignedView from "./TeamAssignedView";
import SidePanel from "../../components/SidePanel";
import CustomSplitButton from "../../components/CustomSplitButton";
import CheckInCustomisations from "./CheckInCustomisations";

export default function MenuBuilder() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState({
    key: menuBuilderConstants.BOTTOM_NAVIGATION,
    value: menuBuilderConstants.BOTTOM_NAVIGATION,
    "data-testid": "bottom-navigation",
  });
  const {
    selectedMenuId,
    menus,
    isMenuBuilderLoading,
    menuTabs,
    selectedMenuConfiguration,
    defaultTemplateConfiguration,
    toastMessages,
    publish,
    cicoCustomisation,
  } = useSelector((state) => state.menuBuilder);
  const { menuBuilderVisited } = useSelector(
    (state) => state.homepage.userPreferences
  );
  const { isPublishLoading, isPublished } = publish;
  const defaultMenuTabs = menuTabs.defaultTabs;
  const customMenuTabsbyId = getTabsbyTabId(menuTabs.customTabs || []);
  const [dialog, setDialog] = useState({ state: false, dialogBox: {} });
  const [createNewTab, setCreateNewTab] = useState({
    state: false,
    data: null,
  });
  const [publishState, setPublishState] = useState({
    state: false,
    data: null,
  });
  const [sidePanelState, setSidePanelState] = useState({
    state: false,
    data: null,
  });
  const teamHierarchy = useSelector((state) => state.homepage.teams);
  const teamLinear = useMemo(() => {
    return hierarchyToLinear(teamHierarchy);
  }, [teamHierarchy]);
  const teamsToMenuCountMapping = useMemo(() => {
    return teamsToMenuMapping(menus?.teamMenu, teamLinear);
  }, [menus, teamLinear]);
  const toast = useToast(addToastMessage);
  const visibleBottomTabsItems = selectedMenuId
    ? selectedMenuConfiguration.bottomNavigation
    : defaultTemplateConfiguration.bottomNavigation;
  const bottomDefaultTabs = defaultMenuTabs?.filter(
    (item) => !excludedTabsInBottomNavigation.includes(item.tabId)
  );
  const availableBottomTabItems = getUniqueTabsByTabId(
    bottomDefaultTabs,
    visibleBottomTabsItems
  );
  const visibleSideTabsItems = selectedMenuId
    ? selectedMenuConfiguration.sideNavigation
    : defaultTemplateConfiguration.sideNavigation;
  const availableSideTabItems = getUniqueTabsByTabId(
    defaultMenuTabs,
    visibleSideTabsItems
  );
  const defaultValues = {
    visibleBottomItems: visibleBottomTabsItems,
    availableBottomItems: availableBottomTabItems,
    visibleSideItems: visibleSideTabsItems,
    availableSideItems: availableSideTabItems,
  };
  const defaultMenuId = menus?.defaultMenu?.menuId;

  const { control } = useForm({ defaultValues: defaultValues });
  const bottomVisibleTabs = useFieldArray({
    control,
    name: "visibleBottomItems",
    rules: { maxLength: 4 },
  });

  const bottomAvailableTabs = useFieldArray({
    control,
    name: "availableBottomItems",
  });
  const sideVisibleTabs = useFieldArray({
    control,
    name: "visibleSideItems",
  });

  const sideAvailableTabs = useFieldArray({
    control,
    name: "availableSideItems",
  });

  useEffect(() => {
    dispatch(getMenuTab());
    if (teamHierarchy.length === 0) {
      dispatch(getTeams());
    }
    if (!menus.teamMenu) dispatch(getTeamsMenus());
  }, []);

  useEffect(() => {
    bottomVisibleTabs.replace(visibleBottomTabsItems || []);
    bottomAvailableTabs.replace(availableBottomTabItems || []);
    sideVisibleTabs.replace(visibleSideTabsItems || []);
    sideAvailableTabs.replace(availableSideTabItems || []);
  }, [menuTabs.defaultTabs, selectedMenuConfiguration, selectedMenuId]);

  useEffect(() => {
    if (selectedMenuConfiguration.config?.cico)
      dispatch(
        setCicoCustomisation({
          ...cicoCustomisation,
          cico: selectedMenuConfiguration.config?.cico,
        })
      );
  }, [selectedMenuConfiguration]);

  const togglePublishView = () => {
    setPublishState({
      state: true,
      data: {
        isDefault: menus?.defaultMenu?.menuId == selectedMenuId,
        teamIds: teamsToMenuCountMapping[selectedMenuId],
        sideNavigation: formatNavigationTabData(
          sideVisibleTabs.fields,
          defaultMenuTabs,
          customMenuTabsbyId,
          selectedMenuId ? selectedMenuConfiguration.publishedSideTabs : []
        ),
        bottomNavigation: formatNavigationTabData(
          bottomVisibleTabs.fields,
          defaultMenuTabs,
          customMenuTabsbyId,
          selectedMenuId ? selectedMenuConfiguration.publishedBottomTabs : []
        ),
        menuName: selectedMenuId ? selectedMenuConfiguration.menuName : "",
        selectedMenuId,
        cico: cicoCustomisation.cico,
      },
    });
  };

  const onTeamsClicked = () => {
    setSidePanelState({
      state: true,
      data: {
        isDefault: defaultMenuId == selectedMenuId,
        teamIds: teamsToMenuCountMapping[selectedMenuId] || [],
        menuName: selectedMenuId ? selectedMenuConfiguration.menuName : "",
        menuId: selectedMenuId,
      },
    });
  };

  const onUnpublishedClicked = () => {
    const postBody = { status: "unpublished" };
    dispatch(apiUpdateMenu({ menuId: selectedMenuId, postBody })).then(
      ({ type, payload }) => {
        if (type.includes(globalConstants.FULFILLED)) {
          toast.success(
            `${payload.menu.name} was unpublished successfully!`,
            "Menu Unpublished"
          );
        }
      }
    );
  };

  const getPublishAdditionalOptions = () => {
    const options = isPublished
      ? [
          {
            label: "Unpublish",
            command: () => {
              onUnpublishedClicked();
            },
          },
          {
            label: "Teams",
            command: () => {
              onTeamsClicked();
            },
          },
        ]
      : [];
    return options;
  };

  const getPublishButtonLabel = () => {
    if (isPublishLoading || !isPublished) return menuBuilderConstants.PUBLISH;
    return menuBuilderConstants.PUBLISHED;
  };

  const getPublishButtonIcon = () => {
    if (isPublishLoading)
      return <LsqProgressSpinner strokeWidth={3} strokeColor="white" />;
    if (!isPublished) return <PublishIcon />;
    return <SavedIcon fill="white" />;
  };

  const onPublishClicked = () => {
    if (isPublishLoading || isPublished) return;
    if (sideVisibleTabs.fields?.length === 0) {
      toast.error(
        toastMessageConstants.ONE_TAB_MUST_BE_PRESENT_IN_SIDE_NAVIGATION
      );
      return;
    }
    togglePublishView();
  };

  const onCoachSkippedOrFinished = () => {
    const payload = {
      menuBuilderVisited: true,
    };
    dispatch(apiUpdateUserPreferences(payload));
  };

  return (
    <div
      className="flex h-screen w-screen flex-row pl-7"
      data-testid="menu-builder-view"
    >
      {!isMenuBuilderLoading && (
        <Joyride
          tooltipComponent={(props) => (
            <CoachTooltip
              {...props}
              onCoachSkippedOrFinished={onCoachSkippedOrFinished}
            />
          )}
          continuous
          run={!menuBuilderVisited}
          showProgress
          showSkipButton
          steps={menuBuilderSteps}
          disableOverlayClose={true}
          disableCloseOnEsc={true}
          spotlightPadding={0}
        />
      )}
      <div className={`flex lg:w-8 sm:w-7`}>
        <Scaffold
          hasBackButton={true}
          onBackButtonClicked={() => {
            navigate(ROUTES_PATH.menus);
            dispatch(resetMenuConfiguration());
          }}
          rightActionButtons={
            <CustomSplitButton
              varient="filled"
              onClick={onPublishClicked}
              icon={getPublishButtonIcon()}
              label={getPublishButtonLabel()}
              id="publish-btn"
              additionalOptions={getPublishAdditionalOptions()}
              dropdownIcon={<DropdownIcon></DropdownIcon>}
            />
          }
          title={
            selectedMenuId
              ? selectedMenuConfiguration.menuName
              : menuBuilderConstants.MENU_BUILDER
          }
          loading={isMenuBuilderLoading}
        >
          <div className="flex w-full justify-content-center mt-3 relative align-items-center">
            <MobileSreenMenuBuilderTemplate
              mobileView={activeTab.value}
              sideNavigationTabs={sideVisibleTabs.fields}
              bottomNavigationTabs={bottomVisibleTabs.fields}
              cico={cicoCustomisation?.cico}
            ></MobileSreenMenuBuilderTemplate>
            {toastMessages.length > 0 ? (
              <div className={`${styles["toast_container"]}`}>
                <ToastMessage toastMessages={toastMessages} />
              </div>
            ) : (
              <></>
            )}
          </div>
        </Scaffold>
      </div>
      <div className="flex lg:w-4 sm:w-5">
        <div
          style={{ backgroundColor: "var(--N20)" }}
          className="flex h-full w-full justify-content-center align-items-center"
        >
          <MenuConfigurator
            key={activeTab.key}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            sideVisibleTabs={sideVisibleTabs}
            sideAvailableTabs={sideAvailableTabs}
            bottomAvailableTabs={bottomAvailableTabs}
            bottomVisibleTabs={bottomVisibleTabs}
            setCreateNewTab={setCreateNewTab}
            setDialog={setDialog}
          ></MenuConfigurator>
        </div>
        {createNewTab.state && (
          <CustomMenu
            activeTab={activeTab}
            createNewTab={createNewTab}
            setCreateNewTab={setCreateNewTab}
          ></CustomMenu>
        )}
        {cicoCustomisation?.showCustomisation && <CheckInCustomisations />}
      </div>
      <Modal
        state={sidePanelState.state}
        backgroundStyle={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SidePanel
          state={sidePanelState.state}
          setSidePanelState={setSidePanelState}
        >
          <TeamsAssignedView
            setSidePanelState={setSidePanelState}
            sidePanelState={sidePanelState}
          />
        </SidePanel>
      </Modal>
      <Modal state={publishState.state}>
        <PublishMenuBuilder
          setPublishState={setPublishState}
          publishState={publishState}
        ></PublishMenuBuilder>
      </Modal>
      <Modal state={dialog.state}>{dialog.dialogBox}</Modal>
    </div>
  );
}
