import React, { useRef, useState } from "react";
import { ReactComponent as Edit } from "../../../assets/svgIcons/lsq_edit.svg";
import { ReactComponent as Delete } from "../../../assets/svgIcons/lsq_delete.svg";
import { FileUpload } from "primereact/fileupload";
import { FormErrorMessage } from "../../../components/FormErrorMessage";
import { leadControlsWidgetConstants } from "../../../constants/globalConstant";

export default function TileImagePreview({
  element,
  imageUploadHandler,
  maxFileSize,
  onElementDeleted,
}) {
  const fileRef = useRef();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  return element?.uploadStatus === leadControlsWidgetConstants.SUCCESS ? (
    <div className="flex flex-column w-full border-round h-5rem relative">
      <img
        src={`${element?.imageURL}`}
        alt="img"
        className="w-full h-full"
        style={{
          borderRadius: "inherit",
        }}
      />
      {showErrorMessage && (
        <FormErrorMessage
          message={`Please upload a file less than ${maxFileSize / 1000000}mb!`}
        />
      )}
      <div className="flex w-full justify-content-end absolute p-1 gap-2">
        <div
          className="flex bg-white border-round cursor-pointer"
          onClick={() => {
            fileRef.current.choose();
          }}
          data-testid="image-edit-btn"
        >
          <Edit />
        </div>
        <div
          className="flex bg-white border-round cursor-pointer"
          onClick={() => onElementDeleted()}
          data-testid="image-delete-btn"
        >
          <Delete style={{ fill: "var(--R800)" }} />
        </div>
        <FileUpload
          style={{ display: "none" }}
          className="w-5"
          mode="basic"
          name="banner-image"
          accept="image/jpeg,image/png,image/jpg,image/gif"
          maxFileSize={maxFileSize}
          chooseLabel="Add"
          customUpload={true}
          auto
          uploadHandler={(e) => {
            imageUploadHandler(e);
            setShowErrorMessage(false);
          }}
          onValidationFail={() => setShowErrorMessage(true)}
          ref={fileRef}
        />
      </div>
    </div>
  ) : (
    <></>
  );
}
