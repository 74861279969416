import React, { useState, useEffect } from "react";
import Text from "../Text";
import { useTranslation } from "react-i18next";
import { ReactComponent as LsqChevronDown } from "../../assets/svgIcons/lsq_chevron_down.svg";
import styles from "./styles.module.css";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";

export const InlineDropdown = ({
  items = [],
  selectedLabel,
  setSelectedLabel,
  ...props
}) => {
  const { t } = useTranslation();

  const menuRef = useOnClickOutside(() => setPopUp(false));
  const [updatedMenuList, setUpdatedMenuList] = useState(() =>
    items.filter(({ label }) => label !== selectedLabel)
  );
  const [popUp, setPopUp] = useState(false);

  const popUpHandler = () => {
    return setPopUp((prevState) => !prevState);
  };

  const selectedItemHanlder = (label) => {
    setSelectedLabel(label)
    setPopUp(false);
  };

  useEffect(() => {
    const newMenuList = items.filter(({ label }) => selectedLabel !== label);
    setUpdatedMenuList(newMenuList);
  }, [selectedLabel]);

  return (
    <div
      className={
        `flex flex-column cursor-pointer ${styles["inline_dropdown_container"]} relative`
      } {...props}
    >
      <div
        className={`flex flex-row align-items-center justify-content-center gap-1 ${styles["value_template"]}`}
        onClick={popUpHandler}
        data-testid="popUpHandler"
        ref={menuRef}
      >
        <Text type="T4B" color="#768196" className="capitalize w-max">
          {selectedLabel}
        </Text>
        <LsqChevronDown />
      </div>
      {popUp ? (
        <div className={`${styles["menu_container"]}`}>
          {updatedMenuList.map(({ label }) => {
            return (
              <div
                className={`${styles["menu_item"]} capitalize`}
                onClick={() => selectedItemHanlder(label)}
                key={label}
              >
                <Text type="T4" color="#768196">
                  {t(label)}
                </Text>
              </div>
            );
          })}
        </div>
      ) : (
        <> </>
      )}
    </div>
  );
};