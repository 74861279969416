import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch} from "react-redux";
import { ReactComponent as SearchIcon } from "../../assets/svgIcons/search.svg";
import styles from "./styles.module.css";
import Text from "../../components/Text";
import CustomInputV2 from "../../components/CustomInputV2";
import { ReactComponent as NoDashboardFound } from "../../assets/svgIcons/no_dashboard_found.svg";
import { NoResultsFound } from "../../components/NoResultsFound";
import { ROUTES_PATH, casaConstants, globalConstants } from "../../constants/globalConstant";
import { ReactComponent as DashboardIcon } from "../../assets/svgIcons/lsq_dashboard.svg";
import {
  addToastMessage,
  getHomepages,
  getTeamDashboards,
  getTeams,
  resetHomeBuilder,
} from "../../reducers/homePageSlice";
import CustomButton from "../../components/CustomButton";
import { ReactComponent as LsqAdd } from "../../assets/svgIcons/lsq_add.svg";
import { homepageFilterItems } from "../../utils/homepageUtils";
import {
  dashboardViewSortItems,
  filterDashboards,
  searchDashboards,
  sortDashboards,
} from "../../utils/casaUtils";
import {
  countLimit,
  dashboardsToTeamMapping,
  hierarchyToLinear,
} from "../../utils/globalUtils";
import DashboardContainer from "./DashboardContainer";
import SidePanel from "../../components/SidePanel";
import SidePanelView from "./SidePanelView";
import Modal from "../../components/Modal";
import ActionBar from "./ActionBar";
import useHomepageCard from "../../hooks/useHomepageCard";
import ToastMessage from "../../components/ToastMessage";
import { IndeterminateLoader } from "../../components/IndeterminateLoader";
import {
  changeSelectedWidgetListTab,
} from "../../reducers/widgetTemplateListSlice";
import useToast from "../../hooks/useToast";
import analytics from "../../utils/analytics";
import { widgetTabs } from "../../utils/widgetUtils";

const NoHomepagesFoundContent = () => {
  return (
    <div data-testid="no-result-text">
      <Text type="T1B" color={"var(--tertiary)"}>
        {casaConstants.NO_DASHBOARD_FOUND}
      </Text>
    </div>
  );
};

const HomepagesView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast(addToastMessage);
  const homepageViewSortState = useState(dashboardViewSortItems[0].label);
  const homepageViewFilterState = useState(homepageFilterItems[0].label);
  const searchState = useState("");
  const [sidePanelState, setSidePanelState] = useState({
    state: false,
    data: null,
  });
  const [dialog, setDialog] = useState({ state: false, dialogBox: {} });
  const { createHomepageClicked } = useHomepageCard();
  const homepageStore = useSelector((state) => state.homepage);
  const {
    homepagesById: dashboardsMappedWithId,
    teamDashboards,
    teams: teamsHierarchy,
    toastMessages,
    isHomepageLoading,
  } = homepageStore;
  const teamsLinear = useMemo(() => {
    return hierarchyToLinear(teamsHierarchy);
  }, [teamsHierarchy]);
  const dashboardsToTeamCountMapping = useMemo(() => {
    return dashboardsToTeamMapping(
      teamDashboards?.teamsDashboards,
      teamsLinear
    );
  }, [teamDashboards, teamsLinear]);
  const [isHomepagesBulkSelectionEnabled, setIsHomepagesBulkSelectionEnabled] =
    useState(false);
  const bulkSelectedHomepagesState = useState({});
  const [bulkSelectedHomepages, setBulkSelectedHomepages] =
    bulkSelectedHomepagesState;

  let filteredAndSortedDashboards = filterDashboards(
    Object.keys(dashboardsMappedWithId),
    homepageViewFilterState[0],
    dashboardsMappedWithId
  );
  filteredAndSortedDashboards = searchDashboards(
    filteredAndSortedDashboards,
    searchState[0],
    dashboardsMappedWithId
  );

  filteredAndSortedDashboards = sortDashboards(
    filteredAndSortedDashboards,
    homepageViewSortState[0],
    dashboardsMappedWithId
  );
  useEffect(() => {
    if(teamsHierarchy.length === 0){
    dispatch(getTeams());
    }
    if(!teamDashboards.teamsDashboards) dispatch(getTeamDashboards())
    dispatch(getHomepages());
    dispatch(resetHomeBuilder());
    dispatch(changeSelectedWidgetListTab(widgetTabs[0]));
  }, []);

  useEffect(() => {
    if (!isHomepagesBulkSelectionEnabled) return;
    const updatedBulkSelectedHomepages = Object.keys(
      bulkSelectedHomepages
    ).filter((homepageId) => dashboardsMappedWithId[homepageId]);
    setBulkSelectedHomepages(
      updatedBulkSelectedHomepages.reduce(
        (acc, curr) => ({ ...acc, [curr]: true }),
        {}
      )
    );
  }, [dashboardsMappedWithId]);

  const navigateToTemplates = () => {
    if (
      Object.keys(dashboardsMappedWithId).length < countLimit.HOMEPAGES_COUNT
    ) {
      navigate(ROUTES_PATH.templates)
      analytics.sendEvent("Template button clicked from homepages View",{
          category: "create new homepage using template",
          type: "click",
      })
    } else
      toast.warn(
        `Maximum ${countLimit.HOMEPAGES_COUNT} homepages can be added`
      );
  };

  return (
    <div
      className="flex flex-row w-screen h-screen p-3"
      data-testid="homepages-data-view"
    >
      <div className="flex h-screen w-screen align-items-center ml-7 flex-column gap-1">
        <div
          className={`flex w-full align-items-center justify-content-between p-2`}
        >
          <Text type="T1B" color="var(--text-primary)">
            {globalConstants.MY_HOMEPAGES}
          </Text>
          <div
            className={`flex align-items-center gap-2`}
            style={{ height: "2.25rem" }}
          >
            <CustomInputV2
              style={{ width: "20rem", height: "2.25rem" }}
              placeholder={"Search Homepages"}
              value={searchState[0]}
              onChange={(e) => searchState[1](e.target.value)}
              LeftIcon={SearchIcon}
              data-testid="homepages-search"
            />
            <div className="flex align-items-center gap-2 w-full">
              <CustomButton
                varient="outline"
                icon={<DashboardIcon />}
                label={"Templates"}
                onClick={navigateToTemplates}
                data-testid="templates-btn"
              />
              <CustomButton
                varient="filled"
                icon={<LsqAdd fill="var(--N0)" />}
                label={"Create New"}
                data-testid="create-new-btn"
                onClick={() => {
                  createHomepageClicked("Homepages View");
                }}
              ></CustomButton>
            </div>
          </div>
        </div>
        <div
          className={`flex h-full w-full align-items-center flex-column overflow-hidden ${styles["homepages-view"]}`}
        >
          <div className={`toast_container`}>
            <ToastMessage toastMessages={toastMessages} />
          </div>
          <ActionBar
            setDialog={setDialog}
            setIsHomepagesBulkSelectionEnabled={
              setIsHomepagesBulkSelectionEnabled
            }
            homepageFilterItems={homepageFilterItems}
            homepageViewFilterState={homepageViewFilterState}
            homepageViewSortState={homepageViewSortState}
            bulkSelectedHomepages={bulkSelectedHomepages}
            setBulkSelectedHomepages={setBulkSelectedHomepages}
            isHomepagesBulkSelectionEnabled={isHomepagesBulkSelectionEnabled}
          ></ActionBar>
          <div className={`flex w-full h-full flex-column overflow-scroll`}>
            {isHomepageLoading ? (
              <IndeterminateLoader
                color="#0A1F43"
                height="0.25rem"
                backgroundColor="#B0B7C2"
              />
            ) : (
              <div style={{ height: "0.2rem" }} />
            )}
            {filteredAndSortedDashboards.length === 0 ? (
              <NoResultsFound
                Illustration={NoDashboardFound}
                children={NoHomepagesFoundContent()}
              />
            ) : (
              <DashboardContainer
                dragAndDrop={false}
                isScrollable={false}
                dashboards={filteredAndSortedDashboards}
                dashboardsMappedWithId={dashboardsMappedWithId}
                dashboardsToTeamCountMapping={dashboardsToTeamCountMapping}
                showTeamCount={true}
                setSidePanelState={setSidePanelState}
                setDialog={setDialog}
                bulkSelectedHomepagesState={bulkSelectedHomepagesState}
                isHomepagesBulkSelectionEnabled={
                  isHomepagesBulkSelectionEnabled
                }
              />
            )}
          </div>
        </div>
        <SidePanel
          state={sidePanelState.state}
          setSidePanelState={setSidePanelState}
          style={{top: "0"}}
        >
          <SidePanelView
            setSidePanelState={setSidePanelState}
            sidePanelState={sidePanelState}
          />
        </SidePanel>
        <Modal state={dialog.state}>{dialog.dialogBox}</Modal>
      </div>
    </div>
  );
};
export default HomepagesView;
