import React from "react";
import Wifi from "../../../assets/svgIcons/wifi.svg";
import Cellular from "../../../assets/svgIcons/cellular.svg";
import Battery from "../../../assets/svgIcons/battery.svg";
import styles from "./styles.module.css";
import { getCurrentTime } from "../../../utils/globalUtils";
import Hamburger from "../../../assets/svgIcons/Menu.svg";
import Text from "../../../components/Text";
import LeadQuickViewCardMobilePreview from "../LeadCard/LeadQuickViewCardMobilePreview";

export default function LeadListPreview() {
  return (
    <div className={`mobile-screen pointer-events-none`}>
      <div className={styles["status-bar"]}>
        <div className={styles["time"]}>{getCurrentTime()}</div>
        <div className={styles["status-bar-content"]}>
          <img alt="wifi" src={Wifi} />
          <img alt="cellular" src={Cellular} />
          <img alt="battery" src={Battery} />
        </div>
      </div>
      <div className={`flex bg-white p-2 gap-2 align-items-center`}>
        <img alt="hamburger" src={Hamburger} />
        <Text type="sub-heading">{"Lead List"}</Text>
      </div>
      <div className="flex flex-column gap-2 p-2 w-full h-full align-items-center">
        <LeadQuickViewCardMobilePreview />
      </div>
    </div>
  );
}
