import React from "react";
import ToggleTab from "../../components/ToggleTab";
import styles from "./styles.module.css";
import BottomNavigation from "./BottomNavigation";
import SideNavigation from "./SideNavigation";
import { menuBuilderConstants } from "../../constants/globalConstant";
import { menuConfiguratorTabs } from "../../utils/homepageUtils";

export default function MenuConfigurator({
  activeTab,
  setActiveTab,
  sideVisibleTabs,
  sideAvailableTabs,
  bottomAvailableTabs,
  bottomVisibleTabs,
  setCreateNewTab,
  setDialog,
}) {
  const renderTab = (activeTab) => {
    switch (activeTab.value) {
      case menuBuilderConstants.BOTTOM_NAVIGATION:
        return (
          <BottomNavigation
            bottomAvailableTabs={bottomAvailableTabs}
            bottomVisibleTabs={bottomVisibleTabs}
            setCreateNewTab={setCreateNewTab}
            setDialog={setDialog}
            sideNavigationTabs={sideVisibleTabs.fields}
          />
        );
      case menuBuilderConstants.SIDE_NAVIGATION:
        return (
          <SideNavigation
            sideVisibleTabs={sideVisibleTabs}
            sideAvailableTabs={sideAvailableTabs}
            setCreateNewTab={setCreateNewTab}
            setDialog={setDialog}
            bottomNavigationTabs={bottomVisibleTabs.fields}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div
      className={`flex flex-column w-full h-full ${styles["menu-configurator"]}`}
    >
      <div
        className={`flex align-items-center justify-content-between w-full py-3 ${styles["menu-configurator-header"]} menu-builder-toggle-bar`}
      >
        <ToggleTab
          tabs={menuConfiguratorTabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        ></ToggleTab>
      </div>
      <div
        className={`flex flex-column w-full h-full my-4 overflow-hidden ${styles["menu-configurator-tab"]}`}
      >
        {renderTab(activeTab)}
      </div>
    </div>
  );
}
