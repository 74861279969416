import React from "react";
import { leadMetricsConstansts } from "../../constants/globalConstant";
import styles from "../styles.module.css";
import Text from "../../components/Text";

export const LeadMetrics = ({ widgetInformation }) => {
  const { title, chartType } = widgetInformation.config;
  const imgURL = `/images/widgetPreview/${chartType.name?.toLowerCase()}_chart.png`
  return (
    <div
      className={`${styles["widget-container"]}`}
      data-testid={`${chartType.name}-chart-preview`}
    >
      <div className="flex flex-column absolute pl-2 pt-2">
        <Text type="sub-heading" color="var(--text-focused)">
          {title}
        </Text>
      </div>
      <img
        className="w-full"
        src={
          imgURL
        }
        alt={
          chartType.name === leadMetricsConstansts.BAR
            ? "bar-chart"
            : "line-chart"
        }
      />
    </div>
  );
};
