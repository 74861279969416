import React from "react";
import styles from "../styles.module.css";
import Text from "../../components/Text";
import { stringConstants } from "../../constants/globalConstant";

export const NearMe = ({ widgetInformation }) => {
  const { title } = widgetInformation.config;
  const imgURL = `/images/widgetPreview/near_me.png`
  return (
    <div
      className={`${styles["widget-container"]}`}
      data-testid={`near-me-preview`}
    >
      <div className="flex flex-column absolute pl-2 pt-2">
        <Text type="sub-heading-bold" color="var(--text-focused)">
          {title}
        </Text>
      </div>
      <img
        className="w-full"
        src={
          imgURL
        }
        alt={stringConstants.NEAR_ME}
      />
    </div>
  );
};
