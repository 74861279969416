import {ReactComponent as Alert} from "../../assets/svgIcons/alert.svg";
import Text from "../../components/Text";
import styles from "./index.module.css";

const ErrorWidget = ()=>{
    return (
        <div className={`flex flex-column justify-content-center align-content-center ${styles.container}`} data-testid = "error-widget">
            <div className="flex flex-column justify-content-center align-items-center">
                <Alert />
                <Text type="sub-heading" color="#B0B7C2">Something Went Wrong</Text>
            </div>
        </div>
    )
}

export default ErrorWidget;