const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
import {
  toastMessageConstants,
  errorMessageConstants,
  menuBuilderConstants,
  excludedTabsInBottomNavigation,
} from "../constants/globalConstant";
import {
  API_ADD_DEFAULT_MENU,
  API_GET_MENUS,
  API_GET_MENU,
  API_ADD_MENU,
  API_UPDATE_MENU,
  API_GET_MENU_TAB,
  API_ADD_CUSTOM_MENU_TAB,
  API_UPDATE_CUSTOM_MENU_TAB,
  API_DELETE_CUSTOM_MENU_TAB,
  API_DELETE_MENU,
  API_CLONE_MENU,
  API_GET_TEAMS_MENUS,
} from "../api/configurationScreenServices";
import { getVisibleTabs } from "../utils/menuBuilderUtils";

const initialState = {
  menus: {},
  menuTabs: [],
  menuById: {},
  selectedMenuId: null,
  selectedMenuConfiguration: {
    menuId: null,
    menuName: "",
    sideNavigation: [],
    bottomNavigation: [],
    publishedSideTabs: [],
    publishedBottomTabs: [],
    config: {},
  },
  defaultTemplateConfiguration: {
    sideNavigation: [],
    bottomNavigation: [],
  },
  toastMessages: [],
  isMenuBuilderLoading: false,
  showMenuInfoBanner: true,
  menuCustomTabEnabled: false,
  publish: {
    isPublishLoading: false,
    isPublished: false,
  },
  cicoCustomisation: {
    cico: {
      hide: false,
      checkIn: "Check In",
      checkOut: "Check Out",
    },
    showCustomisation : false,
  },
};

export const getMenus = createAsyncThunk(
  "menuBuilder/getMenus",
  async (_, thunkAPI) => {
    const response = await API_GET_MENUS();
    const { data } = response;
    if (response?.status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const getTeamsMenus = createAsyncThunk(
  "menuBuilder/getTeamsMenus",
  async (_, thunkAPI) => {
    const response = await API_GET_TEAMS_MENUS();
    const { data } = response;
    if (response?.status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const getMenu = createAsyncThunk(
  "menuBuilder/getMenu",
  async (menuId, thunkAPI) => {
    const response = await API_GET_MENU(menuId);
    const { data } = response;
    if (response?.status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const apiAddMenu = createAsyncThunk(
  "menuBuilder/apiAddMenu",
  async ({ postBody }, thunkAPI) => {
    const response = await API_ADD_MENU(postBody);
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const { addTeamsMenus } = response.data;
    const { orgCode } = thunkAPI.getState().homepage.user;
    let defaultAddMenu = false;
    if (addTeamsMenus?.length > 0) {
      const addTeamIds = addTeamsMenus.map((obj) => obj.teamId);
      defaultAddMenu = addTeamIds.includes(orgCode);
    }
    return { ...response.data, defaultAddMenu };
  }
);

export const apiUpdateMenu = createAsyncThunk(
  "menuBuilder/apiupdateMenu",
  async ({ menuId, postBody }, thunkAPI) => {
    const response = await API_UPDATE_MENU(menuId, postBody);
    const { data } = response;
    if (response?.status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const { deleteTeamsMenus, addTeamsMenus } = response.data;
    const { orgCode } = thunkAPI.getState().homepage.user;
    let defaultDeleteMenu = false;
    let defaultAddMenu = false;
    if (deleteTeamsMenus?.length > 0) {
      const deleteTeamIds = deleteTeamsMenus.map((obj) => obj.teamId);
      defaultDeleteMenu = deleteTeamIds.includes(orgCode);
    }
    if (addTeamsMenus?.length > 0) {
      const addTeamIds = addTeamsMenus.map((obj) => obj.teamId);
      defaultAddMenu = addTeamIds.includes(orgCode);
    }
    return { ...response.data, defaultDeleteMenu, defaultAddMenu };
  }
);

export const apiDeleteMenu = createAsyncThunk(
  "menuBuilder/apiDeleteMenu",
  async ({ menuId }, thunkAPI) => {
    const response = await API_DELETE_MENU(menuId);
    const { data } = response;
    if (response?.status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const apiCloneMenu = createAsyncThunk(
  "menuBuilder/apiCloneMenu",
  async ({ menuId }, thunkAPI) => {
    const response = await API_CLONE_MENU(menuId);
    const { data } = response;
    if (response?.status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const apiAddDefaultMenu = createAsyncThunk(
  "menuBuilder/apiAddDefaultMenu",
  async (_, thunkAPI) => {
    const userPreferencesResponse = await API_ADD_DEFAULT_MENU();
    const { status, data } = userPreferencesResponse;
    if (status !== 200)
      return thunkAPI.rejectWithValue(
        errorMessageConstants.API_GET_USER_PREFERENCE
      );
    return data;
  }
);

export const getMenuTab = createAsyncThunk(
  "menuBuilder/getMenuTab",
  async (_, thunkAPI) => {
    const response = await API_GET_MENU_TAB();
    const { data } = response;
    if (response?.status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const apiAddCustomMenuTab = createAsyncThunk(
  "menuBuilder/apiAddCustomMenuTab",
  async ({ postBody }, thunkAPI) => {
    const response = await API_ADD_CUSTOM_MENU_TAB(postBody);
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const apiDeleteCustomMenuTab = createAsyncThunk(
  "menuBuilder/apiDeleteCustomMenuTab",
  async ({ tabId }, thunkAPI) => {
    const response = await API_DELETE_CUSTOM_MENU_TAB(tabId);
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const { menuTabs } = thunkAPI.getState().menuBuilder;
    return menuTabs?.customTabs.find((item) => item.tabId === tabId);
  }
);

export const apiUpdateCustomMenuTab = createAsyncThunk(
  "menuBuilder/apiUpdateCustomMenuTab",
  async ({ tabId, postBody }, thunkAPI) => {
    const response = await API_UPDATE_CUSTOM_MENU_TAB(tabId, postBody);
    const { data } = response;
    if (response?.status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

const menuBuilderSlice = createSlice({
  name: "menuBuilder",
  initialState,
  reducers: {
    changeSelectedMenuId(state, action) {
      state.selectedMenuId = action.payload;
    },
    addToastMessage(state, action) {
      state.toastMessages.push(action.payload);
    },
    resetToastMessageFromMenuBuilder(state) {
      state.toastMessages.shift();
    },
    toggleShowMenuInfoBanner(state, action) {
      state.showMenuInfoBanner = action.payload;
    },
    resetMenuConfiguration(state) {
      state.selectedMenuId = null;
      state.selectedMenuConfiguration = {
        menuId: null,
        sideNavigation: [],
        publishedSideTabs: [],
        bottomNavigation: [],
        publishedBottomTabs: [],
        config: {},
      };
      state.publish = {
        isPublishLoading: false,
        isPublished: false,
      };
      state.cicoCustomisation = initialState.cicoCustomisation;
    },
    setTeamMenus(state, action) {
      state.menus = action.payload;
    },
    setCustomMenuTabEnabled(state, action) {
      state.menuCustomTabEnabled = action.payload;
    },
    changePublishMenuState(state, action) {
      state.publish.isPublished = action.payload;
    },
    setCicoCustomisation(state, action) {
      state.cicoCustomisation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMenus.fulfilled, (state, action) => {
      state.isMenuBuilderLoading = false;
      const menus = action.payload;
      const _menuById = Object.fromEntries(
        menus?.map((menu) => {
          return [menu.menuId, menu];
        })
      );
      state.menuById = _menuById;
    });
    builder.addCase(getMenus.pending, (state) => {
      state.isMenuBuilderLoading = true;
    });
    builder.addCase(getMenus.rejected, (state, action) => {
      state.isMenuBuilderLoading = false;
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
    builder.addCase(getTeamsMenus.fulfilled, (state, action) => {
      state.isMenuBuilderLoading = false;
      state.menus = action.payload;
    });
    builder.addCase(getTeamsMenus.pending, (state) => {
      state.isMenuBuilderLoading = true;
    });
    builder.addCase(getTeamsMenus.rejected, (state, action) => {
      state.isMenuBuilderLoading = false;
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
    builder.addCase(getMenu.fulfilled, (state, action) => {
      state.isMenuBuilderLoading = false;
      const menu = action.payload;
      state.selectedMenuConfiguration.menuId = menu.menuId;
      state.selectedMenuConfiguration.menuName = menu.name;
      state.selectedMenuConfiguration.sideNavigation = getVisibleTabs(
        menu.sideNavigation
      );
      state.selectedMenuConfiguration.publishedSideTabs = menu.sideNavigation;
      state.selectedMenuConfiguration.bottomNavigation = getVisibleTabs(
        menu.bottomNavigation
      );
      state.selectedMenuConfiguration.publishedBottomTabs =
        menu.bottomNavigation;
      state.selectedMenuConfiguration.config = menu.config;
      state.publish.isPublished =
        menu.status === menuBuilderConstants.PUBLISHED;
    });
    builder.addCase(getMenu.pending, (state) => {
      state.isMenuBuilderLoading = true;
    });
    builder.addCase(getMenu.rejected, (state, action) => {
      state.isMenuBuilderLoading = false;
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
    builder.addCase(getMenuTab.fulfilled, (state, action) => {
      state.isMenuBuilderLoading = false;
      const menuTabs = action.payload;
      state.defaultTemplateConfiguration.sideNavigation =
        menuTabs.defaultTabs?.filter((tab) => !tab.isHidden);
      state.defaultTemplateConfiguration.bottomNavigation = menuTabs.defaultTabs
        ?.filter(
          (tab) =>
            !tab.isHidden && !excludedTabsInBottomNavigation.includes(tab.tabId)
        )
        .slice(0, 4);
      state.menuTabs = menuTabs;
    });
    builder.addCase(getMenuTab.pending, (state) => {
      state.isMenuBuilderLoading = true;
    });
    builder.addCase(getMenuTab.rejected, (state, action) => {
      state.isMenuBuilderLoading = false;
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
    builder.addCase(apiAddMenu.fulfilled, (state, action) => {
      state.publish.isPublishLoading = false;
      state.publish.isPublished = true;
      const { menu, addTeamsMenus, defaultAddMenu } = action.payload;
      if (defaultAddMenu) state.menus["defaultMenu"] = addTeamsMenus[0];
      else state.menus.teamMenu.push(...addTeamsMenus);
      state.selectedMenuConfiguration.menuId = menu.menuId;
      state.selectedMenuConfiguration.menuName = menu.name;
      state.selectedMenuConfiguration.config = menu.config;
      state.selectedMenuConfiguration.sideNavigation = getVisibleTabs(
        menu.sideNavigation
      );
      state.selectedMenuConfiguration.publishedSideTabs = menu.sideNavigation;
      state.selectedMenuConfiguration.bottomNavigation = getVisibleTabs(
        menu.bottomNavigation
      );
      state.selectedMenuConfiguration.publishedBottomTabs =
        menu.bottomNavigation;
      const _menu = menu;
      delete _menu.sideNavigation;
      delete _menu.bottomNavigation;
      state.menuById[menu.menuId] = _menu;
    });
    builder.addCase(apiAddMenu.pending, (state) => {
      state.publish.isPublishLoading = true;
    });
    builder.addCase(apiAddMenu.rejected, (state, action) => {
      state.publish.isPublishLoading = false;
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
    builder.addCase(apiUpdateMenu.fulfilled, (state, action) => {
      state.publish.isPublishLoading = false;
      const {
        menu,
        deleteTeamsMenus,
        addTeamsMenus,
        defaultDeleteMenu,
        defaultAddMenu,
      } = action.payload;
      const { teamMenu, defaultMenu } = state.menus;
      if (deleteTeamsMenus?.length > 0) {
        if (defaultDeleteMenu) {
          const _menus = state.menus;
          delete _menus.defaultMenu;
          state.menus = _menus;
        } else {
          const deleteTeamIds = deleteTeamsMenus.map((obj) => obj.teamId);
          const _teamMenu = teamMenu?.filter(
            (obj) => !deleteTeamIds.includes(obj.teamId)
          );
          state.menus.teamMenu = _teamMenu;
        }
      }
      if (addTeamsMenus?.length > 0) {
        if (defaultAddMenu) state.menus["defaultMenu"] = addTeamsMenus[0];
        else state.menus.teamMenu.push(...addTeamsMenus);
      }
      if (menu.status == menuBuilderConstants.UNPUBLISHED) {
        if (defaultMenu && menu.menuId == defaultMenu.menuId) {
          const _menus = state.menus;
          delete _menus.defaultMenu;
          state.menus = _menus;
        } else {
          const _teamMenu = teamMenu.filter(
            (entry) => entry.menuId != menu.menuId
          );
          state.menus.teamMenu = _teamMenu;
        }
      }
      state.publish.isPublished =
        menu.status === menuBuilderConstants.PUBLISHED;
      state.selectedMenuConfiguration.menuId = menu.menuId;
      state.selectedMenuConfiguration.menuName = menu.name;
      state.selectedMenuConfiguration.config = menu.config;
      state.selectedMenuConfiguration.sideNavigation = getVisibleTabs(
        menu.sideNavigation
      );
      state.selectedMenuConfiguration.publishedSideTabs = menu.sideNavigation;
      state.selectedMenuConfiguration.bottomNavigation = getVisibleTabs(
        menu.bottomNavigation
      );
      state.selectedMenuConfiguration.publishedBottomTabs =
        menu.bottomNavigation;
      const _menu = menu;
      delete _menu.sideNavigation;
      delete _menu.bottomNavigation;
      state.menuById[menu.menuId] = _menu;
    });
    builder.addCase(apiUpdateMenu.pending, (state) => {
      state.publish.isPublishLoading = true;
    });
    builder.addCase(apiUpdateMenu.rejected, (state, action) => {
      state.publish.isPublishLoading = false;
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
    builder.addCase(apiCloneMenu.fulfilled, (state, action) => {
      const menu = action.payload;
      state.selectedMenuConfiguration.menuId = menu.menuId;
      state.selectedMenuConfiguration.menuName = menu.name;
      state.selectedMenuConfiguration.config = menu.config;
      state.selectedMenuConfiguration.sideNavigation = getVisibleTabs(
        menu.sideNavigation
      );
      state.selectedMenuConfiguration.publishedSideTabs = menu.sideNavigation;
      state.selectedMenuConfiguration.bottomNavigation = getVisibleTabs(
        menu.bottomNavigation
      );
      state.selectedMenuConfiguration.publishedBottomTabs =
        menu.bottomNavigation;
    });
    builder.addCase(apiCloneMenu.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
    builder.addCase(apiDeleteMenu.fulfilled, (state, action) => {
      const deletedMenu = action.payload;
      const { teamMenu, defaultMenu } = state.menus;
      const _menuById = state.menuById;
      delete _menuById[deletedMenu.menu.menuId];
      if (defaultMenu && defaultMenu?.menuId == deletedMenu.menu.menuId) {
        const _menus = state.menus;
        delete _menus.defaultMenu;
        state.menus = _menus;
      } else {
        const _teamMenu = teamMenu.filter(
          (entry) => entry.menuId != deletedMenu.menu.menuId
        );
        state.menus.teamMenu = _teamMenu;
      }
      state.menuById = _menuById;
    });
    builder.addCase(apiDeleteMenu.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
    builder.addCase(apiAddCustomMenuTab.fulfilled, (state, action) => {
      const customMenuTab = action.payload;
      customMenuTab.config.id = action.payload.tabId;
      state.menuTabs.customTabs.push(customMenuTab);
    });
    builder.addCase(apiAddCustomMenuTab.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
    builder.addCase(apiDeleteCustomMenuTab.fulfilled, (state, action) => {
      const customMenuTab = state.menuTabs.customTabs;
      const deletedTab = action.payload;
      const _customMenuTab = customMenuTab?.filter(
        (obj) => obj.tabId !== deletedTab.tabId
      );
      state.menuTabs.customTabs = _customMenuTab;
    });
    builder.addCase(apiDeleteCustomMenuTab.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
    builder.addCase(apiUpdateCustomMenuTab.fulfilled, (state, action) => {
      const customMenuTab = action.payload;
      const customTabs = state.menuTabs.customTabs;
      let updatedTabIndex = customTabs.findIndex(
        (obj) => obj.tabId === customMenuTab.tabId
      );
      state.menuTabs.customTabs[updatedTabIndex] = customMenuTab;
    });
    builder.addCase(apiUpdateCustomMenuTab.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
  },
});
export const {
  setTeamMenus,
  changeSelectedMenuId,
  addToastMessage,
  resetToastMessageFromMenuBuilder,
  resetMenuConfiguration,
  toggleShowMenuInfoBanner,
  setCustomMenuTabEnabled,
  setCicoCustomisation,
  changePublishMenuState,
} = menuBuilderSlice.actions;

export default menuBuilderSlice.reducer;
