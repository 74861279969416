import React from "react";
import WebViewWidget from "./WebViewWidget";
import PropTypes from "prop-types";

const WebViewWidgetFactory = ({widgetInformation}) => {
    const {  config } = widgetInformation;
    return (
        <WebViewWidget configData={config} />
    )
}

WebViewWidgetFactory.propTypes = {
    widgetInformation:PropTypes.object
}


export default WebViewWidgetFactory;

