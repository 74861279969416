import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App.jsx";
import {Provider} from "react-redux"
import store from "./store"
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import "primeflex/primeflex.css"
import "./assets/fonts/style.css"
import "./i18n"

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
