import React from "react";
import styles from "./styles.module.css";
import { ReactComponent as Add } from "../../assets/svgIcons/lsq_add.svg";
import Text from "../Text";

const AddCard = ({
    cardName = "",
    cardClickHandler,
}) => {
  return (
    <div
      className={`flex align-items-center gap-1 p-3 ${styles["add-card"]} cursor-pointer`}
      onClick={cardClickHandler}
      data-testid="add-card"
    >
      <div className="bg-white p-1" style={{ borderRadius: "5px" }}>
        <Add style={{ fill: "#627087" }}></Add>
      </div>
      <Text type="T3B" color={"#314361"} className={`ellipsis w-full`}>
        {cardName}
      </Text>
    </div>
  );
};

export default AddCard;
