import React from "react";
import styles from "../styles.module.css";
import Text from "../../components/Text";

export const KeyLeadMetrics = ({ widgetInformation }) => {
  const { title } = widgetInformation.config;
  const imgURL = `/images/widgetPreview/key_lead_metrics.png`
  return (
    <div
      className={`${styles["widget-container"]}`}
      data-testid={`key-lead-metrics-preview`}
    >
      <div className="flex flex-column absolute pl-2 pt-2">
        <Text type="sub-heading-bold" color="var(--text-focused)">
          {title}
        </Text>
      </div>
      <img
        className="w-full"
        src={
          imgURL
        }
        alt="Key Lead Metrics"
      />
    </div>
  );
};
