import React from "react";
import { useDispatch } from "react-redux";
import Text from "../../../components/Text";
import CustomButton from "../../../components/CustomButton";
import { leadControlsWidgetConstants } from "../../../constants/globalConstant";
import CustomInput from "../../../components/CustomInput";
import CustomRadioGroup from "../../../components/CustomRadioGroup";
import ControlledInputRadioGroup from "../../../components/CustomRadioGroup/ControlledInputRadioGroup";
import TileImagePreview from "../LeadWidgetsPreview/TileImagePreview";
import { countLimit } from "../../../utils/globalUtils";
import { resetLeadWidgetConfiguration } from "../../../reducers/leadControlsSlice";
import LsqProgressSpinner from "../../../components/LsqProgressSpinner";

export default function WidgetConfigurationLayout({
  control,
  errors,
  widgetStyles,
  widgetStyle,
  image,
  tileStyle,
  tileStyles,
  imageUploadHandler,
  onSaveClicked,
  isSaveDisabled,
  onImageDeletion,
  hasAdditionalConfiguration = false,
  additionalConfiguratuion = <></>,
  hasAdditionalSection = false,
  additionalSection = <></>,
}) {
  const dispatch = useDispatch();
  return (
    <div className="flex h-full flex-column justify-content-between gap-1 overflow-hidden">
      <div className={`flex flex-column gap-2 h-full overflow-scroll`}>
        <Text type="T4" color="var( --text-primary)">
          {leadControlsWidgetConstants.IDENTIFIER}
        </Text>
        <CustomInput
          control={control}
          errors={errors}
          fieldName="identifier"
          maxLength={30}
          className="lsq-input-bold"
          label={leadControlsWidgetConstants.NAME}
          isMandatory={true}
          data-testid="identifier"
        />
        <hr className="horizontal-divider" />
        <CustomRadioGroup
          control={control}
          label={leadControlsWidgetConstants.WIDGET_TYPE}
          options={widgetStyles}
          radioName="widgetStyle"
        />
        {widgetStyle === leadControlsWidgetConstants.TILE && (
          <>
            <div
              className={`flex p-3 border-round`}
              style={{ background: "var(--N20)" }}
            >
              <ControlledInputRadioGroup
                image={image}
                control={control}
                label={leadControlsWidgetConstants.TILE_TYPE}
                options={tileStyles}
                errors={errors}
                selectedRadio={tileStyle}
                radioName="tileStyle"
                imageUploadHandler={imageUploadHandler}
              />
            </div>
            {image?.uploadStatus === leadControlsWidgetConstants.PENDING && (
              <LsqProgressSpinner strokeWidth={6} strokeColor="#43536F" />
            )}
            {image?.uploadStatus === leadControlsWidgetConstants.SUCCESS && (
              <TileImagePreview
                element={image}
                imageUploadHandler={imageUploadHandler}
                maxFileSize={countLimit.BANNER_MAX_FILE_SIZE}
                onElementDeleted={onImageDeletion}
              />
            )}
          </>
        )}
        {hasAdditionalConfiguration && additionalConfiguratuion}
        {hasAdditionalSection && (
          <>
            <hr className="horizontal-divider" />
            <Text type="T4" color="var( --text-primary)">
              {leadControlsWidgetConstants.ADDITIONAL_FILTERS}
            </Text>
            {additionalSection}
          </>
        )}
      </div>
      <div className="flex justify-content-end gap-2 w-full mb-2">
        <CustomButton
          label={leadControlsWidgetConstants.CANCEL}
          varient="outline"
          onClick={() => dispatch(resetLeadWidgetConfiguration())}
        />
        <CustomButton
          label={leadControlsWidgetConstants.SAVE}
          varient="filled"
          onClick={onSaveClicked}
          disabled={
            isSaveDisabled && widgetStyle === leadControlsWidgetConstants.TILE
          }
        />
      </div>
    </div>
  );
}
