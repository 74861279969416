import React from "react";
import PropTypes from "prop-types";
import { InputText } from "primereact/inputtext";
import { Controller } from "react-hook-form";
import { classNames } from "primereact/utils";
import Text from "../Text";
import "./index.css";
import { FormErrorMessage } from "../FormErrorMessage";

export default function CustomInput({
  label,
  isMandatory = false,
  placeholder = "",
  control,
  errors,
  fieldName = "",
  RightIcon,
  pattern,
  maxLength,
  errorMessage,
  containerStyle,
  ...props
}) {
  return (
    <div className="field" style={{ marginBottom: "0px" , ...containerStyle}}>
      <Controller
        name={fieldName}
        control={control}
        rules={{
          required:
            isMandatory &&
            (errorMessage ? errorMessage : "This field cannot be left blank"),
          pattern: pattern,
          validate: (value) =>{
            if (isMandatory && value.trim().length === 0) {
              return errorMessage || "This field cannot be left blank";
            } else {
              return true;
            }
          }
        }}
        render={({ field, fieldState }) => (
          <span
            className={`w-full p-float-label ${
              RightIcon && "p-input-icon-right"
            }`}
          >
            {RightIcon && <RightIcon />}
            <InputText
              maxLength={maxLength}
              autoComplete="off"
              validateOnly={true}
              placeholder={placeholder}
              id={field.name}
              className={`lsq-input ${classNames({
                "p-invalid": fieldState.error,
              })}`}
              {...field}
              {...props}
            />
            <label
              className="lsq-label flex align-items-center"
              htmlFor={field.name}
            >
              <Text
                color={fieldState.error ? "#E02020" : "#768196"}
                type="T5"
              >
                {label}
              </Text>
              {isMandatory && (
                <span style={{ color: "#E02020", fontSize: "0.8rem" }}> *</span>
              )}
            </label>
          </span>
        )}
      />
      {errors[fieldName] && (
        <FormErrorMessage message={errors[fieldName].message} />
      )}
    </div>
  );
}

CustomInput.propTypes = {
  label: PropTypes.string,
  isMandatory: PropTypes.bool,
  placeholder: PropTypes.string,
  control: PropTypes.object,
  fieldName: PropTypes.string,
  getFormErrorMessage: PropTypes.func,
  RightIcon: PropTypes.object,
  errors: PropTypes.object,
};
