import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Text from "../Text";
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  resetWidgetConfiguration,
  getWidgetMetaData,
  getWidgetMetaValue,
} from "../../reducers/widgetTemplateListSlice";
import { Shimmer } from "../Shimmer";
import SettingsFactory from "../../DashboardSettingsScreen/SettingsFactory";
import { Skeleton } from "primereact/skeleton";
import { ReactComponent as CrossIcon } from "../../assets/svgIcons/lsq_close.svg";
import { changeToastMessage } from "../../reducers/previewPaneSlice";
import { globalConstants, shimmerConstants } from "../../constants/globalConstant";

export default function ConfigurationScreen() {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { mode, widgetTitle, widgetDetails } = useSelector(
    (state) => state.widgetTemplateList.selectedWidget
  );

  useEffect(() => {
    if (mode === "CREATE") {
      const widgetId = widgetDetails?.widgetType;
      dispatch(getWidgetMetaData(widgetId)).then(({ type }) => {
        if (type.includes(globalConstants.FULFILLED)) setLoading(false);
        else {
          dispatch(
            changeToastMessage({
              type: "error",
              title: "Error",
              message: "Something went wrong, please try again!",
            })
          );
          dispatch(resetWidgetConfiguration());
        }
      }).catch((err) => err);
    } else if (mode === "UPDATE") {
      const widgetTemplateId = widgetDetails?.widgetTemplateId;
      Promise.all([
        dispatch(getWidgetMetaData(widgetTemplateId)),
        dispatch(getWidgetMetaValue(widgetDetails?.widgetId)),
      ]).then((value) => {
        if (
          value[0].type.includes(globalConstants.FULFILLED) &&
          value[1].type.includes(globalConstants.FULFILLED)
        )
          setLoading(false);
        else {
          dispatch(
            changeToastMessage({
              type: "error",
              title: "Error",
              message: "Something went wrong, please try again!",
            })
          );
          dispatch(resetWidgetConfiguration());
        }
      });
    }
  }, []);

  const widgetTemplateId =
    mode === "CREATE" ? widgetDetails.id : widgetDetails?.widgetTemplateId;
  return (
    <div
      data-testid = "configuration-screen"
      className={`flex flex-column bg-white w-11 ${styles["configuration-screen-wrapper"]}`}
    >
      <div
        className={`flex h-3rem w-full justify-content-between align-items-center ${styles["title-bar"]}`}
      >
        {loading ? (
          <Skeleton
            className="ml-5"
            width="30%"
            borderRadius="8px"
            style={{ backgroundColor: "#E2ECF9" }}
          />
        ) : (
          <Text color="#314361" type="T1B" className="ml-5">
            {t("Configure WidgetTitle", { widgetTitle: widgetTitle })}
          </Text>
        )}

        <CrossIcon
          data-testid="cross-icon"
          onClick={() => {
            dispatch(resetWidgetConfiguration());
          }}
          style={{ cursor: "pointer", fill: "#314361" }}
          className="mr-3"
        />
      </div>
      {loading ? (
       <Shimmer label = {shimmerConstants.CONFIGURATION_SCREEN}/>
      ) : (
        <SettingsFactory id={widgetTemplateId} />
      )}
    </div>
  );
}
