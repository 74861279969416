import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  API_GET_TEMPLATES,
  API_GET_TEMPLATE_LAYOUT,
  API_GET_TEMPLATE_WIDGET,
  API_SELECT_TEMPLATE,
} from "../api/configurationScreenServices";

import { toastMessageConstants } from "../constants/globalConstant";

const initialState = {
  loading: false,
  templatesCollection: [],
  toastMessages: [],
  isTemplateLayoutLoading : false,
};

export const apiSelectTemplate = createAsyncThunk(
  "templates/selectTemplate",
  async ({ templateId, homepageId }, thunkAPI) => {
    const layoutsResponse = await API_SELECT_TEMPLATE(templateId, homepageId);
    if (layoutsResponse?.status !== 200) {
      const errorMessage =
        layoutsResponse?.data?.message ||
        toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
     return layoutsResponse?.data;
  }
);

export const apiTemplateFetcher = createAsyncThunk(
  "templates/apiTemplateFetcher",
  async (_, thunkAPI) => {
    const allTemplateResponse = await API_GET_TEMPLATES();
    const templateResponse = allTemplateResponse?.data;
    if (allTemplateResponse?.status !== 200) {
      return thunkAPI.rejectWithValue("fetching templates failed");
    }
    return templateResponse;
    }
);

export const getTemplateLayout = createAsyncThunk(
  "templates/getTemplateLayoutSections",
  async (templateId, thunkAPI) => {
    const response = await API_GET_TEMPLATE_LAYOUT(templateId);
    if (response?.status !== 200) {
      return thunkAPI.rejectWithValue("fetching template Layout failed");
    }
    const { sections } = response.data;
    let widgetIdList = [];
    sections?.forEach((section) => {
        section.rows.forEach((row) => {
          row.widgets.forEach((widget) => {
          widgetIdList.push(widget.widgetId);
          });
        });
      });
    let widgetTemplateConfigurationList = {};
    while (widgetIdList.length > 0) {
      let widgetPromiseList = [];
      const widgetBatch = widgetIdList.slice(0, 5);
      widgetIdList.splice(0, 5);
      widgetBatch.forEach((widgetId) => {
        widgetPromiseList.push(API_GET_TEMPLATE_WIDGET(widgetId));
      });
      const widgetResponse = await Promise.all(widgetPromiseList);
      widgetResponse.forEach((widget) => {
        widgetTemplateConfigurationList[widget["widgetId"]] = widget.config;
      });
    }
    sections.forEach((section) => {
        section.rows.forEach((row) => {
          row.widgets.forEach((widget) => {
            widget["config"] =
              widgetTemplateConfigurationList[widget["widgetId"]];
          });
        });
      });
    return {sections, templateId};
  }
);

const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    addTemplateId(state, action) {
      state.templateIds.push(action.payload);
    },
    resetSelectedTemplateFetched(state) {
      state.selectedTemplateFetched = false;
      state.loading = false;
    },
    resetToastMessagesFromTemplates(state) {
      state.toastMessages.shift()
    }
  },
  extraReducers: (builder) => {
    builder.addCase(apiTemplateFetcher.fulfilled, (state, action) => {
      state.templatesCollection = action.payload;
      state.loading = false;
    });
    builder.addCase(apiTemplateFetcher.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(apiTemplateFetcher.rejected, (state, action) => {
      state.loading = false;
      state.toastMessages.push({
      type: toastMessageConstants.ERROR_TYPE,
      title: toastMessageConstants.ERROR,
      message: action.payload || toastMessageConstants.FAILED_TO_FETCH_TEMPLATES,
    });
    });
    builder.addCase(getTemplateLayout.fulfilled, (state, action) => {
      state.isTemplateLayoutLoading = false;
      const { templateId, sections } = action.payload;
      let _templates = [...state.templatesCollection];
      _templates = _templates.map((template) => {
        if (template.templateId === templateId) {
          let _template = {
            ...template,
            sections,
          };
          return _template;
        }
        return template;
      });
      state.templatesCollection = _templates;
    },
    );
    builder.addCase(getTemplateLayout.rejected, (state,action) => {
      state.isTemplateLayoutLoading = false;
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.FAILED_TO_FETCH_TEMPLATE,
      });
    });
    builder.addCase(getTemplateLayout.pending, (state) => {
      state.isTemplateLayoutLoading = true;
    });
    builder.addCase(apiSelectTemplate.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(apiSelectTemplate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(apiSelectTemplate.rejected, (state, action) => {
      state.loading = false;
      state.toastMessages.push({
      type: toastMessageConstants.ERROR_TYPE,
      title: toastMessageConstants.ERROR,
      message: action.payload || toastMessageConstants.FAILED_TO_APPLY_TEMPLATE,
    });
    });
  },
});

export const { addTemplate, resetSelectedTemplateFetched, resetToastMessagesFromTemplates } =
  templatesSlice.actions;

export default templatesSlice.reducer;