import Wifi from "../../../assets/svgIcons/wifi.svg";
import Cellular from "../../../assets/svgIcons/cellular.svg";
import Battery from "../../../assets/svgIcons/battery.svg";
import styles from "./styles.module.css";
import { getCurrentTime } from "../../../utils/globalUtils";
import { ReactComponent as LeadCardBackground } from "../../../assets/svgIcons/lead_card_background.svg";
import LeadCardMobilePreview from "../LeadCard/LeadCardMobilePreview";
import MenusMobileTemplate from "../LeadPage/MenusMobileTemplate";
import WidgetsMobileTemplate from "../LeadPage/WidgetsMobileTemplate";
import { useSelector } from "react-redux";
import QuickActionsMobileTemplate from "../LeadActions/QuickActionsMobileTemplate";

export default function LeadDetailsPreview({ showQuickActions = false }) {
  const { leadPageMenus } = useSelector((state) => state.leadControls);
  return (
    <div className={`mobile-screen pointer-events-none`}>
      <div className={styles["status-bar"]}>
        <div className={styles["time"]}>{getCurrentTime()}</div>
        <div className={styles["status-bar-content"]}>
          <img alt="wifi" src={Wifi} />
          <img alt="cellular" src={Cellular} />
          <img alt="battery" src={Battery} />
        </div>
      </div>
      <div className="flex h-full w-full flex-column">
        <div className="flex w-full absolute" style={{ top: "-6%" }}>
          <LeadCardBackground />
        </div>
        <LeadCardMobilePreview />
        <div className="flex flex-column h-full gap-2 mt-8 overflow-hidden pointer-events-auto">
          {leadPageMenus.selectedMenus.length !== 0 ? (
            <MenusMobileTemplate />
          ) : (
            <WidgetsMobileTemplate />
          )}
        </div>
      </div>
      {showQuickActions && (
        <div
          className={`flex flex-column p-2 absolute ${styles["quick-actions-background"]}`}
        >
          <QuickActionsMobileTemplate />
        </div>
      )}
    </div>
  );
}
