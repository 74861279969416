import React, { useState, useMemo } from "react";
import styles from "./styles.module.css";
import { InlineDropdown } from "../../components/InlineDropdown";
import { useDispatch } from "react-redux";
import { Tooltip } from "primereact/tooltip";
import { ReactComponent as SelectAllIcon } from "../../assets/svgIcons/lsq_select_all.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svgIcons/lsq_delete_filled.svg";
import { ReactComponent as CloseIcon } from "../../assets/svgIcons/lsq_close.svg";
import { ReactComponent as DeleteIllustration } from "../../assets/illustrations/delete_illustration.svg";
import { apiDeleteHomepagesBulk } from "../../reducers/homePageSlice";
import ConfirmDialog from "../../components/Modal/ConfirmDialog";
import {
  tooltipConstants,
  casaConstants,
} from "../../constants/globalConstant";
import { dashboardViewSortItems } from "../../utils/casaUtils";
import ToggleTab from "../../components/ToggleTab";
import Text from "../../components/Text";

const ActionBar = (props) => {
  const dispatch = useDispatch();
  const {
    setDialog,
    setIsHomepagesBulkSelectionEnabled,
    homepageFilterItems,
    homepageViewFilterState,
    homepageViewSortState,
    bulkSelectedHomepages,
    setBulkSelectedHomepages,
    isHomepagesBulkSelectionEnabled,
  } = props;
  const [activeTab, setActiveTab] = useState(homepageFilterItems[0]);
  const onTabChange = (tab) => {
    setActiveTab(tab);
    homepageViewFilterState[1](tab.value);
  };
  const bulkSelectedHomepagesLength = useMemo(
    () => Object.keys(bulkSelectedHomepages).length,
    [bulkSelectedHomepages]
  );
  const onBulkDeleteHomepagesClicked = () => {
    if (bulkSelectedHomepagesLength <= 0) return;
    setDialog({ state: true, dialogBox: bulkDeleteConfirmationModal() });
  };

  const onBulkDeleteCrossIconClicked = () => {
    setBulkSelectedHomepages({});
    setIsHomepagesBulkSelectionEnabled(false);
  };

  const onBulkDeleteConfirmClicked = (e) => {
    e.stopPropagation();
    dispatch(apiDeleteHomepagesBulk(Object.keys(bulkSelectedHomepages)));
    setDialog({ state: false, dialogBox: {} });
    onBulkDeleteCrossIconClicked();
  };

  const bulkDeleteConfirmationModal = () => {
    return (
      <ConfirmDialog
        setState={setDialog}
        buttonLabel={casaConstants.DELETE}
        illustration={<DeleteIllustration />}
        displayText={
          <>
            <Text
              type="heading"
              className={`capitalize`}
              color="var(--primary)"
            >
              {`Confirm ${casaConstants.DELETE} (${bulkSelectedHomepagesLength} Selected)`}
            </Text>
            <Text type="sub-heading" color="var(--primary)">
              {`Are you sure you want to delete the selected homepages?`}
            </Text>
          </>
        }
        clickListener={onBulkDeleteConfirmClicked}
      />
    );
  };
  return (
    <div
      className={`flex w-full align-items-center justify-content-between p-3 ${styles["action-bar"]}`}
    >
      <div className={`flex`} style={{ width: "40%" }}>
        <ToggleTab
          tabs={homepageFilterItems}
          activeTab={activeTab}
          setActiveTab={onTabChange}
        ></ToggleTab>
      </div>
      <div className={`flex gap-2 align-items-center`}>
        <InlineDropdown
          items={dashboardViewSortItems}
          selectedLabel={homepageViewSortState[0]}
          setSelectedLabel={homepageViewSortState[1]}
        />
        {isHomepagesBulkSelectionEnabled ? (
          <div className="flex align-items-center justify-space-between gap-1">
            <Text type="T3B">{bulkSelectedHomepagesLength} selected</Text>
            <>
              <div
                data-pr-tooltip={tooltipConstants.DELETE}
                data-testid="bulkselect-delete"
                className="bulkselect-delete icon-container"
                onClick={onBulkDeleteHomepagesClicked}
              >
                <DeleteIcon style={{ width: "1.2rem" }} fill="var(--N400)" />
              </div>
              <Tooltip
                position="bottom"
                className="preview-pane-tooltip"
                target=".bulkselect-delete"
              />
            </>
            <>
              <div
                data-pr-tooltip={tooltipConstants.CANCEL}
                data-testid="bulkselect-close"
                className="bulkselect-cancel icon-container"
                onClick={onBulkDeleteCrossIconClicked}
              >
                <CloseIcon style={{ width: "1.2rem" }} fill="var(--N400)" />
              </div>
              <Tooltip
                position="bottom"
                className="preview-pane-tooltip"
                target=".bulkselect-cancel"
              />
            </>
          </div>
        ) : (
          <>
            <div
              data-pr-tooltip={tooltipConstants.MULTI_SELECT}
              data-testid="bulkselect-selectall"
              className="select-all icon-container"
              onClick={() => setIsHomepagesBulkSelectionEnabled(true)}
            >
              <SelectAllIcon />
            </div>
            <Tooltip
              position="bottom"
              className="preview-pane-tooltip"
              target=".select-all"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ActionBar;
