import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MenuTab from "./MenuTab";
import ItemAddList from "../../components/ItemAddList";
import useToast from "../../hooks/useToast";
import ItemsDragHandler from "../../components/ItemsDragHandler";
import {
  globalConstantValues,
  menuBuilderConstants,
  toastMessageConstants,
} from "../../constants/globalConstant";
import { getTabsbyTabId, isDefaultMenutab } from "../../utils/menuBuilderUtils";
import {
  addToastMessage,
  changePublishMenuState,
} from "../../reducers/menuBuilderSlice";

export default function BottomNavigation({
  bottomVisibleTabs,
  bottomAvailableTabs,
  setCreateNewTab,
  setDialog,
  sideNavigationTabs,
}) {
  const dispatch = useDispatch();
  const { menuTabs, menuCustomTabEnabled, publish } = useSelector(
    (state) => state.menuBuilder
  );
  const toast = useToast(addToastMessage);
  const defaultMenuTabs = menuTabs.defaultTabs;
  const customMenuTabs = menuTabs?.customTabs;
  const customMenuTabsbyId = getTabsbyTabId(customMenuTabs || []);
  const bottomNavigationCustomTabs = customMenuTabs?.filter(
    (item) => !item.config.navigateTo.id.includes("Form") && !item.config.externalLinkOptions?.id
  );

  useEffect(() => {
    bottomVisibleTabs.fields?.map(
      (field, index) =>
        customMenuTabsbyId[field.tabId]?.config.navigateTo.id.includes(
          "Form"
        ) && customMenuTabsbyId[field.tabId]?.config.externalLinkOptions?.id && bottomVisibleTabs.remove(index)
    );
  }, []);

  const onCreateNewButtonClicked = () => {
    setCreateNewTab({ state: true, data: null });
  };

  const onAvailableItemAddClick = (index, availableItem) => {
    if (
      bottomVisibleTabs.fields.length <
      globalConstantValues.MAX_NUMBER_OF_BOTTOM_NAVIGATION_TABS
    ) {
      bottomVisibleTabs.append(availableItem);
      bottomAvailableTabs.remove(index);
      publish.isPublished && dispatch(changePublishMenuState(false));
    } else
      toast.error(toastMessageConstants.MAXIMUM_FOUR_TABS_IN_BOTTOM_NAVIGATION);
  };
  const onVisibleItemCloseClick = (index, element) => {
    bottomVisibleTabs.remove(index);
    if (isDefaultMenutab(defaultMenuTabs, element.tabId))
      bottomAvailableTabs.append(element);
    publish.isPublished && dispatch(changePublishMenuState(false));
  };

  const onCustomMenuTabAdd = (_, customTab) => {
    if (
      bottomVisibleTabs.fields.length <
      globalConstantValues.MAX_NUMBER_OF_BOTTOM_NAVIGATION_TABS
    ) {
      bottomVisibleTabs.append(customTab);
      publish.isPublished && dispatch(changePublishMenuState(false));
    } else
      toast.error(toastMessageConstants.MAXIMUM_FOUR_TABS_IN_BOTTOM_NAVIGATION);
  };

  const navigationTabs = [...sideNavigationTabs, ...bottomVisibleTabs.fields];

  return (
    <MenuTab
      leftSideLabel={menuBuilderConstants.VISIBLE_MENU_TABS}
      leftSideItems={
        <ItemsDragHandler
          id={"bottom_navigation-"}
          elements={bottomVisibleTabs.fields}
          move={bottomVisibleTabs.move}
          hasCloseButton={true}
          onCloseButtonClick={onVisibleItemCloseClick}
          onDraggingItem={() =>
            publish.isPublished && dispatch(changePublishMenuState(false))
          }
        />
      }
      rightSideLabel={menuBuilderConstants.DEFAULT_MENU_TABS}
      rightSideItems={
        <ItemAddList
          id={"bottom_navigation_available_item-"}
          items={bottomAvailableTabs.fields}
          onAddItemClick={onAvailableItemAddClick}
        ></ItemAddList>
      }
      navigationTabs={navigationTabs}
      hasCreateNewSection={menuCustomTabEnabled}
      customTabs={bottomNavigationCustomTabs}
      onCreateNewButtonClicked={onCreateNewButtonClicked}
      setCreateNewTab={setCreateNewTab}
      onCustomMenuTabAdd={onCustomMenuTabAdd}
      setDialog={setDialog}
    ></MenuTab>
  );
}
