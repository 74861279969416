import React from "react";
import { InputTextarea } from "primereact/inputtextarea";
import "./index.css";

export const LSQInputTextarea = ({ field, id, ...props }) => {
  return (
    <span className={`lsq-input-textarea`}>
      <InputTextarea
        id={id}
        {...field}
        {...props}
        autoResize
        maxLength={500}
        data-testid="input-textarea"
      />
    </span>
  );
};
