import styles from "./style.module.css";
import Text from "../Text";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { resetToastMessages } from "../../reducers/homePageSlice";
const ToastMessage = ({ toastMessages }) => {
  const dispatch = useDispatch();
  const [prev, setPrev] = useState(0);

  const resetToast = (timer) => {
    setTimeout(() => {
      dispatch(resetToastMessages());
    }, timer);
  };

  useEffect(() => {
    if (toastMessages.length >= prev) {
      resetToast(5000);
    }
    setPrev(toastMessages.length);
  }, [toastMessages.length]);

  return (
    <div
      style={{ right: "10px"}}
      className={`flex flex-column absolute justify-content-start gap-1`}
      data-testid = "toast-message"
    >
      {toastMessages.map((toastMessage, index) => {
        const { title, message, type } = toastMessage;
        const typeClass = styles[type];
        return (
          <div
            key={index}
            className={`flex align-items-center justify-content-center bg-white ${styles["toast-container"]} ${typeClass}`}
          >
            <img src={`/images/${type}.svg`} alt={type} />
            <div className="flex flex-column overflow-hidden gap-1">
              <div className={`capitalize ${styles["toast-heading"]}`}>
                {title}
              </div>
              <Text type="T4" color={"#3B4C69"} className = "pre-wrap-ellipsis">
                {message}
              </Text>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ToastMessage;
