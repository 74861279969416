import React from "react";
import styles from "./styles.module.css";
import { ReactComponent as CrossIcon } from "../../assets/svgIcons/lsq_close.svg";
import Text from "../../components/Text";
import {
  globalConstants,
  mobileHomeBuilderConstants,
  stringConstants,
  toastMessageConstants,
} from "../../constants/globalConstant";
import { formatDateTime } from "../../utils/globalUtils";
import CustomButton from "../../components/CustomButton";
import { ReactComponent as Revert } from "../../assets/svgIcons/lsq_retry.svg";
import { ReactComponent as NoResultsFound } from "../../assets/illustrations/no_saved_versions_found.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  revertHomepageVersion,
  addToastMessage,
} from "../../reducers/homePageSlice";
import { getLayoutSections } from "../../reducers/previewPaneSlice";
import useToast from "../../hooks/useToast";
import { IndeterminateLoader } from "../../components/IndeterminateLoader/index.js";

export default function VersionPanelView({
  homepageVersion,
  setHomepageVersion,
  homepageId,
}) {
  const dispatch = useDispatch();
  const toast = useToast(addToastMessage);
  const isVersionLoading = useSelector(
    (state) => state.homepage.isVersionLoading
  );
  const noVersionsFound = homepageVersion.versions?.length === 0 ? true : false;
  const revertHomepage = (versionId, homepageId) => {
    dispatch(revertHomepageVersion({ versionId, homepageId })).then(
      ({ type }) => {
        if (type.includes(globalConstants.FULFILLED)) {
          dispatch(getLayoutSections(homepageId));
        } else toast.error(toastMessageConstants.SOMETHING_WENT_WRONG);
      }
    );
  };
  return (
    <div
      className="flex justify-content-center align-items-center fixed z-1 h-full"
      style={{
        width: "inherit",
      }}
    >
      <div
        className={`flex justify-content-end align-items-center fixed h-full ${styles["version-panel-backdrop"]}`}
      >
        <div
          className={`flex absolute mr-4 flex-column ${styles["version-panel"]}`}
        >
          <div
            className={`flex h-3rem w-full justify-content-between align-items-center ${styles["version-panel-header"]}`}
          >
            <Text color="var(--text-primary)" type="heading" className="ml-3">
              {mobileHomeBuilderConstants.VERSION_HISTORY}
            </Text>
            <div
              data-testid="version-cross-icon"
              className={`flex mr-3 px-1 py-1 cursor-pointer ${styles["version-panel-cross-icon"]}`}
              onClick={() =>
                setHomepageVersion({ versionPanel: false, versions: null })
              }
            >
              <CrossIcon />
            </div>
          </div>
          {isVersionLoading ? (
            <IndeterminateLoader
              color="#0A1F43"
              height="0.25rem"
              backgroundColor="#B0B7C2"
            />
          ) : (
            <div style={{ height: "0.2rem" }} />
          )}
          <div
            className="p-2 h-full overflow-y-auto"
            data-testid="version-panel-view"
          >
            {homepageVersion.versions?.map((version) => {
              return (
                <div
                  className={`flex justify-content-between ${styles["version-card"]}`}
                  key={version.homepageId}
                >
                  <div
                    className={`flex flex-column justify-content-center`}
                    style={{ gap: "0.2rem" }}
                  >
                    <Text
                      color="var(--text-primary)"
                      type="heading"
                    >{`${version.homepageName}`}</Text>
                    <Text type="sub-heading" color="var(--sub-text)">
                      {formatDateTime(version.updatedAt).format("D MMMM YYYY - hh:mm A")}
                    </Text>
                  </div>
                  <div
                    className={`flex justify-content-center m-1`}
                    data-testid="version-revert-btn"
                  >
                    <CustomButton
                      label={"Revert"}
                      icon={<Revert></Revert>}
                      varient="outline"
                      onClick={() =>
                        revertHomepage(version.homepageId, homepageId)
                      }
                    ></CustomButton>
                  </div>
                </div>
              );
            })}
            {noVersionsFound && (
              <div className="flex flex-column justify-items-center align-items-center w-full mt-7">
                <NoResultsFound></NoResultsFound>
                <Text color="var(--text-primary)" type="title">
                  {stringConstants.NO_SAVED_VERSIONS_FOUND}
                </Text>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
