import React from "react";
import styles from "./styles.module.css";
import Text from "../Text";
import CustomAccordion from "../CustomAccordion";
import { ReactComponent as LsqChevronDown } from "../../assets/svgIcons/lsq_chevron_down_icon.svg";
import { ReactComponent as LsqChevronUp } from "../../assets/svgIcons/lsq_chevron_up.svg";
import WidgetConfigurationWrapper from "./WidgetConfigurationWrapper";
import {
  getWidgetTitle,
  findNumberOfConfiguredWidgets,
  hasConfigurationScreen
} from "../../utils/widgetUtils";
import { widgetConstants } from "../../constants/globalConstant";
import { countLimit } from "../../utils/globalUtils";

export default function WidgetConfigurations({ configuredData }) {
  const totalWidgetCountInLayout = countLimit.WIDGET_COUNT_ACROSS_PREVIEW_PANE;

  const configuredWidgetCount = findNumberOfConfiguredWidgets(configuredData);

  return (
    <div className={styles.widgetcontainer} data-testid="widget-configurations">
      <div
        className={`flex flex-row h-3rem w-full gap-1 align-items-center ${styles["title-bar"]}`}
      >
        <Text
          type="T1B"
          color="var(--text-primary)"
          className={styles.titleText}
        >
          {widgetConstants.WIDGET_CONFIGURATIONS}
        </Text>
        <div style={{ height: "35%", border: "0.5px solid #B0B7C2" }}></div>
        <Text
          type="T3"
          color="var(--text-primary)"
        >{`${configuredWidgetCount}/${totalWidgetCountInLayout} widgets`}</Text>
      </div>
      <div className={`flex flex-column h-full w-full gap-1 overflow-scroll`}>
        {configuredData?.length > 0 &&
          configuredData.map((key) => {
            return key.rows?.map((row) => {
              return row.widgets?.map((widget) => {
                return hasConfigurationScreen(widget.widgetTemplateId) ? (
                  <CustomAccordion
                    key={widget.widgetId}
                    title={getWidgetTitle(widget.widgetTemplateId)}
                    collapseIcon={<LsqChevronDown />}
                    expandIcon={<LsqChevronUp />}
                  >
                    <WidgetConfigurationWrapper
                      widget={widget}
                    ></WidgetConfigurationWrapper>
                  </CustomAccordion>
                ) : (
                  <></>
                );
              });
            });
          })}
      </div>
    </div>
  );
}
