export const newRelicAttribute = (attribute, value) => {
    if(window && window.newrelic){
      window.newrelic.setCustomAttribute(attribute, value);
    }
  }
  
  export const newRelicError = (error, customObject) => {
    if(window && window.newrelic){
      window.newrelic.noticeError(error, customObject);
    }
  }
  
  export const newRelicAction = (action, customObject) => {
    if(window && window.newrelic){
      window.newrelic.addPageAction(action, customObject);
    }
  }
  
  export const newRelicTrace = (action, customObject) => {
    if(window && window.newrelic){
      window.newrelic.addToTrace(action, customObject);
    }
  }