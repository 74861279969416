import React from "react";
import styles from "./styles.module.css";
import Text from "../../components/Text";
import { useTranslation } from "react-i18next";
import { ReactComponent as Dashboard } from "../../assets/svgIcons/dashboard.svg";
import { ReactComponent as DashboardFilled } from "../../assets/svgIcons/dashboard_filled.svg";

export default function ListView({
  items = [],
  activeItemIndex,
  setActiveItemIndex,
}) {
  const { t } = useTranslation();
  return (
    <div
      className={`flex h-full justify-content-center align-items-center w-4 ml-3`}  
    >
      <div className={`w-21rem ${styles["view-box"]}`}>
        <div
          className={`flex h-3rem w-full justify-content-start align-items-center pl-4 ${styles["title-bar"]}`}
        >
          <Text color="#314361" type="T1B">
            {t("LSQ Templates")}
          </Text>
        </div>
        <div className={`p-3 h-full`}>
          {items.map((item, index) => {
            return (
              <div
                className={`flex align-items-center gap-1 ${
                  styles["lsq-card"]
                } ${activeItemIndex === index && "active-card"}`}
                data-testid = "list-templates-view"
                onClick={() => setActiveItemIndex(index)
              }
                key={items.templateId}
              >
                {activeItemIndex == index ? (
                  <DashboardFilled style={{ width: "1.5rem" }} />
                ) : (
                  <Dashboard style={{ width: "1.5rem" }} />
                )}
                <Text
                  color="#43536F"
                  type={
                    activeItemIndex == index
                      ? "T4B"
                      : "T4"
                  }
                >{`Template ${item.templateId}`}</Text>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
