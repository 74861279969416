import React, { useState } from "react";
import styles from "../WidgetList/styles.module.css";
import Text from "../Text";
import CustomButton from "../CustomButton";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ReactComponent as View } from "../../assets/svgIcons/view.svg";
import { ReactComponent as Like } from "../../assets/svgIcons/lsq_like.svg";
import { ReactComponent as LikeFilled } from "../../assets/svgIcons/lsq_like_filled.svg";
import { ReactComponent as Email } from "../../assets/svgIcons/email.svg";
import { mobileHomeBuilderConstants } from "../../constants/globalConstant";
import {
  apiUpdateUserFeedback,
  changeSelectedWidget,
} from "../../reducers/widgetTemplateListSlice";
import analytics from "../../utils/analytics";
import { addLabWidget, resetNonSectionWidgets } from "../../reducers/labsSlice";
import SuggestionBox from "../Modal/SuggestionBox";
import Modal from "../Modal";
import { nanoid } from "@reduxjs/toolkit";
import {
  hasConfigurationScreen,
  getWidgetTitle,
} from "../../utils/widgetUtils";

const ExperimentalWidgetWrapper = ({
  children,
  widget,
  index,
  isWidgetLiked,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [suggestionBox, setSuggestionBox] = useState({
    state: false,
    widgetId: null,
  });

  const suggestionBoxCrossClicked = () => {
    setSuggestionBox({ state: !suggestionBox.state, widgetId: null });
  };

  const suggestionBoxSubmitClicked = (data) => {
    const payload = {
      suggestion: data.suggestion,
    };
    const widgetId = suggestionBox.widgetId;
    dispatch(apiUpdateUserFeedback({ widgetId, payload }));
    analytics.sendEvent(`Submit_widget_suggestion`, {
      widgetId: suggestionBox.widgetId,
    });
  };

  const onPreviewClicked = (widget) => {
    analytics.sendEvent(`Preview_Experimental_Widget`, {
      type: "click",
      category: "Experimental Widget",
      widgetType: widget.widgetType,
      widgetTitle: widget.title,
    });
    dispatch(resetNonSectionWidgets());
    if (hasConfigurationScreen(widget?.id)) {
      dispatch(
        changeSelectedWidget({
          mode: "CREATE",
          widgetTitle: getWidgetTitle(widget.id),
          widgetDetails: widget,
        })
      );
      return;
    }
    const widgetId = nanoid();
    const previewWidgetConfig = {
      id: `row-${nanoid()}`,
      widgets: [
        {
          type: "full",
          widgetId: widgetId,
          widgetType: "experimentalWidgets",
          imgUrl: widget.imageUrl,
        },
      ],
    };
    dispatch(addLabWidget(previewWidgetConfig));
  };

  const likeClicked = () => {
    const payload = {
      isWidgetLiked: !isWidgetLiked,
    };
    const widgetId = widget.id;
    dispatch(apiUpdateUserFeedback({ widgetId, payload }));
    analytics.sendEvent(`Like_experimental_widget`, {
      widgetId: widgetId,
      widgetTitle: widget.title,
    });
  };

  return (
    <div
      className={`flex justify-content-center align-items-start gap-2 ${styles["widget-wrapper"]} px-2 py-4`}
    >
      <div className="flex flex-1">{children}</div>
      <div
        className={`flex flex-1 flex-column justify-content-start ${styles["widget-content"]}`}
      >
        <Text type="T1B" color="var(--primary)">
          {t(widget.title)}
        </Text>
        <Text type="T4" color="var(--sub-text)">
          {t(widget.description)}
        </Text>
        <div className="flex flex-row justify-content-start align-items-center gap-1">
          <CustomButton
            id={"coach-labs-preview-" + index}
            data-testid="preview-button"
            varient="outline"
            label={mobileHomeBuilderConstants.PREVIEW}
            icon={<View />}
            onClick={() => onPreviewClicked(widget)}
          />
          <div
            id={"coach-labs-like-" + index}
            className={`flex px-2 py-2 cursor-pointer ${styles["widget-feedback-icon"]}
        }`}
            onClick={likeClicked}
            data-testid="like-button"
          >
            {!isWidgetLiked ? <Like /> : <LikeFilled />}
          </div>
          <div
            id={"coach-labs-comment-" + index}
            className={`flex px-2 py-2 cursor-pointer ${styles["widget-feedback-icon"]}
        }`}
            onClick={() =>
              setSuggestionBox({
                state: !suggestionBox.state,
                widgetId: widget.id,
              })
            }
            data-testid="suggestion-button"
          >
            <Email></Email>
          </div>
        </div>
      </div>
      {suggestionBox.state && (
        <Modal
          state={suggestionBox}
          backgroundStyle={{
            background: "rgba(0, 0, 0, 0.5)",
            backdropFilter: "blur(22px)",
          }}
        >
          <SuggestionBox
            dismissHandler={suggestionBoxCrossClicked}
            submitHandler={suggestionBoxSubmitClicked}
          ></SuggestionBox>
        </Modal>
      )}
    </div>
  );
};
export default ExperimentalWidgetWrapper;
