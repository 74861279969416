import React from "react";

export const NoResultsFound = ({ Illustration, children }) => {
  return (
    <div className= {`flex flex-column gap-2 w-full h-full justify-content-center align-items-center`}
     data-testid = "no-results-found-preview"
    >
       <Illustration />
        {children}
    </div>
  );
};
