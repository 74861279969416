import React from "react";
import Text from "../Text";

export default function CustomViewer({title, value, style}){  
    return(
        <div className={style} data-testid="custom-viewer">
            <Text type="T5" color={"var(--sub-text)"}>{title}</Text>
            <Text type="T4" color={"var(--text-tertiary)"}>{value}</Text>
        </div>
    )
}