import React, { useEffect, useRef } from "react";
import {
  globalConstants,
  mobileHomeBuilderConstants,
  shimmerConstants,
  toastMessageConstants,
} from "../../constants/globalConstant";
import Text from "../Text";
import CustomButton from "../CustomButton";
import { ReactComponent as NoWidgetsFound } from "../../assets/illustrations/no_widgets_found.svg";
import { NoResultsFound } from "../NoResultsFound";
import { ReactComponent as SomethingWentWrong } from "../../assets/illustrations/something_went_wrong.svg";
import { ReactComponent as Retry } from "../../assets/svgIcons/lsq_retry.svg";
import { getImageNameFromImageUrl } from "../../utils/widgetUtils";
import { useDispatch, useSelector } from "react-redux";
import { getWidgetList } from "../../reducers/widgetTemplateListSlice";
import { searchWidgets } from "../../utils/homepageUtils";
import { Shimmer } from "../Shimmer";
import ActiveWidgetWrapper from "./ActiveWidgetWrapper";
import { ReactComponent as ScrollTop } from "../../assets/svgIcons/lsq_chevron_up.svg";
import styles from "./styles.module.css";
import useDebounce from "../../hooks/useDebounce"

const ActiveWidgetList = ({ searchText = "" }) => {
  const dispatch = useDispatch();
  const widgetListRef = useRef()
  const scrollHandler = () => {
    const widgetListElement = document.getElementById(
      "widget-list-scrollTop-icon"
    );
    if (widgetListRef?.current?.scrollTop >= 50) {
      widgetListElement.classList.remove(styles["subHeader_fadeout"]);
      widgetListElement.classList.add(styles["subHeader_fadein"]);
    } else {
      widgetListElement.classList.remove(styles["subHeader_fadein"]);
      widgetListElement.classList.add(styles["subHeader_fadeout"]);
    }
  };

  const debouncedScrollHandler = useDebounce(scrollHandler, 100);
  const widgetTemplateList =
    useSelector((state) => state.widgetTemplateList) || {};
  const { status, list } = widgetTemplateList.widgetList.widgetTemplates;
  const widgetList = searchWidgets(list, searchText);
  const somethingWentWrongContent = () => {
    const retryHandler = () => {
      dispatch(getWidgetList());
    };
    return (
      <div
        data-testid="something-went-wrong"
        className="flex flex-column align-items-center justify-content-center gap-2"
      >
        <Text type="T1" color={"var(--N100)"}>
          {toastMessageConstants.SOMETHING_WENT_WRONG}
        </Text>
        <CustomButton
          data-testid="widgetList-retry-button"
          varient="outline"
          label={mobileHomeBuilderConstants.RETRY}
          icon={<Retry />}
          onClick={retryHandler}
        />
      </div>
    );
  };
  const renderWidgetList = () => {
    if (status === globalConstants.PENDING)
      return <Shimmer label={shimmerConstants.WIDGET_LIST} />;
    else if (status === globalConstants.REJECTED) {
      return (
        <NoResultsFound
          Illustration={SomethingWentWrong}
          children={somethingWentWrongContent()}
        />
      );
    } else if (status === globalConstants.FULFILLED && widgetList?.length !== 0)
      return widgetList?.map((widget) => (
        <ActiveWidgetWrapper key={widget?.id} widget={widget}>
          <img
            src={`/images/${getImageNameFromImageUrl(widget.imageUrl)}.png`}
            className="h-full w-full"
            alt={`${widget.title}`}
          />
        </ActiveWidgetWrapper>
      ));
    else if (status === globalConstants.FULFILLED && widgetList?.length === 0)
      return (
        <NoResultsFound
          Illustration={NoWidgetsFound}
          children={
            <Text type="T1B" color={"var(--N100)"}>
              {mobileHomeBuilderConstants.NO_WIDGETS_FOUND}
            </Text>
          }
        />
      );
  };

  const scrollTopHandler = () => {
    document.getElementById("widgetList-container").style.scrollBehavior =
      "smooth";
    document.getElementById("widgetList-container").scrollTop = 0;
  };

  useEffect(() => {
    dispatch(getWidgetList());
  }, []);

  return (
    <div
      className={`flex flex-column w-full h-full overflow-y-scroll pt-7`}
      onScroll={debouncedScrollHandler}
      ref={widgetListRef}
      id="widgetList-container"
    >
      {renderWidgetList()}
      <div
        className={`icon-container ${styles["scroll-top-icon"]}`}
        onClick={scrollTopHandler}
        id="widget-list-scrollTop-icon"
      >
        <ScrollTop />
      </div>
    </div>
  );
};

export default ActiveWidgetList;
