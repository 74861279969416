import React from 'react'
import { ProgressSpinner } from 'primereact/progressspinner'
import "./index.css"

function LsqProgressSpinner({strokeWidth , strokeColor}) {
  return (
    <ProgressSpinner strokeWidth={strokeWidth} style={{color: strokeColor}} className="lsq-progress"/>
  )
}

export default LsqProgressSpinner