import React, { useEffect } from "react";
import Text from "../../../components/Text";
import { useSelector, useDispatch } from "react-redux";
import {
  leadControlsConstants,
  leadControlsWidgetConstants,
} from "../../../constants/globalConstant";
import { ReactComponent as Back } from "../../../assets/svgIcons/lsq_back.svg";
import CustomButtom from "../../../components/CustomButton";
import LsqItemTile from "../../../components/LsqItemTile/LsqItemTile";
import { Checkbox } from "primereact/checkbox";
import {
  changeSelectedLeadPageMenu,
  getLeadPageMenuTabs,
} from "../../../reducers/leadControlsSlice";
import cloneDeep from "lodash.clonedeep";

export default function MenuTabsPage({
  setShowConfirmationPopUp,
  setCurrentPage,
  setCustomMenu,
}) {
  const dispatch = useDispatch();
  const { leadPageMenus } = useSelector((state) => state.leadControls);

  const onMenuItemsChange = (e, menu) => {
    const clonedSelectedMenus = cloneDeep(leadPageMenus.selectedMenus);
    if (e.target.checked) {
      clonedSelectedMenus.push(menu);
    } else {
      const selectedIndex = leadPageMenus.selectedMenus.findIndex(
        (element) => element.id === menu.id
      );
      clonedSelectedMenus.splice(selectedIndex, 1);
    }
    dispatch(changeSelectedLeadPageMenu(clonedSelectedMenus));
  };

  useEffect(() => {
    leadPageMenus.menus.length == 0 && dispatch(getLeadPageMenuTabs());
  }, []);

  return (
    <div className={`flex h-full flex-column flex-start`}>
      <div className="flex flex-column gap-2 p-3 pb-1">
        <Text type="T2B" color="var(--text-primary)">
          {leadControlsConstants.WIDGETS}
        </Text>
        <CustomButtom
          varient="filledV2"
          data-testid="menus-page-back-btn"
          label={leadControlsConstants.TRY_WIDGETS}
          icon={<Back />}
          onClick={() => {
            leadPageMenus.selectedMenus.length !== 0
              ? setShowConfirmationPopUp({
                  state: true,
                  data: leadControlsWidgetConstants.MENU,
                })
              : setCurrentPage(leadControlsConstants.WIDGETS_PAGE);
            setCustomMenu(false);
          }}
        />
        <hr className="horizontal-divider" />
        <Text type="T2B" color="var(--text-primary)">
          {leadControlsConstants.MENU_ITEMS}
        </Text>
      </div>
      <div className="flex flex-column gap-2 overflow-scroll p-3 h-full">
        {leadPageMenus.menus?.map((menu) => {
          return (
            <LsqItemTile
              key={menu.id}
              label={menu.name}
              hasTileImage={true}
              tileImageName={menu.imgName}
              hasTileActionImg={true}
              isTileClickable = {false}
              tileActionImg={
                <Checkbox
                  onChange={(e) => onMenuItemsChange(e, menu)}
                  checked={leadPageMenus.selectedMenus.some(
                    (selectedMenu) => selectedMenu.id === menu.id
                  )}
                />
              }
            />
          );
        })}
      </div>
      <div className="flex p-3">
        <LsqItemTile
          key={"custom"}
          label={leadControlsConstants.ADD_CUSTOM_MENU}
          hasTileImage={true}
          tileImageName={"lsq_add"}
          hasTileActionImg={false}
          onTileClick={() => {
            setCustomMenu(true);
          }}
        />
      </div>
    </div>
  );
}
