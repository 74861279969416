import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";

export default function SidePanel({ direction = "right", state, children, style}) {
  return (
    <div
      className={
        state
          ? `${styles["side-panel"]} ${styles[direction]} ${styles["open"]}`
          : `${styles["side-panel"]} ${styles[direction]}`
      }
      style={style}
    >
      {children}
    </div>
  );
}

SidePanel.propTypes = {
  children: PropTypes.element,
};
