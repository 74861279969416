import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Joyride from "react-joyride";
import { ReactComponent as SearchIcon } from "../../assets/svgIcons/search.svg";
import styles from "./styles.module.css";
import Text from "../../components/Text";
import CustomInputV2 from "../../components/CustomInputV2";
import CoachTooltip from "../../components/Coach/CoachTooltip";
import { teamsViewSteps } from "../../components/Coach/coachSteps";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Modal from "../../components/Modal";
import PreviewDashboard from "../../components/PreviewDashboard";
import MobileScreenTemplate from "../Templates/MobileScreenTemplate";
import WidgetConfigurations from "../../components/WidgetConfigurations";
import AddCard from "../../components/AddCard";
import useHomepageCard from "../../hooks/useHomepageCard";
import {
  searchTeams,
  sortTeams,
  teamsViewSortItems,
} from "../../utils/casaUtils";
import {
  ROUTES_PATH,
  casaConstants,
  dashboardCardConstants,
  globalConstantValues,
  globalConstants,
  homepageConstants,
} from "../../constants/globalConstant";
import {
  changeSelectedHomepage,
  getHomepages,
  changeSidePanelView,
  getTeamsAndTeamsAssignments,
  apiUpdateUserPreferences,
} from "../../reducers/homePageSlice";
import {
  getAllTeamsViewData,
  hierarchyToLinear,
  homepagesbyTeamIdMapping,
  menubyTeamIdMapping,
} from "../../utils/globalUtils";
import {
  changeSelectedMenuId,
  getMenu,
  getMenus,
  resetMenuConfiguration,
} from "../../reducers/menuBuilderSlice";
import TeamsViewMenuCard from "../../components/TeamsViewMenuCard";
import ToastMessage from "../../components/ToastMessage";
import { IndeterminateLoader } from "../../components/IndeterminateLoader";
import TeamsContainer from "./TeamsContainer";
import { InlineDropdown } from "../../components/InlineDropdown";
import analytics from "../../utils/analytics";
import { changeSelectedWidgetListTab } from "../../reducers/widgetTemplateListSlice";
import { widgetTabs } from "../../utils/widgetUtils";

const Teams = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const navigateTo = searchParams.get("navigateTo");
  const teamsViewSortState = useState(teamsViewSortItems[0].label);
  const searchState = useState("");
  const { createHomepageClicked } = useHomepageCard();
  const [previewPanel, setPreviewPanel] = useState({
    state: false,
    data: null,
  });
  const [dialog, setDialog] = useState({ state: false, dialogBox: {} });
  const { toastMessages, isHomepageLoading } = useSelector(
    (state) => state.homepage
  );
  const teamsHierarchy = useSelector((state) => state.homepage.teams);
  const { menus, menuById, isMenuBuilderLoading } = useSelector(
    (state) => state.menuBuilder
  );
  const { selectedWidgetListTab } = useSelector(
    (state) => state.widgetTemplateList
  );
  const loading = isMenuBuilderLoading || isHomepageLoading;
  const menuByTeamId = menubyTeamIdMapping(menus?.teamMenu || []);
  const isLayoutLoading = useSelector(
    (state) => state.previewPane.isLayoutLoading
  );
  const { teamDashboards, homepagesById, userPreferences } = useSelector(
    (state) => state.homepage
  );
  const { teamsCoachMarksVisited, sideNavigationCoachMarksVisited } =
    userPreferences;
  const teamsDashboards = teamDashboards?.teamsDashboards || [];
  const defaultTeamDashboards = teamDashboards?.defaultDashboards || [];
  const defaultMenu = menus?.defaultMenu || [];
  const linearTeams = useMemo(() => {
    return hierarchyToLinear(teamsHierarchy);
  }, [teamsHierarchy]);
  const teamsMappedWithTeamId = useMemo(() => {
    return Object.fromEntries(linearTeams.map((team) => [team.teamId, team]));
  }, [linearTeams]);
  const homepagesbyTeamId = homepagesbyTeamIdMapping(teamsDashboards);
  const defaultAndTeamsData = getAllTeamsViewData(
    linearTeams,
    homepagesbyTeamId,
    menuByTeamId,
    defaultTeamDashboards,
    defaultMenu
  );

  let filterAndSortedTeams = searchTeams(
    defaultAndTeamsData.teamsData,
    searchState[0],
    teamsMappedWithTeamId
  );

  if (teamsViewSortState[0] === casaConstants.RECENTLY_MODIFIED) {
    filterAndSortedTeams = filterAndSortedTeams.filter(
      (item) => item.homepages.length !== 0 || item.menu !== undefined
    );
  }

  filterAndSortedTeams = sortTeams(
    filterAndSortedTeams,
    teamsViewSortState[0],
    teamsMappedWithTeamId
  );

  filterAndSortedTeams = [
    defaultAndTeamsData.defaultTeam,
    ...filterAndSortedTeams,
  ];

  useEffect(() => {
    dispatch(getTeamsAndTeamsAssignments());
    dispatch(getHomepages());
    dispatch(getMenus());
    selectedWidgetListTab === globalConstants.EXPIREMENTAL_WIDGETS &&
      dispatch(changeSelectedWidgetListTab(widgetTabs[0]));
    if (navigateTo === "menu") {
      dispatch(changeSidePanelView(homepageConstants.MENU_BUILDER_VIEW));
      navigate(ROUTES_PATH.menubuilder);
    }
  }, []);

  const teamTemplate = (data) => {
    return (
      <Text
        type="T1B"
        color="var(--text-primary)"
        className={"overflow-wrap-anywhere"}
      >
        {data.teamLabel}
      </Text>
    );
  };

  const previewEditHandler = () => {
    dispatch(changeSelectedHomepage(previewPanel.data?.homepage?.homepageId));
    dispatch(changeSidePanelView(homepageConstants.HOMEPAGES_VIEW));
    navigate(ROUTES_PATH.homebuilder);
  };

  const homepagesTemplate = (data) => {
    const homepages = data.homepages;
    const clonedhomepages = homepages.filter(
      (homepageId) => homepagesById[homepageId] !== undefined
    );
    return (
      <div className="flex gap-1">
        <TeamsContainer
          dragAndDrop={true}
          isScrollable={true}
          homepages={homepages}
          homepagesById={homepagesById}
          teamId={data.teamId}
          setPreviewPanel={setPreviewPanel}
          setDialog={setDialog}
        ></TeamsContainer>
        {clonedhomepages?.length <
          globalConstantValues.MAX_NUMBER_OF_TEAM_DASHBOARD && (
          <AddCard
            cardName={dashboardCardConstants.ADD_PAGE}
            cardClickHandler={() => {
              createHomepageClicked("Teams View add page card");
              dispatch(changeSidePanelView(homepageConstants.HOMEPAGES_VIEW));
            }}
          ></AddCard>
        )}
      </div>
    );
  };

  const teamsViewMenuCardClicked = (menuId) => {
    dispatch(changeSelectedMenuId(menuId));
    dispatch(getMenu(menuId));
    dispatch(changeSidePanelView(homepageConstants.MENU_BUILDER_VIEW));
    navigate(ROUTES_PATH.menubuilder);
    analytics.sendEvent(`clicked Teams view menu card`, {
      category: "update_menu",
      menuId: menuId,
    });
  };

  const addMenuCardClicked = () => {
    dispatch(resetMenuConfiguration());
    dispatch(changeSidePanelView(homepageConstants.MENU_BUILDER_VIEW));
    navigate(ROUTES_PATH.menubuilder);
    const analytics_data = {
      category: "create_menu",
      type: "click",
    };
    analytics.sendEvent(
      `create menu from Teams view add menu card`,
      analytics_data
    );
  };

  const menuTemplate = (data) => {
    const menuId = data.menu;
    return menuId ? (
      <TeamsViewMenuCard
        menu={menuById[menuId]}
        cardClickHandler={() => teamsViewMenuCardClicked(menuId)}
      ></TeamsViewMenuCard>
    ) : (
      <AddCard
        cardName={"Add Menu"}
        cardClickHandler={addMenuCardClicked}
      ></AddCard>
    );
  };

  const onCoachSkippedOrFinished = () => {
    const payload = {
      teamsCoachMarksVisited: true,
    };
    dispatch(apiUpdateUserPreferences(payload));
  };

  return (
    <div
      className="flex h-screen w-screen align-items-center p-3"
      data-testid="teams-data-view"
    >
      {(sideNavigationCoachMarksVisited && !loading) && (
        <Joyride
          tooltipComponent={(props) => (
            <CoachTooltip
              {...props}
              onCoachSkippedOrFinished={onCoachSkippedOrFinished}
            />
          )}
          continuous
          run={!teamsCoachMarksVisited}
          showProgress
          showSkipButton
          steps={teamsViewSteps}
          disableOverlayClose={true}
          disableCloseOnEsc={true}
          spotlightPadding={0}
        />
      )}
      <div
        className={`flex h-full w-full align-items-center flex-column overflow-hidden ml-7 ${styles["teams-tabular-view"]}`}
      >
        <div
          className={`flex w-full align-items-center justify-content-between p-3`}
        >
          <Text type="T1B" color="var(--text-primary)">
            {globalConstants.TEAM_MANAGEMENT}
          </Text>
          <div className={`flex align-items-center gap-2`}>
            <InlineDropdown
              items={teamsViewSortItems}
              selectedLabel={teamsViewSortState[0]}
              setSelectedLabel={teamsViewSortState[1]}
              style={{ width: "fit-content" }}
            ></InlineDropdown>
            <CustomInputV2
              style={{ width: "20rem", height: "2.25rem" }}
              placeholder={"Search Teams"}
              value={searchState[0]}
              onChange={(e) => searchState[1](e.target.value)}
              LeftIcon={SearchIcon}
            />
          </div>
          <div className={`toast_container`}>
            <ToastMessage toastMessages={toastMessages} />
          </div>
        </div>
        <div className={`flex w-full h-full flex-column overflow-scroll`}>
          {loading ? (
            <IndeterminateLoader
              color="#0A1F43"
              height="0.25rem"
              backgroundColor="#B0B7C2"
            />
          ) : (
            <div style={{ height: "0.2rem" }} />
          )}
          <DataTable
            value={filterAndSortedTeams}
            className="lsq-data-table"
            responsiveLayout="scroll"
            paginator
            rows={10}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
              height: "100%",
            }}
          >
            <Column
              field="teamLabel"
              header={globalConstants.TEAMS}
              style={{ width: "25%", minWidth: "10rem" }}
              body={teamTemplate}
              headerClassName="coach-teams-column"
            ></Column>
            <Column
              field="homepages"
              style={{
                width: "50%",
                border: "1px solid #dee2e6",
                borderWidth: "0 1px  1px 1px",
              }}
              header={globalConstants.HOMEPAGES}
              body={homepagesTemplate}
              headerClassName="coach-homepages-column"
            ></Column>
            <Column
              field="menu"
              style={{ width: "25%" }}
              header={globalConstants.MENUS}
              body={menuTemplate}
              headerClassName="coach-menus-column"
            ></Column>
          </DataTable>
        </div>
      </div>
      <Modal
        state={previewPanel.state}
        backgroundStyle={{
          background: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(2px)",
        }}
      >
        <PreviewDashboard
          key={previewPanel.data?.homepage?.homepageId}
          title={previewPanel.data?.title}
          editHandler={previewEditHandler}
          dismissHandler={() =>
            setPreviewPanel({
              state: false,
              data: null,
            })
          }
          children1={
            <MobileScreenTemplate
              sections={previewPanel.data?.sections}
              isSectionsLoading={isLayoutLoading}
            ></MobileScreenTemplate>
          }
          children2={
            <WidgetConfigurations
              configuredData={previewPanel.data?.sections}
            ></WidgetConfigurations>
          }
        ></PreviewDashboard>
      </Modal>
      <Modal
        state={dialog.state}
        backgroundStyle={{
          background: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(22px)",
        }}
      >
        {dialog.dialogBox}
      </Modal>
    </div>
  );
};
export default Teams;
