let host = "https://fieldsales-in21.leadsquared.com";
if (window.location.host === "fs-dashboard.marketxpander.net") {
  host = "https://fieldsales.marketxpander.net";
} else if (window.location.host === "fs-dashboard.crm4b2c.com") {
  host = "https://fieldsales.crm4b2c.com";
} else if (window.location.host === "fs-dashboard.lsq-staging.com") {
  host = "https://fieldsales.lsq-staging.com";
} else if (window.location.host === "fs-dashboard-in22.leadsquared.com") {
  host = "https://fieldsales-in22.leadsquared.com";
}
const baseURL = host + "/api/dashboard/v1";

export default baseURL;
