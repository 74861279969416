import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { getWidgetWidth, isStandaloneWidgetInSection } from "../utils/previewPaneUtils";
import { WidgetMenu } from "./WidgetMenu";

const WidgetDraggableWrapper = ({
  index,
  id,
  children,
  widgetInformation,
}) => {
  const isStandaloneWidget = isStandaloneWidgetInSection(widgetInformation.widgetTemplateId)
  return (
    <Draggable isDragDisabled={isStandaloneWidget} draggableId={String(id)} index={index}>
      {(provided) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              width: getWidgetWidth(widgetInformation),
              height: "min-content",
              ...provided.draggableProps.style,
            }}
          >
            <WidgetMenu
              widgetInformation={widgetInformation}
              styles={{ top: "5px", right: "5px" }}
            >
              {children}
            </WidgetMenu>
          </div>
        );
      }}
    </Draggable>
  );
};

export default WidgetDraggableWrapper;
