import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { ReactComponent as LinkIcon } from "../assets/svgIcons/link.svg";
import CustomButton from "../components/CustomButton";
import CustomInput from "../components/CustomInput";
import CustomRadioGroup from "../components/CustomRadioGroup";
import { resetWidgetConfiguration } from "../reducers/widgetTemplateListSlice";
import { apiAddWidget, apiUpdateWidget } from "../reducers/previewPaneSlice";
import { getWidgetPreviewOfExperimentalWidget, getWidgetSignature } from "../utils/widgetUtils";
import useWidgetEssentials from "../hooks/useWidgetEssentials";
import analytics from "../utils/analytics";
import { getExternalLinkOpeningOptions, validateWidgetSubmission } from "../utils/globalUtils";
import { listOptionTemplate, valueTemplate } from "../utils/dropdownUtils";
import { CustomDropdown } from "../components/CustomDropdown/CustomDropdown";

export default function WebViewSettings() {
  const { selectedWidget, mode, selectedWidgetConfiguration, homepageId , previewPaneData } =
    useWidgetEssentials()
  const dispatch = useDispatch();
  const displayTypeOptions = selectedWidgetConfiguration.metaData.widgetConfiguration
  const defaultValues = {
    title: "",
    url: "",
    displayType: displayTypeOptions[0].value,
    externalLinkOptions: null
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue
  } = useForm({ defaultValues });


  const onSubmit = (data) => {
    if(selectedWidget.isExperimental){
      getWidgetPreviewOfExperimentalWidget(selectedWidget.imageUrl, dispatch)
      return;
    }
    if(!validateWidgetSubmission(mode , previewPaneData, dispatch)) return;
    const config = {
      title: data.title.trim(),
      url: data.url.trim(),
      displayType: data.displayType,
      externalLinkOptions: data.externalLinkOptions,
    }
    const metaValueData = getWidgetSignature(
      homepageId,
      selectedWidget?.id,
      config
    );
    const analytics_data = {
      'category': 'Widget',
      'type': 'click',
      'widgetType': 'WebView',
      'displayType': metaValueData.config.displayType
    }
    if (mode === "UPDATE") {
      const updatedMetaData = {
        widgetId: selectedWidgetConfiguration?.metaValue.widgetId,
        config: {
          ...metaValueData.config,
        },
      };
      dispatch(apiUpdateWidget(updatedMetaData));
      analytics_data["widgetId"] = selectedWidgetConfiguration?.metaValue.widgetId;
      analytics.sendEvent('Updating_WebView', analytics_data);
    } else if (mode === "CREATE") {
      dispatch(apiAddWidget(metaValueData));
      analytics.sendEvent('Adding_WebView', analytics_data);
    }

    dispatch(resetWidgetConfiguration());
  };

  useEffect(() => {
    if (mode === "UPDATE" && selectedWidgetConfiguration.metaValue !== {}) {
      const {title , url , displayType, externalLinkOptions} = selectedWidgetConfiguration.metaValue.config
      setValue("title" , title);
      setValue("url" , url)
      setValue("displayType", displayType)
      setValue("externalLinkOptions", externalLinkOptions)
    }
  }, []);

  return (
    <form
      style={{ height: "90%" }}
      className="flex flex-column w-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex-1 overflow-y-auto">
        <div className={`flex flex-column mt-5 lg:mx-6 md:mx-5 sm: mx-4 gap-3`}>
          <div className={`flex flex-column gap-2`}>
            <CustomInput
              fieldName="title"
              errors={errors}
              control={control}
              isMandatory
              label="Display Name"
              placeholder="Type Here"
              maxLength={30}
              data-testid="webview-title"
            />
          </div>
          <div className={`flex flex-column gap-2`}>
            <CustomInput
              RightIcon={LinkIcon}
              fieldName="url"
              errors={errors}
              control={control}
              isMandatory
              label="Add URL"
              data-testid="webview-url"
              pattern={
                {
                  value: /^((https):\/\/).+$/,
                  message: "Please enter a valid URL with https protocol"
                }
              }
            />
          </div>
          <CustomRadioGroup
            control={control}
            label="Configure Widget Dimensions"
            options={displayTypeOptions}
            radioName="displayType"
          />
          <CustomDropdown
            control={control}
            isMandatory={false}
            options={getExternalLinkOpeningOptions()}
            id="external-link-options"
            fieldName={"externalLinkOptions"}
            optionLabel="name"
            className="w-full text-base lsq-input"
            placeholder="Select"
            valueTemplate={listOptionTemplate}
            itemTemplate={valueTemplate}
            label="Open with"
            errors={errors}
            showClear />
        </div>
      </div>
      <div
        className={`flex w-11 py-2 mt-3 align-self-center align-items-center justify-content-end mt-auto gap-2`}
      >
       <CustomButton
          type="reset"
          onClick={() => reset()}
          varient="text"
          label="Reset"
        />
        <CustomButton type="submit" varient="filled" label="Save" />
      </div>
    </form>
  );
}
