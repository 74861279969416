const tasks = {
    "0": "All Tasks",
    "0-0": "All Appointments",
    "0-1": "All To-Dos"
}

/* This function takes the value of the multiselect dropdown field
      as an input and returns the list of chips needs to be displayed */
const chipGenerator = (fieldValue = {}) => {
  if (fieldValue === null) return [];
  let listOfTaskChips = [];
  let previousKeyLevel = 0;
  let currentKeyLevel = 0;

  const levels = {
    0: false,
    1: false,
    2: false,
  };

  Object.keys(fieldValue)
    .sort()
    .forEach((key) => {
      currentKeyLevel = String(key).split("-").length;
      if (levels[currentKeyLevel - 1] === true) {
        levels[currentKeyLevel] = true;
      } else if (
        previousKeyLevel <= currentKeyLevel &&
        fieldValue[key].checked === true
      ) {
        levels[currentKeyLevel] = true;
        listOfTaskChips.push({
          key: key,
          value: tasks[key],
        });
      }

      if (previousKeyLevel > currentKeyLevel) {
        levels[currentKeyLevel] = fieldValue[key].checked === true;
        if (!levels[currentKeyLevel - 1] && fieldValue[key].checked) {
          listOfTaskChips.push({
            key: key,
            value: tasks[key],
          });
        }
      }

      previousKeyLevel = currentKeyLevel;
    });
  return listOfTaskChips;
};

const taskTreeBuilder = (taskTypes) => {
    const taskListTree =
    {
        "root": [
            {
                "key": "0",
                "label": "All Tasks",
                "data": "Tasks Folder",
                "children": [{
                    "key": "0-0",
                    "label": "All Appointments",
                    "data": "Appointments folder",
                    "children": []
                },
                {
                    "key": "0-1",
                    "label": "All To-Dos",
                    "data": "To-Dos Folder",
                    "children": []
                }]
            }
        ]
    }


    let counter = 0
    for (let appointment of taskTypes.appointments) {
        taskListTree.root[0].children[0].children.push({
            "key": "0-0-" + counter,
            "label": appointment.Name,
            "data": appointment.Name,
            "id": appointment.Id
        })
        tasks["0-0-" + counter] = appointment.Name
        counter = counter + 1
    }

    counter = 0
    for (let todo of taskTypes.todos) {
        taskListTree.root[0].children[1].children.push({
            "key": "0-1-" + counter,
            "label": todo.Name,
            "data": todo.Name,
            "id": todo.Id
        })
        tasks["0-1-" + counter] = todo.Name
        counter = counter + 1
    }

    return taskListTree
}

const onChipRemoval = (fieldValue, chipKey) => {
    const tempObject = { ...fieldValue }
    let isAnyKeyChecked = false
    Object.keys(tempObject).forEach(key => {
        if (String(key).startsWith(String(chipKey))) {
            tempObject[key].checked = false
        }
        if (tempObject[key].checked === false && tempObject[key].partialChecked === false) {
            delete tempObject[key]
        }

        if (tempObject[key]?.checked === true) isAnyKeyChecked = true
    })

    if (isAnyKeyChecked === false) return {}

    return tempObject
}

const formatTodo = (taskMetaValue, keyString, taskTree, key) => {
  if (
    (taskMetaValue.category.length > 0 &&
      taskMetaValue.category[0] !== "1" &&
      keyString[1] === "1") ||
    (taskMetaValue.category.length === 0 && keyString[1] === "1")
  ) {
    for (let x of taskTree.root[0].children[1].children) {
      if (x.key === key) {
        taskMetaValue.todoId.push(x.id);
        break;
      }
    }
  }
};
const formatAppointment = (
  taskMetaValue,
  keyLength,
  taskTree,
  keyString,
  key,
  fieldValue
) => {
  if (
    keyLength === 3 &&
    fieldValue[key].checked === true &&
    ((taskMetaValue.category.length > 0 &&
      taskMetaValue.category[0] !== "0" &&
      keyString[1] === "0") ||
      (taskMetaValue.category.length === 0 && keyString[1] === "0"))
  ) {
    for (let x of taskTree.root[0].children[0].children) {
      if (x.key === key) {
        taskMetaValue.appointmentId.push(x.id);
        break;
      }
    }
  }
};
const formatTaskMetaValue = (fieldValue, taskTypes) => {
  const taskTree = taskTreeBuilder(taskTypes);
  const taskMetaValue = {
    category: [],
    appointmentId: [],
    todoId: [],
  };

  if (fieldValue["0"].checked === true) {
    taskMetaValue.category.push("-1");
    return taskMetaValue;
  }

  Object.keys(fieldValue)
    .sort()
    .forEach((key) => {
      const keyString = String(key).split("-");
      const keyLength = keyString.length;
      if (keyLength === 2 && fieldValue[key].checked === true) {
        taskMetaValue.category.push(keyString[1]);
      }
      formatAppointment(
        taskMetaValue,
        keyLength,
        taskTree,
        keyString,
        key,
        fieldValue
      );
      formatTodo(taskMetaValue, keyString, taskTree, key);
    });
  return taskMetaValue;
};

const checkAllAppointments = (taskTree) => {
    let appointmentFieldValue = {};
    const appointmentTreeList = taskTree.root[0].children[0].children;
    appointmentTreeList.map((appointment) => {
        appointmentFieldValue[appointment.key] = {
            checked: true,
            partialChecked: false
        }
    })
    return appointmentFieldValue
}

const checkAllTodos = (taskTree) => {
    let todoFieldValue = {};
    const todoTreeList = taskTree.root[0].children[1].children;
    todoTreeList.map((todo) => {
        todoFieldValue[todo.key] = {
            checked: true,
            partialChecked: false
        }
    })
    return todoFieldValue
}

const getKeyForId = (appointmentId, nodeChildren) => {
  for (let child of nodeChildren) {
    if (appointmentId === child.id) {
      return child.key;
    }
  }
};

const updateTaskFieldForAppointment = (
  appointmentId,
  taskFieldValue,
  taskTree
) => {
  let key = getKeyForId(appointmentId, taskTree.root[0].children[0].children);
  if (!key) return;
  taskFieldValue[key] = {
    checked: true,
    partialChecked: false,
  };
  while (key.length >= 1) {
    if (key.length > 1) {
      key = key.slice(0, -2);
      taskFieldValue[key] = {
        checked: false,
        partialChecked: true,
      };
    } else {
      break;
    }
  }
};
const updateTaskFieldForTodo = (todoId, taskFieldValue, taskTree) => {
  let key = getKeyForId(todoId, taskTree.root[0].children[1].children);
  if (key === undefined) return;
  taskFieldValue[key] = {
    checked: true,
    partialChecked: false,
  };
  while (key.length >= 1) {
    if (key.length !== 1) {
      key = key.slice(0, -2);
      taskFieldValue[key] = {
        checked: false,
        partialChecked: true,
      };
    } else {
      break;
    }
  }
};

//TODO: Need to optimize the function
const formatTaskMetaValueToTaskFieldValue = (metaValue, taskTypes) => {
  const taskTree = taskTreeBuilder(taskTypes);
  let taskFieldValue = {};
  for (let appointmentId of metaValue.appointmentId)
    updateTaskFieldForAppointment(appointmentId, taskFieldValue, taskTree);

  for (let todoId of metaValue.todoId)
    updateTaskFieldForTodo(todoId, taskFieldValue, taskTree);

  for (let categoryId of metaValue.category) {
    if (categoryId === "-1") {
      taskFieldValue["0"] = {
        checked: true,
        partialChecked: false,
      };
        taskFieldValue["0-0"] = {
          checked: true,
          partialChecked: false,
        };
      taskFieldValue["0-1"] = {
        checked: true,
        partialChecked: false,
      };
      taskFieldValue = {
        ...taskFieldValue,
        ...checkAllAppointments(taskTree),
      };
      taskFieldValue = {
        ...taskFieldValue,
        ...checkAllTodos(taskTree),
      };
    }
    if (categoryId === "0") {
      taskFieldValue["0-0"] = {
        checked: true,
        partialChecked: false,
      };
      taskFieldValue["0"] = {
        checked: false,
        partialChecked: true,
      };
      taskFieldValue = {
        ...taskFieldValue,
        ...checkAllAppointments(taskTree),
      };
    }
    if (categoryId === "1") {
      taskFieldValue["0-1"] = {
        checked: true,
        partialChecked: false,
      };
      taskFieldValue["0"] = {
        checked: false,
        partialChecked: true,
      };
      taskFieldValue = {
        ...taskFieldValue,
        ...checkAllTodos(taskTree),
      };
    }
  }
  return taskFieldValue;
};


export  {
    onChipRemoval,
    taskTreeBuilder,
    chipGenerator,
    formatTaskMetaValue,
    formatTaskMetaValueToTaskFieldValue
}
