import cloneDeep from "lodash.clonedeep";
import {
  API_ADD_LEAD_WIDGET,
  API_DELETE_LEAD_WIDGET,
  API_GET_LEAD_ACTIONS_METADATA,
  API_GET_LEAD_CARD_META_DATA,
  API_GET_LEAD_FIELDS,
  API_GET_LEAD_PAGE_MENU_TABS,
  API_GET_LEAD_PAGE_WIDGETS,
  API_GET_LEAD_PAGE_WIDGET_METADATA,
  API_GET_LEAD_TYPES,
  API_GET_LEAD_WIDGET,
  API_POST_LEAD_CONTROLS,
  API_PUBLISH_LEAD_CONTROL,
  API_UNPUBLISH_LEAD_CONTROL,
  API_UPDATE_LEAD_ACTIONS,
  API_UPDATE_LEAD_CARD,
  API_UPDATE_LEAD_CONTROLS,
  API_UPDATE_LEAD_CONTROL_TEAM_ASSIGNMENT,
  API_UPDATE_LEAD_PAGE,
  API_UPDATE_LEAD_WIDGET,
} from "../api/configurationScreenServices";
import {
  globalConstants,
  leadControlsConstants,
  leadControlsWidgetConstants,
  toastMessageConstants,
} from "../constants/globalConstant";
import {
  findLeadWidgetsIndexInRowWithID,
  formatLeadActionConfig,
  formatLeadCardConfig,
  formatLeadPageConfig,
  formatLeadTabsWithIsCustom,
  formatSelectedLeadCard,
  removeWidgetFromRow,
} from "../utils/leadControlsUtils";
import {
  setLeadControlById,
  setLeadControls,
} from "./leadControlsAssignmentSlice";
const { createSlice, createAsyncThunk, nanoid } = require("@reduxjs/toolkit");

const initialState = {
  toastMessages: [],
  leadTypes: [],
  allLeadFields: [],
  leadWidgets: {
    widgetTemplates: {
      status: globalConstants.PENDING,
      list: [],
    },
    widgetMetaData: {},
  },
  leadWidgetConfiguration: {},
  selectedWidgetConfiguration: {
    metaData: {},
    metaValue: {},
  },
  leadPageMenus: {
    menus: [],
    selectedMenus: [],
  },
  allLeadActions: [],
  leadActions: {
    actions: [],
    selectedActions: [],
  },
  selectedLeadWidget: {
    mode: null,
    widgetTitle: null,
    widgetDetails: {},
  },
  leadCard: {
    leadFields: [],
    leadActions: [],
  },
  selectedLeadCard: {},
  widgetsLayout: [],
  isLeadTypeLoading: false,
  selectedLeadType: null,
  selectedLeadControl: null,
  isLeadConfigurationDirty: false,
  isLeadConfigSaveLoading: false,
  publish: {
    isPublishLoading: false,
    isPublished: false,
  },
};

export const getLeadTypes = createAsyncThunk(
  "leadControls/getLeadTypes",
  async (_, thunkAPI) => {
    const response = await API_GET_LEAD_TYPES();
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const getLeadFields = createAsyncThunk(
  "leadControls/getLeadFields",
  async (_, thunkAPI) => {
    const response = await API_GET_LEAD_FIELDS();
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const addLeadControls = createAsyncThunk(
  "leadControls/addLeadControls",
  async ({ postBody }, thunkAPI) => {
    const response = await API_POST_LEAD_CONTROLS(postBody);
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const { leadControlById } = thunkAPI.getState().leadControlsAssignment;
    const clonedleadControlById = cloneDeep(leadControlById);
    clonedleadControlById[data.id] = data;
    thunkAPI.dispatch(setLeadControlById(clonedleadControlById));
    return data;
  }
);

export const updateLeadControls = createAsyncThunk(
  "leadControls/updateLeadControls",
  async ({ leadControlId, postBody }, thunkAPI) => {
    const response = await API_UPDATE_LEAD_CONTROLS(leadControlId, postBody);
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const { leadTypes, leadCard, allLeadFields } =
      thunkAPI.getState().leadControls;
    const { leadControlById } = thunkAPI.getState().leadControlsAssignment;
    const { leadTypeId } = data;
    const clonedleadControlById = cloneDeep(leadControlById);
    clonedleadControlById[data.id] = data;
    let leadFields = leadCard.leadFields;
    if (postBody.leadTypeId) {
      const selectedLeadType = leadTypes?.find(
        (leadType) => leadType.LeadTypeId === leadTypeId
      );
      leadFields =
        selectedLeadType?.LeadTypeId !== "default"
          ? allLeadFields.filter((field) =>
              selectedLeadType?.FieldConfiguration?.split(", ").includes(
                field.schemaName
              )
            )
          : allLeadFields;
      thunkAPI.dispatch(resetLeadControlsConfigurations());
    }
    thunkAPI.dispatch(setLeadControlById(clonedleadControlById));
    return { data, leadFields };
  }
);

export const getLeadCardMetaData = createAsyncThunk(
  "leadControls/getLeadCardMetaData",
  async (_, thunkAPI) => {
    const response = await API_GET_LEAD_CARD_META_DATA();
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const updateLeadCard = createAsyncThunk(
  "leadControls/updateLeadCard",
  async ({ leadControlId, cardId, postBody }, thunkAPI) => {
    const response = await API_UPDATE_LEAD_CARD(
      leadControlId,
      cardId,
      postBody
    );
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const getLeadPageMenuTabs = createAsyncThunk(
  "leadControls/getLeadPageMenuTabs",
  async (_, thunkAPI) => {
    const response = await API_GET_LEAD_PAGE_MENU_TABS();
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const getAllLeadPageWidgets = createAsyncThunk(
  "leadControls/getAllLeadPageWidgets",
  async (_, thunkAPI) => {
    const response = await API_GET_LEAD_PAGE_WIDGETS();
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const getLeadPageWidgetMetaData = createAsyncThunk(
  "leadControls/getLeadPageWidgetMetaData",
  async (widgetType, thunkAPI) => {
    const { leadWidgets } = thunkAPI.getState().leadControls;
    const { widgetMetaData } = leadWidgets;
    if (widgetMetaData[widgetType])
      return { _widgetMetaData: widgetMetaData, widgetIdentifier: widgetType };
    const metadataResponse = await API_GET_LEAD_PAGE_WIDGET_METADATA(
      widgetType
    );
    const { data, status } = metadataResponse;
    if (status !== 200) {
      const errorMessage =
        metadataResponse.message ||
        toastMessageConstants.FAILED_TO_FETCH_METADATA;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const _widgetMetaData = { ...widgetMetaData };
    _widgetMetaData[widgetType] = data;
    return { _widgetMetaData, widgetIdentifier: widgetType };
  }
);

export const getLeadPageWidgetMetaValue = createAsyncThunk(
  "leadControls/getLeadPageWidgetMetaValue",
  async ({ pageId, widgetId }, thunkAPI) => {
    const { leadWidgetConfiguration } = thunkAPI.getState().leadControls;
    if (leadWidgetConfiguration[widgetId]) {
      return thunkAPI.fulfillWithValue(leadWidgetConfiguration[widgetId]);
    }
    const response = await API_GET_LEAD_WIDGET(pageId, widgetId);
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const addLeadWidget = createAsyncThunk(
  "leadControls/addLeadWidget",
  async ({ pageId, postbody }, thunkAPI) => {
    const response = await API_ADD_LEAD_WIDGET(pageId, postbody);
    const { status, data } = response;
    const { widgetsLayout } = thunkAPI.getState().leadControls;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const clonedLayout = cloneDeep(widgetsLayout);
    const detailedView =
      data.config.widgetStyle === leadControlsWidgetConstants.DETAILED;
    clonedLayout.push({
      rowId: `row-${nanoid()}`,
      detailedView: detailedView,
      widgets: [data.id],
    });
    thunkAPI.dispatch(updateLeadWidgtesLayout(clonedLayout));
    return data;
  }
);

export const updateLeadWidget = createAsyncThunk(
  "leadControls/updateLeadWidget",
  async ({ pageId, widgetId, postbody }, thunkAPI) => {
    const response = await API_UPDATE_LEAD_WIDGET(pageId, widgetId, postbody);
    const { status, data } = response;
    const { widgetsLayout } = thunkAPI.getState().leadControls;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const clonedLayout = cloneDeep(widgetsLayout);
    const detailedView =
      data.config.widgetStyle === leadControlsWidgetConstants.DETAILED;
    const rowAndWidgetIndex = findLeadWidgetsIndexInRowWithID(
      data.id,
      clonedLayout
    );
    if (widgetsLayout[rowAndWidgetIndex[0]].detailedView != detailedView) {
      if (detailedView) {
        const newLayout = removeWidgetFromRow(clonedLayout, rowAndWidgetIndex);
        newLayout.push({
          rowId: `row-${nanoid()}`,
          detailedView: detailedView,
          widgets: [data.id],
        });
        thunkAPI.dispatch(updateLeadWidgtesLayout(newLayout));
      } else {
        clonedLayout.splice(rowAndWidgetIndex[0], 1);
        clonedLayout.splice(rowAndWidgetIndex[0], 0, {
          ...widgetsLayout[rowAndWidgetIndex[0]],
          detailedView: detailedView,
        });
        thunkAPI.dispatch(updateLeadWidgtesLayout(clonedLayout));
      }
    }
    return data;
  }
);

export const deleteLeadWidget = createAsyncThunk(
  "leadControls/deleteLeadWidget",
  async ({ pageId, widgetId }, thunkAPI) => {
    const response = await API_DELETE_LEAD_WIDGET(pageId, widgetId);
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const { widgetsLayout } = thunkAPI.getState().leadControls;
    const clonedLayout = cloneDeep(widgetsLayout);
    const rowAndWidgetIndex = findLeadWidgetsIndexInRowWithID(
      widgetId,
      clonedLayout
    );
    const [row] = clonedLayout.splice(rowAndWidgetIndex[0], 1);
    const widgets = row.widgets;
    if (widgets.length !== 1) {
      widgets.splice(rowAndWidgetIndex[1], 1);
      clonedLayout.splice(rowAndWidgetIndex[0], 0, {
        ...row,
        widgets: widgets,
      });
    }
    thunkAPI.dispatch(updateLeadWidgtesLayout(clonedLayout));
    thunkAPI.dispatch(resetLeadWidgetConfiguration());
    return widgetId;
  }
);

export const updateLeadPage = createAsyncThunk(
  "leadControls/updateLeadPage",
  async ({ leadControlId, pageId, postBody }, thunkAPI) => {
    const response = await API_UPDATE_LEAD_PAGE(
      leadControlId,
      pageId,
      postBody
    );
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const getLeadActionsMetaValue = createAsyncThunk(
  "leadControls/getLeadActionsMetaValue",
  async (_, thunkAPI) => {
    const response = await API_GET_LEAD_ACTIONS_METADATA();
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const updateLeadActions = createAsyncThunk(
  "leadControls/updateLeadActions",
  async ({ leadControlId, actionId, postBody }, thunkAPI) => {
    const response = await API_UPDATE_LEAD_ACTIONS(
      leadControlId,
      actionId,
      postBody
    );
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const saveLeadCard = createAsyncThunk(
  "leadControls/saveLeadCard",
  async (_, thunkAPI) => {
    const { selectedLeadControl, selectedLeadCard, isLeadConfigurationDirty } =
      thunkAPI.getState().leadControls;
    if (
      isLeadConfigurationDirty &&
      selectedLeadControl?.leadCard?.config?.title
    ) {
      thunkAPI.dispatch(
        updateLeadCard(
          formatLeadCardConfig(selectedLeadControl, selectedLeadCard)
        )
      );
    }
  }
);

export const saveLeadPage = createAsyncThunk(
  "leadControls/saveLeadPage",
  async (_, thunkAPI) => {
    const {
      selectedLeadControl,
      widgetsLayout,
      leadPageMenus,
      isLeadConfigurationDirty,
    } = thunkAPI.getState().leadControls;
    const configuaredWidgets = widgetsLayout.length !== 0;
    const configuaredMenus = leadPageMenus.selectedMenus.length !== 0;
    if (isLeadConfigurationDirty && (configuaredWidgets || configuaredMenus)) {
      thunkAPI.dispatch(
        updateLeadPage(
          formatLeadPageConfig(
            selectedLeadControl,
            widgetsLayout,
            leadPageMenus
          )
        )
      );
    }
  }
);

export const saveLeadActions = createAsyncThunk(
  "leadControls/saveLeadActions",
  async (_, thunkAPI) => {
    const { selectedLeadControl, leadActions, isLeadConfigurationDirty } =
      thunkAPI.getState().leadControls;
    const configuaredLeadActions = leadActions.selectedActions.length !== 0;
    if (isLeadConfigurationDirty && configuaredLeadActions) {
      thunkAPI.dispatch(
        updateLeadActions(
          formatLeadActionConfig(selectedLeadControl, leadActions)
        )
      );
    }
  }
);

export const publishLeadControl = createAsyncThunk(
  "leadControls/publishLeadControl",
  async ({ leadControlId, postBody }, thunkAPI) => {
    const response = await API_PUBLISH_LEAD_CONTROL(leadControlId, postBody);
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const { addTeamsLeadControlConfigs, leadControl } = data;
    const { orgCode } = thunkAPI.getState().homepage.user;
    const { leadControls, leadControlById } =
      thunkAPI.getState().leadControlsAssignment;
    const clonedLeadControls = cloneDeep(leadControls);
    const clonedleadControlById = cloneDeep(leadControlById);
    const _leadControl = cloneDeep(leadControl);
    delete _leadControl.leadCard;
    delete _leadControl.leadPage;
    delete _leadControl.leadAction;
    clonedleadControlById[leadControl.id] = _leadControl;
    let defaultAddLeadControl = false;
    if (addTeamsLeadControlConfigs?.length > 0) {
      const addTeamIds = addTeamsLeadControlConfigs.map((obj) => obj.teamId);
      defaultAddLeadControl = addTeamIds.includes(orgCode);
    }
    if (defaultAddLeadControl)
      clonedLeadControls.defaultLeadControls.push(
        ...addTeamsLeadControlConfigs
      );
    else
      clonedLeadControls.teamLeadControls.push(...addTeamsLeadControlConfigs);
    thunkAPI.dispatch(setLeadControls(clonedLeadControls));
    thunkAPI.dispatch(setLeadControlById(clonedleadControlById));
    return data;
  }
);

export const unpublishLeadControl = createAsyncThunk(
  "leadControls/unpublishLeadControl",
  async ({ leadControlId }, thunkAPI) => {
    const response = await API_UNPUBLISH_LEAD_CONTROL(leadControlId);
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const { leadControls, leadControlById } =
      thunkAPI.getState().leadControlsAssignment;
    const clonedleadControlById = cloneDeep(leadControlById);
    clonedleadControlById[data.id] = data;
    let clonedLeadControls = cloneDeep(leadControls);
    const _defaultLeadControl = clonedLeadControls.defaultLeadControls?.filter(
      (obj) => obj.leadControlId !== leadControlId
    );
    const _teamLeadControls = clonedLeadControls.teamLeadControls?.filter(
      (obj) => obj.leadControlId !== leadControlId
    );
    thunkAPI.dispatch(
      setLeadControls({
        defaultLeadControls: _defaultLeadControl,
        teamLeadControls: _teamLeadControls,
      })
    );
    thunkAPI.dispatch(setLeadControlById(clonedleadControlById));
    return data;
  }
);

export const updateTeamAssignmentForLeadControl = createAsyncThunk(
  "leadControls/updateTeamAssignmentForLeadControl",
  async ({ leadControlId, postBody }, thunkAPI) => {
    const response = await API_UPDATE_LEAD_CONTROL_TEAM_ASSIGNMENT(
      leadControlId,
      postBody
    );
    const { status, data } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const {
      addTeamsLeadControlConfigs,
      deleteTeamsLeadControlsConfigs,
      leadControl,
    } = data;
    const { orgCode } = thunkAPI.getState().homepage.user;
    const { leadControls } = thunkAPI.getState().leadControlsAssignment;
    let clonedLeadControls = cloneDeep(leadControls);

    let defaultAddLeadControl = false;
    let defaultDeleteLeadControl = false;
    if (deleteTeamsLeadControlsConfigs?.length > 0) {
      const deleteTeamIds = deleteTeamsLeadControlsConfigs.map(
        (obj) => obj.teamId
      );
      defaultDeleteLeadControl = deleteTeamIds.includes(orgCode);
      if (defaultDeleteLeadControl) {
        const _defaultLeadControl =
          clonedLeadControls.defaultLeadControls?.filter(
            (obj) => obj.leadControlId !== leadControl.id
          );
        clonedLeadControls = {
          ...clonedLeadControls,
          defaultLeadControls: _defaultLeadControl,
        };
      } else {
        const _teamLeadControls = clonedLeadControls.teamLeadControls?.filter(
          (obj) =>
            !deleteTeamIds.includes(obj.teamId) ||
            obj.leadControlId !== leadControl.id
        );
        clonedLeadControls = {
          ...clonedLeadControls,
          teamLeadControls: _teamLeadControls,
        };
      }
    }
    if (addTeamsLeadControlConfigs?.length > 0) {
      const addTeamIds = addTeamsLeadControlConfigs.map((obj) => obj.teamId);
      defaultAddLeadControl = addTeamIds.includes(orgCode);
      if (defaultAddLeadControl)
        clonedLeadControls.defaultLeadControls.push(
          ...addTeamsLeadControlConfigs
        );
      else
        clonedLeadControls.teamLeadControls.push(...addTeamsLeadControlConfigs);
    }
    thunkAPI.dispatch(setLeadControls(clonedLeadControls));
    return leadControl;
  }
);

const leadControlsSlice = createSlice({
  name: "leadControls",
  initialState,
  reducers: {
    addToastMessageFromLeadControls(state, action) {
      state.toastMessages.push(action.payload);
    },
    resetToastMessagesFromLeadControls(state) {
      state.toastMessages.shift();
    },
    changeSelectedLeadType(state, action) {
      state.selectedLeadType = action.payload;
    },
    changeSelectedLeadControl(state, action) {
      const {
        selectedLeadControl,
        leadFields,
        widgetsLayout,
        selectedMenus,
        leadActions,
      } = action.payload;
      state.selectedLeadControl = selectedLeadControl;
      state.selectedLeadCard = formatSelectedLeadCard(
        selectedLeadControl.leadCard.config
      );
      state.leadCard.leadFields = leadFields;
      state.widgetsLayout = widgetsLayout;
      state.leadPageMenus.selectedMenus = selectedMenus;
      state.leadActions = leadActions;
      state.publish.isPublished =
        selectedLeadControl.status === leadControlsConstants.PUBLISHED
          ? true
          : false;
    },
    updateLeadWidgtesLayout(state, action) {
      state.widgetsLayout = action.payload;
      state.isLeadConfigurationDirty = true;
    },
    changeSelectedLeadWidget(state, action) {
      const { mode, widgetTitle, widgetDetails } = action.payload;
      state.selectedLeadWidget.mode = mode;
      state.selectedLeadWidget.widgetTitle = widgetTitle;
      state.selectedLeadWidget.widgetDetails = widgetDetails;
    },
    createLeadWidgetDefinition(state, action) {
      state.selectedWidgetConfiguration = {
        metaData: action.payload.metaData,
        metaValue: {},
      };
    },
    updateLeadWidgetDefinition(state, action) {
      state.selectedWidgetConfiguration = {
        metaData: action.payload.metaData,
        metaValue: action.payload.metaValue,
      };
    },
    changeSelectedLeadPageMenu(state, action) {
      state.leadPageMenus.selectedMenus = action.payload;
      state.isLeadConfigurationDirty = true;
    },
    changeLeadActions(state, action) {
      state.leadActions = action.payload;
      state.isLeadConfigurationDirty = true;
    },
    changeSelectedLeadCard(state, action) {
      state.selectedLeadCard = action.payload;
    },
    resetLeadWidgetConfiguration(state) {
      state.selectedLeadWidget = {
        mode: null,
        widgetTitle: null,
        widgetDetails: {},
      };
      state.selectedWidgetConfiguration = {
        metaData: {},
        metaValue: {},
      };
    },
    setLeadConfigurationDirty(state, action) {
      state.isLeadConfigurationDirty = action.payload;
    },
    resetLeadControlsConfigurations(state) {
      state.selectedLeadCard = {};
      state.widgetsLayout = [];
      state.isLeadConfigurationDirty = false;
      state.leadPageMenus.selectedMenus = [];
      state.leadActions = {
        actions: state.allLeadActions,
        selectedActions: [],
      };
      state.publish = initialState.publish;
    },
    resetSelectedLeadControls(state) {
      state.selectedLeadType = null;
      state.selectedLeadControl = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLeadTypes.fulfilled, (state, action) => {
      state.isLeadTypeLoading = false;
      state.leadTypes = action.payload;
    });
    builder.addCase(getLeadTypes.pending, (state) => {
      state.isLeadTypeLoading = true;
    });
    builder.addCase(getLeadTypes.rejected, (state, action) => {
      state.isLeadTypeLoading = false;
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
    builder.addCase(getLeadFields.fulfilled, (state, action) => {
      state.allLeadFields = action.payload;
    });
    builder.addCase(getLeadFields.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
    builder.addCase(getLeadCardMetaData.fulfilled, (state, action) => {
      const leadCardMetaData = action.payload;
      state.leadCard.leadActions = leadCardMetaData.cardActions;
    });
    builder.addCase(getLeadCardMetaData.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
    builder.addCase(updateLeadCard.fulfilled, (state, action) => {
      const { config } = action.payload;
      if (state.selectedLeadControl) {
        state.selectedLeadControl.leadCard = action.payload;
        state.selectedLeadCard.visibleCardActions = config.actions;
        state.selectedLeadCard.visibleCardFields = config.leadDetails;
        state.selectedLeadCard.isFieldsEnabledInLeadList =
          config.useLeadDetailsInListCard;
        state.selectedLeadCard.isActionsEnabledInLeadList =
          config.useActionsInListCard;
      }
      state.isLeadConfigurationDirty = false;
      state.isLeadConfigSaveLoading = false;
    });
    builder.addCase(updateLeadCard.pending, (state) => {
      state.isLeadConfigSaveLoading = true;
    });
    builder.addCase(updateLeadCard.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
      state.isLeadConfigSaveLoading = false;
    });
    builder.addCase(addLeadControls.fulfilled, (state, action) => {
      const allLeadFields = state.allLeadFields;
      const leadTypes = state.leadTypes;
      const { leadTypeId } = action.payload;
      const selectedLeadType = leadTypes?.find(
        (leadType) => leadType.LeadTypeId === leadTypeId
      );
      state.leadCard.leadFields =
        selectedLeadType?.LeadTypeId !== "default"
          ? allLeadFields.filter((field) =>
              selectedLeadType?.FieldConfiguration?.split(", ").includes(
                field.schemaName
              )
            )
          : allLeadFields;
      state.selectedLeadControl = action.payload;
    });
    builder.addCase(addLeadControls.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
    builder.addCase(updateLeadControls.fulfilled, (state, action) => {
      const { data, leadFields } = action.payload;
      state.selectedLeadControl = data;
      state.leadCard.leadFields = leadFields;
      state.publish.isPublished =
        data.status === globalConstants.PUBLISHED ? true : false;
    });
    builder.addCase(updateLeadControls.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
    builder.addCase(getAllLeadPageWidgets.fulfilled, (state, action) => {
      state.leadWidgets.widgetTemplates.list = action.payload.leadWidgets;
      state.leadWidgets.widgetTemplates.status = globalConstants.FULFILLED;
    });
    builder.addCase(getAllLeadPageWidgets.pending, (state) => {
      state.leadWidgets.widgetTemplates.status = globalConstants.PENDING;
    });
    builder.addCase(getAllLeadPageWidgets.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload,
      });
      state.leadWidgets.widgetTemplates.status = globalConstants.REJECTED;
    });
    builder.addCase(updateLeadPage.fulfilled, (state, action) => {
      if (state.selectedLeadControl) {
        state.selectedLeadControl.leadPage = action.payload;
      }
      state.isLeadConfigurationDirty = false;
      state.isLeadConfigSaveLoading = false;
    });
    builder.addCase(updateLeadPage.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload,
      });
      state.isLeadConfigSaveLoading = false;
    });
    builder.addCase(updateLeadPage.pending, (state) => {
      state.isLeadConfigSaveLoading = true;
    });
    builder.addCase(
      getLeadPageWidgetMetaData.fulfilled,
      (state, { payload }) => {
        const { _widgetMetaData, widgetIdentifier } = payload;
        state.selectedWidgetConfiguration.metaData =
          _widgetMetaData[widgetIdentifier];
        state.leadWidgets.widgetMetaData = _widgetMetaData;
      }
    );
    builder.addCase(getLeadPageWidgetMetaData.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload,
      });
    });
    builder.addCase(
      getLeadPageWidgetMetaValue.fulfilled,
      (state, { payload }) => {
        if (payload) {
          state.selectedWidgetConfiguration.metaValue = payload;
          state.leadWidgetConfiguration[payload.id] = payload;
        }
      }
    );
    builder.addCase(getLeadPageWidgetMetaValue.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload,
      });
    });
    builder.addCase(addLeadWidget.fulfilled, (state, action) => {
      const response = action.payload;
      state.leadWidgetConfiguration[response.id] = response;
    });
    builder.addCase(addLeadWidget.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload,
      });
    });
    builder.addCase(updateLeadWidget.fulfilled, (state, action) => {
      const response = action.payload;
      state.leadWidgetConfiguration[response.id] = response;
    });
    builder.addCase(updateLeadWidget.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload,
      });
    });
    builder.addCase(deleteLeadWidget.fulfilled, (state, action) => {
      const response = action.payload;
      const widgetConfiguration = state.leadWidgetConfiguration;
      delete widgetConfiguration[response];
      state.leadWidgetConfiguration = widgetConfiguration;
    });
    builder.addCase(deleteLeadWidget.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload,
      });
    });
    builder.addCase(getLeadPageMenuTabs.fulfilled, (state, action) => {
      const menus = action.payload;
      const systemMenuTabs = formatLeadTabsWithIsCustom(
        menus.systemMenuTabs,
        false
      );
      const customMenuTabs = formatLeadTabsWithIsCustom(
        menus.customMenuTabs,
        true
      );
      state.leadPageMenus.menus = [...systemMenuTabs, ...customMenuTabs];
    });
    builder.addCase(getLeadPageMenuTabs.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload,
      });
    });
    builder.addCase(getLeadActionsMetaValue.fulfilled, (state, action) => {
      const menus = action.payload;
      const systemLeadActions = formatLeadTabsWithIsCustom(
        menus.systemLeadActions,
        false
      );
      const customLeadActions = formatLeadTabsWithIsCustom(
        menus.customLeadActions,
        true
      );
      state.allLeadActions = [...systemLeadActions, ...customLeadActions];
      state.leadActions.actions = [...systemLeadActions, ...customLeadActions];
    });
    builder.addCase(getLeadActionsMetaValue.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload,
      });
    });
    builder.addCase(updateLeadActions.fulfilled, (state, action) => {
      state.isLeadConfigurationDirty = false;
      state.isLeadConfigSaveLoading = false;
      if (state.selectedLeadControl) {
        state.selectedLeadControl.leadAction = action.payload;
      }
    });
    builder.addCase(updateLeadActions.pending, (state) => {
      state.isLeadConfigSaveLoading = true;
    });
    builder.addCase(updateLeadActions.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload,
      });
      state.isLeadConfigSaveLoading = false;
    });
    builder.addCase(publishLeadControl.fulfilled, (state, action) => {
      state.publish.isPublishLoading = false;
      state.publish.isPublished = true;
      state.selectedLeadControl = action.payload.leadControl;
    });
    builder.addCase(publishLeadControl.pending, (state) => {
      state.publish.isPublishLoading = true;
    });
    builder.addCase(publishLeadControl.rejected, (state) => {
      state.publish.isPublishLoading = false;
    });
    builder.addCase(
      updateTeamAssignmentForLeadControl.fulfilled,
      (state, action) => {
        state.publish.isPublishLoading = false;
        state.publish.isPublished = true;
        state.selectedLeadControl = action.payload;
      }
    );
    builder.addCase(updateTeamAssignmentForLeadControl.pending, (state) => {
      state.publish.isPublishLoading = true;
    });
    builder.addCase(updateTeamAssignmentForLeadControl.rejected, (state) => {
      state.publish.isPublishLoading = false;
    });
  },
});

export const {
  addToastMessageFromLeadControls,
  resetToastMessagesFromLeadControls,
  changeSelectedLeadType,
  changeSelectedLeadControl,
  updateLeadWidgtesLayout,
  changeSelectedLeadWidget,
  resetLeadWidgetConfiguration,
  changeSelectedLeadPageMenu,
  changeLeadActions,
  changeSelectedLeadCard,
  setLeadConfigurationDirty,
  resetLeadControlsConfigurations,
  resetSelectedLeadControls,
} = leadControlsSlice.actions;

export default leadControlsSlice.reducer;
