import { useForm } from "react-hook-form";
import cloneDeep from "lodash.clonedeep";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as CrossIcon } from "../../../assets/svgIcons/lsq_close.svg";
import Text from "../../../components/Text";
import { leadControlsConstants } from "../../../constants/globalConstant";
import {
  listOptionTemplate,
  valueTemplate,
} from "../../../utils/dropdownUtils";
import { CustomDropdown } from "../../../components/CustomDropdown/CustomDropdown";
import CustomButton from "../../../components/CustomButton";
import CustomInput from "../../../components/CustomInput";
import { getFieldOptionBySchemaName } from "../../../utils/leadControlsUtils";

export default function LeadIdentifierForm({
  setState,
  onSubmitLeadIdentifierForm,
  availableFields,
}) {
  const { selectedLeadControl } = useSelector((state) => state.leadControls);
  const defaultValues = {
    firstA: null,
    firstB: null,
    second: null,
    third: null,
    fourth: leadControlsConstants.NO_NAME,
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
  } = useForm({ defaultValues: defaultValues });
  const watchAll = watch(["firstA", "firstB", "second", "third"]);
  let leadFieldOptions = cloneDeep(availableFields);

  const getLeadFieldOptions = (fieldName) => {
    const fieldValue = getValues(fieldName);
    let excludeFieldOptions = [];
    watchAll.map((option) => {
      option?.schemaName &&
        option?.schemaName !== fieldValue?.schemaName &&
        excludeFieldOptions.push(option?.schemaName);
    });
    return leadFieldOptions.filter(
      (field) => !excludeFieldOptions.includes(field?.schemaName)
    );
  };

  useEffect(() => {
    if (selectedLeadControl?.leadCard?.config?.title) {
      const identifierFields = selectedLeadControl?.leadCard?.config?.title;
      identifierFields.firstA &&
        setValue(
          "firstA",
          getFieldOptionBySchemaName(availableFields, identifierFields.firstA)
        );
      identifierFields.firstB &&
        setValue(
          "firstB",
          getFieldOptionBySchemaName(availableFields, identifierFields.firstB)
        );
      identifierFields.second &&
        setValue(
          "second",
          getFieldOptionBySchemaName(availableFields, identifierFields.second)
        );
      identifierFields.third &&
        setValue(
          "third",
          getFieldOptionBySchemaName(availableFields, identifierFields.third)
        );
      identifierFields.fourth && setValue("fourth", identifierFields.fourth);
    } else reset();
  }, [selectedLeadControl]);

  return (
    <div className="flex flex-column flex-start h-full p-3 gap-2">
      <div className={`flex w-full justify-content-between align-items-center`}>
        <Text color="var(--text-primary)" type="T1B">
          {leadControlsConstants.ADD_LEAD_IDENTIFIER}
        </Text>
        <CrossIcon
          data-testid="cross-icon"
          onClick={() => setState(false)}
          className="cursor-pointer"
        />
      </div>
      <div className="flex flex-column h-full overflow-y-auto gap-2">
        <Text color="var(--text-primary)" type="sub-heading">
          {leadControlsConstants.LEAD_IDENTIFIER_MESSAGE}
        </Text>
        <hr className="horizontal-divider" />
        <CustomDropdown
          fieldName="firstA"
          control={control}
          errors={errors}
          className="w-full text-base lsq-input"
          options={getLeadFieldOptions("firstA")}
          optionLabel="name"
          placeholder="Select"
          itemTemplate={(e) => listOptionTemplate(e, null, "displayName")}
          valueTemplate={(e) => valueTemplate(e, null, "displayName")}
          label={"First A"}
          isMandatory={true}
        />
        <CustomDropdown
          fieldName="firstB"
          control={control}
          errors={errors}
          className="w-full text-base lsq-input"
          options={getLeadFieldOptions("firstB")}
          optionLabel="name"
          placeholder="Select"
          itemTemplate={(e) => listOptionTemplate(e, null, "displayName")}
          valueTemplate={(e) => valueTemplate(e, null, "displayName")}
          label={"First B"}
          showClear
        />
        <hr className="horizontal-divider" />
        <CustomDropdown
          fieldName="second"
          control={control}
          errors={errors}
          className="w-full text-base lsq-input"
          options={getLeadFieldOptions("second")}
          optionLabel="name"
          placeholder="Select"
          itemTemplate={(e) => listOptionTemplate(e, null, "displayName")}
          valueTemplate={(e) => valueTemplate(e, null, "displayName")}
          label={"Second"}
          showClear
        />
        <CustomDropdown
          fieldName="third"
          control={control}
          errors={errors}
          className="w-full text-base lsq-input"
          options={getLeadFieldOptions("third")}
          optionLabel="name"
          placeholder="Select"
          itemTemplate={(e) => listOptionTemplate(e, null, "displayName")}
          valueTemplate={(e) => valueTemplate(e, null, "displayName")}
          label={"Third"}
          showClear
        />
        <CustomInput
          control={control}
          errors={errors}
          fieldName="fourth"
          maxLength={30}
          className="lsq-input-bold"
          label="Fourth"
          isMandatory={true}
          data-testid="fourth"
        />
        <div className="flex justify-content-end ">
          <CustomButton
            label={leadControlsConstants.ADD}
            varient="filled"
            onClick={handleSubmit(onSubmitLeadIdentifierForm)}
            data-testid="add-identifier-btn"
          ></CustomButton>
        </div>
      </div>
    </div>
  );
}
