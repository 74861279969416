import React from "react";
import { ColorPicker } from "primereact/colorpicker";
import { Controller } from "react-hook-form";
import Text from "../Text";
import { FormErrorMessage } from "../FormErrorMessage";
import styles from "./styles.module.css";
import "./index.css";
import { InputText } from "primereact/inputtext";

export default function CustomColorPicker({
  fieldName,
  control,
  label,
  errors,
  id,
  disabled,
  errorMessage,
  isMandatory,
  defaultColor,
  ...props
}) {
  let hexCodeRegex = /^#([0-9a-f]{3}){1,2}$/i;
  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{
        required:
          isMandatory && (errorMessage ? errorMessage : "Color is required."),
        validate: (value) => {
          if (!hexCodeRegex.test(value.trim()) && value.trim() !== "")
            return "Please enter a valid hex colour code";
        },
      }}
      render={({ field, fieldState: { error } }) => (
        <span className={`relative`}>
          <label
            className={`${styles["custom-dropdown-field-label"]} flex align-items-center`}
          >
            <Text type="T5" color={error ? "var(--R800)" : "var(--N90)"}>
              {label}
            </Text>
            {isMandatory && (
              <span style={{ color: "var(--R800)", fontSize: "0.8rem" }}>
                *
              </span>
            )}
          </label>
          <div
            className={`flex align-items-center gap-2 ${styles["custom-dropdown-input-Text"]}`}
          >
            <ColorPicker
              {...field}
              id={id}
              control={control}
              {...props}
              format="hex"
              className="lsq-color-picker"
              onChange={(e) => {
                field.onChange("#" + e.value);
              }}
              defaultColor={defaultColor}
              disabled={disabled}
            />
            <InputText
              className="colour-input"
              value={field.value}
              onChange={(e) => {
                field.onChange(e.target.value);
              }}
              disabled={disabled}
            />
          </div>
          {errors[fieldName] && (
            <FormErrorMessage message={errors[fieldName].message} />
          )}
        </span>
      )}
    />
  );
}
