import React from 'react'
import { Draggable, Droppable } from "react-beautiful-dnd"
import PropTypes from "prop-types";
import styles from "./styles.module.css"

export default function Row({ children, id, index }) {
  return (
    <Draggable draggableId={id} isDragDisabled={true} index={index}>
      {(provided) => {
        return <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={styles["row-drag"]}
        >
          <Droppable type="halfWidgets" droppableId={id} direction="horizontal">
            {(provided) => {
              return <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={styles["row-drop"]}
                >
                {children}
                {provided.placeholder}
              </div>
            }}
          </Droppable>
        </div>
      }}
    </Draggable>
  )
}

Row.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  children: PropTypes.array
}
