import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Text from "../../../components/Text";
import { QuickLauncherIcons } from "../../../assets/icons/QuickLauncherIcons";
import styles from "./styles.module.css";
import {
  changeSelectedLeadWidget,
  getLeadPageWidgetMetaValue,
} from "../../../reducers/leadControlsSlice";
import {
  globalConstants,
  leadControlsWidgetConstants,
} from "../../../constants/globalConstant";
import {
  fetchImageURL,
  getLeadWidgetTitlebyWidgetType,
} from "../../../utils/leadControlsUtils";
import { ReactComponent as DragIndicator } from "../../../assets/svgIcons/drag_indicator.svg";

export default function TileView({
  id,
  dragHandleProps,
  tabCount,
  isDragging,
  disablePointerEvents,
}) {
  const dispatch = useDispatch();
  const [imgUrl, setImgUrl] = useState({});
  const { leadWidgetConfiguration, selectedLeadWidget, selectedLeadControl } =
    useSelector((state) => state.leadControls);
  const widgetInformation = leadWidgetConfiguration[id];
  const widget = widgetInformation?.config;
  const isActiveWidget = selectedLeadWidget.widgetDetails.id === id;

  const setImageInPreview = async () => {
    if (!widget.image.imageURL) {
      const image = await fetchImageURL(widget.image);
      setImgUrl(image);
    } else {
      setImgUrl(widget.image);
    }
  };


  useEffect(() => {
    if (!widgetInformation) {
      dispatch(
        getLeadPageWidgetMetaValue({
          pageId: selectedLeadControl.leadPage.id,
          widgetId: id,
        })
      );
    }
    if (widget?.tileStyle === leadControlsWidgetConstants.IMAGE)
    setImageInPreview();
  }, [leadWidgetConfiguration]);


  if (!widgetInformation) return <></>;
  return (
    <div
      className={`flex gap-1 justify-content-center align-items-center relative z-2 w-full ${
        styles["tile-view"]
      } ${tabCount > 1 && "flex-column"} ${styles[`tab-count-${tabCount}`]} ${
        isActiveWidget && styles["active-widget"]
      } ${isDragging && styles["active-widget"]} ${
        disablePointerEvents && `pointer-events-none`
      } ${
        widget.tileStyle === leadControlsWidgetConstants.IMAGE && `h-5rem p-1`
      }`}
      onClick={() =>
        dispatch(
          changeSelectedLeadWidget({
            mode: globalConstants.UPDATE,
            widgetTitle: getLeadWidgetTitlebyWidgetType(
              widgetInformation.config.widgetType
            ),
            widgetDetails: widgetInformation,
          })
        )
      }
    >
      {widget.tileStyle === leadControlsWidgetConstants.IMAGE ? (
        <div className="flex w-full h-full overflow-hidden border-round">
          <img src={`${imgUrl?.imageURL}`} alt="img" className="flex w-full" />
        </div>
      ) : (
        <>
          <QuickLauncherIcons icon={widget.icon?.imgName} />
          <Text
            type="T4"
            color="#2F3237"
            className="pre-wrap-ellipsis word-break "
          >
            {widget.identifier}
          </Text>
        </>
      )}
      <div
        className={`flex justify-content-center w-full align-items-center absolute ${styles["drag-indicator"]}`}
        style={{ top: "-1rem" }}
        {...dragHandleProps}
      >
        <div className={`${styles["drag-indicator-background"]}`}>
          <DragIndicator style={{ transform: "rotate(90deg)" }} />
        </div>
      </div>
    </div>
  );
}
