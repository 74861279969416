import React from "react";
import styles from "./styles.module.css";
import { useSelector } from "react-redux";
import Text from "../../../components/Text";
import { QuickLauncherIcons } from "../../../assets/icons/QuickLauncherIcons";
import { leadControlsConstants } from "../../../constants/globalConstant";
import { ReactComponent as Cross } from "../../../assets/svgIcons/cross.svg";

export default function QuickActionsMobileTemplate() {
  const { leadActions } = useSelector((state) => state.leadControls);
  return (
    <div
      className={`flex flex-column p-3 ${styles["lead-quick-action-preview"]}`}
    >
      <div className={`flex w-full justify-content-center relative`}>
        <Text type="T1B">{leadControlsConstants.QUICK_ACTIONS}</Text>
        <div
          className={`flex w-full absolute justify-content-end cursor-pointer`}
        >
          <Cross />
        </div>
      </div>
      <div
        className={`flex flex-wrap p-1 w-full overflow-scroll pointer-events-auto`}
      >
        {leadActions.selectedActions?.map((field) => {
          return (
            <div className={`flex ${styles["quick-action-field"]}`} key={field.id}>
              <div
                className={`flex flex-column align-items-center justify-content-center relative gap-1 p-2 w-full`}
              >
                <QuickLauncherIcons
                  icon={field.icon?.imgName}
                  style={{
                    filter: "opacity(0.7)",
                    width: "1.2rem",
                  }}
                ></QuickLauncherIcons>
                <Text type="T3" className="ellipsis w-full text-center">
                  {field.name}
                </Text>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
