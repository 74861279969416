import React, { useEffect } from "react";
import ThemeBuilder from "./index.js";
import { useDispatch, useSelector } from "react-redux";
import { getTheme } from "../../reducers/themeBuilderSlice";
import Loader from "./Loader.js";
import { globalConstants } from "../../constants/globalConstant";
const ThemeBuilderMain = () => {
  const dispatch = useDispatch();
  const { theme } = useSelector((state) => state.themeBuilder);

  useEffect(() => {
    dispatch(getTheme());
  }, []);
  if (theme.responseStatus === globalConstants.PENDING) return <Loader />;
  return <ThemeBuilder />;
};

export default ThemeBuilderMain;
