import { themeConstants } from "../../../constants/globalConstant";
import { lsqPreDefinedThemes } from "../../../utils/themeUtils";

export const getDefalutThemeValues = (currentTheme) => {
  if (
    !(
      currentTheme?.response &&
      Array.isArray(currentTheme.response) &&
      currentTheme.response?.[0]
    )
  )
    return lsqPreDefinedThemes[0];
  const appliedColors = currentTheme.response?.[0];
  const temp = lsqPreDefinedThemes.find((e) => {
    return ["primaryColour", "secondaryColour", "neutralColour", "name"].every(
      (type) => {
        return e[type] === appliedColors[type];
      }
    );
  });
  return (
    temp || {
      name: themeConstants.CUSTOM,
      primaryColour: appliedColors.primaryColour,
      secondaryColour: appliedColors.secondaryColour,
      neutralColour: appliedColors.neutralColour,
    }
  );
};
export const getIsPreviewPaneDirty = (colorPalette = {}, currentTheme = {}) => {
  const appliedColors = currentTheme.response?.[0];
  if (!appliedColors) return false;
  return !["primaryColour", "secondaryColour", "neutralColour", "name"].every(
    (e) => colorPalette[e] === appliedColors[e]
  );
};
