import React, { useState } from "react";
import LeadDetailsPreview from "./LeadDetailsPreview";
import { ReactComponent as Previous } from "../../../assets/svgIcons/back.svg";
import CustomButton from "../../../components/CustomButton";
import styles from "./styles.module.css";
import LeadListPreview from "./LeadListPreview";

export default function MobilePreview({ state }) {
  const items = [
    { item: <LeadListPreview />, label: "lead-list" },
    { item: <LeadDetailsPreview />, label: "lead-details" },
    {
      item: <LeadDetailsPreview showQuickActions={true} />,
      label: "lead-quick-actions",
    },
  ];
  const [activeItemIndex, setActiveItemIndex] = useState(1);
  return (
    state && (
      <div
        className="flex h-full w-full justify-content-center align-items-center absolute ml-7 z-3"
        style={{ background: "#2F3237" }}
      >
        <CustomButton
          varient="text"
          icon={<Previous style={{ width: "0.4rem" }} />}
          disabled={activeItemIndex === 0 || items.length === 0}
          label={""}
          onClick={() => {
            if (activeItemIndex === 0) return;
            setActiveItemIndex((prev) => prev - 1);
          }}
          data-testid="left-btn"
        />
        <div className={`${styles["lsq-slider"]}`}>
          {items?.map(({item,label}, index) => {
            return (
              <div
                className={`${styles["slide"]} ${
                  activeItemIndex === index && styles["active-slide"]
                } ${activeItemIndex > index && styles["prev-slides"]} ${
                  activeItemIndex < index && styles["next-slides"]
                }`}
                key={label}
              >
                {item}
              </div>
            );
          })}
        </div>
        <CustomButton
          varient="text"
          disabled={activeItemIndex === items.length - 1 || items.length === 0}
          icon={
            <Previous style={{ transform: "rotate(180deg)", width: "0.4rem" }} />
          }
          onClick={() => {
            if (activeItemIndex === items.length - 1) return;
            setActiveItemIndex((prev) => prev + 1);
          }}
          label={""}
          data-testid="right-btn"
        />
      </div>
    )
  );
}
