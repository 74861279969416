import React from "react";
import styles from "./styles.module.css";
import { ReactComponent as CrossIcon } from "../../assets/svgIcons/cross.svg";
import { ReactComponent as PublishIcon } from "../../assets/svgIcons/publish.svg";
import Text from "../../components/Text";
import CustomButton from "../../components/CustomButton";
import { ReactComponent as TickMark } from "../../assets/svgIcons/lsq_tick.svg";

export default function PublishView({
  published,
  publishViewIcon,
  publishViewTitle,
  publishSuccessBoxIcon,
  publishSuccessBoxTitle,
  publishSuccessBoxMessage,
  onPublishClicked,
  isPublishDisabled,
  onDismissPublishView,
  children,
}) {
  return !published ? (
    <div
      className={`absolute flex flex-column ${styles["publish-box"]}`}
      data-testid="publish-view"
    >
      <div
        className={`flex align-items-center justify-content-between ${styles["title-bar"]}`}
      >
        <div className="flex align-items-center flex-row gap-2">
          {publishViewIcon}
          <Text color="#314361" type="T1B">
            {publishViewTitle}
          </Text>
        </div>
        <CrossIcon
          data-testid="cross-icon"
          onClick={onDismissPublishView}
          style={{ cursor: "pointer", fill: "#314361" }}
        />
      </div>
      <div
        className={`flex flex-column justify-content-start h-full overflow-y-scroll ${styles["publish-content"]}`}
      >
        {children}
      </div>
      <div className="flex justify-content-end pb-4 pr-4 pt-2">
        <CustomButton
          varient="filled"
          icon={<PublishIcon />}
          label={"Publish"}
          data-testid="publish-btn"
          onClick={onPublishClicked}
          disabled={isPublishDisabled()}
        />
      </div>
    </div>
  ) : (
    <div
      className={`absolute flex flex-column ${styles["publish-box"]} ${styles["success-box"]}`}
    >
      <div
        className={`flex align-items-center flex-row gap-2 justify-content-start ${styles["title-bar"]}`}
      >
        {publishSuccessBoxIcon}
        <Text type="heading">{publishSuccessBoxTitle}</Text>
      </div>
      <div className="flex flex-column justify-content-center align-items-center h-full w-full gap-3">
        <TickMark></TickMark>
        <Text type="T1B">{publishSuccessBoxMessage}</Text>
      </div>
    </div>
  );
}
