import React from "react";
import styles from "./styles.module.css";
import Text from "../../components/Text";
import CustomButton from "../../components/CustomButton";
import { ReactComponent as Add } from "../../assets/svgIcons/lsq_add.svg";
import ItemAddList from "../../components/ItemAddList";
import { useDispatch, useSelector } from "react-redux";
import useToast from "../../hooks/useToast";
import { getCustomMenuTabs } from "../../utils/menuBuilderUtils";
import ConfirmDialog from "../../components/Modal/ConfirmDialog";
import { ReactComponent as DeleteIllustration } from "../../assets/illustrations/delete_illustration.svg";
import {
  apiDeleteCustomMenuTab,
  addToastMessage,
  setCicoCustomisation,
} from "../../reducers/menuBuilderSlice";
import {
  globalConstants,
  menuBuilderConstants,
  toastMessageConstants,
} from "../../constants/globalConstant";

export default function MenuTab({
  leftSideLabel,
  leftSideItems,
  rightSideLabel,
  rightSideItems,
  hasCreateNewSection = false,
  setCreateNewTab,
  onCustomMenuTabAdd,
  setDialog,
  navigationTabs,
  customTabs,
  hasCICOConfigurationSection = false,
}) {
  const dispatch = useDispatch();
  const toast = useToast(addToastMessage);
  const { cicoCustomisation } = useSelector((state) => state.menuBuilder);

  const deleteCustomTab = (item) => {
    dispatch(apiDeleteCustomMenuTab({ tabId: item.tabId })).then(({ type }) => {
      if (type.includes(globalConstants.FULFILLED)) {
        toast.success(
          toastMessageConstants.CUSTOM_MENU_TAB_DELETED_SUCCESSFULLY
        );
      } else toast.error(toastMessageConstants.SOMETHING_WENT_WRONG);
    });
  };

  const onCustomTabDeletion = (_, item) => {
    if (navigationTabs?.find((tab) => tab.tabId === item.tabId)) {
      toast.error(toastMessageConstants.TAB_IS_CURRENTLY_BEING_USED);
    } else {
      setDialog({
        state: true,
        dialogBox: (
          <ConfirmDialog
            setState={setDialog}
            illustration={<DeleteIllustration />}
            buttonLabel={"Delete"}
            displayText={
              <>
                <Text type="heading" className={`capitalize`} color="#0A1F43">
                  {`Delete ${item.name}`}
                </Text>
                <Text type="sub-heading" color="#0A1F43">
                  {`Are you sure you want to Delete ${item.name}?`}
                </Text>
              </>
            }
            clickListener={(e) => {
              deleteCustomTab(item);
              setDialog({ state: false, dialogBox: {} });
              e.stopPropagation();
            }}
          />
        ),
      });
    }
  };

  const onCustomTabUpdation = (_, item) => {
    setCreateNewTab({
      state: true,
      data: {
        edit: true,
        tabId: item.tabId,
      },
    });
  };

  const openCustomMenuDialog = () => {
    setCreateNewTab({ state: true, data: null });
  };

  return (
    <div
      className="flex h-full w-full justify-content-between align-items-center"
      data-testid="menu-tab"
    >
      <div className="flex h-full w-full flex-column p-3 gap-3 overflow-scroll">
        <div className="flex w-full flex-column gap-3">
          <div className="flex justify-content-center align-items-center">
            <Text type="heading" color="var(--text-primary)">
              {leftSideLabel}
            </Text>
          </div>
          <div className="flex flex-column w-full">{leftSideItems}</div>
        </div>
        {hasCreateNewSection && (
          <div className="flex w-full flex-column gap-3">
            <div className="flex justify-content-center align-items-center">
              <Text type="heading" color="var(--text-primary)">
                {menuBuilderConstants.CUSTOM_MENU_TABS}
              </Text>
            </div>
            <div className="flex flex-column w-full">
              <CustomButton
                icon={<Add></Add>}
                varient="outline"
                label={menuBuilderConstants.CREATE_NEW_TAB}
                onClick={openCustomMenuDialog}
                data-testid="create-new-tab"
                id="create_new"
              ></CustomButton>
            </div>
            <ItemAddList
              items={getCustomMenuTabs(customTabs)}
              onAddItemClick={onCustomMenuTabAdd}
              hasDeletebutton={true}
              onDeleteIconClicked={onCustomTabDeletion}
              hasEditbutton={true}
              onEditIconClicked={onCustomTabUpdation}
              id={"custom-tab-item"}
            ></ItemAddList>
          </div>
        )}
      </div>
      <hr className={`${styles["vertical-divider"]}`}></hr>
      <div className="flex h-full w-full flex-column p-3 gap-3 overflow-scroll">
        <div className="flex justify-content-center align-items-center">
          <Text type="heading" color="var(--text-primary)">
            {rightSideLabel}
          </Text>
        </div>
        <div className="flex flex-column w-full h-full gap-1">
          {rightSideItems}
        </div>
        {hasCICOConfigurationSection && (
          <CustomButton
            varient="filled"
            label={menuBuilderConstants.CHECKIN_CUSTOMISATIONS}
            onClick={() => {
              dispatch(
                setCicoCustomisation({
                  ...cicoCustomisation,
                  showCustomisation: true,
                })
              );
            }}
          />
        )}
      </div>
    </div>
  );
}
