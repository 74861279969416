import React from 'react'
import SmartViewCounterView from './SmartViewCounterView'

const SmartViewCounterFactory = ({ widgetInformation }) => {
  const { config } = widgetInformation
  return (
    <SmartViewCounterView title={config.title} smartViewTab = {config.smartViewTab}/>
  )
}

export default SmartViewCounterFactory