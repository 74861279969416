import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";
import { RadioButton } from "primereact/radiobutton";
import Text from "../Text";
import { Controller } from "react-hook-form";
import "./index.css";
import { themeConstants } from "../../constants/globalConstant";

export default function CustomThemeRadioGroup({
  option,
  control,
  radioName,
  onChange,
  ...props
}) {
  return (
    <Controller
      name={radioName}
      control={control}
      render={({ field }) => {
        return (
          <div
            className={`flex justify-content-between align-items-center ${styles["radio-group"]}`}
          >
            <div className="flex flex-column gap-1">
              <Text type="T2B" color="#627087" className="capitalize">
                {option.name}
              </Text>
              {option.name !== themeConstants.CUSTOM && (
                <div className="flex gap-1 ">
                  {["primaryColour", "secondaryColour", "neutralColour"]?.map(
                    (color) => {
                      return (
                        <div
                          key={color}
                          className="flex p-3 border-round"
                          style={{ backgroundColor: option[color] }}
                        ></div>
                      );
                    }
                  )}
                </div>
              )}
            </div>
            <RadioButton
              className="lsq-radio"
              name={radioName}
              value={field.value}
              {...field}
              {...props}
              onChange={() => {
                onChange && onChange(option);
              }}
              checked={field.value === option.name}
            />
          </div>
        );
      }}
    />
  );
}

CustomThemeRadioGroup.propTypes = {
  option: PropTypes.object,
  radioName: PropTypes.string,
  control: PropTypes.object,
};
