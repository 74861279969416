import { Carousel } from "primereact/carousel";
import { useEffect, useState } from "react";
import {
  bannerSettingsConstants,
  widgetConstants,
} from "../../constants/globalConstant";
import { fetchImageLinks } from "../../utils/bannerUtils";
import "./index.css";
import Text from "../Text";
export const CustomCarousel = ({ configData }) => {
  const { displayType, items , displayStyle } = configData.config;
  const [imgUrls, setImgUrls] = useState([]);
  const autoPlay = items?.length > 1? 3000 : 864000000 // Library issue:  We are setting timer to 10 day in milliseconds if there is a single image

  const itemTemplate = (imgUrl) => {
    const isExpired =
      new Date().getTime() > new Date(imgUrl.endDate).getTime() &&
      imgUrl.endDate;
    return (
      <div
        className={`flex justify-content-center align-items-center ${displayType}`}
      >
        <img
          src={`${imgUrl.croppedImageURL}`}
          onError={(e) =>
            (e.target.src = `${
              displayType === bannerSettingsConstants.WIDE
                ? bannerSettingsConstants.DEMO_WIDE_IMAGE
                : bannerSettingsConstants.DEMO_SQUARE_IMAGE
            }`)
          }
          alt="Banner-Image"
          className="product-image"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: `${
              displayStyle === widgetConstants.EDGE_TO_EDGE ? "0px" : "12px"
            }`,
          }}
        />
        {isExpired && (
          <div
            className={`flex justify-content-center align-items-center w-full absolute ${"expired-stamp"}`}
          >
            <Text type="heading" color="var(--N0)">
              {bannerSettingsConstants.IMAGE_EXPIRED}
            </Text>
          </div>
        )}
      </div>
    );
  };

  const setImagesInCarousel = async () => {
    if (items?.length > 0 && !items[0].croppedImageURL) {
      const images = await fetchImageLinks(items);
      setImgUrls(images);
    } else {
      setImgUrls(items);
    }
  };

  useEffect(() => {
    setImagesInCarousel();
  }, [items]);
  return (
    <div className={`carousel-container`}>
      <Carousel
        value={imgUrls}
        itemTemplate={itemTemplate}
        autoplayInterval={autoPlay}
      />
    </div>
  );
};
