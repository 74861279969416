import { API_DELETE_IMAGES, API_GET_SIGNED_URL } from "../api/configurationScreenServices";
import { bannerSettingsConstants } from "../constants/globalConstant";

export const generateWidgetMetaValue = (config , widgetId) => {
  const widgetMetaValue = {
    widget: {
      widgetId: widgetId,
      config: {
        displayType: config.displayType,
        displayStyle: config.displayStyle,
        items: config.items,
      },
    },
  };
  return widgetMetaValue;
};

export const formatItems = (items) => {
    const formattedItems = [];
    for (let item of items) {
      formattedItems.push({
        imageKey: item.imageKey,
        imageName: item.imageName,
        navigateTo: item.navigateTo,
        internalLink: item.internalLink,
        externalLink: item.externalLink,
        externalLinkOptions: item.externalLinkOptions,
        endDate: item.endDate && new Date(item.endDate).toISOString(),
      });
    }
    return formattedItems;
};

export const checkIsSaveDisabled = (fields) => {
    if (fields.length === 0) return true;
    for (let bannerElement of fields) {
      if (bannerElement.uploadStatus !== bannerSettingsConstants.SUCCESS)
        return true;
    }
    return false;
  };


  export const fetchImageLinks = async (items) => {
    let imageKeys = [];
    for (let item of items) {
      imageKeys.push(API_GET_SIGNED_URL(item.imageKey));
    }
    const imageSignedUrlResponse = await Promise.all(imageKeys);
    let images = [];

    imageSignedUrlResponse.forEach((imageSignedUrl, index) => {
      images.push({
        croppedImageURL: imageSignedUrl.data.url,
        imageName: items[index].imageName,
        navigateTo: items[index].navigateTo,
        endDate: items[index].endDate,
        internalLink: items[index].internalLink,
        externalLink: items[index].externalLink,
        externalLinkOptions: items[index].externalLinkOptions,
      });
    });
    return images;
  };


  export const getImageKeys = (elementList) => {
    let imageKeys = [];
    for (let element of elementList){
      imageKeys.push(element.imageKey)
    }
    return imageKeys
  }

  export const deleteImagesFromS3 = (imageKeys) => {
    if(imageKeys.length === 0) return;
    let keys = "";
    imageKeys.forEach((key , index) => {
      keys += String(key)
      if(index !== imageKeys.length - 1){
        keys += ","
      }
    })
    API_DELETE_IMAGES(keys)
  }
