import React from "react";
import { ReactComponent as Edit } from "../../assets/svgIcons/lsq_edit.svg";
import { ReactComponent as Cross } from "../../assets/svgIcons/cross.svg";
import Text from "../../components/Text";

export default function PreviewDashboard({
  title,
  editHandler,
  dismissHandler,
  children1,
  children2,
}) {
  return (
    <div
      className="flex flex-row justify-content-center bg-white"
      data-testid="preview-homepage-panel"
      style={{ height: "95%", borderRadius: "10px" }}
    >
      <div className="flex flex-column flex-start p-3 min-w-min ">
        <div
          className={`flex flex-row w-full align-items-center justify-content-between pb-3 overflow-wrap-anywhere`}
        >
          <Text className="ml-2" color="#314361" type="T1B">
            {title}
          </Text>
          <div
            className={`flex align-items-center justify-content-center gap-1`}
          >
            <div
              className="flex px-2 py-2 cursor-pointer align-items-center lsq-icon-box"
              onClick={editHandler}
              data-testid="edit-preview"
            >
              <Edit></Edit>
            </div>
            <div
              className="flex px-2 py-2 cursor-pointer align-items-center lsq-icon-box"
              onClick={dismissHandler}
              data-testid="cross-preview"
            >
              <Cross />
            </div>
          </div>
        </div>
        <div className="flex flex-1 w-full justify-content-center align-items-center overflow-scroll">
          {children1}
        </div>
      </div>
      {children2}
    </div>
  );
}
