import { Slider } from "primereact/slider";
import React from "react";
import { Controller } from "react-hook-form";
import Text from "../Text";
import "./index.css"

const LSQSlider = ({
  step,
  min,
  max,
  label,
  control,
  fieldName,
  ...props
}) => {
  return (
    <div>
      <Controller
        name={fieldName}
        control={control}
        render={({ field }) => {
          return (
            <div className="flex flex-row align-items-center gap-2">
              <Text color="#283A5A" type="T4">{label}</Text>
              <Slider
                className="lsq-slider"
                value={field.value}
                onChange={(e) => field.onChange(e.value)}
                step={step}
                min={min}
                max={max}
                {...props}
              />
              <Text color="#768196" type="T4">{Math.round(100 * field.value) - 100}%</Text>
            </div>
          );
        }}
      />
    </div>
  );
};

export default LSQSlider;
