import React from "react";
import { QuickLauncherIcons } from "../../assets/icons/QuickLauncherIcons";
import Text from "../../components/Text";
import styles from "./styles.module.css";

export const LauncherScrollView = ({ label, elementList }) => {
  return (
    <div className={styles["launcher-container"]} data-testid = "scroll-view">
      <div className="flex flex-row justify-content-between align-items-center mx-2 mt-1">
        <Text type="sub-heading-bold" color="var(--text-focused)">
          {label}
        </Text>
      </div>
      <div className="flex gap-1 overflow-x-scroll mt-1 pl-1">
        {elementList?.map((element) => {
          return (
            <div
              data-testid={`launcher-element-box-${element.id}`}
              key={element.id}
              className={`${styles["element"]}`}
            >
              <div className={`${styles["image"]}`}>
                <QuickLauncherIcons
                  style={{
                    width: "1.6rem",
                  }}
                  icon={element.icon.imgName}
                />
              </div>
              <Text
                type = "mobile-T5"
                color="#627087"
                className={`word-break`}
              >
                {element.displayName}
              </Text>
            </div>
          );
        })}
      </div>
    </div>
  );
};
