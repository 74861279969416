import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  API_GET_UPCOMING_WIDGETS,
  API_GET_WIDGET,
  API_GET_WIDGET_LIST,
  API_GET_WIDGET_METADATA,
  API_PUT_USER_FEEDBACK,
} from "../api/configurationScreenServices";
import { globalConstants, toastMessageConstants } from "../constants/globalConstant";
import { formatWidgetConfig } from "../utils/globalUtils";
import { getwidgetTemplateList } from "../utils/reducersUtils";
import { getWidgetTypeByWidgetId } from "../utils/widgetUtils";
import { widgetTypes } from "../constants/globalEnums";

const initialState = {
  selectedWidgetListTab: globalConstants.ACTIVE_WIDGETS,
  selectedWidget: {
    mode: null,
    widgetTitle: null,
    widgetDetails: {},
  },
  selectedWidgetConfiguration: {
    metaData: {},
    metaValue: {},
  },
  widgetList: {
    widgetTemplates: {
      status : globalConstants.PENDING,
      list : []
    },
    experimentalWidgetTemplates: {
      status : globalConstants.PENDING,
      list : []
    },
    widgetMetaData: {},
  },
  toastMessages: [],
  isOpportunityEnabled : false,
};

export const getWidgetList = createAsyncThunk(
  "widgetList/getWidgetList",
  async (_, thunkAPI) => {
    const {widgetList} = thunkAPI.getState().widgetTemplateList
    if(widgetList.widgetTemplates.list?.length > 0) return widgetList.widgetTemplates.list
    const response = await API_GET_WIDGET_LIST();
    const { data, status } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const list = data._embedded.widgetTemplates;
    return getwidgetTemplateList(list);
  }
);
export const getUpComingWidgets = createAsyncThunk(
  "widgetList/getUpComingWidgets",
  async (_,thunkAPI) => {
    const {widgetList} = thunkAPI.getState().widgetTemplateList
    if(widgetList.experimentalWidgetTemplates.list?.length > 0) return widgetList.experimentalWidgetTemplates.list
    const response = await API_GET_UPCOMING_WIDGETS();
    const { data, status } = response;
    if (status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const list = data?._embedded?.experimentalWidgetTemplates || [];
    return getwidgetTemplateList(list);
  }
);

export const apiUpdateUserFeedback = createAsyncThunk(
  "widgetList/apiUpdateUserFeedback", async ({widgetId, payload}, thunkAPI) => {
    const userPreferencesResponse = await API_PUT_USER_FEEDBACK(widgetId, payload);
    const {status, data} = userPreferencesResponse;
    if(status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data
  }
)

export const getWidgetMetaData = createAsyncThunk(
  "widgetList/getWidgetMetaData",
  async (widgetIdentifier, thunkAPI) => {
    const _widgetIdentifier = getWidgetTypeByWidgetId(widgetIdentifier)
    const {widgetList} = thunkAPI.getState().widgetTemplateList
    const {widgetMetaData} = widgetList
    if(widgetMetaData[_widgetIdentifier]) return { _widgetMetaData: widgetMetaData , widgetIdentifier: _widgetIdentifier}
    const metadataResponse = await API_GET_WIDGET_METADATA(widgetIdentifier);
    const {data, status} = metadataResponse
    if (status !== 200) {
      const errorMessage = metadataResponse.message || toastMessageConstants.FAILED_TO_FETCH_METADATA;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const _widgetMetaData = {...widgetMetaData};
    _widgetMetaData[_widgetIdentifier] = data;
    return {_widgetMetaData, widgetIdentifier: _widgetIdentifier}
  }
);

export const getNavigationData = createAsyncThunk(
  "widgetList/getNavigationData",
  async (_, thunkAPI) => {
    const widgetIdentifier = widgetTypes.QUICK_LAUNCHER;
    const { widgetList } = thunkAPI.getState().widgetTemplateList;
    const { widgetMetaData } = widgetList;
    if (widgetMetaData[widgetIdentifier])
      return { _widgetMetaData: widgetMetaData };
    const metadataResponse = await API_GET_WIDGET_METADATA(
      widgetTypes.QUICK_LAUNCHER
    );
    const { data, status } = metadataResponse;
    if (status !== 200) {
      const errorMessage =
        metadataResponse.message ||
        toastMessageConstants.FAILED_TO_FETCH_METADATA;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const _widgetMetaData = { ...widgetMetaData };
    _widgetMetaData[widgetIdentifier] = data;
    return { _widgetMetaData };
  }
);

export const getWidgetMetaValue = createAsyncThunk(
  "widgetList/getWidgetMetaValue",
  async (widgetIdentifier, thunkAPI) => {
    const {widgetConfiguration} = thunkAPI.getState().previewPane
    if(widgetConfiguration[widgetIdentifier]){
      return thunkAPI.fulfillWithValue(widgetConfiguration[widgetIdentifier])
    }
    const metavalueResponse = await API_GET_WIDGET(widgetIdentifier);
    const metavalue = metavalueResponse?.data;
    if (metavalueResponse?.status === 200) return metavalue;
    const errorMessage =
      metavalue?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
    return thunkAPI.rejectWithValue(errorMessage);
  }
);

const widgetTemplateListSlice = createSlice({
  name: "widgetList",
  initialState,
  reducers: {
    changeSelectedWidget(state, action) {
      const { mode, widgetTitle, widgetDetails } = action.payload;
      state.selectedWidget.mode = mode;
      state.selectedWidget.widgetTitle = widgetTitle;
      state.selectedWidget.widgetDetails = widgetDetails;
    },
    createWidgetDefinition(state, action) {
      state.selectedWidgetConfiguration = {
        metaData: action.payload.metaData,
        metaValue: {},
      };
    },
    updateWidgetDefinition(state, action) {
      state.selectedWidgetConfiguration = {
        metaData: action.payload.metaData,
        metaValue: action.payload.metaValue,
      };
    },
    resetWidgetConfiguration(state) {
      state.selectedWidget = {
        mode: null,
        widgetTitle: null,
        widgetDetails: {},
      };
      state.selectedWidgetConfiguration = {
        metaData: {},
        metaValue: {},
      };
    },
    resetToastMessageFromWidgetTemplateList(state) {
      state.toastMessages.shift();
    },
    changeSelectedWidgetListTab(state, action){
      state.selectedWidgetListTab = action.payload.key
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getWidgetList.fulfilled, (state, action) => {
      state.widgetList.widgetTemplates.list = action.payload;
      state.widgetList.widgetTemplates.status = globalConstants.FULFILLED;
      const _widgetTemplatesList = action.payload
      state.isOpportunityEnabled = _widgetTemplatesList.some((widget) => widget.id === 8)  || false
    });
    builder.addCase(getWidgetList.pending, (state) => {
      state.widgetList.widgetTemplates.status = globalConstants.PENDING;
     });
    builder.addCase(getWidgetList.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload,
      });
      state.widgetList.widgetTemplates.status = globalConstants.REJECTED;
    });

    builder.addCase(getWidgetMetaData.fulfilled, (state, { payload }) => {
      const {_widgetMetaData, widgetIdentifier} = payload
      state.selectedWidgetConfiguration.metaData = _widgetMetaData[widgetIdentifier]; 
      state.widgetList.widgetMetaData = _widgetMetaData
    });
    builder.addCase(getWidgetMetaValue.fulfilled, (state, { payload }) => {
      if (payload)
        state.selectedWidgetConfiguration.metaValue =
          formatWidgetConfig(payload);
    });
    builder.addCase(getWidgetMetaData.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload,
      });
    });
    builder.addCase(getNavigationData.fulfilled, (state, { payload }) => {
      const {_widgetMetaData } = payload
      state.widgetList.widgetMetaData = _widgetMetaData
    });
    builder.addCase(getNavigationData.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload,
      });
    });
    builder.addCase(getWidgetMetaValue.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload,
      });
    });
    builder.addCase(getUpComingWidgets.fulfilled, (state, action) => {
      const experimentalWidgets = action.payload
      let _experimentalWidgetIds = []
      const _experimentalWidgetsById = Object.fromEntries(
        experimentalWidgets?.map((experimentalWidget) => {
          _experimentalWidgetIds = [_experimentalWidgetIds, experimentalWidget.id]
          return [experimentalWidget.id, experimentalWidget]
        })
      )
      state.widgetList.experimentalWidgetTemplates.list = _experimentalWidgetsById;
      state.widgetList.experimentalWidgetTemplates.status = globalConstants.FULFILLED;
    });
    builder.addCase(getUpComingWidgets.pending, (state) => {
      state.widgetList.experimentalWidgetTemplates.status = globalConstants.PENDING;
    });
    builder.addCase(getUpComingWidgets.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload,
      });
      state.widgetList.experimentalWidgetTemplates.status = globalConstants.REJECTED;
    });
    builder.addCase(apiUpdateUserFeedback.fulfilled, (state, action) => {
      const _widgetTemplateId = action.payload.widgetTemplateId
      state.widgetList.experimentalWidgetTemplates.list[_widgetTemplateId].userFeedback = action.payload;
    })
    builder.addCase(apiUpdateUserFeedback.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload,
      });
    });
  },
});

export const {
  changeSelectedWidget,
  createWidgetDefinition,
  updateWidgetDefinition,
  resetWidgetConfiguration,
  resetToastMessageFromWidgetTemplateList,
  changeSelectedWidgetListTab
} = widgetTemplateListSlice.actions;

export default widgetTemplateListSlice.reducer;
