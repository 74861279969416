import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.css";
import Text from "../../components/Text";
const SieraAceWebView = ({ configData }) => {
  const { title, url, displayType } = configData;
  return (
    <div>
      <div
        className="flex flex-row justify-content-between align-items-center mx-2 mt-1"
        style={{ height: "auto", padding: "5px 0 8px 0" }}
      >
        <Text type="sub-heading-bold" color="var(--text-focused)">
          {title}
        </Text>
      </div>
      <div
        className={`${styles.iframeWrapper} ${
          displayType === "Expanded" ? styles.expanded : styles.standard
        }`}
      >
        <iframe
          target="_parent"
          src={`${url}`}
          width={"100%"}
          height={"100%"}
          title={`${title}`}
          className={styles.useFullWidth}
        ></iframe>
      </div>
    </div>
  );
};

SieraAceWebView.propTypes = {
  configData: PropTypes.object.isRequired,
};

export default SieraAceWebView;
