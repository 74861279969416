import Text from "../../components/Text";
import styles from "../styles.module.css";
import { stringConstants } from "../../constants/globalConstant";

const TaskListWidget = ({ widgetConfig }) => {
  const { title } = widgetConfig;

  return (
    <div className={`${styles["widget-container"]}`}>
      <div className="flex absolute flex-row justify-content-between align-items-center mx-2 mt-1">
        <Text type="sub-heading-bold" color="var(--text-focused)">
          {title}
        </Text>
      </div>
      <img className="w-full" src={`/images/widgetPreview/task_list.png`} alt={stringConstants.TASK_LIST}/>
    </div>
  );
};

export default TaskListWidget;
