import React from "react";
import CustomViewer from "../CustomViewer";
import styles from "./styles.module.css";
import { getWidgetDetails } from "./WidgetDetails";

export default function WidgetConfigurationWrapper({ widget }) {
  const { widgetType, config, widgetId } = widget;

  if (!widgetId) {
    return <></>;
  }
  const widgetDetails = getWidgetDetails(config, widgetType);
  return (
    <div
      className={`flex flex-row align-items-center flex-start ${styles["widget-details"]}`}
    >
      { widgetDetails?.map((item, index) => {
        const widgetDetailsItemClass = styles["widget-details-item"];
        const colSpanClass = styles[`colSpan-${item.colSpan}`];
        const combinedClass = `${widgetDetailsItemClass} ${colSpanClass}`;
            return  <CustomViewer
            key={index}
            title={item.title}
            value={item.value}
            style={combinedClass}
          />
          })}
    </div>
  );
}
