import {
  leadMetricsConstansts,
  widgetConstants,
} from "../../constants/globalConstant";
import {
  getObjectDefinition,
  taskToDisplay,
  getBannerItems,
  getLauncherItems,
} from "../../utils/widgetUtils";
import { capitalizeFirstLetter } from "../../utils/globalUtils";
import { widgetTypes } from "../../constants/globalEnums";

export const getWidgetDetails = (config, widgetType) => {
  const taskCounterDetails = [
    getObjectDefinition(widgetConstants.DISPLAY_NAME, config.title, 1),
    getObjectDefinition(
      widgetConstants.DISPLAY_STYLE,
      capitalizeFirstLetter(config.displayType),
      1
    ),
    getObjectDefinition(
      widgetConstants.TASK_TO_DISPLAY,
      taskToDisplay(config.taskType),
      2
    ),
    getObjectDefinition(
      widgetConstants.STATUS,
      capitalizeFirstLetter(config.taskStatus),
      1
    ),
  ];
  const taskCounterV2Details = [
    getObjectDefinition(widgetConstants.DISPLAY_NAME, config.title, 1),
    getObjectDefinition(
      widgetConstants.TASK_TO_DISPLAY,
      taskToDisplay(config.taskType),
      2
    ),
    getObjectDefinition(
      widgetConstants.DISPLAY_TYPE,
      capitalizeFirstLetter(config.displayType),
      1
    ),
    getObjectDefinition(
      widgetConstants.ASSOCIATED_TO,
      capitalizeFirstLetter(config.associateTo),
      1
    ),
    getObjectDefinition(
      widgetConstants.DEFAULT_DATE_RANGE,
      config.dateFilters?.name,
      1
    ),
  ];

  const taskCounterFuelBarDetails = [
    getObjectDefinition(widgetConstants.DISPLAY_NAME, config.title, 1),
    getObjectDefinition(
      widgetConstants.TASK_TO_DISPLAY,
      taskToDisplay(config.taskType),
      2
    ),
    getObjectDefinition(
      widgetConstants.DEFAULT_DATE_RANGE,
      config.dateFilters?.name,
      1
    ),
  ];

  const taskListDetails = [
    getObjectDefinition(widgetConstants.DISPLAY_NAME, config.title, 1),
    getObjectDefinition(
      widgetConstants.TASK_TO_DISPLAY,
      taskToDisplay(config.taskType),
      2
    ),
    getObjectDefinition(
      widgetConstants.STATUS,
      capitalizeFirstLetter(config.taskStatus),
      1
    ),
    getObjectDefinition(
      widgetConstants.ASSOCIATED_TO,
      capitalizeFirstLetter(config.associateTo),
      1
    ),
  ];
  const webViewDetails = [
    getObjectDefinition(widgetConstants.DISPLAY_NAME, config.title, 1),
    getObjectDefinition(
      widgetConstants.WIDGET_DIMENSION,
      config.displayType,
      1
    ),
    getObjectDefinition(widgetConstants.URL_ADDEED, config.url, 2),
  ];

  const sieraAceWebViewDetails = [
    getObjectDefinition(widgetConstants.DISPLAY_NAME, config.title, 1),
    getObjectDefinition(
      widgetConstants.WIDGET_DIMENSION,
      config.displayType,
      1
    ),
    getObjectDefinition(widgetConstants.URL_ADDEED, config.url, 2),
  ];

  const quickLauncherDetails = [
    getObjectDefinition(widgetConstants.DISPLAY_NAME, config.title, 1),
    getObjectDefinition(widgetConstants.DISPLAY_STYLE, config.displayStyle, 1),
    getObjectDefinition(
      widgetConstants.NO_OF_ICONS_ADDED,
      config.items?.length,
      2
    ),
    ...getLauncherItems(config.items),
  ];

  const bannerDetails = [
    getObjectDefinition(widgetConstants.ASPECT_RATIO, config.displayType, 1),
    getObjectDefinition(widgetConstants.DISPLAY_STYLE, config.displayStyle, 1),
    getObjectDefinition(
      widgetConstants.NO_OF_IMAGES_ADDED,
      config.items?.length,
      2
    ),
    ...getBannerItems(config.items),
  ];

  const leadShortcutDetails = [
    getObjectDefinition(widgetConstants.DISPLAY_NAME, config.title, 1),
    getObjectDefinition(
      widgetConstants.DEFAULT_SORT_ORDER,
      config.defaultCriteria?.name,
      1
    ),
  ];

  const leadListDetails = [
    getObjectDefinition(widgetConstants.DISPLAY_NAME, config.title, 1),
    getObjectDefinition(
      widgetConstants.LEAD_CRITERIA,
      config.leadFields?.name,
      1
    ),
    getObjectDefinition(
      widgetConstants.DEFAULT_FIELD,
      config.leadFieldOption?.length,
      1
    ),
  ];

  const myOpportunitiesDetails = [
    getObjectDefinition(widgetConstants.DISPLAY_NAME, config.title, 1),
    getObjectDefinition(
      widgetConstants.OPPORTUNITY_TYPE,
      config.selectAll
        ? `All Selected`
        : `${config.selectedOpportunityTypes?.length} Selected`,
      1
    ),
    getObjectDefinition(
      widgetConstants.DEFAULT_DATE_RANGE,
      config.dateFilters?.name,
      1
    ),
  ];

  const myScorecardDetails = [
    getObjectDefinition(widgetConstants.DISPLAY_NAME, config.title, 1),
    getObjectDefinition(
      widgetConstants.DEFAULT_ACTIVITY_TYPE,
      `${config.activityType?.length} Selected`,
      1
    ),
    getObjectDefinition(
      widgetConstants.DEFAULT_DATE_RANGE,
      config.dateFilters?.name,
      1
    ),
  ];
  const smartviewCounterDetails = [
    getObjectDefinition(widgetConstants.DISPLAY_NAME, config.title, 1),
    getObjectDefinition(widgetConstants.SMART_VIEW, config.smartView?.name, 1),
    getObjectDefinition(
      widgetConstants.SMART_VIEW_TABS,
      `${config.smartViewTab?.length} Selected`,
      1
    ),
  ];

  const chartingWidgetLeadMetricsDetails = [
    getObjectDefinition(widgetConstants.DISPLAY_NAME, config.title, 1),
    getObjectDefinition(
      leadMetricsConstansts.CHART_TYPE,
      config.chartType?.name,
      1
    ),
  ];

  const keyLeadMetricsDetails = [
    getObjectDefinition(widgetConstants.DISPLAY_NAME, config.title, 1),
    getObjectDefinition("Default Date Range", config.dateFilters?.name, 1),
  ];

  const nearMeDetails = [
    getObjectDefinition(widgetConstants.DISPLAY_NAME, config.title, 2),
    getObjectDefinition(
      widgetConstants.DEFAULT_LEAD_FILTER,
      config.leadFields?.name || "N/A",
      1
    ),
    getObjectDefinition(
      widgetConstants.DEFAULT_FIELD,
      config.leadFieldOption?.length,
      1
    ),
    getObjectDefinition(
      widgetConstants.DEFAULT_MAXIMUN_RADIUS,
      config.radius?.name || "N/A",
      1
    ),
  ];

  const tilesBannerDetails = [
    getObjectDefinition(
      widgetConstants.NO_OF_IMAGES_ADDED,
      config.items?.length,
      2
    ),
    ...getBannerItems(config.items),
  ];

  const userCheckinStatusDetails = [
    getObjectDefinition(widgetConstants.DISPLAY_NAME, config.title, 2),
    getObjectDefinition(
      widgetConstants.NAVIGATE_TO,
      config.navigateTo?.name,
      1
    ),
    getObjectDefinition(
      widgetConstants.DISPLAY_STYLE,
      capitalizeFirstLetter(config.displayStyle),
      1
    ),
  ];

  const imageGalleryDetails = [
    getObjectDefinition(widgetConstants.WIDGET_TITLE, config.title, 2),
    getObjectDefinition(
      widgetConstants.SELECT_LAYOUT,
      config.layout,
      1
    ),
    getObjectDefinition(
      widgetConstants.SELECT_SIZE,
      config.size,
      1
    ),
    getObjectDefinition(
      widgetConstants.IMAGES,
      config.images?.length,
      1
    ),
  ]

  switch (widgetType) {
    case widgetTypes.TASK_COUNTER:
      return taskCounterDetails;
    case widgetTypes.TASK_LIST:
      return taskListDetails;
    case widgetTypes.BANNER:
      return bannerDetails;
    case widgetTypes.QUICK_LAUNCHER:
      return quickLauncherDetails;
    case widgetTypes.WEB_VIEW:
      return webViewDetails;
    case widgetTypes.LEAD_SHORTCUT:
      return leadShortcutDetails;
    case widgetTypes.LEAD_LIST:
      return leadListDetails;
    case widgetTypes.MY_OPPORTUNITIES:
      return myOpportunitiesDetails;
    case widgetTypes.MY_SCORECARD:
      return myScorecardDetails;
    case widgetTypes.SMART_VIEWS_COUNTER:
      return smartviewCounterDetails;
    case widgetTypes.LEAD_METRICS:
      return chartingWidgetLeadMetricsDetails;
    case widgetTypes.NEAR_ME:
      return nearMeDetails;
    case widgetTypes.KEY_LEAD_MATRICS:
      return keyLeadMetricsDetails;
    case widgetTypes.TASK_COUNTER_V2:
      return taskCounterV2Details;
    case widgetTypes.TASK_COUNTER_FUEL_BAR:
      return taskCounterFuelBarDetails;
    case widgetTypes.SIERA_ACE_WEBVIEW:
      return sieraAceWebViewDetails;
    case widgetTypes.TILES_BANNER:
      return tilesBannerDetails;
    case widgetTypes.USER_CHECKIN_STATUS:
      return userCheckinStatusDetails;
    case widgetTypes.IMAGE_GALLERY:
      return imageGalleryDetails;
    default:
      return [];
  }
};
