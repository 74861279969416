import { useDispatch } from "react-redux";
import { globalConstants } from "../constants/globalConstant";

const useToast = (callback) => {
  const dispatch = useDispatch();
  const toast = {};
  const generateToastObject = (type, title = undefined, message = '') => {
    const toastObj = {
      type: type,
      title: title || type,
      message: message,
    };
    return toastObj;
  };
  toast.error = (message, title) => {
    dispatch(callback(generateToastObject(globalConstants.ERROR, title, message)));
  };
  toast.warn = (message,  title) => {
    dispatch(callback(generateToastObject(globalConstants.WARNING, title, message)));
  };
  toast.success = (message, title) => {
    dispatch(callback(generateToastObject(globalConstants.SUCCESS, title, message)));
  };

  return toast;
};

export default useToast;
