import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFieldArray, useForm } from "react-hook-form";
import CustomButton from "../components/CustomButton";
import CustomRadioGroup from "../components/CustomRadioGroup";
import { resetWidgetConfiguration } from "../reducers/widgetTemplateListSlice";
import {
  addWidget,
  apiAddWidget,
  apiUpdateWidget,
  deleteWidget,
  updateWidgetConfiguration,
} from "../reducers/previewPaneSlice";
import { CustomImageInput } from "../components/CustomImageInput";
import useWidgetEssentials from "../hooks/useWidgetEssentials";
import { convertDatetoString, getWidgetPreviewOfExperimentalWidget, getWidgetSignature } from "../utils/widgetUtils";
import {
  API_GET_UPLOAD_URL,
  API_IMAGE_UPLOAD,
} from "../api/configurationScreenServices";
import getCroppedImg from "../components/ImageEdit/getCroppedImg";
import BannerElementDragHandler from "../components/BannerElementDragHandler";
import {
  bannerSettingsConstants,
  casaConstants,
  globalConstants,
  tooltipConstants,
} from "../constants/globalConstant";
import analytics from "../utils/analytics";
import cloneDeep from "lodash.clonedeep";
import { checkIsSaveDisabled, deleteImagesFromS3, fetchImageLinks, formatItems, generateWidgetMetaValue, getImageKeys } from "../utils/bannerUtils";
import { countLimit, validateWidgetSubmission } from "../utils/globalUtils";
import { AddImageForm } from "../components/AddImageForm";

export function BannerSettings() {
  const [updatedElementIndex, setupdatedElementIndex] = useState();
  const [removedImageKeys , setRemovedImageKeys] = useState([])
  const [edit, setEdit] = useState(false);
  const { selectedWidget, selectedWidgetConfiguration, mode, homepageId, previewPaneData } =
    useWidgetEssentials();
  const {selectedHomepageId, homepagesById} = useSelector(
    (state) => state.homepage
  );
  const ref = useRef(null);
  const homepage = homepagesById[selectedHomepageId]
  const {hasDraftChanges , status} = homepage
  const [navigationList, setNavigationList] = useState([
    {
      name: "External Link",
      items: [{ id: "ExternalLink", name: "-----" }],
    },
    {
      name: "Internal Navigation",
      items: [],
    },
  ]);

  const { aspectRatio, displayStyle , menu } = selectedWidgetConfiguration.metaData;
  const bannerDefaultValues = {
    aspectRatio:
    aspectRatio?.length > 0 && aspectRatio[0].value,
    displayStyle : displayStyle?.length > 0 && displayStyle[0].value,
    items: [],
  };

  const imageConfigurationDefaultValues = {
    zoom: 1,
    rotation: 0,
    crop: { x: 0, y: 0 },
    cropPixels: {},
    navigateTo: {},
    endDate: null,
    externalLink: "",
    externalLinkOptions: "",
    internalLink: {},
    originalImage: null,
    croppedImageURL: null,
    uploadStatus: null,
    imageKey: null,
    imageName: null,
    dynamicForm: {
      entity: "",
      workArea: null,
      form: {},
    }
  };

  const dispatch = useDispatch();
  const { control, handleSubmit, reset, getValues, watch, setValue, unregister } = useForm({
    defaultValues: bannerDefaultValues,
  });
  const { fields, append, move, swap, remove, update, replace } = useFieldArray(
    {
      control,
      name: "items",
    }
  );
  const imageConfiguration = useForm({
    defaultValues: imageConfigurationDefaultValues,
  });

  const selectedImage = imageConfiguration.watch(
    bannerSettingsConstants.ORIGINAL_IMAGE
  );
  const setSelectedImage = (img) => {
    imageConfiguration.setValue(bannerSettingsConstants.ORIGINAL_IMAGE, img);
    imageConfiguration.setValue(bannerSettingsConstants.IMAGE_NAME, img.name);
  };

  const widgetId = mode === globalConstants.CREATE ? bannerSettingsConstants.DEMO_BANNER : selectedWidgetConfiguration?.metaValue.widgetId
  const imageUploadHandler = (event) => {
    const file = event.files[0]
    const imgFile = {
        objectURL: file.objectURL,
        name: file.name,
        size: file.size,
        file: file.type,
        lastModified: file.lastModified
      }
    setSelectedImage(imgFile)
    event.options.clear()
  }
  const updateBannerInPreviewPane = () => {
    const config = {
      displayType: getValues(bannerSettingsConstants.ASPECT_RATIO),
      displayStyle : getValues(bannerSettingsConstants.DISPLAY_STYLE),
      items: getValues("items"),
    }
    const widgetMetaValue = generateWidgetMetaValue(config , widgetId);
    dispatch(updateWidgetConfiguration(widgetMetaValue));
  };

  const getTooltipforAddingFile = () => {
    const displayType = getValues(bannerSettingsConstants.ASPECT_RATIO)
    switch(displayType){
      case bannerSettingsConstants.WIDE : 
      return tooltipConstants.WIDE_IMAGE_400X200;
      case bannerSettingsConstants.SQUARE : 
      return  tooltipConstants.SQUARE_IMAGE_400X400;
      default :
      return
    }
  }

  const getTooltipPostition = (ref) => {
    return window.innerWidth - (ref.current?.offsetLeft + ref.current?.offsetWidth)
  }

  const uploadImageInToBucket = async (imageConfigurationValues, index) => {
    let elementIndex;

if (index !== undefined) elementIndex = index;
else if (updatedElementIndex !== undefined) elementIndex = updatedElementIndex;
else elementIndex = fields.length;
    let element = {
      ...imageConfigurationValues,
      uploadStatus: bannerSettingsConstants.PENDING,
    };
    update(elementIndex, element);
    try {
      const { croppedImageURL, imageName, imageKey } = imageConfigurationValues;
      const uploadUrlResponse = await API_GET_UPLOAD_URL(imageKey);
      const uploadUrl = uploadUrlResponse.data.url;
      const signedKey = uploadUrlResponse.data.key;
      const uploadAPIFields = uploadUrlResponse.data.fields
      const res = await fetch(croppedImageURL);
      const blob = await res.blob();
      const file = new File([blob], imageName, { type: blob.type });
      await API_IMAGE_UPLOAD(uploadUrl, uploadAPIFields, file);
      element = {
        ...imageConfigurationValues,
        uploadStatus: bannerSettingsConstants.SUCCESS,
        imageKey: signedKey,
      };
      update(elementIndex, element);
    } catch (error) {
      element = {
        ...imageConfigurationValues,
        uploadStatus: bannerSettingsConstants.FAILED,
      };
      update(elementIndex, element);
    }
  };

  const onImageSubmit = async (data) => {
    imageConfiguration.reset();
    const istEndDate = convertDatetoString(data.endDate) || null;
    const croppedImageURL = await getCroppedImg(
      data.originalImage.objectURL,
      data.cropPixels,
      data.rotation
    );
    const imageConfigurationValues = {
      ...data,
      croppedImageURL: croppedImageURL,
      endDate : istEndDate
    };
    if (updatedElementIndex !== undefined) {
      update(updatedElementIndex, imageConfigurationValues);
    } else {
      append(imageConfigurationValues);
    }
    uploadImageInToBucket(imageConfigurationValues);
    updateBannerInPreviewPane();
    setupdatedElementIndex();
  };

  const onSubmit = (data) => {
    if(selectedWidget.isExperimental){
      getWidgetPreviewOfExperimentalWidget(selectedWidget.imageUrl, dispatch)
      return;
    }
    if(!validateWidgetSubmission(mode , previewPaneData, dispatch)) return;
    const config = {
      displayType: data.aspectRatio,
      displayStyle : data.displayStyle,
      items: formatItems(data.items),
    };
    const metaValueData = getWidgetSignature(
      homepageId,
      selectedWidget?.id,
      config
    );
    const analytics_data = {
      'category': 'Widget',
      'type': 'click',
      'widgetType': 'banner',
      'displayType': metaValueData.config.displayType,
      'displayStyle': metaValueData.config.displayStyle
    }
    if (mode === globalConstants.UPDATE) {
      const updatedMetaData = {
        widgetId: widgetId,
        config: {
          ...metaValueData.config,
        },
      };
      dispatch(apiUpdateWidget(updatedMetaData))
      .then(({type}) => {
        if(type?.includes("fulfilled") && !(status === casaConstants.PUBLISHED || hasDraftChanges)){
          deleteImagesFromS3(removedImageKeys);
        }
      })
      analytics_data["widgetId"] = widgetId
      analytics.sendEvent('Updating_Banner', analytics_data);
    }
    else if (mode === globalConstants.CREATE) {
      dispatch(apiAddWidget(metaValueData));
      analytics.sendEvent("Adding_Banner", analytics_data);
    }
    dispatch(resetWidgetConfiguration());
  };

  const onElementEdit = (index) => {
    setupdatedElementIndex(index);
    if (fields[index][bannerSettingsConstants.END_DATE]) {
      fields[index][bannerSettingsConstants.END_DATE] = new Date(
        fields[index][bannerSettingsConstants.END_DATE]
      );
    }
    Object.keys(fields[index]).forEach((key) => {
      if (key === "id") return;
      imageConfiguration.setValue(key, fields[index][key]);
    });
  };
  const onElementDeleted = (index) => {
    remove(index);
    updateBannerInPreviewPane();
    if(mode === globalConstants.CREATE){
      const imageKeys = [fields[index].imageKey]
      deleteImagesFromS3(imageKeys)
    }else if(mode === globalConstants.UPDATE){
      setRemovedImageKeys((prev) => [...prev,fields[index].imageKey])
    }
  };

  const onBannerReset = () => {
    reset()
    updateBannerInPreviewPane()
    if(mode === globalConstants.CREATE){
      const imageKeys = getImageKeys(fields)
      deleteImagesFromS3(imageKeys)
    }
  }

  const updateBannerConfiguration = () => {
    const items = getValues("items")
    const config = {
      displayType: getValues(bannerSettingsConstants.ASPECT_RATIO),
      displayStyle :  getValues(bannerSettingsConstants.DISPLAY_STYLE),
      items: items.length === 0? [
        {
          croppedImageURL:
            getValues(bannerSettingsConstants.ASPECT_RATIO) ===
            bannerSettingsConstants.SQUARE
              ? bannerSettingsConstants.DEMO_SQUARE_IMAGE
              : bannerSettingsConstants.DEMO_WIDE_IMAGE,
        }]: items,
      }
      const widgetMetaValue = generateWidgetMetaValue(config , widgetId);
      dispatch(updateWidgetConfiguration(widgetMetaValue));
  }

  const onDisplayStyleUpdate = () => {
    if(mode ===globalConstants.CREATE){
      updateBannerConfiguration()
    }
    if(mode === globalConstants.UPDATE){
      const config = {
        ...getValues(),
        displayStyle :  getValues(bannerSettingsConstants.DISPLAY_STYLE)
      }
      const widgetMetaValue = generateWidgetMetaValue(config , widgetId);
      dispatch(updateWidgetConfiguration(widgetMetaValue));
    }
  }

  useEffect(() => {
    if(mode ===globalConstants.CREATE){
      updateBannerConfiguration()
    }
  }, [watch(bannerSettingsConstants.ASPECT_RATIO)]);

  useEffect(() => {
    let tempNavigationList = [...navigationList];
    if (menu) {
      tempNavigationList[1].items = menu;
    }
    setNavigationList(tempNavigationList);
    if (mode === globalConstants.CREATE) {
      const demoBanner = {
        widgetLayout: {
          type: globalConstants.FULL,
          widgetId: bannerSettingsConstants.DEMO_BANNER,
          widgetType: "banner",
        },
        widgetConfig: {
          homepageId: homepageId,
          widgetTemplateId: 5,
          config: {
            displayType: bannerSettingsConstants.SQUARE,
            items: [
              {
                croppedImageURL: bannerSettingsConstants.DEMO_SQUARE_IMAGE,
              },
            ],
          },
        },
      };
      dispatch(addWidget(demoBanner));
    } else if (mode === globalConstants.UPDATE) {
      const { displayType, displayStyle, items } =
        selectedWidgetConfiguration.metaValue.config;
      setValue(bannerSettingsConstants.ASPECT_RATIO, displayType);
      setValue(bannerSettingsConstants.DISPLAY_STYLE, displayStyle);
      let updatedItems = cloneDeep(items);
      fetchImageLinks(items).then((images) => {
        images.forEach((image, index) => {
          updatedItems[index][bannerSettingsConstants.CROPPED_IMAGE_URL] =
            image.croppedImageURL;
          updatedItems[index][bannerSettingsConstants.ORIGINAL_IMAGE] = {
            objectURL: image.croppedImageURL,
          };
          updatedItems[index][bannerSettingsConstants.UPLOAD_STATUS] =
            bannerSettingsConstants.SUCCESS;
          updatedItems[index][bannerSettingsConstants.ZOOM] =
            imageConfigurationDefaultValues.zoom;
          updatedItems[index][bannerSettingsConstants.CROP] =
            imageConfigurationDefaultValues.crop;
          updatedItems[index][bannerSettingsConstants.ROTATION] =
            imageConfigurationDefaultValues.rotation;
          updatedItems[index][bannerSettingsConstants.CROP_PIXELS] =
            imageConfigurationDefaultValues.cropPixels;
        });

        replace(updatedItems);
      });
    }
    return () => {
      if (mode === globalConstants.CREATE) {
        dispatch(
          deleteWidget({
            widgetId: bannerSettingsConstants.DEMO_BANNER,
          })
        );
      } else if (mode === globalConstants.UPDATE) {
        const widgetMetaValue = generateWidgetMetaValue(selectedWidgetConfiguration.metaValue.config, widgetId)
        dispatch(updateWidgetConfiguration(widgetMetaValue));
      }
    };
  }, []);

  return (
    <>
      {selectedImage !== null ? (
        <AddImageForm
          onImageSubmit={onImageSubmit}
          imageSrc={selectedImage}
          imageConfiguration={imageConfiguration}
          imageAspectRatio={getValues(bannerSettingsConstants.ASPECT_RATIO)}
          imageUploadHandler={imageUploadHandler}
          maxFileSize={countLimit.BANNER_MAX_FILE_SIZE}
          navigationList={navigationList}
          onResetClick={() => {
            imageConfiguration.reset();
            setupdatedElementIndex();
          }}
          unregister={unregister}
          endDateFieldName="endDate"
          endDateRequired={false}
          navigateToRequired={false}
          edit={edit}
          setEdit={setEdit}
        ></AddImageForm>
      ) : (
        <form
          style={{ height: "90%" }}
          className="flex flex-column w-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className=" overflow-y-auto">
            <div className={`flex flex-column mt-5 mx-5 gap-3`} ref={ref}>
              <CustomRadioGroup
                radioName={bannerSettingsConstants.ASPECT_RATIO}
                control={control}
                label="Aspect Ratio"
                options={aspectRatio}
                disabled={fields.length > 0}
              />
              <CustomRadioGroup
                radioName={bannerSettingsConstants.DISPLAY_STYLE}
                control={control}
                label="Display Style"
                options={displayStyle}
                onChange={()=>onDisplayStyleUpdate()}
              />
              {fields.length < 5 && (
                <CustomImageInput
                  label="Add Images"
                  imageUploadHandler={imageUploadHandler}
                  maxFileSize={countLimit.BANNER_MAX_FILE_SIZE}
                  tooltipContent = {getTooltipforAddingFile()}
                  tooltipStyling={{right : getTooltipPostition(ref)}}
                />
              )}
              <BannerElementDragHandler
                onElementEdit={onElementEdit}
                onElementDeleted = {onElementDeleted}
                aspectedRatio={getValues(bannerSettingsConstants.ASPECT_RATIO)}
                elementList={fields}
                move={move}
                swap={swap}
                remove={remove}
                updateBannerPreviewPane={updateBannerInPreviewPane}
                uploadImageInToBucket={uploadImageInToBucket}
                setEdit = {setEdit}
              />
            </div>
          </div>

          <div
            className={`flex w-11 py-2 mt-3 align-self-center align-items-center justify-content-end mt-auto gap-3`}
          >
            <CustomButton
              disabled={
                fields.length === 0 &&
                getValues(bannerSettingsConstants.ASPECT_RATIO) ===
                  bannerSettingsConstants.SQUARE
              }
              type="reset"
              onClick={onBannerReset}
              varient="text"
              label="Reset"
            />

            <CustomButton
              disabled={checkIsSaveDisabled(fields)}
              type="submit"
              varient="filled"
              label="Save"
              data-testid = "banner-save-btn"
            />
          </div>
        </form>
      )}
    </>
  );
}
