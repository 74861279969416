import React, { useEffect } from "react";
import styles from "./styles.module.css";
import Text from "../../components/Text";
import CustomButton from "../../components/CustomButton";
import { menuBuilderConstants } from "../../constants/globalConstant";
import CustomInput from "../../components/CustomInput";
import { useForm } from "react-hook-form";
import LsqSwitchV2 from "../../components/LsqSwitchV2/index.js";
import { useDispatch, useSelector } from "react-redux";
import { changePublishMenuState, setCicoCustomisation } from "../../reducers/menuBuilderSlice.js";

export default function CheckInCustomisations() {
  const dispatch = useDispatch();
  const { cicoCustomisation, publish } = useSelector(
    (state) => state.menuBuilder
  );
  const defaultValues = {
    checkin: "Check In",
    checkout: "Check Out",
    hideCheckInCheckOut: false,
  };
  const { control, formState, watch, handleSubmit, setValue } = useForm({
    defaultValues: defaultValues,
  });
  const watchHideCheckInCheckOut = watch("hideCheckInCheckOut");
  const onCancelClicked = () => {
    dispatch(
      setCicoCustomisation({
        ...cicoCustomisation,
        showCustomisation: false,
      })
    );
  };
  const onSaveClicked = (data) => {
    dispatch(
      setCicoCustomisation({
        cico: {
          hide: data.hideCheckInCheckOut,
          checkIn: data.checkin,
          checkOut: data.checkout,
        },
        showCustomisation: false,
      })
    );
    publish.isPublished && dispatch(changePublishMenuState(false));
  };

  useEffect(() => {
    if (cicoCustomisation.cico) {
      const cico = cicoCustomisation?.cico;
      setValue("hideCheckInCheckOut", cico?.hide);
      setValue("checkin", cico?.checkIn);
      setValue("checkout", cico?.checkOut);
    }
  }, [cicoCustomisation]);

  return (
    <div
      className="flex justify-content-center align-items-center fixed z-1 h-full"
      style={{
        width: "inherit",
      }}
    >
      <div
        className={`flex justify-content-end align-items-center fixed h-full ${styles["custom-menu-backdrop"]}`}
      >
        <div
          className={`flex flex-column h-full w-full align-items-center justify-content-center`}
        >
          <div
            className={`flex flex-column justify-content-center ${styles["custom-menu"]}`}
            data-testid="cico-customisation"
          >
            <div className={`flex flex-column p-3 gap-2`}>
              <Text type="T2B" color="#1B2332">
                {menuBuilderConstants.CHECKIN_CUSTOMISATIONS}
              </Text>
              <div className="flex w-full justify-content-between align-items-center">
                <Text type="T4" color="#878B92">
                  {menuBuilderConstants.HIDE_CHECKIN_CHECKOUT}
                </Text>
                <LsqSwitchV2
                  control={control}
                  switchName="hideCheckInCheckOut"
                />
              </div>
              {!watchHideCheckInCheckOut && (
                <>
                  <CustomInput
                    errors={formState.errors}
                    fieldName="checkin"
                    control={control}
                    isMandatory
                    label={menuBuilderConstants.RENAME_CHECKIN}
                    maxLength={30}
                  />
                  <CustomInput
                    errors={formState.errors}
                    fieldName="checkout"
                    control={control}
                    isMandatory
                    label={menuBuilderConstants.RENAME_CHECKOUT}
                    maxLength={30}
                  />
                </>
              )}
              <div className="flex w-full justify-content-center gap-1">
                <CustomButton
                  varient="outline"
                  label={menuBuilderConstants.CANCEL}
                  style={{ width: "100%" }}
                  onClick={onCancelClicked}
                  data-testid="cancel-btn"
                />
                <CustomButton
                  varient="filled"
                  label={menuBuilderConstants.SAVE}
                  style={{ width: "100%" }}
                  onClick={handleSubmit(onSaveClicked)}
                  data-testid="save-btn"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
