import React from "react";
import styles from "../styles.module.css";
import Text from "../../components/Text";
import { useTranslation } from 'react-i18next'
import { stringConstants } from "../../constants/globalConstant";

export default function NumericWidget({ label }) {
  const { t } = useTranslation();
  return (
    <div className={styles["widget-container"]}>
        <div className="flex flex-column absolute mt-1 ml-2">
            <Text type = "sub-heading-bold" color = "#314361"> { t(label) } </Text>
        </div>
        <img 
        className="w-full"
        src={`/images/widgetPreview/taskcounter2_numeric.png`} 
        alt={stringConstants.TASK_COUNTER_V2} />
    </div>
  );
}
