import React from "react";
import TaskListWidget from "./TaskListWidget";

const TaskListWidgetFactory = ({widgetInformation}) => {
  const {config} = widgetInformation;

  return (
      <TaskListWidget widgetConfig={config} />
  );
};

export default TaskListWidgetFactory;
