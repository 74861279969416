import React from "react";
import { useTranslation } from "react-i18next";
import { QuickLauncherIcons } from "../../assets/icons/QuickLauncherIcons";
import Text from "../../components/Text";
import { lsqEntity, smartViewTypeToEntityMap } from "../../constants/globalEnums";
import { getSmartViewPrimaryColorFilter } from "../../utils/globalUtils";
import "../style.css";
import styles from "./styles.module.css";

const SmartViewCounterView = ({ title = "", smartViewTab=[] }) => {
  const { t } = useTranslation();
  const tabCount = smartViewTab?.length
  const visbleTabCount = tabCount >= 5 ? "5" : tabCount
  const tabCountClass = styles[`tab-count-${visbleTabCount}`];
  const getSmartViewIconPath = (type) => {
    const entity = smartViewTypeToEntityMap[type]
    switch (entity) {
      case lsqEntity.LEAD:
        return "lsq_leads"
      case lsqEntity.ACTIVITY:
        return "lsq_activity"
      case lsqEntity.TASK:
        return "lsq_tasks"
      case lsqEntity.ACCOUNTS:
        return "lsq_accounts"
      case lsqEntity.OPPORTUINITY:
        return "lsq_opportunity"
      default:
        return "lsq_smart_views"
    }
  }

  return (
    <div className="widget-view-container">
      <div className="flex flex-column absolute mt-1 ml-2">
        <Text type="sub-heading-bold" color="var(--text-focused)">
          {t(title)}
        </Text>
      </div>
      <div
        className={`mx-2 ${styles["smartview-block"]} ${tabCountClass}`}
      >
        {smartViewTab?.slice(0,5).map(smartView => {
            return <div
            key={smartView.id}
            className={`${styles["smartview-tab-view"]} ${styles["second-row-item"]}`}
          >
            <Text type="T3B" color={smartView.PrimaryColor}>
              100
            </Text>
            <Text className="overflow-wrap-anywhere" type="T5" color="var(--N100)">
             {smartView.name}
            </Text>
            <div style={{backgroundColor: `${smartView.SecondaryColor}`}} className={`${styles["smartview-tab-icon"]}`}>
              <QuickLauncherIcons
                icon={getSmartViewIconPath(smartView.Type)}
                style={{
                  width: "0.8rem",
                  filter: getSmartViewPrimaryColorFilter(smartView.PrimaryColor)
                }}
              />
            </div>
          </div>
        })}
      </div>
      {tabCount > 5 && <div className={`${styles["carousel-pagination-block"]}`}>
            <div className={`${styles["carousel-pagination-view"]}`}>
                <div className={`${styles["circle"]} ${styles["active"]}`}></div>
                <div className={`${styles["circle"]}`}></div>
            </div>
        </div>}
    </div>
  );
};
export default SmartViewCounterView;
