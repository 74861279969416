import { Suspense, useState, useEffect } from "react";
import "./App.css";
import { useDispatch } from "react-redux";
import analytics from "./utils/analytics";
import { CasaSubscription } from "./views/CasaSubscription";
import { removeLoadingIconFromParent } from "./utils/globalUtils";
import { globalConstants } from "./constants/globalConstant";
import { API_GET_IS_DASHBOARD_ENABLED } from "./api/configurationScreenServices";
import AppRoutes from "./routes/AppRoutes";
import {
  apiGetUser,
  apiGetUserPreferences,
  toggleHomepageVersion,
} from "./reducers/homePageSlice";
import ErrorScreen from "./views/ErrorScreen";
import { setCustomMenuTabEnabled } from "./reducers/menuBuilderSlice";

const App = () => {
  const [isDashboardEnabled, setIsDashboardEnabled] = useState();
  const [initialLoadPassed, setInititalLoadPassed] = useState();
  const dispatch = useDispatch();

  const getCriticalMeta = async () => {
    const criticalMetaAPIs = [apiGetUser, apiGetUserPreferences];
    Promise.all(
      criticalMetaAPIs.map((criticalMetaAPI) => dispatch(criticalMetaAPI()))
    )
      .then((responses) => {
        if (
          responses.every((item) =>
            item.type.includes(globalConstants.FULFILLED)
          )
        ) {
          setInititalLoadPassed(true);
        } else throw globalConstants.REJECTED;
      })
      .catch(() => {
        setInititalLoadPassed(false);
      });
  };

  useEffect(() => {
    if (process.env.NODE_ENV === "production") analytics.init();
    removeLoadingIconFromParent();
    (async () => {
      try {
        const { status, data } = await API_GET_IS_DASHBOARD_ENABLED();
        if (status === 200) {
          setIsDashboardEnabled(data?.mobileHomeBuilderEnabled);
          if (data?.versionsEnabled)
            dispatch(toggleHomepageVersion(data?.versionsEnabled));
          dispatch(setCustomMenuTabEnabled(data?.menuCustomTabEnabled));
        } else {
          setInititalLoadPassed(false);
          return;
        }
      } catch (error) {
        setInititalLoadPassed(false);
        return;
      }
      await getCriticalMeta();
    })();
  }, []);

  if (initialLoadPassed === false) {
    return <ErrorScreen onReloadClicked={getCriticalMeta} />;
  }
  if (isDashboardEnabled === undefined || initialLoadPassed === undefined)
    return <></>;

  return (
    <div className="App">
      <Suspense fallback={null}>
        {isDashboardEnabled ? <AppRoutes /> : <CasaSubscription />}
      </Suspense>
    </div>
  );
};

export default App;
