import { useEffect } from "react";
const useUnsavedChangesWarning = (
    message = "Are you sure want to discard changes?",
    isDirty = false
) => {
    useEffect(() => {
        //Detecting browser closing
        window.onbeforeunload = isDirty && (() => message);

        return () => {
            window.onbeforeunload = null;
        };
    }, [isDirty]);
};

export default useUnsavedChangesWarning;