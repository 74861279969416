import React from "react";
import styles from "./styles.module.css";
import Text from "../Text";
import CustomButton from "../CustomButton";

const CoachTooltip = ({
  continuous,
  index,
  step,
  size,
  backProps,
  isLastStep,
  skipProps,
  primaryProps,
  tooltipProps,
  onCoachSkippedOrFinished
}) => {
  const onSkipClicked = (e) => {
    onCoachSkippedOrFinished();
    skipProps.onClick(e)
  }

  const onNextClicked = (e) => {
    if(isLastStep){
      onCoachSkippedOrFinished();
    }
    primaryProps.onClick(e)
  }
  return (
    <div className={`${styles["tooltip-body"]}`} {...tooltipProps}>
      <div className={`${styles["tooltip-header"]}`}>
        {step.title && (
          <Text className="pr-2" type="T2B" color="var(--N400)">
            {step.title}
          </Text>
        )}
        <Text type="T2B" color="var(--N60)">{`${index + 1}/${size}`}</Text>
      </div>
      <Text type="T4" color="var(--N90)">
        {step.content}
      </Text>
      <div className={`${styles["tooltip-footer"]}`}>
        {continuous && (
          <div className={`${styles["tooltip-actions"]}`}>
            {index > 0 && <CustomButton {...backProps} label="Back" varient="outline"  />}
            <CustomButton onClick={onNextClicked} label={isLastStep? "Finish" : "Next"} varient="filled"  />
          </div>
        )}
        {!isLastStep && <CustomButton onClick={onSkipClicked} label="Skip" varient="text" />}
      </div>
    </div>
  );
};

export default CoachTooltip;
