import React from "react";
import { Controller } from "react-hook-form";
import { FormErrorMessage } from "../FormErrorMessage";
import { errorMessageConstants } from "../../constants/globalConstant";
import { LSQDatePicker } from "./LSQDatePicker";

export const ControlledDatePicker = ({
  fieldName,
  control,
  label,
  errors,
  id,
  isMandatory,
  errorMessage,
  minDate,
  dateFormat,
  ...props
}) => {
  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{
        required:
          isMandatory &&
          (errorMessage
            ? errorMessage
            : errorMessageConstants.MANDATORY_FIELD_ERROR),
      }}
      render={({ field, fieldState }) => (
        <div>
          <LSQDatePicker
            {...props}
            id={id}
            label={label}
            field={field}
            error={fieldState.error}
            minDate={minDate}
            dateFormat={dateFormat}
            isMandatory={isMandatory}
            inputStyle={fieldState.error && { borderColor: "var(--R800)" }}
          />
          {errors[fieldName] && (
            <FormErrorMessage message={errors[fieldName].message} />
          )}
        </div>
      )}
    />
  );
};
