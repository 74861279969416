export const NavigationMenu = {
    EXTERNAL: "-----",
    ACTIVITY_FORM: "Activity/Form",
    DAYPLAN: "DayPlan",
    LEADS: "Leads",
    SMART_VIEWS: "Smart Views",
    LEADS_NEAR_ME: "Leads Near Me",
    ACTIVITES: "Activities",
    TASKS: "Tasks",
    REPORTS: "Reports",
    OPPORTUNITIES: "Opportunities",
    DASHBOARD: "Dashboard",
    GOALS: "Goals",
    ACCOUNTS: "Accounts",
    CUSTOM_APPS: "Custom Apps",
    DYNAMIC_FORMS: "Dynamic Forms"
};

export const lsqEntity = {
    LEAD: "LEAD",
    ACTIVITY: "ACTIVITY",
    TASK: "TASK",
    ACCOUNTS: "ACCOUNTS",
    OPPORTUINITY: "OPPORTUNITY"
}

export const LSQApplications = {
    'MOBILE': 'MOBILE',
    'WEB': 'WEB'
};

export const smartViewTypeToEntityMap = {
    0: lsqEntity.LEAD,
    1: lsqEntity.ACTIVITY,
    2: lsqEntity.TASK,
    4: lsqEntity.ACCOUNTS,
    5: lsqEntity.OPPORTUINITY
}

export const widgetTypes = {
    TASK_COUNTER: "taskcounter",
    TASK_LIST: "tasklist",
    WEB_VIEW: "webview",
    QUICK_LAUNCHER: "quicklauncher",
    BANNER: "banner",
    LEAD_SHORTCUT: "leadshortcut",
    LEAD_LIST: "leadlist",
    SMART_VIEWS_COUNTER: "smartviewscounter",
    MY_SCORECARD: "myscorecard",
    LEAD_METRICS: "leadmetrics",
    MY_ACTIVITIES: "myactivities",
    DISTANCE_TRAVELLED: "distancetravelled",
    SIERA_ACE_WEBVIEW : "sieraace",
    TILES_BANNER : "tilesbanner",
    MY_OPPORTUNITIES : "myopportunities",
    EXPERIMENTAL_WIDGETS : "experimentalWidgets",
    KEY_LEAD_MATRICS :  "keyleadmetrics",
    NEAR_ME : "nearme",
    TASK_COUNTER_V2 : "taskcounterv2",
    TASK_COUNTER_FUEL_BAR : "taskcounterfuelbar",
    CUSTOM_FAB : "customfab",
    USER_CHECKIN_STATUS : "usercheckinstatus",
    IMAGE_GALLERY : "imagegallery",
}

export const widgetTemplateIds = {
    TASK_COUNTER: 1,
    TASK_LIST: 2,
    WEB_VIEW: 3,
    QUICK_LAUNCHER: 4,
    BANNER: 5,
    LEAD_SHORTCUT: 6,
    LEAD_LIST: 7,
    MY_OPPORTUNITIES : 8,
    SMART_VIEWS_COUNTER: 9,
    MY_SCORECARD: 10,
    MY_ACTIVITIES: 11,
    DISTANCE_TRAVELLED: 12,
    TASK_COUNTER_V2 : 13,
    LEAD_METRICS: 14,
    KEY_LEAD_MATRICS : 15,
    NEAR_ME : 17,
    TASK_COUNTER_FUEL_BAR : 18,
    TILES_BANNER : 20,
    SIERA_ACE_WEBVIEW : 21,
    CUSTOM_FAB : 16,
    IMAGE_GALLERY : 23,
}
