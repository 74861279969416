// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserProperties, setUserId} from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_ANALYTICS_API_KEY,
  authDomain: process.env.REACT_APP_ANALYTICS_AUTHDOMAIN,
  projectId: process.env.REACT_APP_ANALYTICS_PROJECTID,
  storageBucket: process.env.REACT_APP_ANALYTICS_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_ANALYTICS_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_ANALYTICS_APPID,
  measurementId: process.env.REACT_APP_ANALYTICS_MEASUREMENTID
};

const userAttributesToInclude = {
    'emailAddress': true,
    'orgCode': true,
    'regionId': true,
    'firstName': true,
    'lastName': true,
    'role': true,
    'orgName': true,
    'type': true,
    'teamId': true,
    'teamName': true,
    'groupId': true,
    'name': true
}

const init = () => {
    initializeApp(firebaseConfig);
}

const sendEvent = (action, payload) => {
    if (process.env.NODE_ENV === "production") {
        const analytics = getAnalytics();
        logEvent(analytics, action, payload);
    }
}

const setUser = (user) => {
    const analytics = getAnalytics();
    setUserId(analytics, user.id);
    for (let key in user) {
        const userProp = { [key]: user[key]}
        if(userAttributesToInclude[key]) {
            setUserProperties(analytics, userProp);
        }
    }
}
export default {
    init,
    sendEvent,
    setUser,
}