import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  globalConstants,
  stringConstants,
  widgetConstants,
} from "../constants/globalConstant";
import analytics from "../utils/analytics";
import CustomInput from "../components/CustomInput";
import { CustomDropdown } from "../components/CustomDropdown/CustomDropdown";
import { listOptionTemplate, valueTemplate } from "../utils/dropdownUtils";
import CustomButton from "../components/CustomButton";
import CustomRadioGroup from "../components/CustomRadioGroup";
import useWidgetEssentials from "../hooks/useWidgetEssentials";
import {
  getWidgetPreviewOfExperimentalWidget,
  getWidgetSignature,
} from "../utils/widgetUtils";
import { resetWidgetConfiguration } from "../reducers/widgetTemplateListSlice";
import { apiAddWidget, apiUpdateWidget } from "../reducers/previewPaneSlice";

export default function UserCheckInStatusSettings() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { mode, selectedWidgetConfiguration, selectedWidget, homepageId } =
    useWidgetEssentials();
  const { navigateTo: navigateToOptions, displayStyle: displayStyles } =
    selectedWidgetConfiguration.metaData;
  const defaultValues = {
    title: stringConstants.USER_CHECKIN_STATUS,
    navigateTo: {},
    displayStyle: displayStyles?.length > 0 && displayStyles[0].value,
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({ defaultValues: defaultValues });

  useEffect(() => {
    if (mode === globalConstants.UPDATE) {
      const outdatedMetaValue = selectedWidgetConfiguration.metaValue;
      const outdatedConfig = outdatedMetaValue.config;
      const { title, displayStyle, navigateTo } = outdatedConfig;
      setValue("title", title);
      setValue("navigateTo", navigateTo);
      setValue("displayStyle", displayStyle);
    }
  }, []);

  const onSubmit = (data) => {
    if (selectedWidget.isExperimental) {
      getWidgetPreviewOfExperimentalWidget(selectedWidget.imageUrl, dispatch);
      return;
    }
    if (!data) return;
    const config = {
      title: data.title.trim(),
      navigateTo: data.navigateTo,
      displayStyle: data.displayStyle,
    };
    const metaPostValue = getWidgetSignature(
      homepageId,
      selectedWidget.id,
      config
    );
    const analytics_data = {
      category: "Widget",
      type: "click",
      widgetType: "usercheckinstatus",
    };
    if (mode === globalConstants.CREATE) {
      dispatch(apiAddWidget(metaPostValue));
      analytics.sendEvent("Adding_usercheckinstatus", analytics_data);
    } else {
      const updatedMetaValue = {
        widgetId: selectedWidgetConfiguration?.metaValue.widgetId,
        config: {
          ...metaPostValue.config,
        },
      };
      dispatch(apiUpdateWidget(updatedMetaValue));
      (analytics_data["widgetId"] =
        selectedWidgetConfiguration?.metaValue.widgetId);
        analytics.sendEvent("Updating_usercheckinstatus", analytics_data);
    }
    dispatch(resetWidgetConfiguration());
  };

  const onResetClicked = () => {
    reset();
  };

  return (
    <form
      className="flex flex-column w-full relative h-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex-1 overflow-y-auto">
        <div className={`flex flex-column mt-5 lg:mx-6 md:mx-5 sm: mx-4 gap-3`}>
          <CustomInput
            control={control}
            errors={errors}
            fieldName="title"
            maxLength={30}
            className="lsq-input-bold"
            label={t(widgetConstants.DISPLAY_NAME)}
            isMandatory={true}
            data-testid="title"
          />
        </div>
        <div
          className={`flex flex-column mt-5 lg:mx-6 md:mx-5 sm: mx-4 gap-3 `}
        >
          <CustomDropdown
            fieldName="navigateTo"
            control={control}
            errors={errors}
            className="w-full text-base lsq-input"
            options={navigateToOptions}
            optionLabel="name"
            placeholder="Select"
            itemTemplate={listOptionTemplate}
            valueTemplate={valueTemplate}
            label={t(widgetConstants.NAVIGATE_TO)}
            isMandatory={true}
            data-testid="navigate-to"
          />
          <CustomRadioGroup
            control={control}
            label={widgetConstants.DISPLAY_STYLE}
            options={displayStyles}
            radioName="displayStyle"
          />
        </div>
      </div>
      <div
        className={
          "flex flex-row align-items-center justify-content-end w-11 absolute bottom-0 pb-4 gap-2"
        }
      >
        <CustomButton
          type="reset"
          label={globalConstants.RESET}
          varient="text"
          onClick={onResetClicked}
        />
        <CustomButton
          type="submit"
          label={globalConstants.SAVE}
          varient="filled"
        />
      </div>
    </form>
  );
}
