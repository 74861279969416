import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ROUTES_PATH } from "../constants/globalConstant";
import Teams from "../views/Teams";
import HomepagesView from "../views/HomepagesView";
import HomeScreen from "../views/HomeScreen";
import MenuBuilder from "../views/MenuBuilder";
import Templates from "../views/Templates";
import MenuBuilderView from "../views/MenuBuilder/MenuBuilderView";
import { sideMenuOptions, sideMenuBottomOptions } from "../utils/homepageUtils";
import LeadControlsList from "../views/LeadControls/LeadControlsList";
import ThemeBuilderMain from "../views/ThemeBuilder/main.js";
import LeadControls from "../views/LeadControls/LeadControls";
const SideNavigationMenu = React.lazy(() =>
  import("../components/SideNavigationMenu")
);

function AppRoutes() {
  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <SideNavigationMenu
          sideMenuOptions={sideMenuOptions}
          sideMenuBottomOptions={sideMenuBottomOptions}
        />
        <div className="main-content">
          <Routes>
            <Route path={""} element={<Teams />} />
            <Route path={ROUTES_PATH.homepages} element={<HomepagesView />} />
            <Route path={ROUTES_PATH.menus} element={<MenuBuilderView />} />
            <Route path={ROUTES_PATH.homebuilder} element={<HomeScreen />} />
            <Route path={ROUTES_PATH.menubuilder} element={<MenuBuilder />} />
            <Route path={ROUTES_PATH.templates} element={<Templates />} />
            <Route path={ROUTES_PATH.themebuilder} element={<ThemeBuilderMain />} />
            <Route path={ROUTES_PATH.leadControls} element={<LeadControlsList />} />
            <Route path={ROUTES_PATH.leadControlBuilder} element={<LeadControls />} />
            <Route path="*" element={<Navigate to={{ pathname: "/" }} />} />
          </Routes>
        </div>
      </Suspense>
    </Router>
  );
}

export default AppRoutes;
