import React from "react";
import SieraAceWebView from "./SieraAceWebView";
import PropTypes from "prop-types";

const SieraAceWebViewWidgetFactory = ({ widgetInformation }) => {
  const { config } = widgetInformation;
  return <SieraAceWebView configData={config} />;
};

SieraAceWebViewWidgetFactory.propTypes = {
  widgetInformation: PropTypes.object,
};

export default SieraAceWebViewWidgetFactory;
