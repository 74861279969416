import {
  API_ADD_THEME,
  API_DELETE_THEMES,
  API_EDIT_THEME,
  API_GET_TEAMS_THEMES,
  API_GET_THEMES,
  API_UPDATE_THEME,
} from "../api/configurationScreenServices";
import {
  globalConstants,
  toastMessageConstants,
} from "../constants/globalConstant";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
  toastMessages: [],
  theme: { responseStatus: globalConstants.PENDING },
  deleteTheme: { responseStatus: globalConstants.FULFILLED },
  createTheme: { responseStatus: globalConstants.FULFILLED },
  editTheme: { responseStatus: globalConstants.FULFILLED },
};

export const getThemes = createAsyncThunk(
  "themeBuilder/getThemes",
  async (_, thunkAPI) => {
    const response = await API_GET_THEMES();
    const { data } = response;
    if (response?.status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const getTeamsThemes = createAsyncThunk(
  "themeBuilder/getTeamsThemes",
  async (_, thunkAPI) => {
    const response = await API_GET_TEAMS_THEMES();
    const { data } = response;
    if (response?.status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const getTheme = createAsyncThunk(
  "themeBuilder/getTheme",
  async (_, { rejectWithValue }) => {
    const response = await API_GET_THEMES();
    if (response.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const deleteThemeById = createAsyncThunk(
  "themeBuilder/deleteThemeById",
  async (id, { rejectWithValue }) => {
    const response = await API_DELETE_THEMES(id);
    if (response.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const createThemeByAPI = createAsyncThunk(
  "themeBuilder/createThemeByAPI",
  async (payload, { getState, rejectWithValue }) => {
    const state = getState();
    const response = await API_ADD_THEME({
      ...payload,
      addTeams: [state.homepage.user.orgCode],
    });
    if (response.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const editThemeByAPI = createAsyncThunk(
  "themeBuilder/editThemeByAPI",
  async ({ id, data }, { rejectWithValue }) => {
    const response = await API_EDIT_THEME(id, data);
    if (response.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const apiAddTheme = createAsyncThunk(
  "themeBuilder/apiAddTheme",
  async ({ postBody }, thunkAPI) => {
    const response = await API_ADD_THEME(postBody);
    const { data } = response;
    if (response?.status !== 200) {
      const errorMessage =
        response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const apiUpdateTheme = createAsyncThunk(
  "themeBuilder/apiUpdateTheme",
  async ({ themeId, postBody }, thunkAPI) => {
    const response = await API_UPDATE_THEME(themeId, postBody);
    const { data } = response;
    if (response?.status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

const themeBuilderSlice = createSlice({
  name: "themeBuilder",
  initialState,
  reducers: {
    addToastMessage(state, action) {
      state.toastMessages.push(action.payload);
    },
    resetToastMessageFromThemeBuilder(state) {
      state.toastMessages.shift();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTheme.pending, (state) => {
      state.theme = {
        response: null,
        responseStatus: globalConstants.PENDING,
      };
    });
    builder.addCase(getTheme.fulfilled, (state, action) => {
      state.theme = {
        response: action.payload,
        responseStatus: globalConstants.FULFILLED,
      };
    });
    builder.addCase(getTheme.rejected, (state, action) => {
      state.deleteTheme = {
        responseStatus: globalConstants.REJECTED,
      };
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
    builder.addCase(deleteThemeById.pending, (state) => {
      state.deleteTheme = {
        responseStatus: globalConstants.PENDING,
      };
    });
    builder.addCase(deleteThemeById.fulfilled, (state) => {
      state.deleteTheme = {
        responseStatus: globalConstants.FULFILLED,
      };
      state.theme = {
        response: [],
        responseStatus: globalConstants.FULFILLED,
      };
      state.toastMessages.push({
        type: toastMessageConstants.SUCCESS_TYPE,
        title: toastMessageConstants.UNPUBLISHED,
        message: toastMessageConstants.UNPUBLISHED_INFO,
      });
    });
    builder.addCase(deleteThemeById.rejected, (state, action) => {
      state.deleteTheme = {
        responseStatus: globalConstants.REJECTED,
      };
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
    builder.addCase(createThemeByAPI.pending, (state) => {
      state.createTheme = {
        responseStatus: globalConstants.PENDING,
      };
    });
    builder.addCase(createThemeByAPI.fulfilled, (state, action) => {
      state.createTheme = {
        responseStatus: globalConstants.FULFILLED,
      };
      state.theme = {
        response: [action.payload.theme],
        responseStatus: globalConstants.FULFILLED,
      };
      state.toastMessages.push({
        type: toastMessageConstants.SUCCESS_TYPE,
        title: toastMessageConstants.PUBLISHED,
        message: toastMessageConstants.PUBLISHED_INFO,
      });
    });
    builder.addCase(createThemeByAPI.rejected, (state, action) => {
      state.createTheme = {
        responseStatus: globalConstants.REJECTED,
      };
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
    builder.addCase(editThemeByAPI.pending, (state) => {
      state.editTheme = {
        responseStatus: globalConstants.PENDING,
      };
    });
    builder.addCase(editThemeByAPI.fulfilled, (state, action) => {
      state.editTheme = {
        responseStatus: globalConstants.FULFILLED,
      };
      state.theme = {
        response: [action.payload.theme],
        responseStatus: globalConstants.FULFILLED,
      };
      state.toastMessages.push({
        type: toastMessageConstants.SUCCESS_TYPE,
        title: toastMessageConstants.SAVED,
        message: toastMessageConstants.SAVED_INFO,
      });
    });
    builder.addCase(editThemeByAPI.rejected, (state, action) => {
      state.editTheme = {
        responseStatus: globalConstants.REJECTED,
      };
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
  },
});

export const { addToastMessage, resetToastMessageFromThemeBuilder } =
  themeBuilderSlice.actions;

export default themeBuilderSlice.reducer;
