import React from "react";
import { ProgressBar } from "primereact/progressbar";

export const IndeterminateLoader = ({ color, height, backgroundColor }) => {
  return (
    <div data-testid = "indeterminateLoader">
      <ProgressBar
      mode={"indeterminate"}
      color={color}
      style={{ height: `${height}`, backgroundColor: `${backgroundColor}` }}
    />
    </div>
  );
};
