import React from "react";
import styles from "./styles.module.css";
import CustomColorPicker from "../../components/CustomColorPicker";
import CustomThemeRadioGroup from "../../components/CustomRadioGroup/CustomThemeRadioGroup";
import { themeConstants } from "../../constants/globalConstant";

export default function CustomTheme({
  theme,
  isCustomColorDisabled,
  onChange,
  ...props
}) {
  return (
    <div
      className={`flex flex-column gap-2 ${styles["custom-theme-container"]}`}
      {...props}
    >
      <CustomThemeRadioGroup
        control={theme.control}
        radioName={"name"}
        option={{
          name: themeConstants.CUSTOM,
          primaryColour: "#015CCD",
          secondaryColour: "#303DB1",
          neutralColour: "#1B2332",
        }}
        onChange={onChange}
      />
      {}
      <div
        className={`gap-2 ${styles["custom-color-selector"]} ${
          styles[isCustomColorDisabled && "hide"]
        }`}
      >
        <CustomColorPicker
          fieldName={"primaryColour"}
          label={themeConstants.SELECT_PRIMARY_COLOUR}
          control={theme.control}
          errors={theme.formState.errors}
          isMandatory={true}
        ></CustomColorPicker>
        <CustomColorPicker
          fieldName={"secondaryColour"}
          label={themeConstants.SELECT_SECONDARY_COLOUR}
          control={theme.control}
          errors={theme.formState.errors}
          isMandatory={true}
        ></CustomColorPicker>
        <CustomColorPicker
          fieldName={"neutralColour"}
          label={themeConstants.SELECT_NEUTRAL_COLOUR}
          control={theme.control}
          errors={theme.formState.errors}
          isMandatory={false}
        ></CustomColorPicker>
      </div>
    </div>
  );
}
