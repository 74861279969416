import React, {  useEffect, useState } from "react";
import Text from "../Text";
import { ReactComponent as Edit } from "../../assets/svgIcons/lsq_edit.svg";
import styles from "./styles.module.css";

export const LsqTextEditor = ({ displayText, onSaveHandler }) => {
  const [text, setText] = useState(displayText)
  const [editMode, setEditMode] = useState({ state: false, value: text });

  const handleEdit = () => {
    setEditMode({ state: true, value: text });
  };

  const handleSave = (event) => {
    if (event.target.value) {
      setText(event.target.value);
      setEditMode({ state: false, value: text });
      onSaveHandler(text);
    } else {
      setText(editMode.value);
      setEditMode({ state: false, value: text });
    }
    event.stopPropagation();
  };

  useEffect(()=>{
   setText(displayText);
  },[displayText])

  return (
    <div>
      {editMode.state ? (
        <input
          type="text"
          maxLength={30}
          value={text}
          onBlur={handleSave}
          onChange={(e) => setText(e.target.value)}
          className = {styles["text-edit"]}
          autoFocus
          data-testid="input-box"
        />
      ) : (
        <div
          className={`flex gap-1 cursor-pointer align-items-center`}
          onClick={handleEdit}
          data-testid="lsq-text-box"
        >
          <Text color="#314361" type="T1B" className="min-w-max">
            {text}
          </Text>
          <Edit
            className="lsq-edit"
            data-testid="edit-icon"
          ></Edit>
        </div>
      )}
    </div>
  );
};
