import React from "react";
import styles from "./styles.module.css";
import Text from "../Text";
import CustomButton from "../CustomButton";

export default function InfoBanner({
  state,
  bannerImage,
  bannerTitle,
  bannerInfo,
  actionButtonLabel,
  actionButtonOnClick,
  dismissButtonOnClick,
  hasDismissButton = true,
}) {
  if (state) {
    document.body.classList.add(`${styles["active-modal"]}`);
  } else {
    document.body.classList.remove(`${styles["active-modal"]}`);
  }
  return (
    state && (
      <div
        className={`flex justify-content-center align-items-center w-screen h-screen fixed top-0 right-0 bottom-0 left-0 ${styles["banner"]}`}
      >
        <div
          className={`flex justify-content-center align-items-center w-screen h-screen fixed top-0 right-0 bottom-0 left-0 ${styles["banner-background"]}`}
          style={{
            background: "rgba(0, 0, 0, 0.5)",
            backdropFilter: "blur(22px)",
          }}
        >
          <div className={`flex flex-column gap-2 ${styles["banner-box"]}`}>
            <img className="w-full" src={bannerImage}></img>
            <div className="flex flex-column">
              <Text type="H2B" color="var(--text-focused)">
                {bannerTitle}
              </Text>
              <Text type="T3" color="var(--text-primary)">
                {bannerInfo}
              </Text>
            </div>
            <div className="flex justify-content-end gap-2">
              {hasDismissButton && (
                <CustomButton
                  varient="text"
                  label={"Dismiss"}
                  onClick={dismissButtonOnClick}
                  data-testid="dismiss-btn"
                />
              )}
              <CustomButton
                varient="filled"
                label={actionButtonLabel}
                data-testid="action-btn"
                onClick={actionButtonOnClick}
              ></CustomButton>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
