import { FileUpload } from "primereact/fileupload";
import Text from "../Text";
import { useTranslation } from "react-i18next";
import "./style.css";
import { FormErrorMessage } from "../FormErrorMessage";
import { useState } from "react";
import { Tooltip } from "primereact/tooltip";

export const CustomImageInput = ({
  label,
  imageUploadHandler,
  maxFileSize,
  tooltipContent,
  tooltipStyling,
  isImageInputDisabled = false,
}) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { t } = useTranslation();
  return (
    <div>
      <Text type="T4" className="mb-2">
        {label}
      </Text>
      <div
        className={`flex flex-row justify-content-between align-items-center img-upload-container`}
      >
        <div className="flex flex-column justify-content-center ml-2">
          <Text type="T4" color="#0A1F43">
            {t("Upload Image")}
          </Text>
          <Text type="T4" color="#627087">
            {t("File MaxSize")}
          </Text>
        </div>
        <div className={`custom-file-upload`} data-testid="file-upload">
          <FileUpload
            className="w-5"
            mode="basic"
            name="banner-image"
            accept="image/jpeg,image/png,image/jpg,image/gif"
            maxFileSize={maxFileSize}
            chooseLabel="Add"
            customUpload={true}
            auto
            uploadHandler={(e) => {
              imageUploadHandler(e);
              setShowErrorMessage(false);
            }}
            onValidationFail={() => {
              setShowErrorMessage(true);
            }}
            data-testid="primary-file-upload"
            disabled={isImageInputDisabled}
          />
          <Tooltip
            position="bottom"
            content={tooltipContent}
            target={`.custom-file-upload`}
            className="custom-tooltip"
            style={tooltipStyling}
          />
        </div>
      </div>
      {showErrorMessage && (
        <FormErrorMessage
          message={`Please upload a file less than ${maxFileSize / 1000000}mb!`}
        />
      )}
    </div>
  );
};
