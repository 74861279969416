import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import CustomButton from "../components/CustomButton";
import CustomInput from "../components/CustomInput";
import TaskTypeMultiselectDropdown from "../components/TaskTypeMultiselectDropdown";
import { Skeleton } from "primereact/skeleton";
import { useDispatch } from "react-redux";
import { resetWidgetConfiguration } from "../reducers/widgetTemplateListSlice";
import { apiAddWidget, apiUpdateWidget } from "../reducers/previewPaneSlice";
import {
  formatTaskMetaValue,
  formatTaskMetaValueToTaskFieldValue,
} from "../components/TaskTypeMultiselectDropdown/TaskTypeMultiselectDropdownUtils";
import { listOptionTemplate, valueTemplate } from "../utils/dropdownUtils";
import useWidgetEssentials from "../hooks/useWidgetEssentials";
import {
  formatTaskTypes,
  getWidgetPreviewOfExperimentalWidget,
  getWidgetSignature,
} from "../utils/widgetUtils";
import analytics from "../utils/analytics";
import {
  globalConstants,
  stringConstants,
} from "../constants/globalConstant";
import { CustomDropdown } from "../components/CustomDropdown/CustomDropdown";

export default function TaskCounterFuelBarSettings() {
  const { selectedWidget, mode, selectedWidgetConfiguration, homepageId } =
    useWidgetEssentials();
  const { taskType, dateFilters } = selectedWidgetConfiguration.metaData;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const taskTypes = formatTaskTypes(taskType);
  const defaultValues = {
    taskTitle: "Task Completed",
    tasksToDisplay: {},
    dateFilters: { id: "Today", name: "Today" },
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({ defaultValues });
  const onSubmit = (data) => {
    if (selectedWidget.isExperimental) {
      getWidgetPreviewOfExperimentalWidget(selectedWidget.imageUrl, dispatch);
      return;
    }
    const config = {
      title: data.taskTitle.trim(),
      taskType: formatTaskMetaValue(data.tasksToDisplay, taskTypes),
      dateFilters: data?.dateFilters,
    };
    const metaValueData = getWidgetSignature(
      homepageId,
      selectedWidget?.id,
      config
    );
    const analytics_data = {
      category: "Widget",
      type: "click",
      widgetType: "TaskCounterFuelbar",
      displayType: metaValueData.config.displayType,
    };
    if (mode === globalConstants.UPDATE) {
      const updatedMetaData = {
        widgetId: selectedWidgetConfiguration?.metaValue.widgetId,
        config: {
          ...metaValueData.config,
        },
      };
      dispatch(apiUpdateWidget(updatedMetaData));
      analytics_data["widgetId"] =
        selectedWidgetConfiguration?.metaValue.widgetId;
      analytics.sendEvent("Updating_TaskCounter_FuelBar", analytics_data);
    } else if (mode === globalConstants.CREATE) {
      dispatch(apiAddWidget(metaValueData));
      analytics.sendEvent("Adding_TaskCounter_FuelBar", analytics_data);
    }
    dispatch(resetWidgetConfiguration());
  };

  useEffect(() => {
    if (
      mode === globalConstants.UPDATE &&
      Object.keys(selectedWidgetConfiguration.metaValue).length !== 0
    ) {
      const taskMetaFieldValue = formatTaskMetaValueToTaskFieldValue(
        selectedWidgetConfiguration.metaValue.config.taskType,
        taskTypes
      );
      const outdatedMetaValue = selectedWidgetConfiguration.metaValue;
      const outdatedConfig = outdatedMetaValue.config;
      const { title, dateFilters } = outdatedConfig;
      setValue("taskTitle", title);
      setValue("tasksToDisplay", taskMetaFieldValue);
      setValue("dateFilters", dateFilters);
    }
  }, []);

  const isTaskTypesLoaded =
    taskTypes.appointments.length > 0 || taskTypes.todos.length > 0;
  return (
    <form
      style={{ height: "90%" }}
      className="flex flex-column w-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex-1 overflow-y-auto">
        <div className={`flex flex-column mt-5 lg:mx-6 md:mx-5 sm: mx-4 gap-3`}>
          <CustomInput
            fieldName="taskTitle"
            errors={errors}
            control={control}
            isMandatory
            label="Display Name"
            maxLength={30}
            placeholder="Type Here"
          />
          {isTaskTypesLoaded ? (
            <TaskTypeMultiselectDropdown
              isMandatory={true}
              taskTypes={taskTypes}
              errors={errors}
              fieldName="tasksToDisplay"
              control={control}
            />
          ) : (
            <Skeleton type="rectangle" width="70%" height="2rem"></Skeleton>
          )}
          <CustomDropdown
            fieldName="dateFilters"
            control={control}
            errors={errors}
            className="w-full text-base lsq-input"
            options={dateFilters}
            optionLabel="name"
            placeholder="Select"
            itemTemplate={listOptionTemplate}
            valueTemplate={valueTemplate}
            label={t(stringConstants.SELECT_DEFAULT_DATE_RANGE)}
          />
        </div>
      </div>
      <div
        className={`flex w-11 py-2 mt-3 align-self-center align-items-center justify-content-end mt-auto gap-2`}
      >
        <CustomButton
          type="reset"
          onClick={() => reset()}
          varient="text"
          label={t(globalConstants.RESET)}
        />

        <CustomButton
          type="submit"
          varient="filled"
          label={t(globalConstants.SAVE)}
        />
      </div>
    </form>
  );
}

TaskCounterFuelBarSettings.propTypes = {
  widgetMetaData: PropTypes.object,
};
