import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomInput from "../components/CustomInput";
import { CustomDropdown } from "../components/CustomDropdown/CustomDropdown";
import CustomButton from "../components/CustomButton/index";
import { useDispatch } from "react-redux";
import { resetWidgetConfiguration } from "../reducers/widgetTemplateListSlice";
import useWidgetEssentials from "../hooks/useWidgetEssentials";
import { getWidgetPreviewOfExperimentalWidget, getWidgetSignature } from "../utils/widgetUtils";
import { apiAddWidget, apiUpdateWidget } from "../reducers/previewPaneSlice";
import { valueTemplate, listOptionTemplate } from "../utils/dropdownUtils";
import analytics from "../utils/analytics";
import { stringConstants } from "../constants/globalConstant";

export const LeadShortcutSettings = () => {
  const dispatch = useDispatch();
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const { mode, selectedWidget, selectedWidgetConfiguration, homepageId } =
    useWidgetEssentials();
  const { defaultCriteria, dateFilters } = selectedWidgetConfiguration.metaData;
  const { t } = useTranslation();
  const defaultValues = {
    title: "Favourite Leads",
    defaultCriteria: { id: "", name: "" },
    dateFilters : { id: "Today", name: "Today" },
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    reset,
    getValues,
  } = useForm({ defaultValues });

  const watchAll = watch(["title", "defaultCriteria"]);

  const onSubmit = (data) => {
    if(selectedWidget.isExperimental){
      getWidgetPreviewOfExperimentalWidget(selectedWidget.imageUrl, dispatch)
      return;
    }
    const config = {
      title: data.title.trim(),
      defaultCriteria: data.defaultCriteria,
      dateFilters : data?.dateFilters
    };
    const metaPostValue = getWidgetSignature(
      homepageId,
      selectedWidget.id,
      config
    );
    const analytics_data = {
      'category': 'Widget',
      'type': 'click',
      'widgetType': 'Leadshortcut',
      'defaultCriteria': metaPostValue.config.defaultCriteria?.name
    }
    if (mode === "CREATE") {
      dispatch(apiAddWidget(metaPostValue));
      analytics.sendEvent('Adding_leadshortcut', analytics_data)
    } else {
      const updatedMetaValue = {
        widgetId: selectedWidgetConfiguration?.metaValue.widgetId,
        config: {
          ...metaPostValue.config,
        },
      };
      dispatch(apiUpdateWidget(updatedMetaValue));
      analytics_data["widgetId"] = selectedWidgetConfiguration?.metaValue.widgetId;
      analytics.sendEvent('Updating_leadshortcut', analytics_data)
    }
    dispatch(resetWidgetConfiguration());
  };

  //set values to their respective fields , when trying to update the widget
  useEffect(() => {
    if (mode === "UPDATE") {
      const outdatedMetaValue = selectedWidgetConfiguration.metaValue;
      const outdatedConfig = outdatedMetaValue.config;
      const { title, defaultCriteria, dateFilters } = outdatedConfig;

      setValue("title", title);
      setValue("defaultCriteria", defaultCriteria);
      setValue("dateFilters", dateFilters);
    }
  }, []);

  // To enable or disable the Save Button
  useEffect(() => {
    const title = getValues("title");
    const titleLength = title.trim().length;
    let selectedCriteria = getValues("defaultCriteria");
    selectedCriteria = selectedCriteria.name;
   const selectedCriteriaLength = selectedCriteria.trim().length;
    if (titleLength !== 0 && selectedCriteriaLength !== 0) setIsSaveDisabled(false);
    else setIsSaveDisabled(true);
  }, [watchAll]);

  return (
    <form
      className="flex flex-column w-full relative h-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex-1 overflow-y-auto">
        <div className={`flex flex-column mt-5 lg:mx-6 md:mx-5 sm: mx-4 gap-3`}>
          <CustomInput
            fieldName="title"
            errors={errors}
            control={control}
            isMandatory={true}
            label="Display Name"
            maxLength={30}
            className="lsq-input-bold"
            data-testid="title"
          />
        </div>
        <div
          className={`flex flex-column mt-5 lg:mx-6 md:mx-5 sm: mx-4 gap-3 `}
        >
          <CustomDropdown
            fieldName="defaultCriteria"
            label={t("Default Sort Order")}
            className="w-full text-base lsq-input"
            options={defaultCriteria}
            placeholder="Select"
            control={control}
            errors={errors}
            isMandatory={true}
            optionLabel="name"
            itemTemplate={listOptionTemplate}
            valueTemplate={valueTemplate}
            data-testid="default-criteria"
          />
          <CustomDropdown
            fieldName="dateFilters"
            control={control}
            errors={errors}
            className="w-full text-base lsq-input"
            options={dateFilters}
            optionLabel="name"
            placeholder="Select"
            itemTemplate={listOptionTemplate}
            valueTemplate={valueTemplate}
            label={t(stringConstants.SELECT_DEFAULT_DATE_RANGE)}
          />
        </div>
      </div>
      <div
        className={
          "flex flex-row align-items-center justify-content-end w-11 absolute bottom-0 pb-4 gap-2"
        }
      >
        <CustomButton
          type="reset"
          label="Reset"
          varient="text"
          onClick={() => reset({ defaultValues })}
        />
        <CustomButton
          type="submit"
          label="Save"
          varient="filled"
          disabled={isSaveDisabled}
        />
      </div>
    </form>
  );
};
