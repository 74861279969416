import React from "react";
import styles from "./styles.module.css";
import { ReactComponent as HelpIcon } from "../../../assets/svgIcons/lsq_help.svg";
import { ReactComponent as SearchIcon } from "../../../assets/svgIcons/search.svg";
import Text from "../../../components/Text";
import {
  leadControlsConstants,
  toastMessageConstants,
} from "../../../constants/globalConstant";
import LsqSwitch from "../../../components/LsqSwitch";
import CustomInputV2 from "../../../components/CustomInputV2";
import LeadAvailableCard from "./LeadAvailableCard";
import {
  addToastMessageFromLeadControls,
  changeSelectedLeadCard,
  setLeadConfigurationDirty,
} from "../../../reducers/leadControlsSlice";
import useToast from "../../../hooks/useToast";
import { Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "primereact/tooltip";

export default function LeadCardConfigurationPanel({
  control,
  setValue,
  visibleCardFields,
  availableCardFields,
  visibleCardActions,
  availableCardActions,
  searchState,
  searchedAvailableFields,
}) {
  const dispatch = useDispatch();
  const { selectedLeadCard } = useSelector((state) => state.leadControls);
  const toast = useToast(addToastMessageFromLeadControls);

  const onLeadAvailableActionClicked = (option, index) => {
    const emptyIndex = visibleCardActions.fields.findIndex(
      (item) => item.view === leadControlsConstants.EMPTY
    );
    if (emptyIndex < 0)
      toast.warn(toastMessageConstants.MAXIMUM_FOUR_ACTIONS_IN_LEAD_CARD);
    else {
      visibleCardActions.update(emptyIndex, {
        ...option,
        view: leadControlsConstants.FILLED,
        key: emptyIndex + 1,
      });
      availableCardActions.remove(index);
      dispatch(setLeadConfigurationDirty(true));
    }
  };

  return (
    <div className="flex flex-column flex-start h-full">
      <div className="flex flex-column px-3 pt-3 mb-2 gap-1">
        <div
          className={`flex w-full justify-content-between align-items-center`}
        >
          <Text color="var(--text-primary)" type="T1B">
            {leadControlsConstants.ALL_FIELDS}
          </Text>
          <HelpIcon
            style={{ width: "1.2rem" }}
            className="help-icon cursor-pointer"
          />
          <Tooltip
            position="top"
            content={leadControlsConstants.LEAD_CARD_HELP_INFO}
            target={`.help-icon`}
            className="preview-pane-tooltip"
          />
        </div>
        <div
          className={`flex w-full justify-content-between align-items-center`}
        >
          <Text color="var(--text-primary)" type="sub-heading">
            {leadControlsConstants.SHOW_IN_LEAD_LIST_CARD}
          </Text>
          <LsqSwitch
            control={control}
            switchName="leadCardFieldsEnabledInLeadList"
            onChange={(e) => {
              setValue("leadCardFieldsEnabledInLeadList", e.target.value);
              dispatch(
                changeSelectedLeadCard({
                  ...selectedLeadCard,
                  isFieldsEnabledInLeadList: e.target.value,
                })
              );
              dispatch(setLeadConfigurationDirty(true));
            }}
          />
        </div>
        <CustomInputV2
          style={{ height: "2rem", width: "100%" }}
          placeholder={leadControlsConstants.TYPE_TO_SEARCH}
          LeftIcon={SearchIcon}
          value={searchState[0]}
          onChange={(e) => searchState[1](e.target.value)}
        />
      </div>
      <div className="flex flex-column h-full overflow-hidden">
        <div
          className={`flex flex-column align-items-center`}
          style={{ height: "64%" }}
        >
          <Droppable
            type="lead-available-cards"
            droppableId="lead-available-card"
          >
            {(provided) => {
              return (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="flex w-full h-full"
                >
                  <LeadAvailableCard
                    visibleCardFields={visibleCardFields}
                    availableCardFields={availableCardFields}
                    searchedAvailableFields={searchedAvailableFields}
                    provided={provided}
                  ></LeadAvailableCard>
                </div>
              );
            }}
          </Droppable>
          <hr
            style={{
              border: `1px solid #EFEFF0`,
              width: "90%",
            }}
          />
        </div>
        <div className={`flex flex-column gap-1 p-3`} style={{ height: "36%" }}>
          <Text color="var(--text-primary)" type="T1B">
            {leadControlsConstants.CARD_ACTIONS}
          </Text>
          <div
            className={`flex w-full justify-content-between align-items-center`}
          >
            <Text color="var(--text-primary)" type="sub-heading">
              {leadControlsConstants.SHOW_IN_LEAD_LIST_CARD}
            </Text>
            <LsqSwitch
              control={control}
              switchName="leadCardActionsEnabledInLeadList"
              onChange={(e) => {
                setValue("leadCardActionsEnabledInLeadList", e.target.value);
                dispatch(
                  changeSelectedLeadCard({
                    ...selectedLeadCard,
                    isActionsEnabledInLeadList: e.target.value,
                  })
                );
                dispatch(setLeadConfigurationDirty(true));
              }}
            />
          </div>
          <div
            className={`w-full overflow-scroll ${styles["available-card-icon-layout"]}`}
          >
            {availableCardActions.fields.map((option, index) => {
              return (
                <div
                  className={`flex cursor-pointer justify-content-center ${styles["icon-picker-items"]} ${option.id}`}
                  data-testid={option.id}
                  onClick={() => onLeadAvailableActionClicked(option, index)}
                  key={option.id}
                >
                  <Tooltip
                    position="bottom"
                    content={option.name}
                    target={`.${option.id}`}
                    className="preview-pane-tooltip"
                  />
                  <img
                    src={`/images/launcherIcons/${option.imgName.toLowerCase()}.svg`}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
