import { useSelector } from 'react-redux';

function useWidgetEssentials() {
    const selectedWidget = useSelector(
        (state) => state.widgetTemplateList.selectedWidget.widgetDetails
      );
      const mode = useSelector(
        (state) => state.widgetTemplateList.selectedWidget.mode
      );
      const selectedWidgetConfiguration = useSelector(
        (state) => state.widgetTemplateList.selectedWidgetConfiguration
      );
      const homepageId = useSelector(
        (state) => state.homepage.selectedHomepageId
      );

      const previewPaneData = useSelector(
        (state) => state.previewPane
      );

      const isOpportunityEnabled = useSelector(
        state => state.widgetTemplateList.isOpportunityEnabled
      )

      return {selectedWidget , mode , selectedWidgetConfiguration , homepageId , previewPaneData, isOpportunityEnabled}
}

export default useWidgetEssentials