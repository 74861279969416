import React from "react";
import styles from "./styles.module.css";

export default function Modal({ state, children, backgroundStyle }) {
  if (state) {
    document.body.classList.add(`${styles["active-modal"]}`);
  } else {
    document.body.classList.remove(`${styles["active-modal"]}`);
  }
  return (
    state && (
      <div className="flex justify-content-center align-items-center w-screen h-screen fixed top-0 right-0 bottom-0 left-0 z-5">
        <div
          className={`flex justify-content-center align-items-center w-screen h-screen fixed top-0 right-0 bottom-0 left-0 ${styles["modal-background"]}`}
          style={backgroundStyle}
        >
          {children}
        </div>
      </div>
    )
  );
}
