import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import styles from "./styles.module.css";
import { ReactComponent as Star } from "../../assets/svgIcons/star.svg";
import { ReactComponent as StarFilled } from "../../assets/svgIcons/star_filled.svg";
import { ReactComponent as Teams } from "../../assets/svgIcons/lsq_assigned_teams.svg";
import { ReactComponent as Checkbox } from "../../assets/svgIcons/lsq_checkbox.svg";
import { ReactComponent as CheckboxFilled } from "../../assets/svgIcons/lsq_checkbox_filled.svg";
import { ReactComponent as NoThumbnailIllustration } from "../../assets/illustrations/drafts_illustration.svg";
import {
  ROUTES_PATH,
  globalConstants,
  toastMessageConstants,
  dashboardCardConstants,
  casaConstants,
} from "../../constants/globalConstant";
import Text from "../Text";
import { DashboardCardMenu } from "../DashboardCardMenu";
import {
  addToastMessage,
  changeSelectedHomepage,
  updateHomePage,
} from "../../reducers/homePageSlice";
import { teamViewItems, dashboardViewItems } from "../../utils/casaUtils";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import analytics from "../../utils/analytics";
import useToast from "../../hooks/useToast";
import { delay } from "../../utils/globalUtils";

export default function DashboardCard({
  title,
  status,
  teamsCount,
  isTeamView = false,
  teamId = "",
  homepage = null,
  favourite = false,
  onTeamCountClicked,
  isDefault,
  setDialog,
  bulkSelectedHomepagesState = [],
  isHomepagesBulkSelectionEnabled = false,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast(addToastMessage);
  const [isSelected, setIsSelected] = useState(false);
  const cardRef = useOnClickOutside(() => setIsSelected(false));
  const teamsCountRef = useRef(null);
  const [bulkSelectedHomepages, setBulkSelectedHomepages] =
    bulkSelectedHomepagesState;
  const thumnailContainerRef = useRef();
  const statusColor = ({ status }) => {
    let style = "transaparent-status";
    let statusname = "";
    let _homepageStatus = "";
    if (status === casaConstants.PUBLISHED && homepage.hasDraftChanges) {
      _homepageStatus = dashboardCardConstants.UNPUBLISHED_CHANGES;
    } else {
      _homepageStatus = status;
    }
    switch (_homepageStatus) {
      case dashboardCardConstants.PUBLISHED:
        {
          style = "green-status";
          statusname = "Published";
        }
        break;
      case dashboardCardConstants.UNPUBLISHED:
        {
          style = "red-status";
          statusname = "Unpublished";
        }
        break;
      case dashboardCardConstants.UNPUBLISHED_CHANGES:
        {
          style = "orange-status";
          statusname = dashboardCardConstants.PUBLISHED + " (edited)";
        }
        break;
    }
    return (
      <div className={`flex align-items-center w-8`}>
        {status != dashboardCardConstants.DRAFT && (
          <>
            <div className={`${styles[style]} ${styles["status-style"]}`}></div>
            <Text type="T4" color={"#314361"} className={`capitalize ellipsis`}>
              {statusname}
            </Text>
          </>
        )}
      </div>
    );
  };
  const changeColor = () => {
    const homepageId = homepage.homepageId;
    const postBody = {
      isFavourite: homepage.isFavourite === true ? false : true,
    };
    dispatch(updateHomePage({ homepageId, postBody })).then(({ type }) => {
      if (
        type.includes(globalConstants.FULFILLED) &&
        homepage.isFavourite === false
      ) {
        toast.success(
          `${homepage.homepageName} added to favourites successfully!`,
          toastMessageConstants.ADDED_TO_FAVOURITES
        );
      }
    });
  };

  const onUnchecked = (e) => {
    e.stopPropagation();
    setBulkSelectedHomepages((prev) => {
      const obj = { ...prev };
      delete obj[homepage.homepageId];
      return obj;
    });
  };

  const onChecked = (e) => {
    e.stopPropagation();
    if (Object.keys(bulkSelectedHomepages).length >= 10) return;
    setBulkSelectedHomepages((prev) => {
      return { ...prev, [homepage.homepageId]: true };
    });
  };

  const homepageBulkSelectionView = (homepage) => {
    if (bulkSelectedHomepages[homepage.homepageId]) {
      return (
        <CheckboxFilled
          data-testid={`bulkselect-checkbox-filled-${homepage.homepageId}`}
          className="cursor-pointer"
          onClick={onUnchecked}
        />
      );
    } else {
      return (
        <Checkbox
          data-testid={`bulkselect-checkbox-${homepage.homepageId}`}
          className="cursor-pointer"
          onClick={onChecked}
        />
      );
    }
  };

  const openDashboard = (e) => {
    if (cardRef.current?.contains(e.target)) {
      if (
        e.target.tagName === "svg" ||
        e.target.tagName === "path" ||
        teamsCountRef.current?.contains(e.target)
      )
        return;
    }
    dispatch(changeSelectedHomepage(homepage.homepageId));
    navigate(ROUTES_PATH.homebuilder);
    analytics.sendEvent("homepage_card_tap_from_homepage_view", {
      homepageId: homepage.homepageId,
      status: homepage.status,
      homepageName: homepage.homepageName,
      tab: "Homepages",
    });
  };

  const dashboardCardHandler = (e) => {
    openDashboard(e);
    setIsSelected(true);
  };

  useEffect(() => {
    let scrollingInterval;
    let scrollDirection = 1; // 1 for scrolling down, -1 for scrolling up
    let initialStart = true;
    function startScrolling(downward) {
      if (downward) {
        scrollDirection = 1;
      } else {
        scrollDirection = -1;
      }

      const scrollContainer = thumnailContainerRef.current;
      scrollingInterval = setInterval(async function () {
        if (
          scrollContainer.scrollTop ===
            scrollContainer.scrollHeight - scrollContainer.clientHeight &&
          scrollDirection === 1
        ) {
          await delay(1000);
          scrollDirection = -1;
        } else if (scrollContainer.scrollTop === 0 && scrollDirection === -1) {
          if (!initialStart) {
            await delay(1000);
          } else {
            initialStart = false;
          }
          scrollDirection = 1;
        } else {
          scrollContainer.scrollTop += 1 * scrollDirection;
        }
      }, 10);
    }

    function stopScrolling() {
      clearInterval(scrollingInterval);
    }
    thumnailContainerRef.current?.addEventListener("mouseenter", () => {
      startScrolling();
    });
    thumnailContainerRef.current?.addEventListener("mouseleave", () => {
      stopScrolling();
    });

    return () => {
      thumnailContainerRef.current?.removeEventListener("mouseenter", () => {
        startScrolling();
      });
      thumnailContainerRef.current?.removeEventListener("mouseleave", () => {
        stopScrolling();
      });
    };
  }, []);

  return (
    <div onClick={openDashboard} className={`${styles["dashboard-card"]}`}>
      {isHomepagesBulkSelectionEnabled && (
        <div
          className={`flex absolute justify-content-end p-2 ${styles["bulk-selection"]}`}
        >
          <div className={`flex bg-white p-2 ${styles["bulk-selection-card"]}`}>
            {homepageBulkSelectionView(homepage)}
          </div>
        </div>
      )}
      {homepage.hasThumbnail ? (
        <div
          ref={thumnailContainerRef}
          className={`${styles["dashboard-card-thumnail-container"]} w-full `}
        >
          <img height="300px" src={homepage.thumbnailUri} />
        </div>
      ) : (
        <div
          className={`${styles["dashboard-card-thumnail-container"]} w-full`}
        >
          <NoThumbnailIllustration width={"100px"} />
        </div>
      )}
      <div
        className={`flex flex-column relative justify-content-end ${styles["card-container"]}`}
        ref={cardRef}
      >
        <div className={`flex w-full justify-content-end`}>
          <div className={`flex gap-1 bg-white ${styles["card-action-panel"]}`}>
            <div
              className={`flex justify-content-center ${styles["active-star"]} cursor-pointer mr-1`}
              onClick={changeColor}
              data-testid="favourite"
              id="dashboard-favourite"
            >
              {!favourite ? <Star /> : <StarFilled />}
            </div>
            <DashboardCardMenu
              items={!isTeamView ? teamViewItems(status) : dashboardViewItems}
              teamId={teamId}
              homepage={homepage}
              setDialog={setDialog}
            />
          </div>
        </div>
        <div
          className={`flex flex-column justify-content-between ${
            styles["card-main-container"]
          } ${isSelected && styles["active-card"]}`}
          onClick={dashboardCardHandler}
          data-testid="dashboard-card"
        >
          <div className={`flex justify-content-between align-items-center`}>
            <div
              className={`flex align-items-center gap-1 justify-content-start w-9`}
            >
              <Text type="T3B" className={`ellipsis ${styles["title-name"]}`}>
                {title}
              </Text>
            </div>
          </div>
          <div className={`flex align-items-center justify-content-between`}>
            {statusColor({ status })}
            <div
              className={`flex align-items-center cursor-pointer`}
              ref={teamsCountRef}
            >
              {isTeamView && status !== casaConstants.DRAFT && (
                <div
                  onClick={onTeamCountClicked}
                  className={`flex justify-content-center align-items-center ${styles["teams-container"]}`}
                  style={{
                    backgroundColor: `${isDefault}` ? "#ECEDF0" : "#E2ECF9",
                  }}
                  data-testid="teams-count"
                >
                  {isDefault ? (
                    <Text type="T4B" color="#314361">
                      {"Default"}
                    </Text>
                  ) : (
                    <>
                      <Teams style={{ width: "1rem" }} />
                      <Text type="T4B" color="#015CCD">
                        {teamsCount}
                      </Text>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
