import React from "react";
import CustomButton from "../CustomButton";
import { ReactComponent as Back } from "../../assets/svgIcons/back.svg";
import { IndeterminateLoader } from "../IndeterminateLoader/index.js";
import { ReactComponent as CasaLogo } from "../../assets/svgIcons/casa_logo.svg";
import Text from "../Text";

const Scaffold = ({
  hasBackButton,
  onBackButtonClicked,
  children,
  rightActionButtons,
  icon,
  title,
  loading = false,
}) => {
  return (
    <div className="flex h-screen w-screen flex-column flex-start">
      <div
        className={`flex flex-row w-full align-items-center justify-content-between px-3 py-3 h-4rem top-bar`}
      >
        <div className="flex flex-row align-items-center gap-1">
          {hasBackButton && (
            <CustomButton
              varient="text"
              icon={<Back />}
              label=""
              onClick={onBackButtonClicked}
              data-testid="scaffold-back-btn"
            />
          )}
          {icon && (
            <div className="ml-2">
              <CasaLogo />
            </div>
          )}
          {title && (
            <div>
              <Text type="T1B" color="var(--text-primary)">
                {title}
              </Text>
            </div>
          )}
        </div>
        <div className="flex flex-row align-items-center">
          {rightActionButtons}
        </div>
      </div>
      {loading ? (
        <IndeterminateLoader
          color="#0A1F43"
          height="0.20rem"
          backgroundColor="#B0B7C2"
        />
      ) : (
        <div style={{ minHeight: "0.20rem" }} />
      )}
      <div className="flex flex-1 w-full">{children}</div>
    </div>
  );
};

export default Scaffold;
