import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CrossIcon } from "../../assets/svgIcons/lsq_close.svg";
import CustomButton from "../../components/CustomButton";
import LsqSwitch from "../../components/LsqSwitch";
import TeamAssignmentMultiselectTreeDropdown from "../../components/TeamAssignmentMultiselectTreeDropdown";
import {
  teamIdsToTreeList,
  teamListBuilder,
} from "../../components/TeamAssignmentMultiselectTreeDropdown/TeamAssignmentMultiselectTreeDropdownUtils";
import Text from "../../components/Text";
import { globalConstantValues } from "../../constants/globalConstant";
import { apiAssignTeams } from "../../reducers/homePageSlice";
import { generateTeamAssignmentPostData } from "../../utils/casaUtils";
import { hierarchyToLinear } from "../../utils/globalUtils";
import styles from "./styles.module.css";

const SidePanelView = ({ setSidePanelState, sidePanelState }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const teamHierarchy = useSelector((state) => state.homepage.teams);
  const teamDashboards = useSelector((state) => state.homepage.teamDashboards);
  const teamIds = sidePanelState.data?.teamIds;
  const dashboardName = sidePanelState.data?.dashboardName;
  const homepageId = sidePanelState.data?.dashboardId;
  const isDefault = sidePanelState.data?.isDefault;
  const teamLinear = useMemo(() => {
    return hierarchyToLinear(teamHierarchy);
  }, [teamHierarchy]);
  const teamsDefaultValues = {
    isDefault: false,
    teamAssigned: {},
  };
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
  } = useForm({ defaultValues: teamsDefaultValues });
  const isDefaultEnabled = watch("isDefault");
  const watchTeamAssigned = watch("teamAssigned");

  const isSaveDisabled = () => {
   return (isDefaultEnabled === false &&
      Object.keys(watchTeamAssigned).length === 0)
  };

  const onSaveClicked = (data) => {
    const existingTeamAssigned = {
      isDefault: sidePanelState.data?.isDefault,
      teamAssigned: teamIds,
    };

    const currentTeamAssigned = {
      ...data,
    };

    if (isDefaultEnabled) {
      currentTeamAssigned.teamAssigned = {};
    }

    const teamAssigmentPostData = generateTeamAssignmentPostData(
      existingTeamAssigned,
      currentTeamAssigned
    );
    dispatch(apiAssignTeams({ homepageId, postBody: teamAssigmentPostData }));
    setSidePanelState({ state: false, data: null });
  };

  const defaultHomepages = teamDashboards?.defaultDashboards?.homepages;
  const isDefaultSwitchDisabled =
    defaultHomepages?.length >=
      globalConstantValues.MAX_NUMBER_OF_TEAM_DASHBOARD &&
    !defaultHomepages?.includes(parseInt(homepageId));

  useEffect(() => {
    setValue("teamAssigned", teamIdsToTreeList(teamIds, teamLinear));
    setValue("isDefault", isDefault);
  }, [teamIds]);

  const teamTreeList = teamListBuilder(
    teamHierarchy,
    teamDashboards.teamsDashboards,
    homepageId
  );

  if (sidePanelState.data === null) return <></>;
  return (
    <div className="flex flex-column flex-start h-full">
      <div
        className={`flex h-3rem w-full justify-content-between align-items-center ${styles["title-bar"]}`}
      >
        <Text color="var(--text-primary)" type="heading" className="ml-5">
          {t(dashboardName)}
        </Text>
        <CrossIcon
          data-testid="cross-icon"
          onClick={() => setSidePanelState({ state: false, data: null })}
          style={{ cursor: "pointer", fill: "var(--icon-primary)" }}
          className="mr-3"
        />
      </div>
      <div className="h-full overflow-y-auto">
        <div
          className={`flex flex-column justify-content-between h-full py-5 lg:mx-4 md:mx-3 sm: mx-2`}
        >
          <div className="flex flex-column flex-start gap-4 w-full">
            <LsqSwitch
              control={control}
              label="Set as default homepage"
              switchName="isDefault"
              disabled={isDefaultSwitchDisabled}
            />
            {!isDefaultEnabled && (
              <TeamAssignmentMultiselectTreeDropdown
                control={control}
                errors={errors}
                fieldName="teamAssigned"
                teamHierarchy={teamHierarchy}
                teamTreeList={teamTreeList}
              />
            )}
          </div>
          <div
            className={`flex w-full align-items-center justify-content-end gap-2`}
          >
            <CustomButton
              onClick={handleSubmit(onSaveClicked)}
              varient="filled"
              label="Save"
              disabled={isSaveDisabled()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidePanelView;
