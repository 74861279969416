import React from "react";
import styles from "./styles.module.css";
import Text from "../Text";

const ToggleTab = ({
  tabs = [],
  activeTab,
  setActiveTab,
  textType,
  ...props
}) => {
  return (
    <div className={styles["tab-container"]} {...props}>
      {tabs.map((tab, index) => {
        return (
          <div
            id={tab.id + index}
            key={tab.value}
            className={`${styles["tab"]} ${
              activeTab.key === tab.key && styles["active"]
            } ${tab.disabled && styles["disabled"]}`}
            onClick={() => !tab.disabled && setActiveTab(tab)}
            data-testid={tab["data-testid"]}
          >
            <Text
              color={tab.disabled ? "var(--N60)" : "var(--N400)"}
              type={textType ? textType : "T4"}
              className="capitalize"
            >
              {tab.value}
            </Text>
          </div>
        );
      })}
    </div>
  );
};

export default ToggleTab;
