import { API_GET_SIGNED_URL } from "../api/configurationScreenServices";
import { createImage } from "../components/ImageEdit/getCroppedImg";

export const generateWidgetMetaValueForImageGallery = (config, widgetId) => {
  const widgetMetaValue = {
    widget: {
      widgetId: widgetId,
      config: {
        title: config.title,
        size: config.size,
        layout: config.layout,
        frame: config.frame,
        images: config.images,
      },
    },
  };
  return widgetMetaValue;
};

export const formatImages = (images) => {
  const formattedItems = [];
  for (let image of images) {
    formattedItems.push({
      imageKey: image.imageKey,
      imageName: image.imageName,
      navigateTo: image.navigateTo,
      internalLink: image.internalLink,
      externalLink: image.externalLink,
      externalLinkOptions: image.externalLinkOptions,
      label: image.label,
    });
  }
  return formattedItems;
};

export const fetchImageGalleryLinks = async (items = []) => {
  let imageKeys = [];
  for (let item of items) {
    imageKeys.push(API_GET_SIGNED_URL(item.imageKey));
  }
  const imageSignedUrlResponse = await Promise.all(imageKeys);
  let images = [];

  imageSignedUrlResponse.forEach((imageSignedUrl, index) => {
    images.push({
      imageURL: imageSignedUrl.data.url,
      imageName: items[index].imageName,
      navigateTo: items[index].navigateTo,
      label: items[index].label,
      internalLink: items[index].internalLink,
      externalLink: items[index].externalLink,
      externalLinkOptions: items[index].externalLinkOptions,
    });
  });
  return images;
};

export default async function getSquareCroppedURL(imgUrl) {
  const img = await createImage(imgUrl);
  let canvas = document.createElement("canvas");
  let ctx = canvas.getContext("2d");
  if (!ctx) {
    return null;
  }
  let size = Math.min(img.width, img.height);
  canvas.width = size;
  canvas.height = size;
  ctx.drawImage(
    img,
    (img.width - size) / 2,
    (img.height - size) / 2,
    size,
    size,
    0,
    0,
    size,
    size
  );
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(URL.createObjectURL(file));
    }, "image/jpeg");
  });
}
