import React, { useRef } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";
import Text from "../../components/Text";
import { imageGallerySettingsConstants } from "../../constants/globalConstant";

const GalleryScrollView = ({ widgetInformation, imgUrls }) => {
  const ref = useRef();
  const { title, size, frame } = widgetInformation;
  const imageSize = size.match(/Small|Medium|Large/i);
  return (
    <div
      className={styles["gallery-container"]}
      data-testid="gallery-scroll-view"
    >
      <div className="flex flex-row justify-content-between align-items-center mx-2 mt-1">
        <Text type="sub-heading-bold" color="var(--text-focused)">
          {title}
        </Text>
      </div>
      <div
        className="flex overflow-x-scroll my-1 pl-1"
        style={{ gap: "0.25rem" }}
      >
        {imgUrls?.map((element) => {
          return (
            <div
              data-testid={`gallery-element-box-${element.id}`}
              key={element.id}
              className={`${styles["img-container"]} ${
                styles[`img-container-${imageSize[0].toLowerCase()}`]
              } ${frame && styles["img-container-frame"]}`}
            >
              <div
                ref={ref}
                className={`${styles["image"]}`}
                style={{ height: `${ref?.current?.offsetWidth}` }}
              >
                <img
                  src={`${element.imageURL}`}
                  alt="Gallery-Image"
                  className="product-image w-full"
                  onError={(e) =>
                    (e.target.src = `${imageGallerySettingsConstants.DEMO_SQUARE_IMAGE}`)
                  }
                />
              </div>
              <Text type="mobile-T5" color="#627087" className={`word-break`}>
                {element.label}
              </Text>
            </div>
          );
        })}
      </div>
    </div>
  );
};

GalleryScrollView.propTypes = {
  elementList: PropTypes.array,
};

export default GalleryScrollView;
