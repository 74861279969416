import { useCallback } from "react";

const useDebounce = (callback, timer) => {
  let timerId;
  const debouncedFunction = function (...args) {
    if(timerId) clearTimeout(timerId);
    timerId = setTimeout(() => {
      callback.apply(this, args);
    }, timer);
  };

  return useCallback(debouncedFunction, [])
};

export default useDebounce;