import { useFieldArray, useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../components/CustomButton";
import { resetWidgetConfiguration } from "../reducers/widgetTemplateListSlice";
import {
  addWidget,
  apiAddWidget,
  apiUpdateWidget,
  deleteWidget,
  updateWidgetConfiguration,
} from "../reducers/previewPaneSlice";
import { CustomImageInput } from "../components/CustomImageInput";
import useWidgetEssentials from "../hooks/useWidgetEssentials";
import {
  convertDatetoString,
  generateTileBannerWidgetMetaValue,
  getWidgetPreviewOfExperimentalWidget,
  getWidgetSignature,
} from "../utils/widgetUtils";
import {
  API_GET_UPLOAD_URL,
  API_IMAGE_UPLOAD,
} from "../api/configurationScreenServices";
import getCroppedImg from "../components/ImageEdit/getCroppedImg";
import BannerElementDragHandler from "../components/BannerElementDragHandler";
import {
  bannerSettingsConstants,
  casaConstants,
  globalConstants,
} from "../constants/globalConstant";
import analytics from "../utils/analytics";
import cloneDeep from "lodash.clonedeep";
import {
  checkIsSaveDisabled,
  deleteImagesFromS3,
  fetchImageLinks,
  formatItems,
  getImageKeys,
} from "../utils/bannerUtils";
import { countLimit } from "../utils/globalUtils";
import { AddImageForm } from "../components/AddImageForm";
import { widgetTemplateIds, widgetTypes } from "../constants/globalEnums";

export function TilesBannerSettings() {
  const [updatedElementIndex, setupdatedElementIndex] = useState();
  const [removedImageKeys, setRemovedImageKeys] = useState([]);
  const [edit, setEdit] = useState(false);
  const { selectedWidget, selectedWidgetConfiguration, mode, homepageId } =
    useWidgetEssentials();
  const { selectedHomepageId, homepagesById } = useSelector(
    (state) => state.homepage
  );
  const ref = useRef(null);
  const homepage = homepagesById[selectedHomepageId];
  const { hasDraftChanges, status } = homepage;
  const [navigationList, setNavigationList] = useState([
    {
      name: "External Link",
      items: [{ id: "ExternalLink", name: "-----" }],
    },
    {
      name: "Internal Navigation",
      items: [],
    },
  ]);

  const { menu } = selectedWidgetConfiguration.metaData;
  const bannerDefaultValues = {
    items: [],
  };

  const imageConfigurationDefaultValues = {
    zoom: 1,
    rotation: 0,
    crop: { x: 0, y: 0 },
    cropPixels: {},
    navigateTo: {},
    endDate: null,
    externalLink: "",
    externalLinkOptions: "",
    internalLink: {},
    originalImage: null,
    croppedImageURL: null,
    uploadStatus: null,
    imageKey: null,
    imageName: null,
    dynamicForm: {
      entity: "",
      workArea: null,
      form: {},
    },
  };

  const dispatch = useDispatch();
  const { control, handleSubmit, reset, getValues, unregister } = useForm({
    defaultValues: bannerDefaultValues,
  });
  const { fields, append, move, swap, remove, update, replace } = useFieldArray(
    {
      control,
      name: "items",
    }
  );
  const imageConfiguration = useForm({
    defaultValues: imageConfigurationDefaultValues,
  });

  const selectedImage = imageConfiguration.watch(
    bannerSettingsConstants.ORIGINAL_IMAGE
  );
  const setSelectedImage = (img) => {
    imageConfiguration.setValue(bannerSettingsConstants.ORIGINAL_IMAGE, img);
    imageConfiguration.setValue(bannerSettingsConstants.IMAGE_NAME, img.name);
  };

  const widgetId =
    mode === globalConstants.CREATE
      ? bannerSettingsConstants.DEMO_BANNER
      : selectedWidgetConfiguration?.metaValue.widgetId;
  const imageUploadHandler = (event) => {
    const file = event.files[0];
    const imgFile = {
      objectURL: file.objectURL,
      name: file.name,
      size: file.size,
      file: file.type,
      lastModified: file.lastModified,
    };
    setSelectedImage(imgFile);
    event.options.clear();
  };
  const updateTilesBannerInPreviewPane = () => {
    const config = {
      items: getValues("items"),
    };
    const widgetMetaValue = generateTileBannerWidgetMetaValue(config, widgetId);
    dispatch(updateWidgetConfiguration(widgetMetaValue));
  };

  const uploadImageInToBucket = async (imageConfigurationValues, index) => {
    let elementIndex;
    if (index !== undefined) elementIndex = index;
    else if (updatedElementIndex !== undefined)
      elementIndex = updatedElementIndex;
    else elementIndex = fields.length;
    let element = {
      ...imageConfigurationValues,
      uploadStatus: bannerSettingsConstants.PENDING,
    };
    update(elementIndex, element);
    try {
      const { croppedImageURL, imageName, imageKey } = imageConfigurationValues;
      const uploadUrlResponse = await API_GET_UPLOAD_URL(imageKey);
      const uploadUrl = uploadUrlResponse.data.url;
      const signedKey = uploadUrlResponse.data.key;
      const uploadAPIFields = uploadUrlResponse.data.fields;
      const res = await fetch(croppedImageURL);
      const blob = await res.blob();
      const file = new File([blob], imageName, { type: blob.type });
      await API_IMAGE_UPLOAD(uploadUrl, uploadAPIFields, file);
      element = {
        ...imageConfigurationValues,
        uploadStatus: bannerSettingsConstants.SUCCESS,
        imageKey: signedKey,
      };
      update(elementIndex, element);
    } catch (error) {
      element = {
        ...imageConfigurationValues,
        uploadStatus: bannerSettingsConstants.FAILED,
      };
      update(elementIndex, element);
    }
  };

  const onImageSubmit = async (data) => {
    imageConfiguration.reset();
    const istEndDate = convertDatetoString(data.endDate) || null;
    const croppedImageURL = await getCroppedImg(
      data.originalImage.objectURL,
      data.cropPixels,
      data.rotation
    );
    const imageConfigurationValues = {
      ...data,
      croppedImageURL: croppedImageURL,
      endDate: istEndDate,
    };
    if (updatedElementIndex !== undefined) {
      update(updatedElementIndex, imageConfigurationValues);
    } else {
      append(imageConfigurationValues);
    }
    uploadImageInToBucket(imageConfigurationValues);
    updateTilesBannerInPreviewPane();
    setupdatedElementIndex();
  };

  const onSubmit = (data) => {
    if (selectedWidget.isExperimental) {
      getWidgetPreviewOfExperimentalWidget(selectedWidget.imageUrl, dispatch);
      return;
    }
    const config = {
      items: formatItems(data.items),
    };
    const metaValueData = getWidgetSignature(
      homepageId,
      selectedWidget?.id,
      config
    );
    const analytics_data = {
      category: "Widget",
      type: "click",
      widgetType: "tilesbanner",
    };
    if (mode === globalConstants.UPDATE) {
      const updatedMetaData = {
        widgetId: widgetId,
        config: {
          ...metaValueData.config,
        },
      };
      dispatch(apiUpdateWidget(updatedMetaData)).then(({ type }) => {
        if (
          type?.includes("fulfilled") &&
          !(status === casaConstants.PUBLISHED || hasDraftChanges)
        ) {
          deleteImagesFromS3(removedImageKeys);
        }
      });
      analytics_data["widgetId"] = widgetId;
      analytics.sendEvent("Updating_TilesBanner", analytics_data);
    } else if (mode === globalConstants.CREATE) {
      dispatch(apiAddWidget(metaValueData));
      analytics.sendEvent("Adding_TilesBanner", analytics_data);
    }
    dispatch(resetWidgetConfiguration());
  };

  const onElementEdit = (index) => {
    setupdatedElementIndex(index);
    if (fields[index][bannerSettingsConstants.END_DATE]) {
      fields[index][bannerSettingsConstants.END_DATE] = new Date(
        fields[index][bannerSettingsConstants.END_DATE]
      );
    }
    Object.keys(fields[index]).forEach((key) => {
      if (key === "id") return;
      imageConfiguration.setValue(key, fields[index][key]);
    });
  };
  const onElementDeleted = (index) => {
    remove(index);
    updateTilesBannerInPreviewPane();
    if (mode === globalConstants.CREATE) {
      const imageKeys = [fields[index].imageKey];
      deleteImagesFromS3(imageKeys);
    } else if (mode === globalConstants.UPDATE) {
      setRemovedImageKeys((prev) => [...prev, fields[index].imageKey]);
    }
  };

  const onTilesBannerReset = () => {
    reset();
    updateTilesBannerInPreviewPane();
    if (mode === globalConstants.CREATE) {
      const imageKeys = getImageKeys(fields);
      deleteImagesFromS3(imageKeys);
    }
  };

  useEffect(() => {
    let tempNavigationList = [...navigationList];
    if (menu) {
      tempNavigationList[1].items = menu;
    }
    setNavigationList(tempNavigationList);
    if (mode === globalConstants.CREATE) {
      const demoBanner = {
        widgetLayout: {
          type: globalConstants.HALF,
          widgetId: bannerSettingsConstants.DEMO_BANNER,
          widgetType: widgetTypes.TILES_BANNER,
        },
        widgetConfig: {
          homepageId: homepageId,
          widgetTemplateId: widgetTemplateIds.TILES_BANNER,
          config: {
            items: [
              {
                croppedImageURL: bannerSettingsConstants.DEMO_SQUARE_IMAGE,
              },
            ],
          },
        },
      };
      dispatch(addWidget(demoBanner));
    } else if (mode === globalConstants.UPDATE) {
      const { items } = selectedWidgetConfiguration.metaValue.config;
      let updatedItems = cloneDeep(items);
      fetchImageLinks(items).then((images) => {
        images.forEach((image, index) => {
          updatedItems[index][bannerSettingsConstants.CROPPED_IMAGE_URL] =
            image.croppedImageURL;
          updatedItems[index][bannerSettingsConstants.ORIGINAL_IMAGE] = {
            objectURL: image.croppedImageURL,
          };
          updatedItems[index][bannerSettingsConstants.UPLOAD_STATUS] =
            bannerSettingsConstants.SUCCESS;
          updatedItems[index][bannerSettingsConstants.ZOOM] =
            imageConfigurationDefaultValues.zoom;
          updatedItems[index][bannerSettingsConstants.CROP] =
            imageConfigurationDefaultValues.crop;
          updatedItems[index][bannerSettingsConstants.ROTATION] =
            imageConfigurationDefaultValues.rotation;
          updatedItems[index][bannerSettingsConstants.CROP_PIXELS] =
            imageConfigurationDefaultValues.cropPixels;
        });
        replace(updatedItems);
      });
    }
    return () => {
      if (mode === globalConstants.CREATE) {
        dispatch(
          deleteWidget({
            widgetId: bannerSettingsConstants.DEMO_BANNER,
          })
        );
      } else if (mode === globalConstants.UPDATE) {
        const widgetMetaValue = generateTileBannerWidgetMetaValue(
          selectedWidgetConfiguration.metaValue.config,
          widgetId
        );
        dispatch(updateWidgetConfiguration(widgetMetaValue));
      }
    };
  }, []);

  return (
    <>
      {selectedImage !== null ? (
        <AddImageForm
          onImageSubmit={onImageSubmit}
          imageSrc={selectedImage}
          imageConfiguration={imageConfiguration}
          imageAspectRatio={globalConstants.SQUARE}
          imageUploadHandler={imageUploadHandler}
          maxFileSize={countLimit.BANNER_MAX_FILE_SIZE}
          navigationList={navigationList}
          onResetClick={() => {
            imageConfiguration.reset();
            setupdatedElementIndex();
          }}
          unregister={unregister}
          endDateFieldName="endDate"
          endDateRequired={false}
          navigateToRequired={false}
          edit={edit}
          setEdit={setEdit}
        ></AddImageForm>
      ) : (
        <form
          style={{ height: "90%" }}
          className="flex flex-column w-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="overflow-y-auto">
            <div className={`flex flex-column mt-5 mx-5 gap-3`} ref={ref}>
              {fields.length < 4 && (
                <CustomImageInput
                  label="Add Images"
                  imageUploadHandler={imageUploadHandler}
                  maxFileSize={countLimit.BANNER_MAX_FILE_SIZE}
                />
              )}
              <BannerElementDragHandler
                onElementEdit={onElementEdit}
                onElementDeleted={onElementDeleted}
                aspectedRatio={globalConstants.SQUARE}
                elementList={fields}
                move={move}
                swap={swap}
                remove={remove}
                updateBannerPreviewPane={updateTilesBannerInPreviewPane}
                uploadImageInToBucket={uploadImageInToBucket}
                setEdit={setEdit}
                showInFullView={false}
              />
            </div>
          </div>
          <div
            className={`flex w-11 py-2 mt-3 align-self-center align-items-center justify-content-end mt-auto gap-3`}
          >
            <CustomButton
              disabled={fields.length === 0}
              type="reset"
              onClick={onTilesBannerReset}
              varient="text"
              label={globalConstants.RESET}
            />
            <CustomButton
              disabled={checkIsSaveDisabled(fields)}
              type="submit"
              varient="filled"
              label={globalConstants.SAVE}
              data-testid="tiles-banner-save-btn"
            />
          </div>
        </form>
      )}
    </>
  );
}
