import React from "react";
import Text from "../../../components/Text";
import { useSelector, useDispatch } from "react-redux";
import { cloneDeep } from "lodash";
import LsqItemTile from "../../../components/LsqItemTile/LsqItemTile";
import { leadControlsConstants } from "../../../constants/globalConstant";
import { changeLeadActions } from "../../../reducers/leadControlsSlice";

export default function LeadActionsList() {
  const dispatch = useDispatch();
  const { leadActions } = useSelector((state) => state.leadControls);
  const onItemClick = (field) => {
    const clonedSelectedActions = cloneDeep(leadActions.selectedActions);
    clonedSelectedActions.push(field);
    dispatch(
      changeLeadActions({
        ...leadActions,
        selectedActions: clonedSelectedActions,
      })
    );
  };
  return (
    <div className="flex flex-column flex-start h-full">
      <div className="flex p-3">
        <Text type="T2B">{leadControlsConstants.ACTIONS}</Text>
      </div>
      <div
        className={`flex flex-column h-full w-full gap-1 p-3 pt-1 overflow-scroll`}
      >
        {leadActions.actions?.map((field) => {
          return (
            <LsqItemTile
              key={field.id}
              label={field.name}
              hasTileImage={true}
              tileImageName={field?.icon?.imgName}
              onTileClick={() => onItemClick(field)}
              disabled={leadActions.selectedActions?.some(
                (selectedAction) => selectedAction.id === field.id
              )}
            />
          );
        })}
      </div>
    </div>
  );
}
