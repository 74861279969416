import React, { useEffect } from "react";
import CustomInput from "../components/CustomInput";
import {
  globalConstants,
  placeholderConstants,
  stringConstants,
  widgetConstants,
} from "../constants/globalConstant";
import Text from "../components/Text";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { CustomDropdown } from "../components/CustomDropdown/CustomDropdown";
import { listOptionTemplate, valueTemplate } from "../utils/dropdownUtils";
import CustomButton from "../components/CustomButton";
import { useDispatch } from "react-redux";
import useWidgetEssentials from "../hooks/useWidgetEssentials";
import { apiAddWidget, apiUpdateWidget } from "../reducers/previewPaneSlice";
import { resetWidgetConfiguration } from "../reducers/widgetTemplateListSlice";
import { getWidgetPreviewOfExperimentalWidget, getWidgetSignature } from "../utils/widgetUtils";
import analytics from "../utils/analytics";
import { validateWidgetSubmission } from "../utils/globalUtils";

export const LeadMetricsSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { mode, homepageId, selectedWidget, selectedWidgetConfiguration, previewPaneData } =
    useWidgetEssentials();
  const { chartType, dateFilters } = selectedWidgetConfiguration.metaData;

  const defaultValues = {
    title: widgetConstants.LEAD_METRICS_TITLE,
    chartType: { id: "", name: "" },
    dateFilters: { id: "This week", name: "This week" },
  };
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
    setValue,
  } = useForm({ defaultValues });

  const onSubmit = (data) => {
    if(selectedWidget.isExperimental){
      getWidgetPreviewOfExperimentalWidget(selectedWidget.imageUrl, dispatch)
      return;
    }
    if(!validateWidgetSubmission(mode , previewPaneData, dispatch)) return;
    const config = {
      title: data.title.trim(),
      chartType: data?.chartType,
      dateFilters: data?.dateFilters,
    };
    const metaPostValue = getWidgetSignature(
      homepageId,
      selectedWidget.id,
      config
    );
    const analytics_data = {
      category: "Widget",
      type: "click",
      widgetType: "LeadMetrices",
      chart_type: metaPostValue.config.chartType.id,
      date_range: metaPostValue.config.dateFilters.id,
    };

    if (mode === globalConstants.CREATE) {
      dispatch(apiAddWidget(metaPostValue));
      analytics.sendEvent("Adding_leadMetrices", analytics_data);
    } else {
      const updatedMetaValue = {
        widgetId: selectedWidgetConfiguration?.metaValue.widgetId,
        config: {
          ...metaPostValue.config,
        },
      };
      dispatch(apiUpdateWidget(updatedMetaValue));
      const updated_analytics_data = {
        ...analytics_data,
        'widgetId': selectedWidgetConfiguration?.metaValue.widgetId,
      };
      analytics.sendEvent("Updating_leadMetrices", updated_analytics_data);
    }
    dispatch(resetWidgetConfiguration());
  };

  useEffect(() => {
    if (mode === globalConstants.UPDATE) {
      const { title, chartType, dateFilters } =
        selectedWidgetConfiguration.metaValue.config;
      setValue("title", title);
      setValue("chartType", chartType);
      setValue("dateFilters", dateFilters);
    }
  }, []);
  return (
    <form
      className="flex flex-column w-full h-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-column overflow-y-auto mt-5 lg:mx-6 md:mx-5 sm: mx-4 gap-3 flex-1">
        <div className="flex flex-column gap-2">
          <Text type="T4">{t(widgetConstants.WIDGET_TITLE)}</Text>
          <CustomInput
            fieldName={"title"}
            control={control}
            errors={errors}
            label={widgetConstants.DISPLAY_NAME}
            className="lsq-input-bold"
            isMandatory={true}
            data-testid="title"
            maxLength={30}
          />
        </div>
        <div className="flex flex-column gap-2">
          <Text type="T4">{t(widgetConstants.ADDITIONAL_CONFIGURATIONS)}</Text>
          <CustomDropdown
            fieldName={"chartType"}
            label={stringConstants.SELECT_DEFAULT_CHART_TYPE}
            control={control}
            errors={errors}
            isMandatory={true}
            options={chartType}
            optionLabel="name"
            placeholder={placeholderConstants.SELECT}
            className="w-full lsq-input"
            itemTemplate={listOptionTemplate}
            valueTemplate={valueTemplate}
          />
          <CustomDropdown
            fieldName={"dateFilters"}
            label={stringConstants.SELECT_DEFAULT_DATE_RANGE}
            control={control}
            errors={errors}
            options={dateFilters}
            optionLabel="name"
            placeholder={placeholderConstants.SELECT}
            className="w-full lsq-input"
            itemTemplate={listOptionTemplate}
            valueTemplate={valueTemplate}
          />
        </div>
      </div>

      <div className={`flex align-items-center justify-content-end gap-2 p-4`}>
        <CustomButton
          label={globalConstants.RESET}
          type="reset"
          varient="text"
          onClick={() => reset({ defaultValues })}
        />
        <CustomButton
          label={globalConstants.SAVE}
          type="submit"
          varient="filled"
        />
      </div>
    </form>
  );
};
