import React from "react";
import Text from "../../components/Text";
import styles from '../styles.module.css';
import { useTranslation } from "react-i18next";
import { stringConstants } from "../../constants/globalConstant";

export const LeadShortcut = ({ title }) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles["widget-container"]}`}>
      <div className="flex flex-column absolute mt-2 ml-2">
        <Text type="sub-heading-bold" color="var(--text-focused)"> {t(title)}</Text>
      </div>
      <img className="w-full" src={`/images/widgetPreview/lead_shortcut.png`} alt={stringConstants.LEAD_SHORTCUT} />
    </div>
  );
};
