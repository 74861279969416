import React, { useState } from "react";
import styles from "./styles.module.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSelector, useDispatch } from "react-redux";
import cloneDeep from "lodash.clonedeep";
import {
  updateLeadPage,
  updateLeadWidgtesLayout,
} from "../../../reducers/leadControlsSlice";
import DetailedView from "../LeadWidgetsPreview/DetailedView";
import TileView from "../LeadWidgetsPreview/TileView";

export default function LeadWidgetsMobilePreview() {
  const dispatch = useDispatch();
  const { widgetsLayout, selectedLeadControl } = useSelector(
    (state) => state.leadControls
  );
  const [disablePointerEvents, setDisablePointerEvents] = useState(false);
  const [disableDrop, setDisableDrop] = useState(null);
  const [disableDragHandle, setDisableDragHandle] = useState(true);

  const updateLayoutHandler = (newLayout) => {
    dispatch(
      updateLeadPage({
        leadControlId: selectedLeadControl?.id,
        pageId: selectedLeadControl?.leadPage.id,
        postBody: {
          config: {
            type: "widget",
            items: newLayout,
          },
        },
      })
    );
  };

  const onDragEnd = (result) => {
    setDisablePointerEvents(false);
    let clonedWidgetsLayout = cloneDeep(widgetsLayout);
    const source = result.source;
    const destination = result.destination;
    if (!destination) return;
    const newLayout = [...clonedWidgetsLayout];
    if (result.type === "row") {
      const [removed] = newLayout.splice(source.index, 1);
      newLayout.splice(destination.index, 0, removed);
      dispatch(updateLeadWidgtesLayout(newLayout));
    }
    if (result.type === "row-widgets") {
      if (destination.droppableId === source.droppableId) {
        const rowIndex = newLayout.findIndex(
          (row) => String(row.rowId) === destination.droppableId
        );
        const [row] = newLayout.splice(rowIndex, 1);
        const widgets = row.widgets;
        const [removed] = widgets.splice(source.index, 1);
        widgets.splice(destination.index, 0, removed);
        const newRow = { ...row, widgets: widgets };
        newLayout.splice(rowIndex, 0, newRow);
        dispatch(updateLeadWidgtesLayout(newLayout));
      } else {
        const destinationRowIndex = newLayout.findIndex(
          (row) => String(row.rowId) === destination.droppableId
        );
        const sourceRowIndex = newLayout.findIndex(
          (row) => String(row.rowId) === source.droppableId
        );
        const [sourceRow] = newLayout.splice(sourceRowIndex, 1);
        const clone = cloneDeep(sourceRow);
        const sourceWidgets = sourceRow.widgets;
        sourceWidgets.splice(source.index, 1);
        const newSourceRow = { ...sourceRow, widgets: sourceWidgets };
        newLayout.splice(sourceRowIndex, 0, newSourceRow);
        const [destinationRow] = newLayout.splice(destinationRowIndex, 1);
        const destinationWidgets = destinationRow.widgets;
        destinationWidgets.splice(
          destination.index,
          0,
          clone.widgets[source.index]
        );
        const newDestinationRow = {
          ...destinationRow,
          widgets: destinationWidgets,
        };
        newLayout.splice(destinationRowIndex, 0, newDestinationRow);
        if (clone.widgets.length === 1) newLayout.splice(sourceRowIndex, 1);
        dispatch(updateLeadWidgtesLayout(newLayout));
      }
    }
    updateLayoutHandler(newLayout);
    return;
  };
  const onDragStart = (instance) => {
    setDisablePointerEvents(true);
    setDisableDrop(instance.source.droppableId);
  };
  return (
    <div
      className={`flex flex-column align-items-center p-3 gap-2 overflow-scroll ${styles["lead-page-preview-backgrond"]}`}
    >
      <DragDropContext
        onDragEnd={(result) => onDragEnd(result)}
        onDragStart={onDragStart}
      >
        <Droppable type="row" droppableId={`row-widget`} direction="vertical">
          {(provided) => {
            return (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="flex flex-column gap-2 w-full"
              >
                {widgetsLayout?.map((row, index) => {
                  return (
                    <Draggable
                      key={row.rowId}
                      draggableId={`detailed-${row.rowId}`}
                      index={index}
                      isDragDisabled={!row.detailedView || disableDragHandle}
                    >
                      {(provided, snapshot1) => {
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={`flex ${!row.detailedView && "w-full"}`}
                            key={index}
                            onMouseEnter={() => setDisableDragHandle(false)}
                            onMouseLeave={() => setDisableDragHandle(true)}
                          >
                            {provided.placeholder}
                            {row.detailedView ? (
                              <DetailedView
                                id={row.widgets[0]}
                                dragHandleProps={{
                                  ...provided.dragHandleProps,
                                }}
                                isDragging={snapshot1.isDragging}
                                disablePointerEvents={disablePointerEvents}
                              />
                            ) : (
                              <Droppable
                                type="row-widgets"
                                droppableId={String(row.rowId)}
                                direction="horizental"
                                isDropDisabled={
                                  row.widgets.length >= 3 &&
                                  disableDrop !== String(row.rowId)
                                }
                              >
                                {(provided) => {
                                  return (
                                    <div
                                      className={`flex w-full gap-1`}
                                      key={row.rowId}
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                    >
                                      {row.widgets.map((widget, index) => {
                                        return (
                                          <Draggable
                                            key={widget}
                                            draggableId={String(widget)}
                                            className={`flex`}
                                            index={index}
                                            isDragDisabled={disableDragHandle}
                                          >
                                            {(provided, snapshot2) => {
                                              return (
                                                <div
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  key={index}
                                                  className={`flex ${
                                                    !snapshot2.isDragging &&
                                                    "w-full"
                                                  }`}
                                                  onMouseEnter={() =>
                                                    setDisableDragHandle(false)
                                                  }
                                                  onMouseLeave={() =>
                                                    setDisableDragHandle(true)
                                                  }
                                                >
                                                  <TileView
                                                    id={widget}
                                                    dragHandleProps={{
                                                      ...provided.dragHandleProps,
                                                    }}
                                                    tabCount={
                                                      row.widgets.length
                                                    }
                                                    isDragging={
                                                      snapshot2.isDragging
                                                    }
                                                    disablePointerEvents={
                                                      disablePointerEvents
                                                    }
                                                  />
                                                  {provided.placeholder}
                                                </div>
                                              );
                                            }}
                                          </Draggable>
                                        );
                                      })}
                                      {provided.placeholder}
                                    </div>
                                  );
                                }}
                              </Droppable>
                            )}
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
